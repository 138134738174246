import $i18n from '@/locales/i18n';
import React from 'react';
import { calculateTextExprValue, condition, getBizComponent, getBizComponentNameList, getRunTimeBizComponent, isArrayNotEmpty, } from '../util/util';
import { DisplayPosition } from './position/display-position';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import './cell.scss';
import uniq from 'lodash/uniq';
import { __dataSource__, getCommonBoolDataSource, } from '@/common/util/expr-const';
import { dataOriginRequest, dataOriginStatic } from '@/common/util/const';
import { transRequestConfigToRemote } from '@/common/util/request';
const { ActionSetter } = window.VisualEngineUtils || {};
function renderEnumOne(enumMap, value) {
    if (value !== undefined) {
        const label = enumMap[value];
        if (label !== undefined) {
            return label;
        }
    }
    else {
        return '';
    }
    return value;
}
const tag = {
    title: $i18n.get({ id: '31255238841339904.CNTM', dm: '标签' }),
    position: DisplayPosition.tableCell,
    componentName: 'tag',
};
const enumeration = {
    componentName: 'enumeration',
    title: $i18n.get({ id: 'Enumeration', dm: '枚举' }),
    position: DisplayPosition.tableCell,
    beforeHandler: (props, config) => {
        const { _context } = config || {};
        const { format, options } = props || {};
        if (format === 'enumeration') {
            const { enumDataOrigin, enumRequestConfig, enumDataSource } = options || {};
            if (enumDataOrigin === dataOriginRequest && enumRequestConfig) {
                props.options = {
                    requestConfig: transRequestConfigToRemote({
                        requestConfig: enumRequestConfig,
                        state: _context?.state,
                        needFormatResult: true,
                    }),
                };
            }
            else if (enumDataOrigin === dataOriginStatic) {
                const realDataSource = calculateTextExprValue(enumDataSource, {
                    state: _context?.state,
                    recordDataSource: _context?.state,
                });
                if (isArrayNotEmpty(realDataSource)) {
                    props.options = {
                        dataSource: realDataSource,
                    };
                }
            }
        }
    },
    component: (props) => {
        let result;
        const { value, enumMap = {} } = props;
        if (Array.isArray(value)) {
            result = value
                .map((item) => renderEnumOne(enumMap, item))
                .filter((item) => item !== undefined && item !== '')
                .join(', ');
        }
        else {
            result = renderEnumOne(enumMap, value);
        }
        return <div style={{ display: 'flex', flexWrap: 'noWrap' }}>{result}</div>;
    },
    getDefaultProps: () => {
        return {
            enumDataOrigin: 'request',
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'enumDataOrigin',
                title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'RemoteRequest', dm: '远程请求' }),
                                value: 'request',
                            },
                            {
                                title: $i18n.get({ id: 'StaticData', dm: '静态数据' }),
                                value: 'static',
                            },
                        ],
                    },
                },
            },
            {
                name: 'enumRequestConfig',
                title: $i18n.get({ id: 'QueryService', dm: '查询服务' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        mockDataTemplate: {
                            success: true,
                            data: [
                                {
                                    label: $i18n.get({ id: 'OptionOne', dm: '选项一' }),
                                    value: 'first',
                                },
                                {
                                    label: $i18n.get({ id: 'Option2.NBzVe', dm: '选项二' }),
                                    value: 'second',
                                },
                            ],
                        },
                        paramTitleDom: (<div className="">
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}
              </div>),
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                        },
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    {
                                        componentName: 'ParamSelectSetter',
                                        props: {
                                            dataKey: 'aa',
                                            labelKey: 'aa',
                                            valueKey: 'aa',
                                            groupName: 'aa',
                                        },
                                        title: $i18n.get({
                                            id: 'SelectParameters',
                                            dm: '选择参数',
                                        }),
                                    },
                                    {
                                        componentName: 'StringSetter',
                                        title: $i18n.get({ id: 'String', dm: '字符串' }),
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                        resultProcessFuncTemplate: $i18n.get({
                            id: 'FunctionresTheFollowingDataStruc_958986499',
                            dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: [\n  //     {\n  //        label:"xx",\n  //        value:"xx",\n  //     }\n  //   ]\n  // }\n return res;\n}',
                        }),
                    },
                },
                condition(prop) {
                    return (condition(prop, 'enumeration', 'format') &&
                        prop?.parent?.getPropValue?.('enumDataOrigin') === 'request');
                },
            },
            {
                name: 'enumDataSource',
                title: $i18n.get({ id: 'EditStaticData', dm: '编辑静态数据' }),
                display: 'plain',
                tip: $i18n.get({
                    id: 'EditStaticPresentationData',
                    dm: '编辑静态展示数据',
                }),
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'ArraySetter',
                                title: $i18n.get({ id: 'SetStaticData', dm: '设置静态数据' }),
                                props: {
                                    mode: 'list',
                                    itemSetter: {
                                        componentName: 'ObjectSetter',
                                        initialValue: {
                                            label: $i18n.get({ id: 'Name.IL5MN', dm: '名称' }),
                                            value: 'jack',
                                        },
                                        props: {
                                            config: {
                                                items: [
                                                    {
                                                        name: 'label',
                                                        display: 'inline',
                                                        title: $i18n.get({
                                                            id: '31255238841339904.CNTM',
                                                            dm: '标签',
                                                        }),
                                                        isRequired: true,
                                                        setter: 'CnI18nSetter',
                                                    },
                                                    {
                                                        name: 'value',
                                                        display: 'inline',
                                                        title: $i18n.get({ id: 'Value', dm: '值' }),
                                                        isRequired: true,
                                                        setter: {
                                                            componentName: 'MixedSetter',
                                                            props: {
                                                                setters: [
                                                                    {
                                                                        componentName: 'StringSetter',
                                                                        title: $i18n.get({
                                                                            id: 'String',
                                                                            dm: '字符串',
                                                                        }),
                                                                    },
                                                                    {
                                                                        componentName: 'NumberSetter',
                                                                        title: $i18n.get({
                                                                            id: 'Numbers',
                                                                            dm: '数字',
                                                                        }),
                                                                    },
                                                                    {
                                                                        componentName: 'CnSelectSetter',
                                                                        props: {
                                                                            options: getCommonBoolDataSource(),
                                                                            selectProps: {
                                                                                hasClear: true,
                                                                            },
                                                                        },
                                                                        title: $i18n.get({
                                                                            id: 'BooleanTruefalse',
                                                                            dm: '布尔（true/false）',
                                                                        }),
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                componentName: 'ParamSelectSetter',
                                props: {
                                    configList: [
                                        {
                                            groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                            groupExprName: __dataSource__,
                                            needSecondParam: true,
                                        },
                                    ],
                                },
                                title: $i18n.get({
                                    id: 'SelectFromDataSource',
                                    dm: '从数据源选择',
                                }),
                            },
                            {
                                componentName: (<ActionSetter defaultCode={$i18n.get({
                                        id: 'GetStaticEnumDataSourceFunctionG_1638947271',
                                        dm: "/**\n * getStaticEnumDataSource\n */\nfunction getStaticEnumDataSource() {\n  // 需要返回数组，格式如下\n  return [\n    {\n      label: '选项一',\n      value: 'first'\n    }\n  ];\n}",
                                    })} defaultActionName="getStaticEnumDataSource"/>),
                                title: $i18n.get({ id: 'JSCodeEditing', dm: 'JS代码编辑' }),
                            },
                            getJSExpressionPrototype({
                                type: 'select',
                                offlineTip: $i18n.get({
                                    id: 'JSCodeEditingUseDynamicControlDa_642285703',
                                    dm: '动态控制数据推荐使用「JS代码编辑」设置该属性',
                                }),
                            }),
                        ],
                    },
                },
                condition(prop) {
                    return (condition(prop, 'enumeration', 'format') &&
                        prop?.parent?.getPropValue?.('enumDataOrigin') === 'static');
                },
            },
        ];
    },
};
export const allCellMap = {
    text: {
        componentName: 'text',
        title: $i18n.get({ id: 'Text', dm: '文本' }),
        position: DisplayPosition.tableCell,
    },
    ellipsis: {
        componentName: 'ellipsis',
        title: $i18n.get({ id: 'TextoverflowOmission', dm: '文本-溢出省略' }),
        position: DisplayPosition.tableCell,
    },
    number: {
        componentName: 'number',
        title: $i18n.get({ id: 'Numbers', dm: '数字' }),
        position: DisplayPosition.tableCell,
    },
    currency: {
        componentName: 'currency',
        title: $i18n.get({ id: 'AmountShow', dm: '金额展示' }),
        position: DisplayPosition.tableCell,
    },
    'currency-with-unit': {
        componentName: 'currency-with-unit',
        title: $i18n.get({
            id: 'AmountPresentationWithCurrency',
            dm: '金额展示（带币种）',
        }),
        position: DisplayPosition.tableCell,
    },
    percent: {
        componentName: 'percent',
        title: $i18n.get({ id: 'Percentage', dm: '百分比' }),
        position: DisplayPosition.tableCell,
    },
    bank: {
        componentName: 'bank',
        title: $i18n.get({ id: 'BankCardNumber', dm: '银行卡号' }),
        position: DisplayPosition.tableCell,
    },
    date: {
        componentName: 'date',
        title: $i18n.get({ id: 'Date', dm: '日期' }),
        position: DisplayPosition.tableCell,
    },
    'date-time': {
        componentName: 'date-time',
        title: $i18n.get({ id: 'Time', dm: '时间' }),
        position: DisplayPosition.tableCell,
    },
    tag,
    progress: {
        componentName: 'progress',
        title: $i18n.get({ id: 'ProgressBar', dm: '进度条' }),
        position: DisplayPosition.tableCell,
    },
    enumeration,
    user: {
        componentName: 'user',
        title: $i18n.get({ id: 'Personnel', dm: '人员' }),
        position: DisplayPosition.tableCell,
    },
    'multiple-text': {
        componentName: 'multiple-text',
        title: $i18n.get({ id: 'MultipleLinesOfText', dm: '多行文本' }),
        position: DisplayPosition.tableCell,
    },
    'image-text': {
        componentName: 'image-text',
        title: $i18n.get({ id: 'Graphic', dm: '图文' }),
        position: DisplayPosition.tableCell,
    },
    file: {
        componentName: 'file',
        title: $i18n.get({ id: 'FilePreview', dm: '文件预览' }),
        position: DisplayPosition.tableCell,
    },
};
// 获取cell列表
export function getCellList() {
    const defaultCellList = Object.keys(allCellMap);
    const bizComponentNameList = getBizComponentNameList();
    const allCellList = uniq([...defaultCellList, ...bizComponentNameList]);
    const result = [];
    allCellList.forEach((name) => {
        const component = getCell(name);
        if (component) {
            const { bizInfo = [] } = component;
            if (bizInfo.length > 0) {
                bizInfo.forEach((item) => {
                    const { label, value } = item;
                    const existGroup = result.find((item2) => item2.value === value);
                    if (existGroup) {
                        existGroup?.children.push(component);
                    }
                    else {
                        result.push({
                            title: label,
                            value,
                            children: [component],
                        });
                    }
                });
                return;
            }
            result.push(component);
        }
    });
    return result;
}
export function getCell(format, propKey) {
    let result;
    if (format) {
        let item = allCellMap[format];
        if (!item) {
            item = getBizComponent(format, DisplayPosition.tableCell);
        }
        if (item) {
            if (!item.label) {
                item.label = item.title;
            }
            if (!item.value) {
                item.value = item.componentName;
            }
            if (propKey) {
                result = item[propKey];
            }
            else {
                result = item;
            }
        }
    }
    return result;
}
// 获取button的node
export function getRunTimeCell(format) {
    let result;
    if (format) {
        result = allCellMap[format];
        if (!result) {
            result = getRunTimeBizComponent(format);
        }
    }
    return result;
}
