import React from 'react';
import { CnCardSubCard as UICnCardSubCard } from '@cainiaofe/cn-ui';
import { executeFunction, isArrayNotEmpty, makeButtons } from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';

const CnCardSubCard = (props) => {
  const { subTitle, subDesc, cols, children, buttons, _context } = props;
  const state = _context?.state;
  let action;
  if (isArrayNotEmpty(buttons)) {
    action = makeButtons({
      buttons: buttons?.map((item) => {
        let realChildren = item?.children;
        if (typeof item?.children === 'function') {
          realChildren = executeFunction(item.children, {}, state);
        }
        return {
          ...item,
          style: { marginLeft: '8px' },
          size: item.text ? null : 'small',
          position: ButtonPosition.subCardActionNotInForm,
          children: realChildren,
        };
      }),
      _context,
      state,
      recordDataSource: {},
    });
  }

  return (
    <UICnCardSubCard
      subTitle={subTitle}
      subDesc={subDesc}
      cols={cols}
      action={action}
    >
      {children}
    </UICnCardSubCard>
  );
};
CnCardSubCard.displayName = 'CnCardSubCard';

export default CnCardSubCard;

export { CnCardSubCard };
