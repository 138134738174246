import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { isArrayNotEmpty, uuid } from '../../util/util';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { FormItemSlot: { title, thumbUrl } } = FORM_ITEM_INFO;
const FormItemSlot = {
    position: [
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    thumbUrl,
    componentName: 'FormItemSlot',
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig, isDesign } = config || {};
        const { options } = formItemConfig || {};
        const { componentSlot } = options || {};
        const componentProps = formItem?.['x-component-props'];
        if (componentProps) {
            componentProps.getComponentSlot = () => {
                return componentSlot;
            };
            componentProps.componentSlot = undefined;
            if (isDesign) {
                componentProps._designStamp = Date.now();
            }
        }
    },
    formComponent: (props) => {
        const { getComponentSlot, useDetailValue } = props || {};
        const componentSlot = getComponentSlot?.();
        if (isArrayNotEmpty(componentSlot)) {
            return (<div>
          {componentSlot.map((item, index) => {
                    return React.cloneElement(item, {
                        value: props?.value,
                        onChange: props?.onChange,
                        key: index,
                        useDetailValue,
                        isFromFormItem: true,
                    });
                })}
        </div>);
        }
        return <div>{componentSlot}</div>;
    },
    getFormItemDefaultProps: () => {
        return {
            label: $i18n.get({ id: 'Table', dm: '表格' }),
            name: `formItemSlot_${uuid()}`,
        };
    },
    getDefaultProps: () => {
        return {
            useDetailValue: false,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'componentSlot',
                title: $i18n.get({ id: 'OpenSlot', dm: '开启插槽' }),
                setter: {
                    componentName: 'SlotSetter',
                    title: $i18n.get({ id: 'ComponentPit', dm: '组件坑位' }),
                    initialValue: {
                        type: 'JSSlot',
                        value: [],
                    },
                },
            },
            {
                name: 'useDetailValue',
                title: $i18n.get({ id: 'UseDetailedData', dm: '使用详细数据' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: $i18n.get({ id: 'Yes', dm: '是' }), value: true },
                            { title: $i18n.get({ id: 'No', dm: '否' }), value: false },
                            { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
                        ],
                    },
                },
            },
        ];
    },
};
export default FormItemSlot;
