import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import { CnRating as UIRating } from '@cainiaofe/cn-ui';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { Rating: { title, thumbUrl } } = FORM_ITEM_INFO;
const Rating = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    componentName: 'Rating',
    thumbUrl,
    component: UIRating,
    formComponent: UIRating,
    getDefaultProps: () => {
        return {
            count: 5,
            allowClear: true,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'allowHalf',
                title: $i18n.get({ id: 'AllowHalfstarRating', dm: '允许半星评分' }),
                display: 'inline',
                setter: 'BoolSetter',
            },
            {
                name: 'count',
                title: $i18n.get({ id: 'TotalRating', dm: '评分总数' }),
                display: 'inline',
                setter: 'NumberSetter',
            },
        ];
    },
};
export default Rating;
