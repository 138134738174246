import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { uuid } from '@/common/util/util';
import { arrayTableOperateHidden } from '../common-style/array-table-operate-hidden';
import { DisplayPosition } from '../../position/display-position';
import { ossImport } from '../oss-import';
export const createFooterButtonListSetters = () => {
    const tempButtonList = [ossImport];
    const styleConfigure = {
        onecodeDisplay: 'visible',
        title: $i18n.get({ id: 'Style', dm: '样式' }),
        type: 'group',
        display: 'accordion',
        collapsed: true,
        items: [arrayTableOperateHidden(DisplayPosition.cnArrayTable)],
    };
    const items = [
        {
            name: 'primaryKey',
            title: $i18n.get({ id: 'UniqueIdentification', dm: '唯一标识' }),
            condition: {
                type: 'JSFunction',
                value: '() => false',
            },
            initialValue: {
                type: 'JSFunction',
                value: 'val => {\n                        if (val) return val;\n                        return String(Math.floor(Math.random() * 10000));\n                      }',
            },
            setter: 'StringSetter',
        },
        {
            onecodeDisplay: 'visible',
            name: 'optType',
            title: $i18n.get({ id: 'ButtonFunction', dm: '按钮功能' }),
            isRequired: true,
            display: 'inline',
            editable: true,
            setter: {
                componentName: 'CnSelectSetter',
                props: () => {
                    return {
                        options: tempButtonList,
                    };
                },
            },
        },
        {
            name: 'options',
            display: 'block',
            title: $i18n.get({ id: 'ButtonConfigurationItems', dm: '按钮配置项' }),
            extraProps: {
                defaultCollapsed: false,
            },
            setter: {
                componentName: 'ObjectSetter',
                props: () => {
                    return {
                        config: {
                            items: tempButtonList[0].getPrototypeList(),
                        },
                    };
                },
            },
        },
        styleConfigure,
    ];
    return {
        componentName: 'ArraySetter',
        props: {
            mode: 'list',
            itemSetter: {
                componentName: 'ObjectSetter',
                initialValue() {
                    return {
                        primaryKey: uuid(6),
                        optType: tempButtonList[0].value,
                    };
                },
                props: {
                    config: {
                        items,
                    },
                },
            },
        },
    };
};
