import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { createFormItemSetters } from '@/lowcode/cn-form-alc/prototype/form-item';
import { makeFilterItem, makeFormItemSchema, uuid } from '@/common/util/util';
import isPlainObject from 'lodash/isPlainObject';
import { CnFilterItem as UICnFilterItem, componentMap as formComponentMap, } from '@cainiaofe/cn-ui';
import './compose.scss';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { Compose: { title, thumbUrl } } = FORM_ITEM_INFO;
const Compose = {
    position: [
        DisplayPosition.filter,
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'Compose',
    component: 'div',
    formComponent: formComponentMap.CnFormGrid,
    filterItemBeforeHandler: (filterItemProps) => {
        if (filterItemProps) {
            filterItemProps.name = undefined;
            filterItemProps.className = 'cn-filter-item-compose';
        }
    },
    formItemBeforeHandler: (formItem, _config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, isDesign, usedComponentList, } = _config || {};
        if (formItem) {
            const { options = {} } = formItemConfig;
            const { config = [] } = options;
            const componentProps = formItem['x-component-props'];
            const configLength = Array.isArray(config) ? config.length : 0;
            if (isPlainObject(componentProps)) {
                componentProps.config = undefined;
                componentProps.hasClear = undefined;
                if (configLength) {
                    componentProps.cols = config.length;
                }
            }
            formItem.properties = {};
            if (configLength) {
                config.forEach((item) => {
                    const { name } = item || {};
                    const gridFormItem = makeFormItemSchema({
                        formItemConfig: item,
                        isDesign,
                        urlParams,
                        formValue,
                        state,
                        usedComponentList,
                        formProps,
                        _context,
                        formInstance,
                    });
                    if (gridFormItem?.['x-decorator-props']) {
                        gridFormItem['x-decorator-props'].labelCol = {
                            hidden: true,
                        };
                    }
                    formItem.properties[name] = gridFormItem;
                });
            }
        }
    },
    getFormItemDefaultProps: () => {
        return {
            label: $i18n.get({ id: 'ComboBox', dm: '组合框' }),
            name: `compose_${uuid()}`,
        };
    },
    getRenderDom: (iconfig) => {
        const { filterItemConfig, _context } = iconfig || {};
        const { options } = filterItemConfig || {};
        const { config } = options || {};
        if (Array.isArray(config) && config.length > 0) {
            const result = [];
            config.forEach((item, index) => {
                const { name } = item || {};
                if (name) {
                    const filterItemResult = makeFilterItem({
                        ...iconfig,
                        filterItemConfig: item,
                    });
                    if (isPlainObject(filterItemResult)) {
                        const { filterItemProps, component, componentProps } = filterItemResult;
                        let realDom;
                        if (component) {
                            realDom = (<UICnFilterItem key={index} {...filterItemProps} label={undefined} style={{ width: '100%' }}>
                  {React.createElement(component, {
                                    ...componentProps,
                                    name,
                                    _context,
                                })}
                </UICnFilterItem>);
                        }
                        if (realDom) {
                            result.push(realDom);
                        }
                    }
                }
            });
            if (result.length > 0) {
                return (<div className="cn-filter-item-compose-form-item-control">
            {result}
          </div>);
            }
        }
    },
    getPrototypeList: (position) => {
        return [
            {
                name: 'config',
                title: $i18n.get({ id: 'FilterItemConfiguration', dm: '筛选项配置' }),
                setter: createFormItemSetters({ position }),
            },
        ];
    },
};
export default Compose;
