import $i18n from '@/locales/i18n';
import { CnDepartmentSelect } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { condition } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';

const { DepartmentSelect: { title, thumbUrl } } = FORM_ITEM_INFO;

const DepartmentSelect = {
  position: [
    DisplayPosition.form,
    DisplayPosition.filter,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  title,
  componentName: 'DepartmentSelect',
  thumbUrl,
  component: CnDepartmentSelect,
  formComponent: CnDepartmentSelect,
  getDefaultProps: () => {
    return {
      multiple: false,
      showSearch: true,
    };
  },
  // select的prototype列表
  getPrototypeList: () => {
    return [
      {
        name: 'multiple',
        title: $i18n.get({ id: 'SelectMode', dm: '选择模式' }),
        display: 'inline',
        // supportVariable: true,
        // hidden:hidden('DepartmentSelect'),
        condition(prop) {
          return condition(prop, 'DepartmentSelect', 'componentName');
        },
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({ id: 'SingleSelection', dm: '单选' }),
                value: false,
              },
              {
                title: $i18n.get({ id: 'MultipleChoice', dm: '多选' }),
                value: true,
              },
            ],
          },
        },
      },
      {
        name: 'showSearch',
        title: $i18n.get({ id: 'SupportSelection', dm: '支持选择' }),
        // supportVariable: true,
        // hidden:hidden('DepartmentSelect'),
        condition() {
          return false;
        },
        setter: 'BoolSetter',
      },
      {
        name: 'mokelay',
        display: 'inline',
        title: $i18n.get({ id: 'WhetherCentralized', dm: '是否中心化' }),
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({ id: 'Yes', dm: '是' }),
                value: true,
              },
              {
                title: $i18n.get({ id: 'No', dm: '否' }),
                value: false,
              },
              {
                title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }),
              },
            ],
          },
        },
      },
    ];
  },
};
export default DepartmentSelect;
