import React from 'react';
export function cnBizComponentHoc(WrappedComponent) {
    class CnBizComponentHoc extends React.Component {
        constructor(props) {
            super(props);
            this.ref = React.createRef();
        }
        load = () => {
            this.ref?.current?.load?.();
        };
        reRender = () => {
            this.ref?.current?.reRender?.();
        };
        render() {
            return <WrappedComponent ref={this.ref} {...this.props}/>;
        }
    }
    return CnBizComponentHoc;
}
