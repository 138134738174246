import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import { UploadCom } from './components/upload';
// eslint-disable-next-line import/no-cycle
import { getJSExpressionPrototype } from '../common-style';
import { handleRequestParams, handleResultProcessFunc, transRequestConfigToRemote, } from '../../util/request';
import { __advancedConfig__ } from '@/common/util/const';
import { getRealRequestUrl, handleRequestExtraConfig, setAdvancedConfigToProps, } from '@/common/util/util';
import { getRequestConfigSetterSnippet } from '@/common/manager/setter-snippet';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { Upload: { title, thumbUrl } } = FORM_ITEM_INFO;
const Upload = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    componentName: 'Upload',
    thumbUrl,
    component: UploadCom,
    formComponent: UploadCom,
    getDefaultProps: () => {
        return {
            mode: 'oss',
            useDetailValue: false,
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { state, formValue, urlParams, formProps } = config;
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                const { uploadProps, webOfficeEnable, webOfficeEditRequestConfig, webOfficePreviewRequestConfig, } = componentProps;
                if (uploadProps && Array.isArray(uploadProps.accept)) {
                    uploadProps.accept = uploadProps.accept.join(',');
                }
                if (webOfficeEnable === true) {
                    componentProps.webOfficeEditRequestConfig =
                        transRequestConfigToRemote({
                            requestConfig: webOfficeEditRequestConfig,
                            urlParamsDataSource: urlParams,
                            recordDataSource: formValue,
                            state,
                        });
                    componentProps.webOfficePreviewRequestConfig =
                        transRequestConfigToRemote({
                            requestConfig: webOfficePreviewRequestConfig,
                            urlParamsDataSource: urlParams,
                            recordDataSource: formValue,
                            state,
                        });
                }
                else {
                    delete componentProps.webOfficeEditRequestConfig;
                    delete componentProps.webOfficePreviewRequestConfig;
                }
                if (componentProps.requestConfig) {
                    handleRequestExtraConfig(componentProps.requestConfig, state);
                    const { method, serviceType, params, resultProcessFunc, headers, __extraConfig__, } = componentProps.requestConfig;
                    const url = getRealRequestUrl({
                        requestConfig: componentProps.requestConfig,
                        state,
                    });
                    if (url) {
                        const requestConfig = { url, headers };
                        const { withCredentials } = __extraConfig__ || {};
                        if (withCredentials === true) {
                            requestConfig.withCredentials = true;
                            // 2023.3.23 文件上传组件升级后，获取凭证和推送文件两个请求拆分为两个requestConfig
                            componentProps.ossUploadConfig = { withCredentials: true };
                        }
                        if (serviceType === 'mokelay') {
                            requestConfig.method = 'post';
                        }
                        else if (method) {
                            requestConfig.method = method;
                        }
                        requestConfig.formatParam = (oldParams) => {
                            const realParams = handleRequestParams(params, {
                                urlParamsDataSource: urlParams,
                                recordDataSource: formValue,
                                state,
                            });
                            return {
                                ...oldParams,
                                ...realParams,
                            };
                        };
                        if (typeof resultProcessFunc === 'function') {
                            requestConfig.formatResult = handleResultProcessFunc(componentProps.requestConfig);
                        }
                        componentProps.requestConfig = requestConfig;
                    }
                }
                setAdvancedConfigToProps(componentProps);
                formItem['x-component-props'] = {
                    _dataSourceName: formProps?._dataSourceName,
                    ...componentProps,
                    ...uploadProps,
                };
            }
        }
    },
    // filterItemBeforeHandler: (filterItemProps, componentProps)=> {
    //   if(componentProps) {
    //     const { requestConfig } = componentProps;
    //     if(requestConfig){
    //       const { url, method, serviceType } = requestConfig
    //       if(url) {
    //         componentProps.action = url;
    //       }
    //       if(serviceType === 'mokelay') {
    //         componentProps.method = 'post'
    //       }else if(method){
    //         componentProps.method = method;
    //       }
    //     }
    //     delete componentProps.requestConfig;
    //   }
    // },
    getPrototypeList: () => {
        const extraConfigSetter = [
            {
                name: 'withCredentials',
                title: $i18n.get({
                    id: 'CrossdomainWithCookiesWithCreden_664067675',
                    dm: '跨域带cookie（withCredentials）',
                }),
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'BoolSetter',
                                title: $i18n.get({ id: 'YesNo', dm: '是 / 否' }),
                            },
                        ],
                    },
                },
            },
        ];
        return [
            {
                name: 'mode',
                title: $i18n.get({ id: 'UploadMode', dm: '上传模式' }),
                // display: 'inline',
                condition() {
                    return false;
                },
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                value: 'java',
                                title: $i18n.get({
                                    id: 'UploadToBackendService',
                                    dm: '上传到后端服务',
                                }),
                            },
                            {
                                value: 'oss',
                                title: $i18n.get({
                                    id: 'UploadTheFrontendDirectlyToOss',
                                    dm: '前端直接上传到oss',
                                }),
                            },
                        ],
                    },
                },
            },
            {
                name: '',
                setter: 'UploadEnhanceSetter',
            },
            {
                name: 'requestConfig',
                title: $i18n.get({ id: 'UploadAddress', dm: '上传地址' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramTitleDom: (<div className="">
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}
              </div>),
                        responseDom: (<div style={{ paddingTop: '10px' }}>
                {$i18n.get({
                                id: 'DataStructureOfTheRequestReturnR_137379149',
                                dm: '请求返回结果的数据结构：',
                            })}

                <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/EGd6jK4Nvk3JlZkYDBPnWZOP0LawMmQq" rel="noreferrer">
                  {$i18n.get({
                                id: 'DataStructureDocument',
                                dm: '数据结构文档',
                            })}
                </a>
              </div>),
                        extraConfigSetter,
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    {
                                        componentName: 'ParamSelectSetter',
                                        props: {
                                            dataKey: 'config',
                                            labelKey: 'label',
                                            valueKey: 'name',
                                            groupName: $i18n.get({
                                                id: 'ListOfParameters',
                                                dm: '参数列表',
                                            }),
                                        },
                                        title: $i18n.get({
                                            id: 'SelectParameters',
                                            dm: '选择参数',
                                        }),
                                    },
                                    {
                                        componentName: 'StringSetter',
                                        title: $i18n.get({ id: 'String', dm: '字符串' }),
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                        resultProcessFuncTemplate: $i18n.get({
                            id: 'FunctionresTheFollowingDataStruc_1638526278',
                            dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: [\n  //     {\n  //        host:"xx",\n  //        key:"xx",\n  //        name:"xx",\n  //        ossAccessKeyId:"xx",  // 对应 OSSAccessKeyId\n  //        policy:"xx",\n  //        signature:"xx",\n  //        expire:"xx",\n  //        success_action_status:"xx",\n  //     }\n  //   ]\n  // }\n return res;\n}',
                        }),
                    },
                },
            },
            {
                name: 'uploadProps',
                display: 'plain',
                title: $i18n.get({ id: 'UploadProps', dm: '上传props' }),
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'limit',
                                    title: $i18n.get({
                                        id: 'MaximumNumberOfFilesUploaded',
                                        dm: '最大文件上传个数',
                                    }),
                                    setter: 'NumberSetter',
                                },
                                {
                                    name: 'multiple',
                                    title: $i18n.get({
                                        id: 'SupportMultipleFileSelection',
                                        dm: '支持多选文件',
                                    }),
                                    setter: 'BoolSetter',
                                },
                                // {
                                //   name: 'name',
                                //   title: '发到后台的文件参数名',
                                //   setter: {
                                //     componentName: 'StringSetter',
                                //     props: {
                                //       placeholder: '默认是file'
                                //     }
                                //   },
                                // },
                                {
                                    name: 'accept',
                                    title: $i18n.get({
                                        id: 'AcceptFileTypesForUpload',
                                        dm: '接受上传的文件类型',
                                    }),
                                    setter: {
                                        componentName: 'MixedSetter',
                                        props: {
                                            setters: [
                                                {
                                                    componentName: 'CnSelectSetter',
                                                    props: {
                                                        mode: 'multiple',
                                                        options: [
                                                            {
                                                                value: 'image/*',
                                                                title: $i18n.get({
                                                                    id: 'ImageImage',
                                                                    dm: '图片（image/*）',
                                                                }),
                                                            },
                                                            {
                                                                value: '.png',
                                                                title: $i18n.get({
                                                                    id: 'PngImagePng',
                                                                    dm: 'png图片（.png）',
                                                                }),
                                                            },
                                                            {
                                                                value: '.jpg',
                                                                title: $i18n.get({
                                                                    id: 'JPGImageJpg',
                                                                    dm: 'jpg图片（.jpg）',
                                                                }),
                                                            },
                                                            {
                                                                value: '.jpeg',
                                                                title: $i18n.get({
                                                                    id: 'JpegPictureJpeg',
                                                                    dm: 'jpeg图片（.jpeg）',
                                                                }),
                                                            },
                                                            {
                                                                value: '.pdf',
                                                                title: $i18n.get({
                                                                    id: 'PDFFilePdf',
                                                                    dm: 'pdf文件（.pdf）',
                                                                }),
                                                            },
                                                            {
                                                                value: '.doc',
                                                                title: $i18n.get({
                                                                    id: 'DocFileDoc',
                                                                    dm: 'doc文件（.doc）',
                                                                }),
                                                            },
                                                            {
                                                                value: '.zip',
                                                                title: $i18n.get({
                                                                    id: 'ZipCompressedFileZip',
                                                                    dm: 'zip压缩文件（.zip）',
                                                                }),
                                                            },
                                                            {
                                                                value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                                                                title: $i18n.get({
                                                                    id: 'ExcelFile',
                                                                    dm: 'excel文件',
                                                                }),
                                                            },
                                                            {
                                                                value: '.csv',
                                                                title: $i18n.get({
                                                                    id: 'CsvFileCsv',
                                                                    dm: 'csv文件（.csv）',
                                                                }),
                                                            },
                                                            {
                                                                value: 'audio/*',
                                                                title: $i18n.get({
                                                                    id: 'AudioAudio',
                                                                    dm: '音频（audio/*）',
                                                                }),
                                                            },
                                                            {
                                                                value: 'video/*',
                                                                title: $i18n.get({
                                                                    id: 'VideoVideo',
                                                                    dm: '视频（video/*）',
                                                                }),
                                                            },
                                                        ],
                                                        selectProps: {
                                                            hasClear: true,
                                                        },
                                                    },
                                                    title: $i18n.get({
                                                        id: 'CommonFormats',
                                                        dm: '常用格式',
                                                    }),
                                                },
                                                {
                                                    componentName: 'StringSetter',
                                                    title: $i18n.get({
                                                        id: 'HandwrittenAccept',
                                                        dm: '手写accept',
                                                    }),
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: 'description',
                                    title: $i18n.get({ id: 'HelpInformation', dm: '帮助信息' }),
                                    setter: 'CnI18nSetter',
                                },
                            ],
                        },
                    },
                },
            },
            {
                name: 'webOfficeEnable',
                title: $i18n.get({
                    id: 'EnableTheAliYunWebOfficeService',
                    dm: '开启AliYunWebOffice服务',
                }),
                setter: 'BoolSetter',
            },
            {
                display: 'none',
                setter: {
                    componentName: 'DocSetter',
                    props: {
                        content: (<a target={'_blank'} href={'https://alidocs.dingtalk.com/i/nodes/ZK0RbznqyYaWdQgoodjBV953mBE4AdDw?dontjump=true'} rel="noreferrer">
                {$i18n.get({
                                id: 'AlibabaCloudWebOfficeServiceAcce_1623905608',
                                dm: '阿里云WebOffice服务接入手册',
                            })}
              </a>),
                    },
                },
            },
            getRequestConfigSetterSnippet({
                name: 'webOfficeEditRequestConfig',
                title: $i18n.get({
                    id: 'WebOfficeOnlineEditCredentialReq_307722357',
                    dm: 'WebOffice在线编辑凭证请求配置',
                }),
                condition: (prop) => {
                    return prop?.parent?.getPropValue?.('webOfficeEnable') === true;
                },
            }),
            getRequestConfigSetterSnippet({
                name: 'webOfficePreviewRequestConfig',
                title: $i18n.get({
                    id: 'WebOfficeOnlinePreviewCredential_42451345',
                    dm: 'WebOffice在线预览凭证请求配置',
                }),
                condition: (prop) => {
                    return prop?.parent?.getPropValue?.('webOfficeEnable') === true;
                },
            }),
            {
                title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'shape',
                                    title: $i18n.get({ id: 'Style', dm: '样式' }),
                                    setter: {
                                        componentName: 'RadioGroupSetter',
                                        props: {
                                            options: [
                                                {
                                                    title: $i18n.get({ id: 'Ordinary', dm: '普通' }),
                                                    value: 'normal',
                                                },
                                                {
                                                    title: $i18n.get({ id: 'Streamlining', dm: '精简' }),
                                                    value: 'mini',
                                                },
                                                {
                                                    title: $i18n.get({ id: 'Draggable', dm: '可拖拽' }),
                                                    value: 'dragger',
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    name: 'useDetailValue',
                                    title: $i18n.get({
                                        id: 'UseDetailedData',
                                        dm: '使用详细数据',
                                    }),
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
export default Upload;
