import $i18n from '@/locales/i18n';
import { getButtonListByPosition, getButtonPrototypeListByPosition, } from '../manager/button';
import { condition } from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { AfterRequestSuccess } from '@/common/util/const';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import { getJSXTemplate } from '../manager/setter-snippet';
export const createRequestSuccessPrototype = (position, config) => {
    const { afterRequestCondition } = config || {};
    const buttonList = getButtonListByPosition(position);
    const buttonPrototypeList = getButtonPrototypeListByPosition(position);
    const configure = [
        {
            name: 'optType',
            title: $i18n.get({
                id: 'AfterTheRequestIsSuccessfulAband_361964829',
                dm: '请求成功后(废弃,请使用动作编排)',
            }),
            display: 'inline',
            setter: {
                componentName: 'CnSelectSetter',
                props: {
                    options: buttonList,
                    selectProps: {
                        hasClear: true,
                        disabled: true,
                    },
                },
            },
        },
    ];
    if (buttonPrototypeList?.length > 0) {
        configure.push({
            name: 'options',
            display: 'plain',
            title: $i18n.get({ id: 'ButtonConfigurationItems', dm: '按钮配置项' }),
            setter: {
                componentName: 'ObjectSetter',
                props: {
                    config: {
                        items: [...buttonPrototypeList],
                    },
                },
            },
        });
    }
    const result = {
        name: 'afterRequest',
        title: $i18n.get({
            id: 'AfterTheRequestIsSuccessfulAband_361964829',
            dm: '请求成功后(废弃,请使用动作编排)',
        }),
        display: 'plain',
        setter: {
            componentName: 'ObjectSetter',
            props: {
                config: {
                    items: configure,
                },
            },
        },
    };
    if (afterRequestCondition) {
        result.condition = afterRequestCondition;
    }
    return result;
};
export const dialogTypeOptions = [
    {
        title: $i18n.get({ id: 'LightInquiry', dm: '轻询问' }),
        value: 'confirm',
    },
    {
        title: $i18n.get({ id: 'Warning', dm: '警告' }),
        value: 'alert',
    },
    {
        title: $i18n.get({ id: 'SevereWarning', dm: '严重警告' }),
        value: 's-alert',
    },
];
export const confirmShowTypeOptions = [
    {
        title: $i18n.get({ id: 'PopupWindowDefault', dm: '弹窗（默认）' }),
        value: 'dialog',
    },
    {
        title: $i18n.get({ id: 'Bubble', dm: '气泡' }),
        value: 'balloon',
    },
];
export const createDoubleConfirmConfig = (config) => {
    const { optType, position, paramSelectSetter, jsExpressionSetter } = config || {};
    return [
        {
            name: 'needConfirm',
            title: $i18n.get({ id: 'SecondaryConfirmation', dm: '二次确认' }),
            display: 'inline',
            setter: {
                componentName: 'BoolSetter',
                initialValue: (prop) => {
                    return prop?.parent?.parent?.getPropValue?.('confirmInfo.needConfirm') === true;
                },
            },
            condition(prop) {
                return condition(prop, optType, 'optType');
            },
        },
        {
            name: 'confirmInfo',
            display: 'plain',
            title: $i18n.get({ id: 'ButtonConfigurationItems', dm: '按钮配置项' }),
            condition(prop) {
                return condition(prop, optType, 'optType');
            },
            setter: {
                componentName: 'ObjectSetter',
                initialValue: (prop) => {
                    const { title: oldTitle, content: oldContent } = prop?.parent?.parent?.getPropValue?.('confirmInfo') || {};
                    return {
                        dialogType: 'confirm',
                        confirmShowType: 'dialog',
                        title: oldTitle || $i18n.get({ id: 'ConfirmOperation', dm: '确认操作' }),
                        content: oldContent || $i18n.get({
                            id: 'YouAreAboutToDoThisPleaseConfirm',
                            dm: '您即将执行此操作。请再次确认，操作后将无法撤销，确定继续吗？',
                        }),
                    };
                },
                props: {
                    config: {
                        items: [
                            {
                                name: 'dialogType',
                                title: $i18n.get({
                                    id: 'TypeOfSecondaryConfirmation',
                                    dm: '二次确认类型',
                                }),
                                display: 'inline',
                                setter: {
                                    componentName: 'RadioGroupSetter',
                                    props: {
                                        options: dialogTypeOptions,
                                    },
                                },
                                condition(prop) {
                                    // 删除操作不允许设置二次确认类型
                                    return (condition(prop, true, 'needConfirm') && optType !== 'remove');
                                },
                            },
                            {
                                name: 'confirmShowType',
                                title: $i18n.get({
                                    id: 'SecondConfirmationDisplayEffect',
                                    dm: '二次确认展示效果',
                                }),
                                display: 'inline',
                                setter: {
                                    componentName: 'RadioGroupSetter',
                                    props: {
                                        options: confirmShowTypeOptions,
                                    },
                                },
                                condition(prop) {
                                    return condition(prop, true, 'needConfirm') && [ButtonPosition.tableOperate].includes(position);
                                },
                            },
                            {
                                name: 'title',
                                title: $i18n.get({
                                    id: 'SecondaryConfirmationTitle',
                                    dm: '二次确认标题',
                                }),
                                display: 'inline',
                                setter: {
                                    componentName: 'MixedSetter',
                                    props: {
                                        setters: [
                                            {
                                                componentName: 'CnI18nSetter',
                                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                                            },
                                            paramSelectSetter,
                                            jsExpressionSetter ||
                                                getJSExpressionPrototype({
                                                    type: 'tableRequest',
                                                }),
                                        ],
                                    },
                                },
                                condition(prop) {
                                    return condition(prop, true, 'needConfirm');
                                },
                            },
                            {
                                name: 'content',
                                title: $i18n.get({
                                    id: 'SecondConfirmationContent',
                                    dm: '二次确认内容',
                                }),
                                display: 'inline',
                                setter: {
                                    componentName: 'MixedSetter',
                                    props: {
                                        setters: [
                                            {
                                                componentName: 'CnI18nSetter',
                                                props: {
                                                    type: 'textarea',
                                                },
                                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                                            },
                                            paramSelectSetter,
                                            jsExpressionSetter ||
                                                getJSExpressionPrototype({
                                                    type: 'tableRequest',
                                                }),
                                            getJSXTemplate({ position }),
                                        ],
                                    },
                                },
                                condition(prop) {
                                    return condition(prop, true, 'needConfirm');
                                },
                            },
                        ],
                    },
                },
            },
        },
    ];
};
export const createRequestConfig = (config) => {
    const { optType, position, paramSelectSetter, jsExpressionSetter } = config || {};
    return [
        {
            name: 'requestInfo',
            display: 'plain',
            condition(prop) {
                return condition(prop, optType, 'optType');
            },
            setter: {
                componentName: 'ObjectSetter',
                props: {
                    config: {
                        items: [
                            {
                                name: 'needSuccessToast',
                                title: $i18n.get({
                                    id: 'PromptForSuccessfulRequest',
                                    dm: '请求成功的提示',
                                }),
                                defaultValue: (props) => {
                                    const path = props.getName();
                                    // 兼容按钮触发动作编排、事件触发动作编排2种情况
                                    const isInFlowList = path?.includes?.('options.flowList')
                                        || (path.includes('events') && path?.includes?.('flowList'));
                                    return !isInFlowList;
                                },
                                display: 'inline',
                                setter: {
                                    componentName: 'BoolSetter',
                                },
                                condition() {
                                    return optType !== 'remove';
                                },
                            },
                            {
                                name: 'successToastMsg',
                                title: $i18n.get({
                                    id: 'SuccessPromptContent',
                                    dm: '成功提示内容',
                                }),
                                display: 'inline',
                                setter: {
                                    componentName: 'MixedSetter',
                                    initialValue: () => {
                                        return optType === 'remove'
                                            ? $i18n.get({
                                                id: 'DeletedSuccessfully.9Ts6H',
                                                dm: '删除成功！',
                                            })
                                            : $i18n.get({
                                                id: 'OperationSuccessful',
                                                dm: '操作成功！',
                                            });
                                    },
                                    props: {
                                        setters: [
                                            {
                                                componentName: 'CnI18nSetter',
                                                props: {
                                                    type: 'textarea',
                                                },
                                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                                            },
                                            paramSelectSetter,
                                            jsExpressionSetter || getJSExpressionPrototype({
                                                type: 'tableRequest',
                                            }),
                                            getJSXTemplate({ position }),
                                        ],
                                    },
                                },
                                condition(prop) {
                                    return (prop?.parent?.getPropValue('needSuccessToast') === true || optType === 'remove');
                                },
                            }
                        ],
                    },
                },
            },
        },
    ];
};
export const createServiceSelectPrototype = (config) => {
    const { optType, paramSetter, wholeParamSetter, position, paramTitleDom, dynamicUrlTemplate, } = config || {};
    const configure = [
        {
            name: 'requestConfig',
            title: $i18n.get({ id: 'QueryService', dm: '查询服务' }),
            display: 'inline',
            setter: {
                componentName: 'ServiceChoiceSetter',
                props: {
                    paramTitleDom,
                    buttonText: $i18n.get({ id: 'SelectRequestAPI', dm: '选择请求API' }),
                    params: {
                        env: 'pre',
                        pageSize: 999,
                    },
                    paramSetter,
                    wholeParamSetter,
                    dynamicUrlTemplate,
                },
            },
            condition(prop) {
                return condition(prop, optType, 'optType');
            },
        },
        ...createDoubleConfirmConfig(config),
        ...createRequestConfig(config),
    ];
    if ([
        ButtonPosition.tableOperate,
        ButtonPosition.tableCell,
        ButtonPosition.tableToolArea,
    ].includes(position)) {
        configure.push(createRequestSuccessPrototype(position + AfterRequestSuccess));
    }
    if (position === ButtonPosition.tableOperate) {
        configure.push({
            name: 'keepPagination',
            title: $i18n.get({
                id: 'PagingInformationIsPreservedWhen_693677707',
                dm: '刷新表格时保留分页信息',
            }),
            setter: 'BoolSetter',
        });
    }
    return configure;
};
