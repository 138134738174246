import $i18n from '@/locales/i18n';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { CnBox, CnPage as UICnPage } from '@cainiaofe/cn-ui';
import { getButtonAction } from '@/common/manager/button';
import { ButtonPosition } from '@/common/manager/position/button-position';
import {
  getRealResponse,
  handleResultProcessFunc,
  makeRequest,
} from '@/common/util/request';
import {
  calculateTextExprValue,
  calculateUrl,
  clearState,
  executeFlowList,
  executeFunction,
  getPermissionGranted,
  getSlotDom,
  handlePageDataSource,
  isArrayNotEmpty,
  isDesignMode,
  isRequestConfig,
  makeButtons,
} from '@/common/util/util';
import {
  CnPageForceUpdate,
  CnPageRequestFinish,
  emitEvent,
  onEvent,
} from '@/common/util/event-name';
import { __permissionGranted__ } from '@/common/util/expr-const';
import set from 'lodash/set';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';
import { dataOriginStatic } from '@/common/util/const';

const CnPage = forwardRef((props, ref) => {
  const {
    children,
    componentDidMount,
    _context,
    cnBreadcrumbDataSource,
    breadcrumb = {},
    action: originalAction,
    title,
    handleProps,
    componentSlot,
    ...otherProps
  } = props;

  useImperativeHandle(ref, () => ({
    doMakeAction,
  }));

  if (!_context?.state.valueOf.__permissionGranted__) {
    set(_context?.state.valueOf, __permissionGranted__, getPermissionGranted());
  }

  const getUrlParams = () => {
    return _context?.state?.urlParams || {};
  };
  const isDesign = isDesignMode(props);
  const { dataOrigin, requestConfig } = breadcrumb;
  const [action, setAction] = useState();
  const getBreadcrumbData = useCallback((originalBreadcrumb) => {
    let result;
    if (originalBreadcrumb) {
      const { dataSource, dataOrigin: bDataOrigin } = originalBreadcrumb;
      if (bDataOrigin === 'static') {
        if (Array.isArray(dataSource) && dataSource?.length > 0) {
          const urlParams = getUrlParams();
          result = dataSource.map((item) => {
            const { children: _children, link } = item || {};
            const url = calculateUrl(link, {
              urlParamsDataSource: urlParams,
              recordDataSource: {},
              state: _context?.state,
            });
            let realChildren = _children;
            if (link?.redirectType === 'back') {
              realChildren = (
                <span
                  onClick={() => {
                    window?.history?.back?.();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {_children}
                </span>
              );
            }
            const temp = {
              children: realChildren,
            };
            if (url) {
              temp.link = url;
            }
            return temp;
          });
        } else if (typeof dataSource === 'function') {
          try {
            const temp = dataSource();
            if (Array.isArray(temp)) {
              result = temp;
            }
          } catch (e) { /* empty */ }
        }
      }
    }
    if (!result) {
      result = cnBreadcrumbDataSource;
    }
    return result;
  }, []);

  const [breadcrumbResult, setBreadcrumbResult] = useState(
    getBreadcrumbData(breadcrumb) || [],
  );
  const [, setForceUpdate] = useState(false);
  // 最后一个阻塞页面渲染的请求名称
  let lastDeferRequest;
  if (componentDidMount && componentDidMount.requestList?.length > 0) {
    for (const req of componentDidMount.requestList) {
      if (req?.name && req?.deferPageRender && req?.isAsync !== true) {
        lastDeferRequest = req.name;
      }
    }
  }
  const needDefer = !!lastDeferRequest && !isDesign;
  const [deferRender, setDeferRender] = useState(needDefer);

  const executeActionList = () => {
    if (!isDesign) {
      const { actionList } = componentDidMount || {};
      executeFlowList({
        list: actionList,
        _context,
        position: ButtonPosition.pageDidMount,
      }).finally(() => {
        clearState({ _context });

        doMakeAction();
      });
    }
  };

  function doMakeAction() {
    const tempAction = makeAction();
    if (tempAction) {
      setAction(tempAction);
    }
  }

  const executeActionListAfterAsyncRequest = (actionList) => {
    if (!isDesign && actionList?.length > 0) {
      executeFlowList({
        list: actionList,
        _context,
        position: ButtonPosition.pageAsyncRequestComplete,
      }).finally(() => {
        clearState({ _context });
      });
    }
  };

  useEffect(() => {
    onEvent(CnPageForceUpdate, () => {
      setForceUpdate(Date.now());
      setBreadcrumbResult(getBreadcrumbData(breadcrumb));
    });
    (async () => {
      if (componentDidMount) {
        const { requestList = [] } = componentDidMount;
        if (requestList?.length > 0) {
          let needForceUpdate = false;
          for (const item of requestList) {
            const {
              name,
              requestConfig: _requestConfig,
              dataOrigin: _dataOrigin,
              dataSource,
              isAsync,
              asyncCompleteActionList,
            } = item;
            if (name) {
              if (_dataOrigin === dataOriginStatic) {
                const tempP = {
                  dataOrigin: _dataOrigin,
                  dataSource,
                };
                handlePageDataSource({
                  componentProps: tempP,
                  state: _context?.state,
                });
                if (
                  tempP.dataSource !== undefined &&
                  tempP.dataSource !== null
                ) {
                  needForceUpdate = true;
                  _context?.setState({
                    [name]: tempP.dataSource,
                  });
                }
              } else if (_requestConfig) {
                try {
                  const buttonConfig = {
                    optType: 'request',
                    position: ButtonPosition.pageDidMount,
                    options: {
                      requestConfig: _requestConfig,
                    },
                  };
                  const _action = getButtonAction(buttonConfig);
                  if (isAsync) {
                    _action?.({
                      position: ButtonPosition.pageDidMount,
                      urlParamsDataSource: getUrlParams(),
                      // recordDataSource: newFormValue,
                      state: _context?.state,
                      buttonConfig,
                      _context,
                      needSuccessToast: false,
                      noNeedHandleResult: true,
                      isDesign,
                      // eslint-disable-next-line no-loop-func
                    }).then((result) => {
                      const resultProcessFunc =
                        handleResultProcessFunc(_requestConfig);
                      if (typeof resultProcessFunc === 'function') {
                        result = resultProcessFunc(result, _context?.state);
                      }
                      if (result !== null && result !== undefined) {
                        needForceUpdate = true;
                        _context?.setState({
                          [name]: result,
                        });
                        emitEvent(CnPageRequestFinish, {});
                        executeActionListAfterAsyncRequest(
                          asyncCompleteActionList,
                        );
                      }
                    });
                  } else {
                    let result = await _action?.({
                      position: ButtonPosition.pageDidMount,
                      urlParamsDataSource: getUrlParams(),
                      // recordDataSource: newFormValue,
                      state: _context?.state,
                      buttonConfig,
                      _context,
                      needSuccessToast: false,
                      noNeedHandleResult: true,
                      isDesign,
                    });
                    const resultProcessFunc =
                      handleResultProcessFunc(_requestConfig);
                    if (typeof resultProcessFunc === 'function') {
                      result = resultProcessFunc(result, _context?.state);
                    }
                    if (result !== null && result !== undefined) {
                      needForceUpdate = true;
                      _context?.setState({
                        [name]: result,
                      });
                    }
                  }
                } catch (e) {
                  console.log(`${name} 的请求执行异常`, e);
                }
                if (deferRender && name === lastDeferRequest) {
                  setDeferRender(false);
                }
              }
            }
          }
          if (needForceUpdate) {
            setForceUpdate(true);
          }
          emitEvent(CnPageRequestFinish, {});
          setTimeout(() => {
            executeActionList();
          });
        } else {
          executeActionList();
        }
      } else {
        doMakeAction();
      }
    })();

    if (breadcrumb) {
      if (dataOrigin === 'request' && isRequestConfig(requestConfig)) {
        makeRequest({
          buttonConfig: {
            options: {
              requestConfig,
            },
          },
          urlParamsDataSource: getUrlParams(),
          recordDataSource: {},
          state: _context?.state || {},
          needSuccessToast: false,
          isDesign,
        }).then((res) => {
          const res2 = getRealResponse(res);
          const { success, data } = res2;
          if (success) {
            if (Array.isArray(data) && data?.length > 0) {
              setBreadcrumbResult(data);
            }
          }
        });
      }
    }
  }, []);

  const slotDom = getSlotDom({
    componentSlot,
    isDesign,
  });

  const makeAction = useCallback(() => {
    if (isArrayNotEmpty(originalAction) || slotDom) {
      return (
        <>
          {slotDom && (
            <CnBox style={{ flexGrow: 1 }} padding={8}>
              {slotDom}
            </CnBox>
          )}

          <CnBox
            spacing={8}
            direction="row"
            align={'center'}
            justify="flex-end"
          >
            {isArrayNotEmpty(originalAction)
              ? makeButtons({
                buttons: originalAction?.map((item) => {
                  return {
                    ...item,
                    position: ButtonPosition.pageRightButton,
                  };
                }),
                _context,
                state: _context?.state,
                urlParamsDataSource: getUrlParams(),
                recordDataSource: {},
              })
              : null}
          </CnBox>
        </>
      );
    }
  }, [originalAction]);

  useEffect(() => {
    if (isDesign) {
      const result = getBreadcrumbData(breadcrumb);
      setBreadcrumbResult(result);
    }
  }, [breadcrumb]);

  useEffect(() => {
    if (isDesign) {
      const tempAction = makeAction();
      if (tempAction) {
        setAction(tempAction);
      }
    }
  }, [originalAction]);

  let realBreadcrumbResult;
  if (isArrayNotEmpty(breadcrumbResult)) {
    realBreadcrumbResult = breadcrumbResult;
  }

  let realTitle = calculateTextExprValue(title, {
    state: _context?.state,
    recordDataSource: {},
  });
  if (isDesign) {
    if (title === undefined) {
      realTitle = $i18n.get({ id: 'JsExpressionTitle', dm: 'js表达式标题' });
    }
  }

  const extraProps = {};
  if (dataOrigin === 'useStandard') {
    extraProps.cnBreadcrumbProps = {
      useStandard: true,
    };
  }

  let componentProps = {
    title: realTitle,
    action,
    ...otherProps,
    cnBreadcrumbDataSource: realBreadcrumbResult,
    ...extraProps,
  };

  if (typeof handleProps === 'function') {
    const tempProps = executeFunction(
      handleProps,
      { ...componentProps },
      _context?.state,
    );
    if (isPlainObject(tempProps)) {
      componentProps = merge(tempProps, { ...componentProps });
    }
  }

  return <UICnPage {...componentProps}>{!deferRender && children}</UICnPage>;
});
CnPage.displayName = 'CnPage';
export default CnPage;

export { CnPage };
