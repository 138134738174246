import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
export const createDebouncedAction = (() => {
    const debouncedActions = {};
    /**
     * @description 返回防抖后的动作
     * @param action 原始执行函数
     * @param actionKey 动作唯一标识
     * @param delay 防抖时间（单位ms）
     */
    return ({ action, actionKey, delay }) => {
        if (!actionKey || !isFunction(action)) {
            return;
        }
        if (!debouncedActions[actionKey]) {
            debouncedActions[actionKey] = debounce(action, delay || 0);
        }
        return debouncedActions[actionKey];
    };
})();
