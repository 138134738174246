import $i18n from '@/locales/i18n';
import { CnDialog, CnMessage } from '@cainiaofe/cn-ui';
import { request as requestInstance } from 'cn-request';
// eslint-disable-next-line import/no-cycle
import { calculateRequestExecute, calculateTextExprValue, executeFunction, getRealRequestUrl, handleDesignMokelayUrl, handleSuccessMessage, } from './util';
// eslint-disable-next-line import/no-cycle
import { getButtonAction, } from '../manager/button';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import { AfterRequestSuccess, formUrlencoded } from '@/common/util/const';
import qs from 'query-string';
import { __dataSource__ } from '@/common/util/expr-const';
function doubleConfirm({ buttonConfig, config, action, onCancel, onClose }) {
    const { confirmInfo: styleTabConfirmInfo, options = {}, optType } = buttonConfig;
    /**
     * 该段为兼容二次确认配置从tab迁移至按钮配置项处，Schema协议的兼容逻辑；
     * 若在「按钮配置项」中打开二次确认开关，优先取「按钮配置项」中的confirmInfo
     */
    const needConfirm = options.needConfirm || styleTabConfirmInfo?.needConfirm;
    const confirmInfo = options.needConfirm ? options.confirmInfo : styleTabConfirmInfo;
    if (!needConfirm) {
        action();
        return;
    }
    const { dialogType } = confirmInfo || {};
    const title = calculateTextExprValue(confirmInfo?.title, config);
    const content = calculateTextExprValue(confirmInfo?.content, config);
    const CnDialogQuickFunc = dialogType === 'alert' || dialogType === 's-alert' || optType === 'remove'
        ? CnDialog.warning
        : CnDialog.confirm;
    CnDialogQuickFunc({
        title: title || $i18n.get({ id: 'Notifications', dm: '通知' }),
        content: content || '',
        footerActions: ['cancel', 'ok'],
        messageProps: {
            className: 'l2-dialog-message-content',
        },
        okProps: {
            warning: dialogType === 's-alert' || optType === 'remove',
        },
        onOk: () => { action(); },
        onCancel,
        onClose,
    });
}
export function makeRequest(config) {
    let { needSuccessToast = true } = config;
    const { handleParams, buttonConfig, state, recordDataSource, urlParamsDataSource, onCancel, extraParamList, isDesign, position = '', noNeedHandleResult, _context, getExtraParam, } = config;
    return new Promise((resolve, reject) => {
        if (buttonConfig) {
            const { options = {} } = buttonConfig;
            const { requestConfig, afterRequest } = options;
            const sendRequest = () => {
                const realUrl = getRealRequestUrl({
                    requestConfig,
                    state,
                    extraParam: getExtraParam?.(),
                });
                if (realUrl) {
                    const execute = calculateRequestExecute(requestConfig, {
                        [__dataSource__]: state,
                    }, {}, state);
                    if (execute === false) {
                        reject();
                        return;
                    }
                    requestInstance(transRequestConfigToRemote({
                        requestConfig,
                        handleParams,
                        state,
                        urlParamsDataSource,
                        recordDataSource,
                        isDesign,
                        extraParamList,
                        getExtraParam,
                    }))
                        .then((res) => {
                        let result = getRealResponse(res);
                        if (!noNeedHandleResult) {
                            result = handleResultProcessFunc2(res, requestConfig, {
                                state,
                            });
                        }
                        const { success, errorCode, errorMsg } = result;
                        if (success) {
                            // TODO: 请求成功后配置项已不对用户透出，以下为兼容逻辑
                            if (afterRequest?.optType) {
                                const pos = position + AfterRequestSuccess;
                                const action = getButtonAction({
                                    ...afterRequest,
                                    position: pos,
                                });
                                if (action) {
                                    needSuccessToast = false;
                                    action?.({
                                        position: pos,
                                        urlParamsDataSource,
                                        recordDataSource,
                                        state,
                                        buttonConfig: afterRequest,
                                        response: result,
                                        _context,
                                    });
                                }
                            }
                            resolve(result);
                            handleSuccessMessage({
                                requestInfo: options?.requestInfo,
                                needSuccessToast,
                                successRes: {
                                    ...result,
                                    msg: result?.msg,
                                },
                            });
                        }
                        else {
                            reject(result);
                            if (!res?.hadShowErrorFeedback) {
                                CnMessage.error(errorMsg ||
                                    errorCode ||
                                    $i18n.get({
                                        id: 'RequestConfigUrlRequestException',
                                        dm: '{requestConfigUrl} 请求异常',
                                    }, { requestConfigUrl: requestConfig?.url }));
                            }
                        }
                    })
                        .catch((err) => {
                        reject(err);
                    });
                }
            };
            doubleConfirm({
                buttonConfig,
                config: {
                    urlParamsDataSource,
                    recordDataSource,
                    state,
                    extraParamList,
                },
                action: sendRequest,
                onCancel: () => {
                    onCancel && onCancel();
                    reject();
                },
                onClose: (actionType) => {
                    /**
                       * 一码多端兼容代码：
                       *  差异点：PC端点击确认按钮时不会执行onClose；移动端点击确认按钮时会触发onClose。
                       *  兼容逻辑：当onClose是点击确认按钮时触发的，此时不执行reject，保持PC与移动行为一致。
                       */
                    if (actionType !== 'ok') {
                        onCancel && onCancel();
                        reject();
                    }
                },
            });
        }
    });
}
export function transRequestConfigToRemote(config = {}) {
    const { requestConfig, handleParams, isDesign, urlParamsDataSource, recordDataSource, state, extraParamList, getExtraParam, needFormatResult, noQsStringifyFormatting, refreshDeps, formatParam, } = config;
    if (requestConfig) {
        const realUrl = getRealRequestUrl({
            requestConfig,
            state,
            extraParam: getExtraParam?.(),
        });
        const { url, params = [], serviceType, method, contentType, timeout, __extraConfig__ = {}, withCredentials, } = requestConfig;
        const temp = {
            url,
            method: 'post',
            data: {},
        };
        if (realUrl) {
            temp.url = realUrl;
        }
        if (formatParam) {
            temp.formatParam = formatParam;
        }
        if (refreshDeps) {
            temp.refreshDeps = refreshDeps;
        }
        if (typeof __extraConfig__.headers === 'function') {
            temp.headers = executeFunction(__extraConfig__.headers, state);
        }
        if (typeof __extraConfig__.headers === 'object') {
            temp.headers = __extraConfig__.headers;
        }
        if (timeout) {
            temp.timeout = timeout;
        }
        if (isDesign && serviceType === 'mokelay') {
            temp.url = handleDesignMokelayUrl(temp.url);
        }
        if (serviceType === 'http') {
            temp.withCredentials = withCredentials !== false;
        }
        if (typeof handleParams === 'function') {
            temp.data = handleParams(temp.data);
        }
        const realParams = handleRequestParams(params, {
            urlParamsDataSource,
            recordDataSource,
            state,
            extraParamList,
            getExtraParam,
            flexibleParams: true,
        });
        if (typeof realParams === 'function') {
            temp.data = realParams();
        }
        else if (Object.keys(realParams).length > 0) {
            temp.data = { ...temp.data, ...realParams };
        }
        if (serviceType === 'http') {
            if (method) {
                temp.method = method;
            }
            if (contentType === formUrlencoded) {
                temp.headers = temp.headers || {};
                temp.headers['content-type'] = contentType;
                if (temp.method === 'post' &&
                    isPlainObject(temp.data) &&
                    !noQsStringifyFormatting) {
                    temp.data = qs.stringify(temp.data);
                }
            }
        }
        if (temp.method === 'get') {
            temp.params = temp.data;
            delete temp.data;
        }
        if (needFormatResult === true) {
            temp.formatResult = handleResultProcessFunc(requestConfig);
        }
        return temp;
    }
    else {
        return {};
    }
}
export function handleRequestParams(params, config = {}) {
    let result = {};
    const { flexibleParams = false } = config;
    if (typeof params === 'function') {
        const temp = calculateTextExprValue(params, config);
        if (isPlainObject(temp) || (isFunction(temp) && flexibleParams)) {
            result = temp;
        }
    }
    else if (params && params.length > 0) {
        params.forEach((item) => {
            if (item) {
                // label 是参数名，value 是函数，执行后的结果是参数值
                const { label, value } = item;
                if (label && value !== undefined) {
                    result[label] = calculateTextExprValue(value, config);
                }
            }
        });
    }
    return result;
}
export function getRealResponse(res = {}) {
    const { status, data } = res;
    let result = res;
    if (status === 200 && data) {
        result = data;
    }
    return result;
}
export function handleResultProcessFunc(requestConfig) {
    if (requestConfig) {
        const { resultProcessFunc, serviceType, mockData } = requestConfig;
        if (typeof resultProcessFunc === 'function') {
            return (res, state) => {
                let newRes = getRealResponse(res);
                try {
                    const temp = resultProcessFunc(newRes, state);
                    if (temp && temp.data && temp.success !== undefined) {
                        newRes = temp.data;
                    }
                    else {
                        newRes = temp;
                    }
                }
                catch (e) {
                    console.error('请求结果回调执行失败', e);
                }
                return newRes;
            };
        }
        else if (serviceType === 'mock' && isPlainObject(mockData)) {
            return () => {
                return mockData?.data;
            };
        }
        else {
            return (res) => {
                if (res?.data) {
                    return res.data;
                }
                return res;
            };
        }
    }
}
export function handleResultProcessFunc2(response, requestConfig, extra) {
    const { state } = extra || {};
    let newRes = getRealResponse(response);
    if (requestConfig) {
        const { resultProcessFunc, serviceType, mockData } = requestConfig;
        if (typeof resultProcessFunc === 'function' &&
            (serviceType === 'mokelay' || serviceType === 'http')) {
            try {
                if (isPlainObject(state)) {
                    newRes = resultProcessFunc(newRes, state);
                }
                else {
                    newRes = resultProcessFunc(newRes);
                }
            }
            catch (e) {
                console.error('请求结果回调执行失败', e);
            }
            return newRes;
        }
        else if (serviceType === 'mock' && isPlainObject(mockData)) {
            return mockData;
        }
    }
    return newRes;
}
