import $i18n from '@/locales/i18n';
import { DisplayPosition } from '@/common/manager/position/display-position';
import lt from 'lodash/lt';
import lte from 'lodash/lte';
import eq from 'lodash/eq';
import _includes from 'lodash/includes';
export const __formValue__ = '__formValue__';
export const __filterValue__ = '__filterValue__';
export const __tableCurrentRow__ = '__tableCurrentRow__';
export const __dataSource__ = '__dataSource__';
export const __permissionGranted__ = '__permissionGranted__';
export const __permission_point_list__ = '__permission_point_list__';
export const __arrayTableCurrentRow__ = '__arrayTableCurrentRow__';
export const __arrayTableCurrentRowIndex__ = '__arrayTableCurrentRowIndex__';
// 点击整列
export const __arrayTableCurrentRowByWhole__ = '__arrayTableCurrentRowByWhole__';
// 点击整列
export const __arrayTableCurrentRowIndexByWhole__ = '__arrayTableCurrentRowIndexByWhole__';
// 点击卡片整列
export const __arrayCardCurrentRowByWhole__ = '__arrayCardCurrentRowByWhole__';
// 点击卡片整列
export const __arrayCardCurrentRowIndexByWhole__ = '__arrayCardCurrentRowIndexByWhole__';
export const __selectedRowKeys__ = '__selectedRowKeys__';
export const __selectedRowRecords__ = '__selectedRowRecords__';
export const __totalCount__ = '__totalCount__';
export const __tableExtra__ = '__tableExtra__';
// 当前第几步
export const __step_current__ = '__step_current__';
export const __step_list__ = '__step_list__';
export const __step_activeKey__ = '__step_activeKey__';
// 当前步骤详细信息
export const __step_currentItem__ = '__step_currentItem__';
export const __paging__ = '__paging__';
export const __tableData__ = '__tableData__';
export const __tableColumns__ = '__tableColumns__';
export const __flowData__ = '__flowData__';
export const __tab_activeKey__ = '__tab_activeKey__';
export const __tab_removeKey__ = '__tab_removeKey__';
export const __statisticCard_activeKey__ = '__statisticCard_activeKey__';
export const __statisticCard_currentItem__ = '__statisticCard_currentItem__';
export const __statisticCard_clickKey__ = '__statisticCard_clickKey__';
export const __statisticCard_clickItem__ = '__statisticCard_clickItem__';
export const __list_activeKey__ = '__list_activeKey__';
export const __list_currentItem__ = '__list_currentItem__';
export const __list_pro_selected_row_keys__ = '__list_pro_selected_row_keys__';
export const __list_pro_selected_rows__ = '__list_pro_selected_rows__';
export const __list_pro_click_current_item__ = '__list_pro_click_current_item__';
// 左树当前选中的值
export const __left_tree_currentValue__ = '__left_tree_currentValue__';
// 左树当前选中的项
export const __left_tree_currentItem__ = '__left_tree_currentItem__';
// 公共参数
export const __extraParam__ = '__extraParam__';
// 挂到valueOf上的属性间填充符号
export const __stateValueOfSplit__ = '.';
export const openDialogModeLabel = $i18n.get({
    id: 'ThePurposeOfThisOpenPopupForm',
    dm: '弹窗表单本次打开的用途',
});
export const formDialogStateLabel = $i18n.get({
    id: 'StatusInformationOfTheCurrentPop_758602280',
    dm: '[当前弹窗表单的状态信息]',
});
export const formStateLabel = $i18n.get({
    id: 'StatusInformationForTheCurrentFo_661548677',
    dm: '[当前表单的状态信息]',
});
export const tableCurrentRowByOperateClickLabel = $i18n.get({
    id: 'RowDataAfterClickingTheOperation_1019164482',
    dm: '操作列按钮点击后的行数据',
});
export const tableCurrentRowIndexByOperateClickLabel = $i18n.get({
    id: 'TheRowIndexAfterTheOperationColu_11399524',
    dm: '操作列按钮点击后的行Index',
});
export const arrayTableCurrentRowLabel = $i18n.get({
    id: 'TheRowDataCurrentlyBeingOperated_438136733',
    dm: '当前正在操作的行数据',
});
export const arrayTableCurrentRowIndex = $i18n.get({
    id: 'TheRowCurrentlyBeingOperatedOnIn_1639516193',
    dm: '当前正在操作的行Index',
});
export const arrayCardCurrentRowLabel = $i18n.get({
    id: 'CardDataCurrentlyBeingOperated',
    dm: '当前正在操作的卡片数据',
});
export const arrayCardCurrentRowIndex = $i18n.get({
    id: 'TheCurrentlyOperatingCardIndex',
    dm: '当前正在操作的卡片Index',
});
export const tableCurrentRowLabel = $i18n.get({
    id: 'CurrentRowDataClicked',
    dm: '点击的当前行数据',
});
export const tableCurrentRowIndex = $i18n.get({
    id: 'TheCurrentLineOfTheClickIndex',
    dm: '点击的当前行Index',
});
export const currentPageLabel = $i18n.get({
    id: 'TheCurrentlyClickedPageNumberOfT_30461671',
    dm: '表格当前点击的页码(currentPage)',
});
export const pageSizeLabel = $i18n.get({
    id: 'TheCurrentPageOfTheTableShowsSev_561292626',
    dm: '表格当前一页展示几条(pageSize)',
});
export const sortListLabel = $i18n.get({
    id: 'TableSortingInformationSortList',
    dm: '表格排序信息(sortList)',
});
export const currentFormCardLabel = $i18n.get({
    id: 'DataOfTheCurrentCard',
    dm: '当前卡片的数据',
});
export const currentFormStepLabel = $i18n.get({
    id: 'StepbystepFormcurrentlyTheNumber_1301307614',
    dm: '分步表单-当前是第几步',
});
export const leftTreeStateLabel = $i18n.get({
    id: 'InformationForTheCurrentTree',
    dm: '当前树的信息',
});
export const leftTreeAfterDropDataSourceLabel = $i18n.get({
    id: 'FullDataOfTheDraggedTreeDataSour_1328165629',
    dm: '拖拽后树的完整数据（dataSource）',
});
// 分步表单当前第几步
export const __form_step_current__ = '__form_step_current__';
export const componentRefSplit = '_$ref$_';
// 权限点变量
export const button_view = 'button_view';
export function getFormExprNameByPosition(config) {
    const { position } = config;
    let result;
    switch (position) {
        case DisplayPosition.filter:
            result = __filterValue__;
            break;
        case DisplayPosition.form:
        case DisplayPosition.cnArrayTable:
        case DisplayPosition.formDialog:
            result = __formValue__;
            break;
        default:
            result = __formValue__;
    }
    return result;
}
function equal(a, b, config) {
    const { type } = config || {};
    if (type === 'array') {
        return (a === undefined ||
            a === null ||
            a === '' ||
            (Array.isArray(a) && a.length === 0));
    }
    else if (b === undefined || b === null || b === '') {
        if (a === 0 || a === false) {
            return false;
        }
        else {
            return !a;
        }
    }
    else {
        return eq(a, b);
    }
}
function notEqual(a, b, config) {
    const { type } = config || {};
    if (type === 'array') {
        return Array.isArray(a) && a.length > 0;
    }
    else if (b === undefined || b === null || b === '') {
        if (a === 0 || a === false) {
            return true;
        }
        else {
            return !!a;
        }
    }
    else {
        return !eq(a, b);
    }
}
function includes(a, b) {
    return _includes(a, b);
}
function notIncludes(a, b) {
    return !_includes(a, b);
}
export const symbolMap = {
    '<': lt,
    '<=': lte,
    '>': function (a, b) {
        return a > b;
    },
    '>=': function (a, b) {
        return a >= b;
    },
    '==': equal,
    '=': equal,
    '===': equal,
    '!=': notEqual,
    includes,
    notIncludes,
};
export const compareList = [
    {
        label: $i18n.get({ id: 'Greater', dm: '大于' }),
        value: '>',
    },
    {
        label: $i18n.get({ id: 'GreaterThanOrEqual', dm: '大于等于' }),
        value: '>=',
    },
    {
        label: $i18n.get({ id: 'Less', dm: '小于' }),
        value: '<',
    },
    {
        label: $i18n.get({ id: 'LessThanOrEqual', dm: '小于等于' }),
        value: '<=',
    },
];
export const compareMap = {};
compareList.forEach((item) => {
    const { label, value } = item || {};
    compareMap[value] = label;
});
export const timeUnitList = [
    {
        label: $i18n.get({ id: 'Day', dm: '天' }),
        value: 'day',
    },
    {
        label: $i18n.get({ id: 'Week', dm: '周' }),
        value: 'week',
    },
    {
        label: $i18n.get({ id: 'Month', dm: '月' }),
        value: 'month',
    },
    {
        label: $i18n.get({ id: 'Year', dm: '年' }),
        value: 'year',
    },
];
export const timeUnitList2 = [
    {
        label: $i18n.get({ id: 'Seconds', dm: '秒' }),
        value: 'second',
    },
    {
        label: $i18n.get({ id: 'Minutes', dm: '分钟' }),
        value: 'minute',
    },
    {
        label: $i18n.get({ id: 'Hours', dm: '小时' }),
        value: 'hour',
    },
];
export const timeUnitMap = {};
timeUnitList.forEach((item) => {
    const { label, value } = item || {};
    timeUnitMap[value] = label;
});
timeUnitList2.forEach((item) => {
    const { label, value } = item || {};
    timeUnitMap[value] = label;
});
export const timeMillisecondMap = {
    hour: 3600000,
    minute: 60000,
    second: 1000,
};
export const getCommonBoolDataSource = () => {
    return [
        {
            label: $i18n.get({ id: 'Yes', dm: '是' }),
            value: true,
        },
        {
            label: $i18n.get({ id: 'No', dm: '否' }),
            value: false,
        },
    ];
};
export function getOpenDialogModeEnum(config) {
    const { prefix = '', suffix = '' } = config || {};
    return [
        {
            label: $i18n.get({
                id: 'PrefixAddSuffix',
                dm: '{prefix}新增{suffix}',
            }, { prefix, suffix }),
            value: 'new',
        },
        {
            label: $i18n.get({
                id: 'PrefixEditSuffix',
                dm: '{prefix}编辑{suffix}',
            }, { prefix, suffix }),
            value: 'edit',
        },
        {
            label: $i18n.get({
                id: 'PrefixDetailsSuffix',
                dm: '{prefix}详情{suffix}',
            }, { prefix, suffix }),
            value: 'detail',
        },
    ];
}
// @ice/stark-data hack取法
export const iceStore = window?.ICESTARK?.store?.store || {};
