import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import { CnNumberPicker, componentMap as formComponentMap, } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { getBizExtendPrototype, handleBizExtendComponentProps, } from '@/common/manager/plugin';
import { setAdvancedConfigToProps } from '@/common/util/util';
import { ADAPTER_LOCALE_SETTER, FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { NumberPicker: { title, thumbUrl } } = FORM_ITEM_INFO;
const NumberPicker = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'NumberPicker',
    component: CnNumberPicker,
    formComponent: formComponentMap.CnNumberPicker,
    getFormItemDefaultProps: () => {
        return {
            placeholder: $i18n.get({ id: 'PleaseEnter', dm: '请输入' }),
        };
    },
    formItemBeforeHandler: (formItem) => {
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            handleBizExtendComponentProps(componentProps, 'CnNumberPicker');
            setAdvancedConfigToProps(componentProps);
        }
    },
    // filterItemBeforeHandler:(filterItemProps, componentProps)=>{
    //
    // },
    getPrototypeList: () => {
        return [
            ADAPTER_LOCALE_SETTER,
            {
                name: 'step',
                title: $i18n.get({ id: 'StepSize', dm: '步长' }),
                display: 'inline',
                setter: {
                    componentName: 'NumberPickerSetter',
                    props: {
                        placeholder: $i18n.get({
                            id: 'PleaseEnterANumberToIncreaseOrDe_552846478',
                            dm: '请输入数字增减步长',
                        }),
                        precision: 2,
                        style: {
                            width: '100%',
                        },
                    },
                },
            },
            {
                name: 'precision',
                title: $i18n.get({
                    id: 'PrecisionDecimalPlaces',
                    dm: '精度（小数点位数）',
                }),
                display: 'inline',
                setter: 'NumberSetter',
            },
            {
                name: 'max',
                title: $i18n.get({ id: 'Maximum', dm: '最大值' }),
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'min',
                title: $i18n.get({ id: 'Minimum', dm: '最小值' }),
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'innerAfter',
                title: $i18n.get({
                    id: 'AdditionalContentAfterNumberInpu_1836562594',
                    dm: '数字输入框后附加内容',
                }),
                display: 'inline',
                setter: {
                    componentName: 'CnI18nSetter',
                    title: $i18n.get({ id: 'MultilingualText', dm: '多语言文本' }),
                },
            },
            ...getBizExtendPrototype({
                componentName: 'CnNumberPicker',
            }),
        ];
    },
};
export default NumberPicker;
