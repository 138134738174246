import React from 'react';
import { CnResult as UICnResult } from '@cainiaofe/cn-ui';
import {
  calculateTextExprValue,
  executeFunction,
  isArrayNotEmpty,
  makeALink,
  makeButtons,
} from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';

const CnResult = (props) => {
  const {
    title = '',
    _context,
    subTitle,
    type,
    buttons,
    handleProps,
    ...rest
  } = props;
  const realTitle =
    calculateTextExprValue(title, {
      state: _context?.state,
      recordDataSource: {},
    }) || '';
  const realType = calculateTextExprValue(type, {
    state: _context?.state,
    recordDataSource: {},
  });

  const getSubTitle = () => {
    if (React.isValidElement(subTitle)) {
      return subTitle;
    }

    if (isPlainObject(subTitle)) {
      return makeALink({
        linkExpr: subTitle,
        recordDataSource: {},
        _context,
      });
    }

    return calculateTextExprValue(subTitle, {
      state: _context?.state,
      recordDataSource: {},
    });
  };

  const getExtra = () => {
    const { _unsafe_MixedSetter_buttons_select } = props || {};
    if (_unsafe_MixedSetter_buttons_select === 'SlotSetter') {
      return buttons;
    }

    if (isArrayNotEmpty(buttons)) {
      return makeButtons({
        buttons: buttons?.map((item) => {
          return {
            ...item,
            position: ButtonPosition.result,
          };
        }),
        _context,
        state: _context?.state,
        urlParamsDataSource: _context?.state?.urlParams || {},
        recordDataSource: {},
      });
    }

    return null;
  };


  const extraProps = {
    title: realTitle,
    subTitle: getSubTitle(),
    type: realType,
    extra: getExtra(),
  };


  let componentProps = { ...rest, ...extraProps };

  if (typeof handleProps === 'function') {
    const tempProps = executeFunction(
      handleProps,
      { ...componentProps },
      _context?.state,
    );
    if (isPlainObject(tempProps)) {
      componentProps = merge(tempProps, { ...componentProps });
    }
  }

  return <UICnResult {...componentProps} />;
};
CnResult.displayName = 'CnResult';
export default CnResult;

export { CnResult };
