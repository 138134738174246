export var __urlParams__ = '__urlParams__';
export var __record__ = '__record__';
export var __ds__ = '__ds__';
export var __blank__ = '__blank__';
export var __formValue__ = '__formValue__';
export var __filterValue__ = '__filterValue__';
export var __tableCurrentRow__ = '__tableCurrentRow__';
export var __dataSource__ = '__dataSource__';
export var __arrayTableCurrentRow__ = '__arrayTableCurrentRow__';
export var __arrayTableCurrentRowIndex__ = '__arrayTableCurrentRowIndex__';
export var __selectedRowKeys__ = '__selectedRowKeys__';
export var __selectedRowRecords__ = '__selectedRowRecords__';
export var __totalCount__ = '__totalCount__';
export var __tableExtra__ = '__tableExtra__';
// 当前第几步
export var __step_current__ = '__step_current__';
export var __step_list__ = '__step_list__';
export var __step_activeKey__ = '__step_activeKey__';
// 当前步骤详细信息
export var __step_currentItem__ = '__step_currentItem__';
export var __paging__ = '__paging__';
export var __tableData__ = '__tableData__';
export var __tableColumns__ = '__tableColumns__';
export var __flowData__ = '__flowData__';
export var __tab_activeKey__ = '__tab_activeKey__';
// 左树当前选中的值
export var __left_tree_currentValue__ = '__left_tree_currentValue__';
// 左树当前选中的项
export var __left_tree_currentItem__ = '__left_tree_currentItem__';
// 列表当前点击的行
export var __list_currentItem__ = '__list_currentItem__';
// 列表当前点击的行的索引
export var __list_activeKey__ = '__list_activeKey__';
export var __list_pro_click_current_item__ = '__list_pro_click_current_item__';
// 公共参数
export var __extraParam__ = '__extraParam__';
