import React, { useEffect } from 'react';

const Page = (props) => {
  useEffect(() => {
    window.VisualEngine?.designer?.currentDocument?.nodesMap?.forEach((element) => {
      if (!window.top.__lowcode_cacheNodeMap__) {
        window.top.__lowcode_cacheNodeMap__ = {};
      }
      window.top.__lowcode_cacheNodeMap__[element.id] = element;
    });
  }, []);
  const { children } = props;
  return <div>{children}</div>;
};
Page.displayName = 'Page';
export default Page;

export { Page };
