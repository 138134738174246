var _a, _b;
import React from 'react';
import $i18n from 'panda-i18n';
var CnIcon = ((_a = window.CNUI) === null || _a === void 0 ? void 0 : _a.CnIcon) || ((_b = window.CNUIM) === null || _b === void 0 ? void 0 : _b.CnIcon);
/**
 * @description 编辑态画布中支持一键添加CnFormat
 */
var FormatEmpty = function (_a) {
    var showText = _a.showText, onClick = _a.onClick;
    return (React.createElement("div", { className: "format-empty", style: {
            color: '#284cc0',
            fontSize: '12px',
            fontWeight: '400',
        } },
        React.createElement("span", { className: "format-empty-btn", onClick: onClick },
            CnIcon ? (React.createElement(CnIcon, { size: "medium", type: "add-r", className: "cn-format-empty-add-icon" })) : (React.createElement("span", null, "+")),
            showText && (React.createElement("span", { style: { marginLeft: '4px' } }, $i18n.get({ id: 'FormattingText', dm: '格式化文本' }))))));
};
export { FormatEmpty };
