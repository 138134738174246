import isArray from 'lodash/isArray';
import classNames from 'classnames';
import { executeFunction, makeButtons, } from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';
import '../index.scss';
// @ts-ignore 一码多端逻辑
const { CnButtonGroup } = window.CNUIM || {};
const getButtons = ({ buttons, size, _context, }) => {
    const state = _context?.state;
    if (isArray(buttons) && buttons.length > 0) {
        return makeButtons({
            buttons: buttons?.map((item) => {
                const realChildren = typeof item?.children === 'function' ?
                    executeFunction(item.children, {}, state) : item?.children;
                return {
                    ...item,
                    position: ButtonPosition.actionBar,
                    children: realChildren,
                    size,
                };
            }),
            _context,
            state,
            recordDataSource: {},
        });
    }
    return null;
};
const getActions = ({ buttons, size, setterType, isReverse, needButtonGroup, _context, }) => {
    if (!buttons) {
        return null;
    }
    if (setterType === 'SlotSetter') {
        return buttons;
    }
    if (isArray(buttons) && buttons.length > 0) {
        const realButtons = isReverse ? [...(buttons.reverse())] : buttons;
        const buttonDoms = getButtons({ buttons: realButtons, size, _context });
        return needButtonGroup ?
            <CnButtonGroup size={size} fullWidth>{buttonDoms}</CnButtonGroup> : buttonDoms;
    }
    return null;
};
const ActionBarBlock = (props) => {
    const { setterType, buttons, size, extClassName, mode = 'block', } = props;
    const isValidActions = buttons && (setterType === 'SlotSetter' || (isArray(buttons) && buttons.length > 0));
    if (!isValidActions) {
        return null;
    }
    if (mode === 'btn-group') {
        return (CnButtonGroup ? (<CnButtonGroup size={size} fullWidth>
          {getActions(props)}
        </CnButtonGroup>) : null);
    }
    return (<div className={classNames('cn-action-bar-block', extClassName)}>
      {getActions(props)}
    </div>);
};
export default ActionBarBlock;
