import React, { forwardRef } from 'react';
import { CnFormat as UICnFormat } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import {
  calculateTextExprValue,
  executeFunction,
  isArrayNotEmpty,
  isDesignMode,
} from '@/common/util/util';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import './view.scss';


const CnFormat = forwardRef((props, ref) => {
  if (!UICnFormat) {
    return null;
  }

  // eslint-disable-next-line max-len
  const { formatSchema, flexWrap, gap, justifyContent, _context, __getContextData__, __loopData__ } =
    props || {};
  // CnFormat依赖宿主组件是否传递props，因此只通过props无法判定是否为编辑态
  const isDesign = isDesignMode(props) || !window?.RenderEngine;
  const functionArg = executeFunction(__getContextData__)?.functionArg;
  const transComponentProps = executeFunction(__getContextData__)?.transComponentProps || {};
  const { active, loop } = __loopData__ || {};

  const calculateValue = (config) => {
    const { value, funcArg } = config || {};
    if (typeof value === 'function') {
      return executeFunction(value, {
        ...funcArg,
        state: _context?.state,
      });
    } else if (isPlainObject(value)) {
      const { __hasMockData__ } = value || {};
      if (__hasMockData__) {
        if (isDesign) {
          if (
            (value?.value !== 0 && !value?.value) ||
            (isPlainObject(value?.value) &&
              Object.keys(value?.value).includes(
                '__jsFunctionExecuteImmediately__',
              ))
          ) {
            return value?.__mockValue__;
          } else if (
            typeof value?.value === 'string' &&
            value?.value?.startsWith('__record__.')
          ) {
            const fieldKey = value?.value?.split('__record__.')[1];
            return funcArg?.item?.[fieldKey];
          } else {
            return calculateValue({
              value: value?.value,
              funcArg,
            });
          }
        } else {
          return calculateValue({
            value: value?.value,
            funcArg,
          });
        }
      } else {
        if (typeof value?.__jsFunctionExecuteImmediately__ === 'function') {
          return executeFunction(value.__jsFunctionExecuteImmediately__, {
            ...funcArg,
            state: _context?.state,
          });
        }
        const group = value?.group;
        const param = value?.param;
        if (typeof group === 'string' && group.startsWith('__')) {
          if (group === '__value__') {
            return funcArg?.value;
          } else {
            if (param) {
              return calculateTextExprValue(value, {
                recordDataSource: funcArg?.item,
                valueDataSource: funcArg?.value,
              });
            }
            return undefined;
          }
        }
        const result = {};
        for (const key in value) {
          if (key) {
            const v = value[key];
            if (
              typeof v === 'function' &&
              value[`__${key}ExecuteImmediately__`] !== true
            ) {
              result[key] = v;
            } else {
              result[key] = calculateValue({
                value: v,
                funcArg,
              });
            }
          }
        }
        return result;
      }
    } else if (typeof value === 'string' && value?.startsWith('__record__.')) {
      const fieldKey = value?.split('__record__.')[1];
      return funcArg?.item?.[fieldKey];
    } else {
      return value;
    }
  };

  const transformFormatSchema = (funcArg, schema) => {
    const newFormatSchema = cloneDeep(schema);
    if (isArrayNotEmpty(schema)) {
      for (const newFormatSchemaKey in newFormatSchema) {
        const item = newFormatSchema[newFormatSchemaKey];
        if (isPlainObject(item)) {
          const { format, options, itemStyle } = item || {};
          if (format === 'CnFormatArrayView2') {
            newFormatSchema[newFormatSchemaKey] = {
              format,
              itemStyle,
              value: transformFormatSchema(funcArg, item?.options?.formatSchema),
              gap: options?.gap,
              flexWrap: options?.flexWrap,
              justifyContent: options?.justifyContent,
              _context,
            };
          } else {
            const newOptions = calculateValue({ value: options, funcArg });
            newFormatSchema[newFormatSchemaKey] = {
              ...item,
              ...transComponentProps,
              options: undefined,
              ...newOptions,
              _context,
            };
          }
        }
      }
    }
    return newFormatSchema;
  };

  if (!active) {
    return (
      <UICnFormat
        format={'CnFormatArrayView2'}
        value={transformFormatSchema(functionArg, formatSchema)}
        __getContextData__={props?.__getContextData__}
        flexWrap={flexWrap}
        justifyContent={justifyContent}
        gap={gap}
        _context={_context}
      />
    );
  }

  let loopData = executeFunction(loop, functionArg);

  if (!isArrayNotEmpty(loopData) && isDesign) {
    loopData = [{}];
  }

  if (isArrayNotEmpty(loopData)) {
    return loopData?.map((item, index) => {
      const funcArg = {
        item,
        index,
        state: _context?.state,
        _context,
      };
      return (
        <UICnFormat
          format={'CnFormatArrayView2'}
          value={transformFormatSchema(funcArg, formatSchema)}
          __getContextData__={() => {
            return {
              functionArg: funcArg,
            };
          }}
          flexWrap={flexWrap}
          justifyContent={justifyContent}
          gap={gap}
          _context={_context}
        />
      );
    });
  }
  return null;
});
CnFormat.displayName = 'CnFormat';
export default CnFormat;
export { CnFormat };
