import $i18n from '@/locales/i18n';
import React, { useState } from 'react';
import { Button, CnBalloon, CnButton, CnTooltip, MenuButton, SplitButtonGroup, } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { ImportField } from './import-field';
import isPlainObject from 'lodash/isPlainObject';
import { handleI18nLabel } from '@/common/util/util';
const { CodeControl } = window?.VisualEngineUtils || {};
export const TableAddOneBtn = (props) => {
    const [importVisible, setImportVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [code, setCode] = useState('');
    const { onAdd, prop, dataPrimaryKey, handleFieldList } = props;
    const currentId = prop?.getNode?.()?.id;
    const allInsert = () => {
        let result = getNewList();
        if (typeof handleFieldList === 'function') {
            result = handleFieldList(result);
        }
        if (result.length > 0) {
            props?.prop?.setValue(result);
            const sel = window?.AliLowCodeEngine?.project?.currentDocument?.selection;
            if (sel) {
                sel?.select?.();
                setTimeout(() => {
                    sel?.select?.(currentId);
                });
            }
        }
    };
    const addInsert = () => {
        const primaryKey = dataPrimaryKey || 'dataIndex';
        const existColumns = props?.prop?.getValue() || [];
        const existMap = {};
        existColumns.forEach((item) => {
            if (item[primaryKey]) {
                existMap[item[primaryKey]] = true;
            }
        });
        let result = getNewList();
        if (typeof handleFieldList === 'function') {
            result = handleFieldList(result);
        }
        const toAddList = [...existColumns];
        if (result.length > 0) {
            result.forEach((item) => {
                if (!(item[primaryKey] && existMap[item[primaryKey]])) {
                    toAddList.push(item);
                }
            });
            if (toAddList.length > 0) {
                props?.prop?.setValue(toAddList);
                const sel = window?.AliLowCodeEngine?.project?.currentDocument?.selection;
                if (sel) {
                    sel?.select?.();
                    setTimeout(() => {
                        sel?.select?.(currentId);
                    });
                }
            }
        }
        setVisible(false);
    };
    const getNewList = () => {
        const list = [];
        try {
            const originList = JSON.parse(code);
            if (originList.length > 0) {
                originList.forEach((item = {}) => {
                    let title;
                    let dataIndex;
                    const originTitle = handleI18nLabel(item.title);
                    if (typeof originTitle === 'string') {
                        title = originTitle;
                    }
                    else if (isPlainObject(originTitle) && originTitle?.zh_CN) {
                        title = originTitle?.zh_CN;
                    }
                    title = handleI18nLabel(title);
                    if (item.dataIndex) {
                        dataIndex = item.dataIndex;
                    }
                    else if (item.dataKey) {
                        dataIndex = item.dataKey;
                    }
                    if (dataIndex && title) {
                        list.push({
                            dataIndex,
                            title,
                            format: 'text',
                        });
                    }
                });
            }
        }
        catch (e) { /* empty */ }
        return list;
    };
    return (<SplitButtonGroup className={'cn-array-setter-add-one-btn'}>
      <CnButton onClick={() => onAdd?.()} type={'secondary'} style={{ flex: 1 }}>
        {$i18n.get({ id: 'AddAnItem', dm: '添加一项' })}
      </CnButton>
      <MenuButton autoWidth={false} type={'secondary'} label={$i18n.get({ id: 'QuickImport', dm: '快速导入' })}>
        <MenuButton.Item onClick={() => setImportVisible(true)}>
          {$i18n.get({
            id: 'SelectFieldsOfOtherComponentsAnd_586323906',
            dm: '选择其他组件的字段并导入',
        })}
        </MenuButton.Item>
        <MenuButton.Item onClick={() => setVisible(true)}>
          {$i18n.get({
            id: 'ImportTheTableColumnConfiguratio_2060901436',
            dm: '导入老Cone搭建的表格列配置',
        })}
        </MenuButton.Item>
      </MenuButton>
      <CnBalloon 
    // type={'secondary'}
    visible={visible} align={'tr'} v2 onVisibleChange={(v) => {
            setVisible(v);
        }} closable triggerType={'click'} title={<div className="">
            {$i18n.get({
                id: 'PasteTableColumnJSONToImport',
                dm: '粘贴要导入的表格列JSON',
            })}{' '}
            <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/NkPaj7GAXdpWOkPeZ6p0Wqwgylnomz9B?utm_scene=team_space&iframeQuery=anchorId%3Duu_lbusmehud8ouhpilqw" rel="noreferrer">
              {$i18n.get({ id: 'UsingDocuments', dm: '使用文档' })}
            </a>
          </div>} trigger={' '}>
        <div className="">
          <div style={{ marginBottom: '10px' }}>
            {$i18n.get({ id: 'PleaseGoToTheOldCone', dm: '请到老cone' })}{' '}
            <CnTooltip v2 align={'tr'} trigger={<a>{$i18n.get({ id: 'HeaderJSON', dm: '表头JSON' })}</a>}>
              <div style={{ width: '260px', height: '190px' }}>
                <img style={{ width: '100%' }} src="https://img.alicdn.com/imgextra/i4/O1CN01UT7hFP1DLdyo9g5hs_!!6000000000200-0-tps-1812-1272.jpg"/>
              </div>
            </CnTooltip>
            {$i18n.get({
            id: 'CopyAndPasteIntoTheCodeBox',
            dm: '中复制，并粘贴到代码框中',
        })}
          </div>
          <CodeControl value={code} options={{ minimap: { enabled: false }, lineNumbers: 'off' }} className="" mode="json" // 'javascript', 'json', 'html', 'css', 'jsx'
     fontSize={14} tabSize={2} 
    // readOnly={false}
    style={{ width: '100%', height: 200 }} onChange={(_code) => {
            setCode(_code);
        }} 
    // // 如果为 false 或者 0 则不启动节流阀
    throttle={600} lineNumbers={'off'} enableOutline={false}/>
          <div style={{
            paddingTop: '10px',
            borderTop: '1px solid #ddd',
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
            <Button size={'small'} onClick={allInsert}>
              {$i18n.get({ id: 'OverwriteImport', dm: '覆盖导入' })}
            </Button>
            &nbsp;&nbsp;
            <Button size={'small'} onClick={addInsert} type={'secondary'}>
              {$i18n.get({ id: 'IncrementalImport', dm: '增量导入' })}
            </Button>
          </div>
        </div>
      </CnBalloon>
      <ImportField visible={importVisible} setVisible={setImportVisible} prop={prop} dataPrimaryKey={dataPrimaryKey} trigger={<div />}/>
    </SplitButtonGroup>);
};
