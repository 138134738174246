import { CnListProItem as UICnListProItem } from '@cainiaofe/cn-ui';
import React from 'react';
import { executeEventWithoutJS, isDesignMode } from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';
export class CnListProItem extends React.Component {
    render() {
        const isDesign = isDesignMode(this.props);
        const { slotManager, children, events, _context } = this.props || {};
        const { titleSlot, actionsSlot, avatarSlot, descriptionSlot, extraSlot, bottomAreaSlot } = slotManager || {};
        let title;
        let avatar;
        let description;
        let action;
        let extra;
        let bottomArea;
        if (Array.isArray(children)) {
            children?.forEach((item) => {
                const { __componentName__, _componentName } = item?.props || {};
                if (__componentName__ === 'CnListProItemAvatar' || _componentName === 'CnListProItemAvatar') {
                    avatarSlot && (avatar = item);
                }
                else if (__componentName__ === 'CnListProItemTitle' || _componentName === 'CnListProItemTitle') {
                    titleSlot && (title = item);
                }
                else if (__componentName__ === 'CnListProItemDescription' || _componentName === 'CnListProItemDescription') {
                    descriptionSlot && (description = item);
                }
                else if (__componentName__ === 'CnListProItemAction' || _componentName === 'CnListProItemAction') {
                    actionsSlot && (action = item);
                }
                else if (__componentName__ === 'CnListProItemExtra' || _componentName === 'CnListProItemExtra') {
                    extraSlot && (extra = item);
                }
                else if (__componentName__ === 'CnListProItemBottomArea' || _componentName === 'CnListProItemBottomArea') {
                    bottomAreaSlot && (bottomArea = item);
                }
            });
        }
        const extraProps = {};
        if (Array.isArray(events) && events.length > 0) {
            extraProps.onClick = (e, record) => {
                if (!isDesign) {
                    executeEventWithoutJS({
                        eventType: 'onListProItemClick',
                        events,
                        _context,
                        position: ButtonPosition.listProItemEvent,
                        recordDataSource: record,
                    });
                }
            };
        }
        return (<UICnListProItem title={title} description={description} actions={action} avatar={avatar} extra={extra} bottomArea={bottomArea} {...extraProps}/>);
    }
}
CnListProItem.displayName = 'CnListProItem';
