import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { getJSExpressionPrototype, getParamSetterPrototype, } from '@/common/manager/common-style';
import { ButtonPosition } from '@/common/manager/position/button-position';
// eslint-disable-next-line import/no-cycle
import { executeFunction, getArrayTableFieldList, getCnFormatPluginList, getDialogTitle, getNodeById, handleI18nLabel, handleOriginList, handlePrototypeCondition, isArrayNotEmpty, isRecursionComponent, } from '@/common/util/util';
import { CnTooltip, Select } from '@cainiaofe/cn-ui';
import { getFormatDocSetter } from '@/common/setter/cn-format-setter';
import { __arrayTableCurrentRow__, __dataSource__, __extraParam__, formDialogStateLabel, getCommonBoolDataSource, getFormExprNameByPosition, getOpenDialogModeEnum, openDialogModeLabel, } from '@/common/util/expr-const';
// eslint-disable-next-line import/no-cycle
import { createButtonListSetters, createFilterSelectSetters, createTableSelectSetters, getButtonListByPosition, getButtonPrototypeListByPosition, getItemDefaultProps, } from '@/common/manager/button';
import { DisplayPosition } from '@/common/manager/position/display-position';
import ExprSetter from '@/common/setter/expr-setter';
import { componentTitleKeyMap, dataOriginRequest, dataOriginStatic, } from '@/common/util/const';
import isFunction from 'lodash/isFunction';
import { createEventSetters } from '@/common/manager/event';
const { ActionSetter } = window.VisualEngineUtils || {};
export function getStringSetter() {
    return {
        componentName: 'StringSetter',
        title: $i18n.get({ id: 'String', dm: '字符串' }),
    };
}
const getTableOperateRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'table', prototypeConfig: config }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tableRequest',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getTableOperateUrlSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getParamSetterPrototype({ type: 'table', prototypeConfig: config }),
                getJSExpressionPrototype({
                    type: 'tableRequest',
                    prototypeConfig: config,
                }),
            ],
        },
    };
};
const getTableToolAreaUrlSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getParamSetterPrototype({ type: 'filter', prototypeConfig: config }),
                getJSExpressionPrototype({
                    type: 'formRequest',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getTableToolAreaRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'filter', prototypeConfig: config }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tableBatchArea',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getTableToolAreaRequestParamSetter2 = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'filter' }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'formRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
// 带表格当前页信息请求参数的setter
const getTableToolAreaRequestParamSetter3 = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'filterWithPagination' }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'formRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getFormRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'form', prototypeConfig: config }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'formRequest',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getFormUrlSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getParamSetterPrototype({ type: 'form', prototypeConfig: config }),
                getJSExpressionPrototype({
                    type: 'formRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getFormDialogRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({
                    type: 'formDialog',
                    prototypeConfig: config,
                }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tableBatchArea',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getArrayTableOperateRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'arrayTable' }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tableRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getArrayTableItemEventRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'arrayTableItemEvent' }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tableRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
// 自增卡片中子卡片请求参数setter
const getArrayCardSubcardRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({
                    type: 'arrayCardSubCardButton',
                    prototypeConfig: config,
                }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'arrayTableCurrentRowRequest',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getArrayTableOperateUrlSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getParamSetterPrototype({ type: 'arrayTable' }),
                getJSExpressionPrototype({
                    type: 'tableRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getStepRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'base', prototypeConfig: config }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'base',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getListOperateRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tableRequest',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getStepUrlSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getParamSetterPrototype({ type: 'base' }),
                getJSExpressionPrototype({
                    type: 'base',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getTabUrlSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getStringSetter(),
                getParamSetterPrototype({ type: 'base' }),
                getJSExpressionPrototype({
                    type: 'tab',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getTabRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'base' }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'tab',
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const getTreeEventRequestParamSetter = (config) => {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                getParamSetterPrototype({ type: 'tree', prototypeConfig: config }),
                getStringSetter(),
                getJSExpressionPrototype({
                    type: 'base',
                    prototypeConfig: config,
                    extraSlot: config.templates,
                }),
            ],
        },
    };
};
const templatesMap = {
    /* i18n-disable-next-line */
    urlLink: `// 返回字符串,当返回的值包含http的时候，会作为链接地址直接跳转;其他情况则会拼接到指定链接后面
  // return 'https://dsm.cone.cainiao-inc.com/components/cnui/overview' 
  // return '/cone/adcenter/zhuque/promotion/rtb/campaignDetail'`,
    /* i18n-disable-next-line */
    urlParams: `// 当前返回的参数值会被拼接到链接地址后面
  // return 'params' || state.xxxx`,
    /* i18n-disable-next-line */
    requestParams: `// 返回自定义类型参数值
  // return '参数值'`,
};
const getConfigWithTemplates = (config, type) => ({
    ...config,
    templates: config?.template || templatesMap[type],
});
export function getSetterSnippet(config) {
    const { position, optType, additionalPosition } = config || {};
    let urlSetter;
    let requestParamSetter;
    const urlConfig = getConfigWithTemplates(config, 'urlLink');
    const urlParamsConfig = getConfigWithTemplates(config, 'urlParams');
    const paramsConfig = getConfigWithTemplates(config, 'requestParams');
    if (position && optType) {
        switch (optType) {
            case 'link':
                switch (position) {
                    case ButtonPosition.listItemRightButton:
                        urlSetter = getListOperateRequestParamSetter(urlConfig);
                        requestParamSetter =
                            getListOperateRequestParamSetter(urlParamsConfig);
                        break;
                    case ButtonPosition.tableOperate:
                    case ButtonPosition.tableCell:
                        urlSetter = getTableOperateUrlSetter(urlConfig);
                        requestParamSetter =
                            getTableOperateRequestParamSetter(urlParamsConfig);
                        break;
                    case ButtonPosition.tableToolArea:
                    case ButtonPosition.tableBatchArea:
                    case ButtonPosition.tableToolAreaAfterRequestSuccess:
                        urlSetter = getTableToolAreaUrlSetter(urlConfig);
                        requestParamSetter =
                            getTableToolAreaRequestParamSetter(urlParamsConfig);
                        break;
                    case ButtonPosition.form:
                    case ButtonPosition.formDialog:
                    case ButtonPosition.filterItemEvent:
                    case ButtonPosition.formItemEvent:
                    case ButtonPosition.formDialogItemEvent:
                    case ButtonPosition.formSubmitAfterRequestSuccess:
                    case ButtonPosition.cardAction:
                    case ButtonPosition.subCardAction:
                    case DisplayPosition.form:
                    case DisplayPosition.formDialog:
                    case ButtonPosition.formItemRightButton:
                        urlSetter = getFormUrlSetter(urlConfig);
                        if (additionalPosition === DisplayPosition.cnArraySubAreaCard) {
                            requestParamSetter =
                                getArrayCardSubcardRequestParamSetter(urlParamsConfig);
                        }
                        else {
                            requestParamSetter = getFormRequestParamSetter(urlParamsConfig);
                        }
                        break;
                    case ButtonPosition.arrayTableOperate:
                    case ButtonPosition.arrayTableCell:
                        urlSetter = getArrayTableOperateUrlSetter(urlConfig);
                        requestParamSetter =
                            getArrayTableOperateRequestParamSetter(urlParamsConfig);
                        break;
                    case ButtonPosition.tabEvent:
                    case ButtonPosition.entryPointEvent:
                    case ButtonPosition.listItemEvent:
                    case ButtonPosition.listProItemEvent:
                    case ButtonPosition.statisticCardRightButton:
                        urlSetter = getTabUrlSetter(urlConfig);
                        requestParamSetter = getTabRequestParamSetter(urlParamsConfig);
                        break;
                    case ButtonPosition.arraySubAreaCardOperate:
                        requestParamSetter =
                            getArrayTableItemEventRequestParamSetter(urlParamsConfig);
                        break;
                    default:
                        urlSetter = getStepUrlSetter(urlConfig);
                        requestParamSetter = getStepRequestParamSetter(urlParamsConfig);
                        break;
                }
                break;
            case 'request':
            case 'remove':
                switch (position) {
                    case ButtonPosition.tableOperate:
                    case ButtonPosition.tableCell:
                        requestParamSetter =
                            getTableOperateRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.tableToolArea:
                    case ButtonPosition.tableBatchArea:
                        requestParamSetter =
                            getTableToolAreaRequestParamSetter2(paramsConfig);
                        break;
                    case ButtonPosition.form:
                    case ButtonPosition.formDialog:
                    case ButtonPosition.cardAction:
                    case ButtonPosition.subCardAction:
                    case ButtonPosition.formItemEvent:
                    case ButtonPosition.formDialogItemEvent:
                    case ButtonPosition.filterEvent:
                    case ButtonPosition.filterItemEvent:
                    case ButtonPosition.formItemRightButton:
                        requestParamSetter = getFormRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.arrayTableOperate:
                        requestParamSetter =
                            getArrayTableOperateRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.cnArrayTableItemEvent:
                    case ButtonPosition.arraySubAreaCardOperate:
                    case ButtonPosition.cnArraySubAreaCardItemEvent:
                        requestParamSetter =
                            getArrayTableItemEventRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.dialog:
                    case ButtonPosition.step:
                    case ButtonPosition.treeNode:
                    case ButtonPosition.result:
                    case ButtonPosition.stepEvent:
                    case ButtonPosition.tabEvent:
                    case ButtonPosition.pageDidMount:
                    case ButtonPosition.blockButton:
                        requestParamSetter = getStepRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.treeEvent:
                        requestParamSetter = getTreeEventRequestParamSetter(paramsConfig);
                        break;
                }
                break;
            case 'batch':
                requestParamSetter = getTableToolAreaRequestParamSetter(paramsConfig);
                break;
            case 'submit':
                switch (position) {
                    case ButtonPosition.form:
                    case ButtonPosition.cardAction:
                        requestParamSetter = getFormRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.formDialog:
                        requestParamSetter = getFormDialogRequestParamSetter(paramsConfig);
                        break;
                }
                break;
            case 'download':
                switch (position) {
                    case ButtonPosition.cardAction:
                    case ButtonPosition.subCardAction:
                        requestParamSetter = getFormRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.tableBatchArea:
                        requestParamSetter =
                            getTableToolAreaRequestParamSetter3(paramsConfig);
                        break;
                }
                break;
            case 'export':
                switch (position) {
                    case ButtonPosition.tableOperate:
                        requestParamSetter =
                            getTableOperateRequestParamSetter(paramsConfig);
                        break;
                    case ButtonPosition.tableBatchArea:
                    case ButtonPosition.tableToolArea:
                        requestParamSetter =
                            getTableToolAreaRequestParamSetter2(paramsConfig);
                        break;
                }
                break;
        }
    }
    return {
        urlSetter,
        requestParamSetter,
    };
}
export function getStaticDataSourceSnippet(config) {
    const { extraArraySetterItems = [], jsExpressionSetter } = config || {};
    const realJsExpressionSetter = jsExpressionSetter ||
        getJSExpressionPrototype({
            type: 'select',
            offlineTip: $i18n.get({
                id: 'JSCodeEditingUseDynamicControlDa_642285703',
                dm: '动态控制数据推荐使用「JS代码编辑」设置该属性',
            }),
        });
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'ArraySetter',
                    title: $i18n.get({ id: 'SetStaticData', dm: '设置静态数据' }),
                    props: {
                        mode: 'list',
                        itemSetter: {
                            componentName: 'ObjectSetter',
                            initialValue: {
                                label: $i18n.get({ id: 'Name.IL5MN', dm: '名称' }),
                                value: 'jack',
                            },
                            props: {
                                config: {
                                    items: [
                                        {
                                            name: 'label',
                                            display: 'inline',
                                            title: $i18n.get({
                                                id: '31255238841339904.CNTM',
                                                dm: '标签',
                                            }),
                                            isRequired: true,
                                            setter: 'CnI18nSetter',
                                        },
                                        {
                                            name: 'value',
                                            display: 'inline',
                                            title: $i18n.get({ id: 'Value', dm: '值' }),
                                            isRequired: true,
                                            setter: {
                                                componentName: 'MixedSetter',
                                                props: {
                                                    setters: [
                                                        {
                                                            componentName: 'StringSetter',
                                                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                                                        },
                                                        {
                                                            componentName: 'NumberSetter',
                                                            title: $i18n.get({ id: 'Numbers', dm: '数字' }),
                                                        },
                                                        {
                                                            componentName: 'BoolSetter',
                                                            title: $i18n.get({
                                                                id: 'BooleanTruefalse',
                                                                dm: '布尔（true/false）',
                                                            }),
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                        ...extraArraySetterItems,
                                    ],
                                },
                            },
                        },
                    },
                },
                {
                    componentName: 'JsonSetter',
                    title: $i18n.get({ id: 'JSONEditor', dm: 'JSON编辑器' }),
                },
                {
                    componentName: 'ParamSelectSetter',
                    props: {
                        dataKey: 'aa',
                        labelKey: 'aa',
                        valueKey: 'aa',
                        groupName: $i18n.get({ id: 'ListOfParameters', dm: '参数列表' }),
                    },
                    title: $i18n.get({ id: 'SelectFromDataSource', dm: '从数据源选择' }),
                },
                {
                    componentName: (<ActionSetter defaultCode={$i18n.get({
                            id: 'GetStaticDataSourceFunctionGetSt_1363414519',
                            dm: "/**\n * getStaticDataSource\n */\nfunction getStaticDataSource() {\n  // 需要返回数组，格式如下\n  return [\n    {\n      label: '选项一',\n      value: 'first'\n    }\n  ];\n}",
                        })} defaultActionName="getStaticDataSource"/>),
                    title: $i18n.get({ id: 'JSCodeEditing', dm: 'JS代码编辑' }),
                },
                realJsExpressionSetter,
                {
                    componentName: 'VariableSetter',
                },
            ],
        },
    };
}
export function getSelectDialogSnippet(config) {
    return {
        title: $i18n.get({ id: 'AssociatedPopupWindow', dm: '关联弹窗' }),
        name: '_bindDialog',
        description: $i18n.get({
            id: 'SelectThePopupWindowToBeOpened',
            dm: '选择将要打开的弹窗',
        }),
        setter(prop) {
            const options = [];
            prop?.getNode?.()?.document?.nodesMap?.forEach((item) => {
                if (item.getPropValue('isCnDialog')) {
                    const id = item.id || '';
                    // 二次确认
                    const current = prop.getNode?.()?.document?.getNode?.(id);
                    if (current) {
                        const title = getDialogTitle(current);
                        options.push({
                            title,
                            value: id,
                        });
                    }
                }
            });
            return {
                componentName: 'CnSelectSetter',
                props: {
                    options: [...options],
                    selectProps: {
                        hasClear: true,
                        autoWidth: true,
                    },
                },
            };
        },
        ...config,
    };
}
export function getJSXTemplate(config) {
    if (config) {
        const { position } = config || {};
        const temp = {
            componentName: 'CnJSXSetter',
            props: {
                usePopup: true,
                enableFullscreen: true,
                theme: 'vs',
            },
            title: $i18n.get({ id: 'ReactJSXCode', dm: 'React JSX代码' }),
            initialValue: {
                type: 'js',
                source: $i18n.get({
                    id: 'FunctionContentState',
                    dm: 'function content(_, state) { \n  // state: 全部组件的数据 \n}',
                }),
                compiled: $i18n.get({
                    id: 'FunctionMain',
                    dm: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(_, state) {\n  // state: 全部组件的数据 \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                }),
                error: {},
            },
        };
        if ([ButtonPosition.tableToolArea, ButtonPosition.tableBatchArea].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: $i18n.get({
                    id: 'FunctionContentformValueStateSel_715766825',
                    dm: 'function content(formValue, state, selectedRowKeys, selectedRowRecords) { \n  // formValue：当前筛选栏的所有数据。\n  // state：全部组件的数据。\n  // selectedRowKeys：当前选择的数据主键列表。\n  // selectedRowRecords：当前选择的数据列表。\n}',
                }),
                compiled: $i18n.get({
                    id: 'FunctionMain.QAGXB',
                    dm: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(formValue, state, selectedRowKeys, selectedRowRecords) {\n  // formValue：当前筛选栏的所有数据。\n  // state：全部组件的数据。\n  // selectedRowKeys：当前选择的数据主键列表。\n  // selectedRowRecords：当前选择的数据列表。\n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                }),
                error: {},
            };
        }
        else if ([ButtonPosition.formDialog, ButtonPosition.form].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: $i18n.get({
                    id: 'FunctionContentformValueState',
                    dm: 'function content(formValue, state) { \n  // formValue: 当前表单的所有数据\n  // state: 全部组件的数据\n \n}',
                }),
                compiled: $i18n.get({
                    id: 'FunctionMainUseStrict.fYN2W',
                    dm: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(formValue, state) {\n // formValue: 当前表单的所有数据\n  // state: 全部组件的数据\n \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                }),
                error: {},
            };
        }
        else if ([
            ButtonPosition.dialog,
            ButtonPosition.result,
            ButtonPosition.step,
            ButtonPosition.pageRightButton,
            DisplayPosition.detailInfoDescriptionRender,
        ].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: $i18n.get({
                    id: 'FunctionContentState.83Or8',
                    dm: 'function content(_, state) { \n  // state：全部组件的数据\n}',
                }),
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(_, state) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        else if ([DisplayPosition.tableTopSlot].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: 'function content(selectedRowKeys, selectedRowRecords, dataSource, tableInfo, state) { \n  \n}',
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(selectedRowKeys, selectedRowRecords, dataSource, tableInfo, state) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        else if ([DisplayPosition.arrayTableSummary].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: 'function summary(dataSource, Summary, state) { \n  \n}',
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function summary(dataSource, Summary, state) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        else if ([DisplayPosition.tabItem].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: 'function content(currentItem, state) { \n  \n}',
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(currentItem, state) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        else if ([DisplayPosition.tableCell].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: 'function content(value, index, record) { \n return {value} \n}',
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(value, index, record) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        else if ([ButtonPosition.cardAction].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: $i18n.get({
                    id: 'FunctionContentformValueState',
                    dm: 'function content(formValue, state) { \n  // formValue: 当前表单的所有数据\n  // state: 全部组件的数据\n \n}',
                }),
                compiled: $i18n.get({
                    id: 'FunctionMainUseStrict.fYN2W',
                    dm: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(formValue, state) {\n // formValue: 当前表单的所有数据\n  // state: 全部组件的数据\n \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                }),
                error: {},
            };
        }
        else if ([
            DisplayPosition.listItemContentRender,
            DisplayPosition.listItemDescriptionRender,
        ].includes(position)) {
            temp.initialValue = {
                type: 'js',
                source: 'function itemContentRender(record, index, state) { \n  return null; \n}',
                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function itemContentRender(record, index, state) {\n  return null; \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                error: {},
            };
        }
        return temp;
    }
}
export function getRequestWhenFocusSetterSnippet() {
    return {
        name: 'requestWhenFocus',
        title: $i18n.get({
            id: 'ResendRequestWhenFocusIsObtained',
            dm: '获得焦点时重新发请求',
        }),
        display: 'inline',
        defaultValue: true,
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: getCommonBoolDataSource(),
            },
        },
    };
}
export function getFormStateSetterSnippet(config) {
    const { title, children, extraConfig = {} } = config || {};
    const ds = getOpenDialogModeEnum({});
    return {
        groupExprName: __extraParam__,
        handleCustomGroup: () => {
            return {
                label: title || formDialogStateLabel,
                children: children || [
                    {
                        label: openDialogModeLabel,
                        value: `${__extraParam__}.openDialogMode`,
                    },
                ],
            };
        },
        renderTypeDom: () => {
            return null;
        },
        renderValueDom: (props) => {
            const { value, onChange } = props || {};
            return (<Select size={'small'} hasClear value={value?.value} dataSource={ds} onChange={(v) => {
                    onChange?.(value, {
                        value: v,
                    });
                }}/>);
        },
        ...extraConfig,
    };
}
export function getDataOriginSetterSnippet(config = {}) {
    return {
        name: 'dataOrigin',
        title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
        display: 'block',
        initialValue: 'static',
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: [
                    {
                        title: $i18n.get({ id: 'RemoteRequest', dm: '远程请求' }),
                        value: 'request',
                    },
                    {
                        title: $i18n.get({ id: 'StaticData', dm: '静态数据' }),
                        value: 'static',
                    },
                ],
            },
        },
        ...config,
    };
}
export function getDateTypeSetterSnippet(config = {}) {
    return {
        name: 'type',
        title: $i18n.get({ id: 'Type', dm: '类型' }),
        display: 'inline',
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: [
                    {
                        title: $i18n.get({ id: 'Date', dm: '日期' }),
                        value: 'date',
                        tip: $i18n.get({ id: 'DatePicker', dm: '日期选择器' }),
                    },
                    {
                        title: $i18n.get({ id: 'Week', dm: '周' }),
                        value: 'week',
                        tip: $i18n.get({ id: 'WeekSelector', dm: '周选择器' }),
                    },
                    {
                        title: $i18n.get({ id: 'Month', dm: '月' }),
                        value: 'month',
                        tip: $i18n.get({ id: 'MonthSelector', dm: '月选择器' }),
                    },
                    {
                        title: $i18n.get({ id: 'Year', dm: '年' }),
                        value: 'year',
                        tip: $i18n.get({ id: 'YearSelector', dm: '年选择器' }),
                    },
                ],
            },
        },
        ...config,
    };
}
export function getTableCellClickSetterSnippet(config) {
    const { buttonPosition } = config || {};
    return {
        componentName: 'ObjectSetter',
        props: {
            config: {
                items: [
                    {
                        name: 'optType',
                        title: $i18n.get({ id: 'ButtonFunction', dm: '按钮功能' }),
                        display: 'inline',
                        setter: {
                            componentName: 'CnSelectSetter',
                            props: {
                                selectProps: {
                                    hasClear: true,
                                },
                                options: getButtonListByPosition(buttonPosition),
                            },
                        },
                    },
                    {
                        name: 'options',
                        display: 'plain',
                        title: $i18n.get({
                            id: 'ButtonConfigurationItems',
                            dm: '按钮配置项',
                        }),
                        setter: {
                            componentName: 'ObjectSetter',
                            props: {
                                config: {
                                    items: [...getButtonPrototypeListByPosition(buttonPosition)],
                                },
                            },
                        },
                    },
                ],
            },
        },
    };
}
export function getRequestConfigSetterSnippet(config, extraConfig) {
    const { extraServiceProps, position } = extraConfig || {};
    let extraConfigSetter;
    const requestExecuteSetter = getRequestExecuteSetter({
        exprSetter: getExprSetterSnippet({
            position,
            ignoreArrayTableCurrentRow: true,
        }),
    });
    if (requestExecuteSetter) {
        extraConfigSetter = [requestExecuteSetter];
    }
    return {
        name: 'requestConfig',
        title: $i18n.get({ id: 'QueryService', dm: '查询服务' }),
        display: 'inline',
        setter: {
            componentName: 'ServiceChoiceSetter',
            props: {
                mockDataTemplate: {
                    success: true,
                    data: [
                        {
                            label: $i18n.get({ id: 'OptionOne', dm: '选项一' }),
                            value: 'first',
                            children: [
                                {
                                    label: $i18n.get({ id: 'SubOptionOne', dm: '子选项一' }),
                                    value: 'sub1',
                                },
                            ],
                        },
                        {
                            label: $i18n.get({ id: 'Option2.NBzVe', dm: '选项二' }),
                            value: 'second',
                        },
                    ],
                },
                paramTitleDom: (<div className="">
            {$i18n.get({
                        id: 'RequestParameterConfiguration',
                        dm: '请求参数配置：',
                    })}
          </div>),
                responseDom: (<div style={{ paddingTop: '10px' }}>
            {$i18n.get({
                        id: 'DataStructureOfTheRequestReturnR_137379149',
                        dm: '请求返回结果的数据结构：',
                    })}
            <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/3mzaPNMZ6jkJq3dwkOLLWYLDwXq4Ky1r" rel="noreferrer">
              {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
            </a>{' '}
            <CnTooltip v2 align={'t'} trigger={<a>{$i18n.get({ id: 'InterfacePreview', dm: '接口预览' })}</a>}>
              <div style={{ width: '200px', height: '260px' }}>
                <img style={{ width: '100%' }} src="https://img.alicdn.com/imgextra/i1/O1CN01W5SNFR25Fg4QyRC53_!!6000000007497-0-tps-456-570.jpg"/>
              </div>
            </CnTooltip>
          </div>),
                buttonText: $i18n.get({ id: 'SelectRequestAPI', dm: '选择请求API' }),
                params: {
                    env: 'pre',
                    pageSize: 999,
                    // serviceType: 'HSF',
                },
                extraConfigSetter,
                paramSetter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'ParamSelectSetter',
                                props: {
                                    dataKey: 'config',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({
                                        id: 'ListOfParameters',
                                        dm: '参数列表',
                                    }),
                                },
                                title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
                            },
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                            },
                            getJSExpressionPrototype({ type: 'formRequest' }),
                        ],
                    },
                },
                resultProcessFuncTemplate: $i18n.get({
                    id: 'FunctionresTheFollowingDataStruc_958986499',
                    dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: [\n  //     {\n  //        label:"xx",\n  //        value:"xx",\n  //     }\n  //   ]\n  // }\n return res;\n}',
                }),
                ...extraServiceProps,
            },
        },
        ...config,
    };
}
export function getColsSetterSnippet(config = {}) {
    return {
        name: 'cols',
        title: $i18n.get({ id: 'ARowOfSeveralColumns', dm: '一行几列' }),
        display: 'inline',
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: [
                    {
                        title: $i18n.get({ id: 'Adaptive', dm: '自适应' }),
                        value: undefined,
                    },
                    { title: '2', value: 2 },
                    { title: '3', value: 3 },
                    { title: '4', value: 4 },
                    { title: '5', value: 5 },
                    { title: '6', value: 6 },
                ],
            },
        },
        ...config,
    };
}
export function getStepShapeSetterSnippet() {
    return {
        title: $i18n.get({ id: 'Style', dm: '样式' }),
        name: 'shape',
        defaultValue: 'circle',
        setter: {
            componentName: 'SelectSetter',
            props: {
                options: [
                    {
                        label: $i18n.get({ id: 'CircleCircle', dm: '圆形（circle）' }),
                        value: 'circle',
                    },
                    {
                        label: $i18n.get({ id: 'Arrow', dm: '箭头（arrow）' }),
                        value: 'arrow',
                    },
                    {
                        label: $i18n.get({ id: 'PointDot', dm: '点（dot）' }),
                        value: 'dot',
                    },
                ],
            },
        },
    };
}
export function getFormHandleTypeSetterSnippet(config) {
    const { componentTitle = $i18n.get({ id: 'Form', dm: '表单' }), componentName = 'Form', handleTypeName, } = config || {};
    return {
        title: $i18n.get({
            id: 'WhatToDoWithComponentTitleData',
            dm: '对{componentTitle}数据做何处理',
        }, { componentTitle }),
        name: handleTypeName || 'handleType',
        setter: {
            componentName: 'CnSelectSetter',
            props: {
                selectProps: {
                    hasClear: true,
                },
                options: [
                    {
                        label: $i18n.get({
                            id: 'SetDataForEachComponentTitleFiel_149684975',
                            dm: '给{componentTitle}每个字段设置数据',
                        }, { componentTitle }),
                        value: `set${componentName}FieldValueShallowMerge`,
                    },
                    {
                        label: $i18n.get({
                            id: 'SetDataForEachComponentTitleFiel_1207752325',
                            dm: '给{componentTitle}每个字段设置数据（merge模式）',
                        }, { componentTitle }),
                        value: `set${componentName}FieldValue`,
                    },
                    {
                        label: $i18n.get({
                            id: 'ClearDataForComponentTitle',
                            dm: '清空{componentTitle}的数据',
                        }, { componentTitle }),
                        value: `clear${componentName}Value`,
                    },
                    {
                        label: $i18n.get({
                            id: 'SetDataForTheEntireComponentTitl_1774913928',
                            dm: '给整个{componentTitle}设置数据',
                        }, { componentTitle }),
                        value: `setAll${componentName}Value`,
                    },
                ],
            },
        },
    };
}
export function getRequestExecuteSetter(config) {
    const { exprSetter } = config || {};
    if (isArrayNotEmpty(exprSetter)) {
        return {
            name: 'execute',
            display: 'inline',
            title: $i18n.get({
                id: 'WhetherTheRequestIsExecuted',
                dm: '请求是否执行',
            }),
            setter: {
                componentName: 'MixedSetter',
                props: {
                    setters: [
                        ...exprSetter,
                        {
                            title: $i18n.get({
                                id: 'BooleanTruefalse.9N8Sf',
                                dm: '布尔 (true/false)',
                            }),
                            componentName: 'RadioGroupSetter',
                            props: {
                                options: [
                                    {
                                        value: true,
                                        title: $i18n.get({ id: 'Execution', dm: '执行' }),
                                    },
                                    {
                                        value: false,
                                        title: $i18n.get({ id: 'DoNotExecute', dm: '不执行' }),
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        };
    }
}
export function getExprSetterSnippet(config) {
    const { position, ignoreArrayTableCurrentRow } = config || {};
    let configList = [];
    let jsExpressionPrototype;
    if ([
        DisplayPosition.form,
        DisplayPosition.filter,
        DisplayPosition.formDialog,
    ].includes(position)) {
        configList = [
            {
                dataKey: 'config',
                labelKey: 'label',
                valueKey: 'name',
                groupName: $i18n.get({ id: 'CurrentFormFields', dm: '当前表单字段' }),
                groupExprName: getFormExprNameByPosition({ position }),
                needSecondParam: false,
            },
            {
                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                groupExprName: __dataSource__,
                needSecondParam: true,
            },
        ];
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
        return [
            {
                componentName: <ExprSetter configList={configList}/>,
                title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
            },
            jsExpressionPrototype,
        ];
    }
    else if ([DisplayPosition.cnArrayTable].includes(position)) {
        configList = [
            {
                dataKey: 'config',
                labelKey: 'label',
                valueKey: 'name',
                groupName: $i18n.get({ id: 'CurrentFormFields', dm: '当前表单字段' }),
                groupExprName: getFormExprNameByPosition({ position }),
                needSecondParam: false,
            },
            {
                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                groupExprName: __dataSource__,
                needSecondParam: true,
            },
        ];
        if (ignoreArrayTableCurrentRow !== true) {
            configList.unshift({
                dataKey: 'arrayTable',
                labelKey: 'label',
                valueKey: 'name',
                groupName: $i18n.get({
                    id: 'TableCurrentRowData',
                    dm: '表格当前行数据',
                }),
                groupExprName: __arrayTableCurrentRow__,
                needSecondParam: false,
            });
        }
        jsExpressionPrototype = getJSExpressionPrototype({
            type: 'arrayTableCurrentRow',
        });
        return [
            {
                componentName: <ExprSetter configList={configList}/>,
                title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
            },
            jsExpressionPrototype,
        ];
    }
    else if (position === 'formDefaultValue') {
        configList = [
            {
                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                groupExprName: __dataSource__,
                needSecondParam: true,
            },
        ];
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'base' });
        return [
            {
                componentName: <ExprSetter configList={configList}/>,
                title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
            },
            jsExpressionPrototype,
        ];
    }
}
export function getSelectTableSnippet(config) {
    return createTableSelectSetters({}, config)[0];
}
export function getSelectFilterSnippet(config, extraConfig) {
    return createFilterSelectSetters(config, extraConfig)[0];
}
export function getNodeIdSetterSnippet() {
    return {
        name: '_nodeId',
        title: $i18n.get({ id: 'NodeID', dm: '节点 ID' }),
        display: 'inline',
        initialValue() {
            return this.getNode().id;
        },
        setter: 'IdSetter',
    };
}
export function getTableRemoteSnippet(config) {
    const { needMockRequestInTable = true, paramSelectSetter } = config || {};
    return {
        componentName: 'ServiceChoiceSetter',
        props: {
            paramTitleDom: (<div>
          {$i18n.get({
                    id: 'RequestParameterConfiguration.bCrZF',
                    dm: '请求参数配置',
                })}{' '}
          <span style={{ fontWeight: 'bold', color: 'red', marginLeft: '30px' }}>
            {$i18n.get({
                    id: 'TipWhenRequestingAFormTheDataIn',
                    dm: '提示：表格请求时会默认带上筛选栏的数据作为参数，无需手动配置！',
                })}
          </span>
        </div>),
            responseDom: (<div style={{ paddingTop: '10px' }}>
          {$i18n.get({
                    id: 'DataStructureOfTheRequestReturnR_137379149',
                    dm: '请求返回结果的数据结构：',
                })}
          <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/KOEmgBoGwD78veZwdymrJndLerP9b30a" rel="noreferrer">
            {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
          </a>{' '}
          <CnTooltip v2 align={'t'} trigger={<a>{$i18n.get({ id: 'InterfacePreview', dm: '接口预览' })}</a>}>
            <div style={{ width: '200px', height: '340px' }}>
              <img style={{ width: '100%' }} src="https://img.alicdn.com/imgextra/i3/O1CN01cjQiJp1EE99hRXt2d_!!6000000000319-0-tps-638-1090.jpg"/>
            </div>
          </CnTooltip>
        </div>),
            mockDataTemplate: {
                success: true,
                data: {
                    tableData: [
                        {
                            name: 'aa',
                            age: 18,
                        },
                    ],
                    paging: {
                        currentPage: 1,
                        pageSize: 10,
                        totalCount: 1,
                    },
                },
            },
            buttonText: $i18n.get({ id: 'SelectRequestAPI', dm: '选择请求API' }),
            params: {
                env: 'pre',
                pageSize: 999,
                // serviceType: 'HSF',
            },
            resultProcessFuncTemplate: $i18n.get({
                id: 'FunctionresTheFollowingDataStruc_634257367',
                dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: {\n  //     tableColumns: [{title:"xx",dataIndex:"xx"}],\n  //     tableData: [],\n  //     paging: {\n  //       currentPage: 1,\n  //       pageSize: 20,\n  //       totalCount: 99,\n  //     }\n  //   }\n  // }\n return res;\n}',
            }),
            needMockRequestInTable,
            paramSetter: paramSelectSetter || [
                getParamSetterPrototype({ type: 'filterWithPagination' }),
                {
                    componentName: 'StringSetter',
                    title: $i18n.get({ id: 'String', dm: '字符串' }),
                },
                getJSExpressionPrototype({ type: 'tableInitRequest' }),
            ],
        },
    };
}
export const getCnFormStepConfigSnippet = (config) => {
    const { extraConfig } = config || {};
    return [
        {
            name: 'cnFormStepConfig',
            title: $i18n.get({
                id: 'StepbystepFormConfiguration',
                dm: '分步表单配置',
            }),
            display: 'accordion',
            collapsed: true,
            setter: {
                componentName: 'ObjectSetter',
                props: {
                    config: {
                        items: [
                            {
                                name: 'current',
                                title: $i18n.get({ id: 'CurrentStep', dm: '当前步骤' }),
                                display: 'inline',
                                setter: {
                                    componentName: 'MixedSetter',
                                    props: {
                                        setters: [
                                            {
                                                componentName: 'NumberSetter',
                                                title: $i18n.get({
                                                    id: 'TheFirstFewSteps',
                                                    dm: '第几步',
                                                }),
                                            },
                                            getJSExpressionPrototype({ type: 'base' }),
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            },
            ...extraConfig,
        },
    ];
};
export function getLabelAlignSetterSnippet() {
    return {
        name: 'labelAlign',
        title: $i18n.get({ id: 'LabelLocation', dm: '标签位置' }),
        display: 'inline',
        // defaultValue: 'top',
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: [
                    { title: $i18n.get({ id: 'Automatic', dm: '自动' }), value: 'auto' },
                    {
                        title: $i18n.get({ id: 'AboveInputBox', dm: '输入框上方' }),
                        value: 'top',
                    },
                    {
                        title: $i18n.get({ id: 'LeftSideOfInputBox', dm: '输入框左侧' }),
                        value: 'left',
                    },
                ],
            },
        },
    };
}
export function getfilterDisplaySetterSnippet() {
    return {
        name: 'filterDisplay',
        title: $i18n.get({ id: 'FilterLayout', dm: '筛选器布局' }),
        display: 'inline',
        defaultValue: 'grid',
        initialValue: 'grid',
        setter: {
            componentName: 'RadioGroupSetter',
            props: {
                options: [
                    { title: $i18n.get({ id: 'Grid', dm: '栅格' }), value: 'grid' },
                    { title: $i18n.get({ id: 'Streaming', dm: '流式' }), value: 'flex' },
                ],
            },
        },
    };
}
export function getFieldSelectSetterSnippet() {
    return {
        name: 'label',
        display: 'inline',
        title: $i18n.get({ id: 'SelectField', dm: '选择字段' }),
        isRequired: true,
        setter: {
            componentName: 'CnTreeSelectSetter',
            props: (field) => {
                const dataSource = [];
                const currentData = field?.parent?.parent?.parent?.getValue?.();
                const { _bindForm } = currentData || {};
                if (_bindForm && field) {
                    const node = getNodeById({
                        field,
                        id: _bindForm,
                    });
                    if (node?.id) {
                        const config = node?.getPropValue?.('config');
                        if (isArrayNotEmpty(config)) {
                            config.forEach((item) => {
                                if (item?.name) {
                                    let tempLabel = handleI18nLabel(item?.label) || item.name;
                                    if (typeof tempLabel !== 'string') {
                                        tempLabel = item.name;
                                    }
                                    const temp = {
                                        label: tempLabel,
                                        value: item.name,
                                    };
                                    dataSource.push(temp);
                                    if (isRecursionComponent(item?.componentName)) {
                                        const children = getArrayTableFieldList({
                                            config: item?.options?.config,
                                            arrayTableName: item.name,
                                        });
                                        if (isArrayNotEmpty(children)) {
                                            temp.children = children;
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
                return {
                    dataSource,
                    popupClassName: 'cn-tree-select-setter-pupup',
                };
            },
        },
    };
}
export function getCnSelectSetterSnippet(config) {
    const { propsConfig, options } = config || {};
    return {
        componentName: 'CnSelectSetter',
        props: {
            options,
            selectProps: {
                hasClear: true,
            },
            ...propsConfig,
        },
    };
}
export function getObjectSetterSnippet(config) {
    const { items, initialValue } = config || {};
    const result = {
        componentName: 'ObjectSetter',
        props: {
            config: {
                items,
            },
        },
    };
    if (initialValue) {
        result.initialValue = initialValue;
    }
    return result;
}
export function getMixedSetterSnippet(config) {
    const { setters } = config || {};
    return {
        componentName: 'MixedSetter',
        props: {
            setters,
        },
    };
}
export function getRightButtonSetterSnippet() {
    return {
        name: 'rightButtons',
        title: $i18n.get({
            id: 'ConfigureTheRightButtonOfTheFiel_2024038627',
            dm: '配置字段的右侧按钮',
        }),
        display: 'accordion',
        collapsed: true,
        extraProps: {
            defaultCollapsed: true,
        },
        className: 'cn-array-setter-validator',
        setter: createButtonListSetters({
            position: ButtonPosition.formItemRightButton,
        }),
    };
}
export function getArraySetterSnippet(config) {
    const { initialValue, configure, title } = config || {};
    return {
        componentName: 'ArraySetter',
        props: {
            mode: 'list',
            itemSetter: {
                componentName: 'ObjectSetter',
                initialValue,
                props: {
                    config: {
                        items: configure,
                    },
                },
            },
        },
        title,
    };
}
// 生成可编辑字段的配置项
export function getArrayTableCurrentRowFieldSnippet() {
    return {
        groupName: $i18n.get({ id: 'CurrentRowData', dm: '当前行数据' }),
        groupExprName: __arrayTableCurrentRow__,
        handleCustomGroup: (extra, field) => {
            const path = field?.path;
            if (Array.isArray(path) &&
                path.length > 5 &&
                path[0] === 'config' &&
                path[2] === 'options' &&
                (path[3] === 'config' || path[3] === 'buttons')) {
                const arrayTableIndex = path[1];
                if (typeof arrayTableIndex === 'number') {
                    const formConfig = field?.getNode?.()?.getPropValue?.('config');
                    const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
                    if (isRecursionComponent(arrayTableConfig?.componentName) &&
                        arrayTableConfig?.options?.config?.length > 0) {
                        const originList = arrayTableConfig?.options?.config;
                        const tempList = handleOriginList(originList, 'label', 'name', `${__extraParam__}.${__arrayTableCurrentRow__}`);
                        if (isArrayNotEmpty(tempList)) {
                            extra.children = tempList;
                        }
                    }
                }
            }
        },
    };
}
export function getHandlePropsSetterSnippet() {
    return {
        name: 'handleProps',
        title: $i18n.get({
            id: 'SetComponentPropsManually',
            dm: '手动设置组件props',
        }),
        display: 'inline',
        setter: getJSExpressionPrototype({
            type: 'props',
        }),
    };
}
export function getAdvancedSetterSnippet(config) {
    const { items, extraConfig } = config || {};
    if (isArrayNotEmpty(items)) {
        return {
            title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
            type: 'group',
            collapsed: true,
            display: 'accordion',
            items: items || [],
            ...extraConfig,
        };
    }
}
export function getDataSetterSnippet() {
    return [
        {
            name: 'dataFrom',
            title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
            setter: getObjectSetterSnippet({
                items: [
                    getDataOriginSetterSnippet({
                        defaultValue: dataOriginStatic,
                        display: 'plain',
                    }),
                    getRequestConfigSetterSnippet({
                        condition: (prop) => {
                            return (prop?.parent?.getPropValue?.('dataOrigin') === dataOriginRequest);
                        },
                    }),
                    {
                        name: 'dataSource',
                        title: $i18n.get({ id: 'EditStaticData', dm: '编辑静态数据' }),
                        display: 'plain',
                        setter: getStaticDataSourceSnippet(),
                        condition: (prop) => {
                            return (prop?.parent?.getPropValue?.('dataOrigin') === dataOriginStatic);
                        },
                    },
                ],
            }),
        },
    ];
}
export function getCnSelectSetter(config) {
    const { options } = config || {};
    return {
        componentName: 'CnSelectSetter',
        props: {
            options,
            selectProps: {
                hasClear: true,
            },
        },
    };
}
export function createSamllFlexFilter(config) {
    return {
        componentName: 'CnFilter',
        props: {
            title: $i18n.get({ id: 'TileScreeningBar', dm: '瓷片筛选栏' }),
            filterStyle: {
                showFolder: false,
                showSelected: false,
                enableConfig: false,
                showBottomLine: false,
                hideButton: true,
                removeEmptyLabel: true,
                size: 'small',
                maxVisibleRow: 999,
                filterDisplay: 'flex',
            },
            mobileProps: {
                type: config?.type || 'card-level-subtitle-action',
            },
            className: config?.className || 'l2-cn-filter-in-chart',
            config: [
                {
                    label: '',
                    name: 'radio',
                    componentName: 'RadioGroup',
                    options: {
                        extra: {
                            direction: 'hoz',
                            type: 'button',
                        },
                        dataSource: [
                            {
                                label: $i18n.get({ id: 'Option1', dm: '选项1' }),
                                value: 'radio1',
                            },
                            {
                                label: $i18n.get({ id: 'Option2', dm: '选项2' }),
                                value: 'radio2',
                            },
                        ],
                        dataOrigin: 'static',
                    },
                },
            ],
        },
    };
}
export function createSmallFilter(config) {
    return {
        componentName: 'CnFilter',
        props: {
            title: $i18n.get({ id: 'TileScreeningBar', dm: '瓷片筛选栏' }),
            filterStyle: {
                showFolder: false,
                showSelected: false,
                enableConfig: false,
                showBottomLine: false,
                hideButton: true,
                removeEmptyLabel: true,
                size: 'small',
                maxVisibleRow: 999,
            },
            mobileProps: {},
            className: config?.className || 'l2-cn-filter-in-chart',
            config: [
                {
                    label: '',
                    name: 'radio',
                    componentName: 'RadioGroup',
                    options: {
                        extra: {
                            direction: 'hoz',
                            type: 'button',
                        },
                        dataSource: [
                            {
                                label: $i18n.get({ id: 'Option1', dm: '选项1' }),
                                value: 'radio1',
                            },
                            {
                                label: $i18n.get({ id: 'Option2', dm: '选项2' }),
                                value: 'radio2',
                            },
                        ],
                        dataOrigin: 'static',
                    },
                },
            ],
        },
    };
}
export function getComponentSlotSetterSnippet(config) {
    const { title, slotInitialValue, ...rest } = config || {};
    return {
        name: 'componentSlot',
        title: title || $i18n.get({ id: 'TurnOnAreaFiltering', dm: '开启区域筛选' }),
        setter: getSlotSetterSnippet({
            setterTitle: $i18n.get({ id: 'ComponentSlot', dm: '组件插槽' }),
            slotInitialValue,
        }),
        extraProps: {
            getValue(target, value) {
                if (value && value?.type === 'JSSlot') {
                    return value;
                }
                else if (value === undefined || value === null) {
                    return '';
                }
                else {
                    return {
                        type: 'JSSlot',
                        value: [],
                    };
                }
            },
        },
        ...rest,
    };
}
export function getSlotSetterSnippet(config) {
    const { setterTitle, slotInitialValue } = config || {};
    return {
        componentName: 'SlotSetter',
        title: setterTitle || $i18n.get({ id: 'ComponentSlot', dm: '组件插槽' }),
        initialValue: slotInitialValue || {
            type: 'JSSlot',
            value: [],
        },
    };
}
export function getCnFormatArrayView2SetterSnippet(config) {
    const { extraConfig } = config || {};
    return {
        title: $i18n.get({ id: 'ContentCombination', dm: '内容组合' }),
        name: 'formatSchema',
        display: 'block',
        setter: {
            // componentName: <AccordionArraySetter />,
            componentName: 'ArraySetter',
            props: {
                // mode: 'card-list',
                mode: 'list',
                useQuickAdd: {
                    optionKey: 'format',
                },
                itemSetter: {
                    componentName: 'ObjectSetter',
                    // initialValue: {
                    //   "type": "JSFunction",
                    //   "value": "() => {return {primaryKey: String(Math.floor(Math.random() * 10000)),children: \"Title\",optType:\"link\"               };}"
                    // },
                    initialValue: {
                        format: 'CnFormatText',
                    },
                    props: {
                        config: {
                            items: [
                                {
                                    title: $i18n.get({ id: 'Type', dm: '类型' }),
                                    name: 'format',
                                    isRequired: true,
                                    extraProps: {
                                        setValue(target) {
                                            target?.parent?.setPropValue?.('options', null);
                                            target?.parent?.setPropValue?.('options', {});
                                        },
                                    },
                                    setter: {
                                        componentName: 'CnSelectSetter',
                                        props: () => {
                                            return {
                                                options: getCnFormatPluginList(),
                                                selectProps: {
                                                    showSearch: true,
                                                    hasClear: true,
                                                },
                                            };
                                        },
                                    },
                                },
                                getFormatDocSetter(),
                                {
                                    name: 'options',
                                    display: 'plain',
                                    title: '',
                                    condition() {
                                        return true;
                                    },
                                    extraProps: {
                                        defaultCollapsed: false,
                                    },
                                    setter: {
                                        componentName: 'ObjectSetter',
                                        props: () => {
                                            let options = [];
                                            const pluginList = getCnFormatPluginList();
                                            if (isArrayNotEmpty(pluginList)) {
                                                pluginList.forEach((item) => {
                                                    const { value, lowCodeConfig } = item || {};
                                                    if (value &&
                                                        typeof lowCodeConfig?.getPrototypeList ===
                                                            'function') {
                                                        const list = executeFunction(lowCodeConfig?.getPrototypeList);
                                                        options = [
                                                            ...options,
                                                            ...handlePrototypeCondition(list, value, 'format'),
                                                        ];
                                                    }
                                                });
                                            }
                                            return {
                                                config: {
                                                    items: [...options],
                                                },
                                            };
                                        },
                                    },
                                },
                                ...createEventSetters({
                                    position: ButtonPosition.cnFormatEvent,
                                    initialValue: {
                                        optType: 'flowAction',
                                    },
                                    title: $i18n.get({ id: 'EventCallback', dm: '事件回调' }),
                                    extraConfig: {
                                        tab: 'advanced',
                                    },
                                }),
                            ],
                        },
                    },
                },
            },
        },
        ...extraConfig,
    };
}
export function getCnFormatArrayView2ExtraSetterSnippet() {
    return [
        {
            name: 'flexWrap',
            title: $i18n.get({ id: 'ContentWrap', dm: '内容换行' }),
            display: 'inline',
            setter: {
                componentName: 'BoolSetter',
                title: $i18n.get({
                    id: 'BooleanTruefalse',
                    dm: '布尔（true/false）',
                }),
            },
        },
        {
            name: 'gap',
            title: $i18n.get({ id: 'ContentSpacing', dm: '内容间距' }),
            display: 'inline',
            setter: {
                componentName: 'BoolSetter',
                title: $i18n.get({
                    id: 'BooleanTruefalse',
                    dm: '布尔（true/false）',
                }),
            },
        },
        {
            name: 'justifyContent',
            title: $i18n.get({ id: 'ContentAlignment.Ah4QR', dm: '内容对齐' }),
            display: 'inline',
            setter: {
                componentName: 'RadioGroupSetter',
                props: {
                    options: [
                        {
                            title: $i18n.get({ id: 'AlignLeft', dm: '左对齐' }),
                            value: 'left',
                        },
                        {
                            title: $i18n.get({ id: 'Centered', dm: '居中' }),
                            value: 'center',
                        },
                        {
                            title: $i18n.get({ id: 'AlignRight', dm: '右对齐' }),
                            value: 'right',
                        },
                    ],
                },
            },
        },
    ];
}
/**
 * 组件选择setter
 * @param config
 */
export function getComponentSelectSetterSnippet(config) {
    const { mode, containsKey } = config || {};
    return [
        {
            name: '_bindComponent',
            title: $i18n.get({ id: 'SelectComponent', dm: '选择组件' }),
            setter(prop) {
                const options = [];
                prop?.getNode?.()?.document?.nodesMap?.forEach((item) => {
                    if (isFunction(item?.getPropValue)) {
                        for (const key of containsKey) {
                            const isMatch = item.getPropValue(key);
                            if (isMatch) {
                                const prefix = window?.VisualEngine?.designer
                                    ?.getComponentMeta(item?.componentName)
                                    ?.prototype?.getConfig?.()?.title;
                                const componentTitle = item?.propsData?.[componentTitleKeyMap[item?.componentName] || 'title'] ?? item?.id;
                                if (componentTitle) {
                                    options.push({
                                        title: `${prefix}_${handleI18nLabel(componentTitle) || ''}`,
                                        value: item.id || '',
                                    });
                                }
                                break;
                            }
                        }
                    }
                });
                return {
                    componentName: 'SelectSetter',
                    props: {
                        mode,
                        // hasClear: true,
                        options,
                    },
                };
            },
        },
    ];
}
export function getDefaultCnFormatSetterSnippet() {
    return {
        componentName: 'CnFormat',
        props: {
            isCnFormat: true,
            title: $i18n.get({ id: 'ContentFormatting', dm: '内容格式化' }),
            formatSchema: [
                {
                    format: 'CnFormatText',
                    options: {
                        value: {
                            text: {
                                __hasMockData__: true,
                                __mockValue__: '一段mock文本',
                            },
                            __colorExecuteImmediately__: true,
                        },
                    },
                },
            ],
        },
    };
}
export function getCnFormatSingleButtonSetterSnippet() {
    return {
        componentName: 'CnFormat',
        props: {
            isCnFormat: true,
            title: $i18n.get({ id: 'ContentFormatting', dm: '内容格式化' }),
            formatSchema: [
                {
                    format: 'CnFormatSingleButton',
                    options: {
                        children: {
                            type: 'cn-i18n',
                            dm: $i18n.get({ id: 'Button', dm: '按钮' }),
                        },
                        btnStyle: {
                            iconSize: 'small',
                        },
                    },
                },
            ],
            justifyContent: 'right',
        },
    };
}
export function createEventSetterSnippets(config) {
    const { position = ButtonPosition.baseButton } = config || {};
    return getObjectSetterSnippet({
        items: [
            {
                name: 'optType',
                title: $i18n.get({ id: 'EventtriggeredBehavior', dm: '事件触发行为' }),
                display: 'inline',
                extraProps: {
                    setValue(target, value) {
                        const currentRowValue = target?.parent?.getValue();
                        const newButtonInitialValue = getItemDefaultProps(position, value);
                        target?.parent.setValue({
                            ...currentRowValue,
                            optType: value,
                            options: newButtonInitialValue,
                        });
                    },
                },
                editable: true,
                setter: {
                    componentName: 'CnSelectSetter',
                    props: () => {
                        return {
                            options: getButtonListByPosition(position, {
                                ...config,
                                excludeButtonList: [
                                    'pollingAction',
                                    ...(config?.excludeButtonList || []),
                                ],
                            }),
                            selectProps: {
                                showSearch: true,
                            },
                        };
                    },
                },
            },
            {
                name: 'options',
                display: 'plain',
                title: $i18n.get({ id: 'ButtonConfigurationItems', dm: '按钮配置项' }),
                setter: {
                    componentName: 'ObjectSetter',
                    props: () => {
                        return {
                            config: {
                                items: [...getButtonPrototypeListByPosition(position, {})],
                            },
                        };
                    },
                },
            },
        ],
    });
}
export function getVariableSetterSnippet() {
    return {
        title: $i18n.get({ id: 'BindingVariables', dm: '绑定变量' }),
        componentName: 'VariableSetter',
    };
}
export function getJsActionSetterSnippet(config) {
    const { defaultActionName, defaultCode } = config || {};
    return {
        title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
        componentName: 'ActionSetter',
        props: {
            supportTypes: ['page'],
            defaultCode: defaultCode ||
                `
function jsAction(){
  // return '';
}
`,
            defaultActionName: defaultActionName || 'jsAction',
        },
    };
}
/**
 * 布尔类型的setter
 */
export function getBooleanSetterSnippet(config) {
    const { jsActionConfig } = config || {};
    return getMixedSetterSnippet({
        setters: [
            {
                componentName: 'BoolSetter',
                title: $i18n.get({
                    id: 'BooleanTruefalse',
                    dm: '布尔（true/false）',
                }),
            },
            getVariableSetterSnippet(),
            getJsActionSetterSnippet(jsActionConfig),
        ],
    });
}
export function getActionInterventionSetter() {
    return [
        {
            name: 'interventionType',
            display: 'inline',
            title: $i18n.get({ id: 'ActionIntervention', dm: '动作干预' }),
            condition: (props) => {
                const eventType = props?.parent?.getPropValue('name');
                return eventType === 'onFieldInputValueChange';
            },
            defaultValue: 'none',
            setter: {
                componentName: 'RadioGroupSetter',
                props: {
                    options: [
                        {
                            label: $i18n.get({ id: 'Nonintervention', dm: '不干预' }),
                            value: 'none',
                        },
                        {
                            label: $i18n.get({ id: 'Antishake', dm: '防抖' }),
                            value: 'debounce',
                        },
                    ],
                },
            },
        },
        {
            name: 'intervalTime',
            display: 'inline',
            title: $i18n.get({ id: 'TimeIntervalMs', dm: '时间间隔(ms)' }),
            condition: (props) => {
                const eventType = props?.parent?.getPropValue('name');
                const interventionType = props?.parent?.getPropValue('interventionType');
                return (eventType === 'onFieldInputValueChange' &&
                    interventionType === 'debounce');
            },
            defaultValue: 300,
            setter: {
                componentName: 'NumberSetter',
                props: {
                    step: 1,
                },
            },
        },
    ];
}
