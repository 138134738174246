import React from 'react';
import { ViewWrapper } from '@cainiaofe/cn-ui-lowcode-view-common';
import View from './view';
class CnFormat extends ViewWrapper {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    render() {
        return <View {...this.props} ref={this.ref}/>;
    }
}
CnFormat.displayName = View.displayName;
export default CnFormat;
