import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import { CnRangeNumberPicker } from '@cainiaofe/cn-ui';
import { ADAPTER_LOCALE_SETTER, FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { RangeNumberPicker: { title, thumbUrl } } = FORM_ITEM_INFO;
const RangeNumberPicker = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'RangeNumberPicker',
    component: CnRangeNumberPicker,
    formComponent: CnRangeNumberPicker,
    getPrototypeList: () => {
        return [
            ADAPTER_LOCALE_SETTER,
            {
                name: 'step',
                title: $i18n.get({ id: 'StepSize', dm: '步长' }),
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 1,
                    },
                },
            },
            {
                name: 'precision',
                title: $i18n.get({
                    id: 'PrecisionDecimalPlaces',
                    dm: '精度（小数点位数）',
                }),
                display: 'inline',
                setter: 'NumberSetter',
            },
            {
                name: 'max',
                title: $i18n.get({ id: 'Maximum', dm: '最大值' }),
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'min',
                title: $i18n.get({ id: 'Minimum', dm: '最小值' }),
                display: 'inline',
                setter: {
                    componentName: 'NumberSetter',
                    props: {
                        precision: 10,
                    },
                },
            },
            {
                name: 'innerAfter',
                title: $i18n.get({
                    id: 'AdditionalContentAfterNumberInpu_1836562594',
                    dm: '数字输入框后附加内容',
                }),
                display: 'inline',
                setter: {
                    componentName: 'CnI18nSetter',
                    title: $i18n.get({ id: 'MultilingualText', dm: '多语言文本' }),
                },
            },
        ];
    },
};
export default RangeNumberPicker;
