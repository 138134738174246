import React from 'react';
import { CnSlider as UICnSlider, CnSliderItem as UICnSliderItem } from '@cainiaofe/cn-ui';
import { isDesignMode, mergeHandleProps2 } from '@/common/util/util';
import { useData } from '@/common/hook/useData';
import './index.scss';
import isPlainObject from 'lodash/isPlainObject';

const CnSlider = (props) => {
  const {
    _context,
    handleProps,
    dataFrom,
    normalStyle,
    autoplay,
    autoplayInterval,
    loop,
    direction,
    showArrow,
  } = props;
  const isDesign = isDesignMode(props);
  const { height } = normalStyle || {};

  const { data = [] } = useData({
    dataFrom,
    _context,
    isDesign,
    designMockData: [
      {
        url: 'https://img.alicdn.com/imgextra/i2/O1CN01NnuVGH29BnDaaAZQG_!!6000000008030-0-tps-2960-1184.jpg',
      },
    ],
  });

  let realComponentProps = {
    autoplay,
    autoplayInterval,
    loop,
    direction,
    showArrow,
    height,
  };
  const temp = mergeHandleProps2({
    handleProps,
    componentProps: realComponentProps,
    _context,
  });

  if (isPlainObject(temp)) {
    realComponentProps = temp;
  }

  return (
    <UICnSlider {...realComponentProps}>
      {data.map((item, index) => {
        const { label, value, url } = item || {};
        return (
          <UICnSliderItem key={index}>
            <div
              alt={label || ''}
              className={'l2-cn-slider-item-wrap'}
              style={{
                backgroundImage: `url(${url || value})`,
              }}
              key={index}
            />
          </UICnSliderItem>
        );
      })}
    </UICnSlider>
  );
};
CnSlider.displayName = 'CnSlider';
export default CnSlider;

export { CnSlider };
