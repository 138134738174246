import { dataOriginRequest, dataOriginStatic } from '@/common/util/const';
import { calculateWaitComponentList, isArrayNotEmpty, } from '@/common/util/util';
import { useEffect, useState } from 'react';
import { CnFilterDefaultValueFinished, CnFilterOnSearch, onEvent, } from '@/common/util/event-name';
/**
 * 监听筛选器搜索
 * 目的是确保筛选器搜索时，联动生效
 * @param options 筛选器的配置
 */
export default function useFilterSearchListener(options) {
    const { _bindFilter: optionsBindFilter, sendRequest, _context } = options || {};
    const _bindFilter = typeof optionsBindFilter === 'string' && optionsBindFilter ? [optionsBindFilter] : optionsBindFilter;
    const [requestFinishedFilter, setRequestFinishedFilter] = useState(() => {
        const needRequestFilterList = [];
        _bindFilter?.forEach?.((item) => {
            const filterDom = _context?.$(item);
            const dataOrigin = filterDom?.props?.defaultParams?.dataOrigin;
            if (dataOrigin === dataOriginRequest) {
                needRequestFilterList.push(item);
            }
            else if (dataOrigin === dataOriginStatic) {
                const tempResult = calculateWaitComponentList?.(filterDom?.props?.config, filterDom?.props?.defaultParams);
                if (isArrayNotEmpty(tempResult?.waitComponentList)) {
                    needRequestFilterList.push(item);
                }
            }
        });
        return needRequestFilterList;
    });
    useEffect(() => {
        if (isArrayNotEmpty(_bindFilter)) {
            onEvent(CnFilterOnSearch, (item) => {
                console.log('CnFilterOnSearch', item);
                const { componentProps } = item || {};
                const { _nodeId } = componentProps || {};
                if (_nodeId && _bindFilter.indexOf(_nodeId) !== -1) {
                    sendRequest?.();
                }
            });
            onEvent(CnFilterDefaultValueFinished, (item) => {
                console.log('CnFilterDefaultValueFinished', item);
                const { componentProps } = item || {};
                const { _nodeId } = componentProps || {};
                if (requestFinishedFilter?.indexOf(_nodeId) !== -1) {
                    const leaveFilterList = requestFinishedFilter?.filter?.((item2) => item2 !== _nodeId);
                    setRequestFinishedFilter(leaveFilterList);
                }
            });
        }
    }, []);
    useEffect(() => {
        if (requestFinishedFilter.length === 0) {
            sendRequest?.();
        }
    }, [requestFinishedFilter]);
}
