import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import ExprSetter from '@/common/setter/expr-setter';
import { getJSExpressionPrototype2 } from '@/common/util/util';
import { __arrayTableCurrentRow__, __dataSource__ } from '@cainiaofe/cn-ui-lowcode-view-common';
import { getFormExprNameByPosition } from '@/common/util/expr-const';
import { getJsActionSetterSnippet } from '../../setter-snippet';
import { getPermissionListPrototype } from '../../common-style';
/**
 * 用于隐藏可编辑表格操作按钮
 */
export const arrayTableOperateHidden = (position) => {
    return {
        name: 'hidden',
        title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    dataKey: 'arrayTable',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({
                                        id: 'TableCurrentRowData',
                                        dm: '表格当前行数据',
                                    }),
                                    groupExprName: __arrayTableCurrentRow__,
                                    needSecondParam: false,
                                },
                                {
                                    dataKey: 'config',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
                                    groupExprName: getFormExprNameByPosition({ position }),
                                    needSecondParam: false,
                                },
                                {
                                    groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    getJSExpressionPrototype2({ type: 'arrayTableOperate' }),
                    getJsActionSetterSnippet({
                        defaultCode: `
function btn_hidden(record, state, index, formValues, field){
  // return true/false;
}
`,
                        defaultActionName: 'btn_hidden',
                    }),
                    getPermissionListPrototype(),
                ],
            },
        },
    };
};
