import $i18n from '@/locales/i18n';
import cloneDeep from 'lodash/cloneDeep';
import { ButtonPosition } from '../../position/button-position';
export const arraySubAreaCardCopy = {
    title: $i18n.get({ id: 'Copy', dm: '复制' }),
    value: 'arraySubAreaCardCopy',
    position: [ButtonPosition.arraySubAreaCardOperate],
    action: (config) => {
        const { arrayBaseField: field, arrayBaseFieldIndex: index } = config || {};
        if (!field)
            return;
        if (field.disabled === true || field.pattern !== 'editable')
            return;
        field.insert(index + 1, cloneDeep(field.value[index]));
    },
};
