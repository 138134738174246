import $i18n from '@/locales/i18n';
import { Button, CnBalloon, CnMessage } from '@cainiaofe/cn-ui';
import React, { useState } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
const { CodeControl } = window?.VisualEngineUtils || {};
export function JsonEditor(props) {
    const [visible, setVisible] = useState(false);
    const [code, setCode] = useState('');
    const { value, onChange } = props;
    const edit = () => {
        if (value) {
            if (isPlainObject(value) || isArray(value)) {
                try {
                    setCode(JSON.stringify(value, null, '\t'));
                }
                catch (e) { /* empty */ }
                setVisible(true);
            }
        }
    };
    const onOk = () => {
        try {
            const result = JSON.parse(code);
            if (result) {
                onChange?.(result);
                setCode('');
                setVisible(false);
            }
        }
        catch (e) {
            CnMessage.error($i18n.get({
                id: 'DataDoesNotConformToJSONFormat',
                dm: '数据不符合JSON格式',
            }));
        }
    };
    return (<div className="">
      <CnBalloon 
    // type={'primary'}
    visible={visible} align={'tr'} v2 onVisibleChange={(v) => {
            setVisible(v);
        }} closable triggerType={'click'} title={$i18n.get({ id: 'JSONEditor', dm: 'JSON编辑器' })} trigger={<Button size={'small'} style={{ width: '160px' }} onClick={edit}>
            {$i18n.get({ id: 'JSONEditor', dm: 'JSON编辑器' })}
          </Button>}>
        <CodeControl value={code} options={{ minimap: { enabled: false }, lineNumbers: 'off' }} className="" mode="json" // 'javascript', 'json', 'html', 'css', 'jsx'
     fontSize={14} tabSize={2} 
    // readOnly={false}
    style={{ width: '260px', height: 200 }} onChange={(_code) => {
            setCode(_code);
        }} 
    // // 如果为 false 或者 0 则不启动节流阀
    throttle={600} lineNumbers={'off'} enableOutline={false}/>
        <div style={{
            paddingTop: '10px',
            borderTop: '1px solid #ddd',
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
          <Button onClick={() => {
            setCode('');
            setVisible(false);
        }} size={'small'}>
            {$i18n.get({ id: 'TERM.Cancel', dm: '取消' })}
          </Button>
          &nbsp;&nbsp;
          <Button onClick={onOk} size={'small'} type={'secondary'}>
            {$i18n.get({ id: 'TERM.OK', dm: '确定' })}
          </Button>
        </div>
      </CnBalloon>
    </div>);
}
