import $i18n from '@/locales/i18n';
import React, { useState } from 'react';
import { CnBox, CnButton, CnIcon, CnDropdown, Tree, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '@/common/manager/position/display-position';
// eslint-disable-next-line import/no-cycle
import { getItem } from '@/common/manager/filter-item';
import { handleComponentTitle, handleI18nLabel } from '@/common/util/util';
import './add-one.scss';
export const AddOneBtn = (props) => {
    const { position } = props || {};
    const [visible, setVisible] = useState(false);
    const [selectList, setSelectList] = useState([]);
    const nodeData = [];
    const { onAdd, prop } = props;
    const nodesMap = prop.getNode?.()?.document?.nodesMap;
    const currentId = prop?.getNode?.()?.id;
    if (nodesMap?.forEach) {
        nodesMap.forEach((item) => {
            const { id } = item;
            if (item?.getPropValue('isCnTable')) {
                const columns = item.getPropValue('columns') || [];
                if (columns?.length > 0) {
                    const realName = item?.propsData?.tableName;
                    const temp = {
                        label: `${$i18n.get({ id: 'Table', dm: '表格' })}_${realName || id.replace('node', '')}`,
                        key: id,
                        children: [],
                    };
                    columns.forEach((column) => {
                        let { title } = column;
                        const { dataIndex } = column;
                        title = handleI18nLabel(title);
                        if (title && dataIndex) {
                            temp.children.push({
                                label: `${title}(${dataIndex})`,
                                key: `${id}_${dataIndex}`,
                                originValue: dataIndex,
                                originLabel: title,
                                originConfig: column,
                                parentComponent: item.componentName,
                            });
                        }
                    });
                    if (temp.children.length > 0) {
                        nodeData.unshift(temp);
                    }
                }
            }
            else if (item.componentName === 'CnFilter' ||
                item.componentName === 'CnForm' ||
                item.componentName === 'CnFormDialog') {
                if (item.id !== currentId) {
                    const config = item.getPropValue('config') || [];
                    let tempName = $i18n.get({ id: 'FilterBar', dm: '筛选栏' });
                    if (item.componentName === 'CnFormDialog') {
                        tempName = $i18n.get({ id: 'FormPopupWindow', dm: '表单弹窗' });
                    }
                    else if (item.componentName === 'CnForm') {
                        tempName = $i18n.get({ id: 'Form', dm: '表单' });
                    }
                    let comName = tempName + id.replace('node', '');
                    if (item?.propsData?.title) {
                        comName = `${tempName}_${handleComponentTitle(item?.propsData?.title)}`;
                    }
                    const temp = {
                        label: comName,
                        key: id,
                        children: [],
                    };
                    if (config?.length > 0) {
                        config.forEach((con) => {
                            let { label } = con;
                            const { name, componentName } = con;
                            label = handleI18nLabel(label);
                            if (name) {
                                const tempItem = {
                                    label: `${label}(${name})`,
                                    key: `${id}_${name}`,
                                    originValue: name,
                                    originLabel: label,
                                    originConfig: con,
                                    parentComponent: item.componentName,
                                };
                                if (componentName) {
                                    tempItem.componentName = componentName;
                                }
                                temp.children.push(tempItem);
                            }
                        });
                        if (temp.children.length > 0) {
                            nodeData.push(temp);
                        }
                    }
                }
            }
        });
    }
    const select = (selectedKeys, extra = {}) => {
        const { checkedNodes = [] } = extra;
        const result = [];
        checkedNodes.forEach((item) => {
            const { originLabel, originValue, componentName, originConfig, parentComponent, } = item?.props;
            if (originValue) {
                result.push({
                    label: originLabel,
                    name: originValue,
                    componentName,
                    config: originConfig,
                    parentComponent,
                });
            }
        });
        setSelectList(result);
    };
    const getNewList = () => {
        const result = [];
        const currentComponent = props?.prop?.getNode?.()?.componentName;
        if (selectList?.length > 0) {
            selectList.forEach((item) => {
                if (item) {
                    const { label, name, componentName, parentComponent } = item;
                    let newItem = {};
                    if (parentComponent === currentComponent) {
                        newItem = { ...item.config };
                    }
                    else if (currentComponent === 'CnFilter' ||
                        currentComponent === 'CnForm' ||
                        currentComponent === 'CnFormDialog') {
                        newItem.label = label;
                        newItem.name = name;
                        newItem.componentName = componentName;
                    }
                    let component;
                    if (currentComponent === 'CnFilter') {
                        component = getItem(DisplayPosition.filter, componentName);
                    }
                    else if (currentComponent === 'CnForm') {
                        component = getItem(DisplayPosition.form, componentName);
                    }
                    else if (currentComponent === 'CnFormDialog') {
                        component = getItem(DisplayPosition.formDialog, componentName);
                    }
                    if (!component) {
                        newItem.componentName = 'Input';
                    }
                    result.push(newItem);
                }
            });
        }
        return result;
    };
    const addInsert = () => {
        const result = getNewList();
        const existConfig = props?.prop?.getValue() || [];
        const existMap = {};
        existConfig.forEach((item) => {
            if (item.name) {
                existMap[item.name] = true;
            }
        });
        const toAddList = [...existConfig];
        if (result?.length > 0) {
            result.forEach((item) => {
                const { name } = item;
                if (!existMap[name]) {
                    toAddList.push(item);
                }
            });
            if (toAddList.length > 0) {
                props?.prop?.setValue(toAddList);
                const sel = window?.AliLowCodeEngine?.project?.currentDocument?.selection;
                if (sel) {
                    sel?.select?.();
                    setTimeout(() => {
                        sel?.select?.(currentId);
                    });
                }
            }
        }
        setVisible(false);
    };
    const allInsert = () => {
        const result = getNewList();
        const existConfig = props?.prop?.getValue() || [];
        const existMap = {};
        existConfig.forEach((item) => {
            if (item.name) {
                existMap[item.name] = true;
            }
        });
        if (result.length > 0) {
            props?.prop?.setValue(result);
            const sel = window?.AliLowCodeEngine?.project?.currentDocument?.selection;
            if (sel) {
                sel?.select?.();
                setTimeout(() => {
                    sel?.select?.(currentId);
                });
            }
        }
        setVisible(false);
    };
    const addButton = (<CnButton onClick={() => onAdd?.()} type={'secondary'} style={{ flex: 1, marginRight: '4px' }}>
      {$i18n.get({ id: 'AddAnItem', dm: '添加一项' })}
    </CnButton>);
    return (<CnBox className={'cn-array-setter-add-one-btn'} direction="row">
      {addButton}
      <CnDropdown style={{ flex: 1 }} visible={visible} onVisibleChange={(_visible) => {
            setVisible(_visible);
        }} trigger={<CnButton type={'secondary'}>
            {$i18n.get({ id: 'SelectExisting', dm: '选择已有' })}

            <CnIcon type="arrow-down"/>
          </CnButton>} triggerType={['click']}>
        <div style={{
            background: '#fff',
            padding: '12px',
            border: '1px solid #ddd',
            borderRadius: '3px',
        }}>
          <div style={{
            maxHeight: '300px',
            overflow: 'auto',
        }}>
            <Tree selectable={false} isNodeBlock onCheck={select} defaultExpandAll checkable dataSource={nodeData}/>
          </div>
          <div style={{
            paddingTop: '10px',
            borderTop: '1px solid #ddd',
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
            <CnButton size={'small'} onClick={() => {
            setVisible(false);
        }}>
              {$i18n.get({ id: 'TERM.Cancel', dm: '取消' })}
            </CnButton>
            &nbsp;&nbsp;
            <CnButton size={'small'} type={'secondary'} onClick={allInsert}>
              {$i18n.get({ id: 'OverwriteImport', dm: '覆盖导入' })}
            </CnButton>
            &nbsp;&nbsp;
            <CnButton size={'small'} type={'secondary'} onClick={addInsert}>
              {$i18n.get({ id: 'IncrementalImport', dm: '增量导入' })}
            </CnButton>
          </div>
        </div>
      </CnDropdown>
    </CnBox>);
};
