import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { condition, getArrayTableCurrentRowByField, handleSelectDataSource, handleSelectRequestConfig, } from '../../util/util';
import { CnTooltip, CnTreeSelect, componentMap as formComponentMap, formilyReact, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import { getJSExpressionPrototype } from '../common-style';
import isPlainObject from 'lodash/isPlainObject';
import { getSelectParamSelectSetter } from '@/common/manager/filter-item/select';
import { handleRequestParams } from '@/common/util/request';
import { getExprSetterSnippet, getRequestExecuteSetter, getRequestWhenFocusSetterSnippet, getStaticDataSourceSnippet, } from '@/common/manager/setter-snippet';
import { useRef } from 'react';
import { formComponentRefStorage } from '@/common/util/const';
import { __arrayTableCurrentRow__, componentRefSplit, } from '@/common/util/expr-const';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { useField } = formilyReact;
const { TreeSelect: { title, thumbUrl } } = FORM_ITEM_INFO;
const TreeSelect = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'TreeSelect',
    component: CnTreeSelect,
    formComponent: (props) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const realRef = useRef(null);
        const { _dataSourceName } = props || {};
        const params = props?.requestConfig?.params;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const field = useField?.();
        if (Array.isArray(params) && params?.length > 0) {
            let _params = {};
            const { getRequestParams } = props;
            if (typeof getRequestParams === 'function') {
                _params = getRequestParams();
            }
            if (typeof field?.index === 'number') {
                const temp = field.query('..').value();
                const currentRow = temp?.[field.index];
                if (isPlainObject(currentRow)) {
                    _params.tableCurrentRow = currentRow;
                }
            }
            _params.getExtraParam = () => {
                return {
                    [__arrayTableCurrentRow__]: 
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    getArrayTableCurrentRowByField(useField?.()) || {},
                };
            };
            _params.extraParamList = [
                {
                    [__arrayTableCurrentRow__]: 
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    getArrayTableCurrentRowByField(useField?.()) || {},
                },
            ];
            props.requestConfig.formatParam = (oldParams) => {
                if (typeof _params?.getFormInstance === 'function') {
                    const temp = _params.getFormInstance()?.values;
                    if (isPlainObject(temp)) {
                        _params.recordDataSource = temp;
                    }
                }
                const realParams = handleRequestParams(params, { ..._params });
                return { ...oldParams, ...realParams };
            };
            delete props.requestConfig.params;
        }
        const extraProps = {
            getRequestParams: undefined,
        };
        // handleFormUrlencodedData(props?.requestConfig);
        const fieldEntire = field?.path?.entire;
        if (_dataSourceName && fieldEntire && realRef) {
            formComponentRefStorage[`${_dataSourceName}${componentRefSplit}${fieldEntire}`] = realRef;
        }
        return (<formComponentMap.CnTreeSelect {...props} {...extraProps} ref={realRef}/>);
    },
    getDefaultProps: () => {
        return {
            dataOrigin: 'request',
            multiple: false,
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { isDesign, urlParams, formValue, state, formProps, getFormInstance, } = config;
        handleSelectRequestConfig({
            componentProps: formItem?.['x-component-props'],
            isDesign,
            urlParamsDataSource: urlParams,
            recordDataSource: formValue,
            state,
            ignoreHandleParam: true,
            handleDynamicUrl: true,
        });
        if (isPlainObject(formItem?.['x-component-props']?.multipleExtraProps)) {
            const { treeCheckable, treeCheckedStrategy } = formItem?.['x-component-props']?.multipleExtraProps;
            if (treeCheckable === true) {
                formItem['x-component-props'].treeCheckable = true;
                if (treeCheckedStrategy) {
                    formItem['x-component-props'].treeCheckedStrategy =
                        treeCheckedStrategy;
                }
            }
        }
        if (formItem?.['x-component-props']) {
            formItem['x-component-props'].getRequestParams = () => {
                return {
                    urlParamsDataSource: urlParams,
                    recordDataSource: formValue,
                    state,
                    getFormInstance,
                };
            };
            formItem['x-component-props']._dataSourceName =
                formProps?._dataSourceName;
        }
        handleSelectDataSource({
            componentProps: formItem?.['x-component-props'],
            state,
        });
    },
    filterItemBeforeHandler: (filterItemProps, config) => {
        handleSelectRequestConfig(config);
        if (isPlainObject(config?.componentProps?.multipleExtraProps)) {
            const { treeCheckable, treeCheckedStrategy } = config?.componentProps?.multipleExtraProps;
            if (treeCheckable === true) {
                config.componentProps.treeCheckable = true;
                if (treeCheckedStrategy) {
                    config.componentProps.treeCheckedStrategy = treeCheckedStrategy;
                }
            }
        }
        handleSelectDataSource(config);
    },
    // select的prototype列表
    getPrototypeList: (position) => {
        const extraServiceProps = {};
        let jsExpressionPrototype = getJSExpressionPrototype({
            type: 'formRequest',
        });
        let executeProps = {
            initialCode: `(formValues, state) => {
  return formValues.xxxx === "xxxx";
}`,
            tip: $i18n.get({
                id: 'TheCurrentFunctionNeedsToReturnT_1636293856',
                dm: '当前函数需要返回 true/false。\nformValues：当前筛选栏/表单的数据。\nstate：全部的数据，在左侧列表中选择使用。\n',
            }),
        };
        if (DisplayPosition.cnArrayTable === position) {
            extraServiceProps.dynamicUrlTemplate = $i18n.get({
                id: 'FunctionstateArrayTableCurrentRo_933468039.Dq8sC',
                dm: "function(state, { {arrayTableCurrentRow} }) {\n  // __arrayTableCurrentRow__: 可编辑表格当前行的数据。\n  return '/xxx';\n}",
            }, { arrayTableCurrentRow: __arrayTableCurrentRow__ });
            executeProps = {
                initialCode: `(formValues, state, { ${__arrayTableCurrentRow__} }) => {
  return formValues.xxxx === "xxxx";
}`,
                tip: $i18n.get({
                    id: 'TheCurrentFunctionNeedsToReturnT_837123558',
                    dm: '当前函数需要返回 true/false。\nformValues：当前筛选栏/表单的数据。\nstate：全部的数据，在左侧列表中选择使用。\n__arrayTableCurrentRow__：可编辑表格当前行的数据。\n',
                }),
            };
            jsExpressionPrototype = getJSExpressionPrototype({
                type: 'arrayTableCurrentRow',
            });
        }
        const paramSelectSetter = getSelectParamSelectSetter({ position });
        let extraConfigSetter;
        const requestExecuteSetter = getRequestExecuteSetter({
            exprSetter: getExprSetterSnippet({
                position,
            }),
        });
        if (requestExecuteSetter) {
            extraConfigSetter = [requestExecuteSetter];
        }
        return [
            {
                name: 'multiple',
                title: $i18n.get({ id: 'SelectMode', dm: '选择模式' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'SingleSelection', dm: '单选' }),
                                value: false,
                            },
                            {
                                title: $i18n.get({ id: 'MultipleChoice', dm: '多选' }),
                                value: true,
                            },
                        ],
                    },
                },
            },
            {
                name: 'dataOrigin',
                title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'RemoteRequest', dm: '远程请求' }),
                                value: 'request',
                            },
                            {
                                title: $i18n.get({ id: 'StaticData', dm: '静态数据' }),
                                value: 'static',
                            },
                        ],
                    },
                },
            },
            {
                name: 'requestConfig',
                title: $i18n.get({ id: 'QueryService', dm: '查询服务' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        mockDataTemplate: {
                            success: true,
                            data: [
                                {
                                    label: $i18n.get({ id: 'OptionOne', dm: '选项一' }),
                                    value: 'first',
                                    children: [
                                        {
                                            label: $i18n.get({ id: 'SubOptionOne', dm: '子选项一' }),
                                            value: 'sub1',
                                        },
                                    ],
                                },
                                {
                                    label: $i18n.get({ id: 'Option2.NBzVe', dm: '选项二' }),
                                    value: 'second',
                                },
                            ],
                        },
                        paramTitleDom: (<div className="">
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}
              </div>),
                        responseDom: (<div style={{ paddingTop: '10px' }}>
                {$i18n.get({
                                id: 'DataStructureOfTheRequestReturnR_137379149',
                                dm: '请求返回结果的数据结构：',
                            })}
                <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/3mzaPNMZ6jkJq3dwkOLLWYLDwXq4Ky1r" rel="noreferrer">
                  {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
                </a>{' '}
                <CnTooltip v2 align={'t'} trigger={<a>
                      {$i18n.get({ id: 'InterfacePreview', dm: '接口预览' })}
                    </a>}>
                  <div style={{ width: '200px', height: '260px' }}>
                    <img style={{ width: '100%' }} src="https://img.alicdn.com/imgextra/i1/O1CN01W5SNFR25Fg4QyRC53_!!6000000007497-0-tps-456-570.jpg"/>
                  </div>
                </CnTooltip>
              </div>),
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        extraConfigSetter,
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    paramSelectSetter,
                                    {
                                        componentName: 'StringSetter',
                                        title: $i18n.get({ id: 'String', dm: '字符串' }),
                                    },
                                    jsExpressionPrototype,
                                ],
                            },
                        },
                        resultProcessFuncTemplate: $i18n.get({
                            id: 'FunctionresTheFollowingDataStruc_958986499',
                            dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: [\n  //     {\n  //        label:"xx",\n  //        value:"xx",\n  //     }\n  //   ]\n  // }\n return res;\n}',
                        }),
                        // paramSetter:{
                        //   componentName: 'ParamSelectSetter',
                        //   props:{
                        //     dataKey: 'config',
                        //     labelKey: 'label',
                        //     valueKey: 'name',
                        //     groupName: '参数列表',
                        //   },
                        //   title:'选择参数',
                        // },
                        executeProps,
                        ...extraServiceProps,
                    },
                },
                // hidden:hidden('Select'),
                condition(prop) {
                    return (condition(prop, 'TreeSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'request');
                },
            },
            {
                name: 'dataSource',
                title: $i18n.get({ id: 'EditStaticData', dm: '编辑静态数据' }),
                display: 'plain',
                tip: $i18n.get({
                    id: 'EditStaticPresentationData',
                    dm: '编辑静态展示数据',
                }),
                // initialValue: [],
                setter: getStaticDataSourceSnippet(),
                condition(prop) {
                    return (condition(prop, 'TreeSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'static');
                },
            },
            getRequestWhenFocusSetterSnippet(),
            {
                name: 'searchRemote',
                title: $i18n.get({
                    id: 'ResendRequestWhenNewInput',
                    dm: '当新输入时 重新发请求',
                }),
                display: 'inline',
                tip: $i18n.get({
                    id: 'WhenTurnedOnEachInputWillResendT_979364164',
                    dm: '开启时，每次输入都会重新发请求获取数据',
                }),
                setter: {
                    componentName: 'BoolSetter',
                    props: {},
                },
            },
            {
                name: 'searchKey',
                title: $i18n.get({ id: 'KeyForRemoteSearch', dm: '远程搜索时的key' }),
                display: 'inline',
                setter: {
                    componentName: 'StringSetter',
                    props: {},
                },
                condition(prop) {
                    return (condition(prop, 'TreeSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('searchRemote') === true);
                },
            },
            {
                name: 'isOnlyLeafNodeSelectable',
                title: $i18n.get({
                    id: 'OnlySelectedChildNodesAreAllowed',
                    dm: '只允许选中子节点',
                }),
                display: 'inline',
                setter: {
                    componentName: 'BoolSetter',
                    props: {},
                },
            },
            {
                name: 'showCheckAll',
                title: $i18n.get({ id: 'SupportAllSelection', dm: '支持全选' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: $i18n.get({ id: 'Yes', dm: '是' }), value: true },
                            { title: $i18n.get({ id: 'No', dm: '否' }), value: false },
                            { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
                        ],
                    },
                },
                condition(prop) {
                    return (condition(prop, 'TreeSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('multiple') === true);
                },
            },
            {
                title: $i18n.get({
                    id: 'AdditionalConfigurationItemsDuri_2063097880',
                    dm: '多选时的额外配置项',
                }),
                name: 'multipleExtraProps',
                display: 'accordion',
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'treeCheckable',
                                    title: $i18n.get({
                                        id: 'UseCheckboxesToCheckData',
                                        dm: '使用复选框勾选数据',
                                    }),
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                                {
                                    name: 'treeCheckedStrategy',
                                    title: $i18n.get({
                                        id: 'BackfillPolicyAfterSelection',
                                        dm: '选中后的回填策略',
                                    }),
                                    display: 'inline',
                                    setter: {
                                        componentName: 'SelectSetter',
                                        props: {
                                            options: [
                                                {
                                                    label: $i18n.get({
                                                        id: 'WhenAllChildNodesAreSelectedOnly_1049112637',
                                                        dm: '子节点都选中时只提交父节点',
                                                    }),
                                                    value: 'parent',
                                                },
                                                {
                                                    label: $i18n.get({
                                                        id: 'WhenBothParentAndChildNodesAreSe_1175782084',
                                                        dm: '父子节点都选中时只提交子节点',
                                                    }),
                                                    value: 'child',
                                                },
                                                {
                                                    label: $i18n.get({
                                                        id: 'SubmitAllSelectedNodes',
                                                        dm: '提交所有选中的节点',
                                                    }),
                                                    value: 'all',
                                                },
                                            ],
                                        },
                                    },
                                    condition(prop) {
                                        return (prop?.parent?.getPropValue?.('treeCheckable') === true);
                                    },
                                },
                            ],
                        },
                    },
                },
                condition(prop) {
                    return (condition(prop, 'TreeSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('multiple') === true);
                },
            },
        ];
    },
};
export default TreeSelect;
