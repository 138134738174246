const preMokelayDomain = 'https://pre-cn-x-gateway.cainiao.com';
const pageDataSource = 'pageDataSource';
const _dataSourceName = '_dataSourceName';
const onFieldValueChange = 'onFieldValueChange';
const AfterRequestSuccess = 'AfterRequestSuccess';
const columnSuffix = 'Column';
const dataOriginStatic = 'static';
const dataOriginRequest = 'request';
// 允许选择默认第一项的组件列表
const allowSelectFirstComponentList = ['Select'];
const formUrlencoded = 'application/x-www-form-urlencoded';
// 有循环渲染的组件
const recursionComponentList = ['CnArrayTable', 'CnArraySubAreaCard'];
// 高级配置关键词
const __advancedConfig__ = '__advancedConfig__';
// 源码相关
const __advancedCodeConfig__ = '__advancedCodeConfig__';
// 需要使用数据的动作编排类型
const needSaveDataFlowActionList = ['request', 'remove', 'submit', 'pollingAction'];
// 缓存表单下拉框的ref
const formComponentRefStorage = {};
// 业务扩展配置分组
const __bizExtendConfig__ = '__bizExtendConfig__';

const __CnFormatPluginPrefix__ = 'CnFormatPlugin-';

const statusList = [
  {
    label: 'invalid',
    value: 'invalid',
  },
  {
    label: 'warning',
    value: 'warning',
  },
  {
    label: 'error',
    value: 'error',
  },
  {
    label: 'success',
    value: 'success',
  },
  {
    label: 'info',
    value: 'info',
  },
];

const componentTitleKeyMap = {
  CnTable: 'tableName',
  CnList: 'header',
};

const allowQuickComponentList = [
  'Select',
  'CascaderSelect',
  'DepartmentSelect',
  'EmployeeSelect',
  'CheckboxGroup',
  'RadioGroup',
  'DatePicker',
  'RangePicker',
];

export {
  preMokelayDomain,
  pageDataSource,
  _dataSourceName,
  onFieldValueChange,
  AfterRequestSuccess,
  columnSuffix,
  dataOriginStatic,
  dataOriginRequest,
  allowSelectFirstComponentList,
  formUrlencoded,
  recursionComponentList,
  __advancedConfig__,
  needSaveDataFlowActionList,
  formComponentRefStorage,
  __advancedCodeConfig__,
  __bizExtendConfig__,
  statusList,
  __CnFormatPluginPrefix__,
  componentTitleKeyMap,
  allowQuickComponentList,
};
