import React from 'react';
import CnPageView from './cn-page-view';

class CnPage extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  reRender() {
    this.ref?.current?.doMakeAction?.();
  }

  render() {
    return <CnPageView ref={this.ref} {...this.props} />;
  }
}
CnPage.displayName = 'CnPage';
export default CnPage;

export { CnPage };
