import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import {
  getFormItemDefaultProps,
  getItem,
  getItemDefaultProps,
  getItemInitialValue,
  getItemListByPosition,
  getItemPrototypeListByPosition,
  getItemStylePrototype,
} from '@/common/manager/filter-item';
import { CnAsyncSelect, CnTooltip } from '@cainiaofe/cn-ui';
import { AddOneBtn } from './add-one';
import { createValidatorSetters } from '@/common/manager/validator';
import { createEventSetters } from '@/common/manager/event';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import {
  generateDataSource,
  getDsNameFromVariable,
  getJSExpressionPrototypeTemplate,
  handleI18nLabel,
  isArrayNotEmpty,
} from '@/common/util/util';
import DataSourceSetter from '@/common/setter/datasource-setter/datasource-setter';
import { createRequestSuccessPrototype } from '@/common/setter-snippet/request';
import DefaultValueSetter from '@/common/setter/default-value-setter';
import ExprSetter from '@/common/setter/expr-setter';
import {
  __dataSource__,
  __extraParam__,
  formDialogStateLabel,
  getOpenDialogModeEnum,
  openDialogModeLabel,
} from '@/common/util/expr-const';
import { DisplayPosition } from '@/common/manager/position/display-position';
import FormilyReactionSetter from '@/common/setter/formily-reaction-setter';
import { ButtonPosition } from '@/common/manager/position/button-position';
import {
  getCnSelectSetterSnippet,
  getColsSetterSnippet,
  getExprSetterSnippet,
  getLabelAlignSetterSnippet,
  getNodeIdSetterSnippet,
  getRequestExecuteSetter,
  getRightButtonSetterSnippet,
  getTableCellClickSetterSnippet,
} from '@/common/manager/setter-snippet';
import { ParamSelectSetter } from '@/common/setter/param-select-setter';
import { TableAddOneBtn } from '@/lowcode/cn-table-alc/component/table-add-one';
import { __advancedCodeConfig__ } from '@/common/util/const';

const { ActionSetter } = window.VisualEngineUtils || {};

export function createFormItemSetters(config) {
  const { position } = config || {};
  const itemList = getItemListByPosition({ position });
  let configure = [
    {
      name: 'id',
      setter: 'StringSetter',
      condition() {
        return false;
      },
    },
    // {
    //   name: "primaryKey",
    //   title: "唯一标识",
    //   condition: {
    //     "type": "JSFunction",
    //     "value": "() => false"
    //   },
    //   initialValue: {
    //     "type": "JSFunction",
    //     "value": "val => {\n                        if (val) return val;\n                        return String(Math.floor(Math.random() * 10000));\n                      }"
    //   },
    //   setter: "StringSetter"
    // },
    {
      onecodeDisplay: 'visible',
      onecodeReadonly: true,
      name: 'label',
      title: $i18n.get({ id: 'FieldName.dA0Wl', dm: '字段名称' }),
      display: 'inline',
      editable: true,
      isRequired: true,
      setter: {
        componentName: 'MixedSetter',
        props: {
          setters: [
            {
              componentName: 'CnI18nSetter',
              title: $i18n.get({ id: 'MultilingualSetup', dm: '多语言设置器' }),
            },
            {
              title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
              componentName: 'ActionSetter',
              props: {
                supportTypes: ['page'],
                defaultCode: $i18n.get({
                  id: 'FunctionLabelargState',
                  dm: "\nfunction label(arg, state){\n  // 返回标签文案\n  // return '标签文案';\n}\n",
                }),

                defaultActionName: 'label',
              },
            },
            getJSExpressionPrototypeTemplate({
              initialCode: $i18n.get({
                id: 'ArgStateReturn',
                dm: "(arg, state) => {\n  return '标题';\n}",
              }),

              tip: $i18n.get({
                id: 'TheCurrentFunctionNeedsToReturnT_1773331637',
                dm: '当前函数需要返回 标题文本。\nstate：全部的数据，在左侧列表中选择使用。\n',
              }),
            }),
          ],
        },
      },
    },
    {
      onecodeDisplay: 'visible',
      onecodeReadonly: true,
      name: 'name',
      title: $i18n.get({ id: 'FieldEncoding', dm: '字段编码' }),
      display: 'inline',
      // supportVariable: true,
      // initialValue: function init(val) {
      //   if (val) return val;
      //   return uniqueId(null, 'dataIndex');
      // },
      editable: true,
      isRequired: true,
      setter: 'StringSetter',
    },
    {
      onecodeDisplay: 'visible',
      onecodeReadonly: true,
      name: 'componentName',
      title: $i18n.get({ id: 'ComponentType', dm: '组件类型' }),
      display: 'inline',
      extraProps: {
        setValue(target, value) {
          const defaultProps = getItemDefaultProps(position, value);
          if (defaultProps) {
            target?.parent?.setPropValue?.('options', defaultProps);
          } else {
            target?.parent?.setPropValue?.('options', {});
          }
          const formItemDefaultProps = getFormItemDefaultProps(position, value);
          if (formItemDefaultProps) {
            target?.parent?.setPropValue?.('placeholder', undefined);
            const keys = Object.keys(formItemDefaultProps);
            keys.forEach((key) => {
              target?.parent?.setPropValue?.(key, formItemDefaultProps[key]);
            });
          }
        },
      },
      setter: {
        componentName: 'CnComponentSetter',
        props: () => {
          return {
            dataSource: getItemListByPosition({ position }),
          };
        },
      },
    },
  ];

  configure.push({
    name: 'options',
    display: 'plain',
    title: $i18n.get({
      id: 'PleaseCompleteTheConfigurationIt_1631955185',
      dm: '请完善组件的配置项',
    }),
    condition(prop) {
      const componentName = prop?.parent?.getPropValue?.('componentName');
      if (componentName) {
        const item = getItem(position, componentName) || {};
        const { getPrototypeList, configure: _configure = [] } = item;
        if (
          (getPrototypeList && getPrototypeList()?.length > 0) ||
          _configure?.length > 0
        ) {
          return true;
        }
      }
      return false;
    },
    extraProps: {
      defaultCollapsed: false,
    },
    setter: {
      componentName: 'ObjectSetter',
      props: () => {
        return {
          config: {
            items: [...getItemPrototypeListByPosition({ position })],
          },
        };
      },
    },
  });
  if (position) {
    if ([DisplayPosition.cnArrayTable].includes(position)) {
      configure.push({
        name: 'readOnlyClick',
        display: 'accordion',
        title: $i18n.get({
          id: 'ConfigureTheClickBehaviorOfCells',
          dm: '配置单元格的点击行为',
        }),
        extraProps: {
          defaultCollapsed: true,
        },
        setter: getTableCellClickSetterSnippet({
          buttonPosition: ButtonPosition.arrayTableCell,
        }),
      });
    }
    configure = [
      ...configure,
      ...createValidatorSetters({
        position: position.replace('display', 'validator'),
      }),
      ...getItemStylePrototype({ position }),
    ];

    if (
      [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
      ].includes(position)
    ) {
      configure.push(
        getRightButtonSetterSnippet({
          position: ButtonPosition.formItemRightButton,
        }),
      );
    }

    const eventSetters = createEventSetters({
      position: `${position.replace('display', 'btn')}ItemEvent`,
      extraConfig: {
        collapsed: false,
      },
    });
    if (eventSetters?.length > 0) {
      configure = [...configure, ...eventSetters];
    }
    configure = [
      ...configure,
      {
        title: $i18n.get({
          id: 'AdvancedConfigurationUseCaution',
          dm: '高级配置（请谨慎使用）',
        }),
        name: __advancedCodeConfig__,
        display: 'accordion',
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [
                {
                  name: 'handleProps',
                  title: $i18n.get({
                    id: 'SetComponentPropsManually',
                    dm: '手动设置组件props',
                  }),
                  tip: $i18n.get({
                    id: 'TheManuallySetPropsWillBeMergedI_98679499',
                    dm: '手动设置的props会被merge进x-component-props',
                  }),
                  display: 'inline',
                  setter: getJSExpressionPrototype({
                    type: 'props',
                  }),
                },
                {
                  name: 'handleDecoratorProps',
                  title: $i18n.get({
                    id: 'ManuallySetPropsForFormItem',
                    dm: '手动设置formItem的props',
                  }),
                  tip: $i18n.get({
                    id: 'TheManuallySetPropsWillBeMergedI_764049907',
                    dm: '手动设置的props会被merge进x-decorator-props',
                  }),
                  display: 'inline',
                  setter: getJSExpressionPrototype({
                    type: 'props',
                  }),
                },
              ],
            },
          },
        },
      },
    ];

    if ([DisplayPosition.form, DisplayPosition.formDialog].includes(position)) {
      configure.push({
        name: '_block',
        display: 'plain',
        setter: 'BlockSetter',
      });
    } else if ([DisplayPosition.filter].includes(position)) {
      configure.push({
        name: '_block',
        display: 'plain',
        setter: {
          componentName: 'BlockSetter',
          props: {
            subType: 'FILTER',
          },
        },
      });
    }
  }
  return {
    componentName: 'ArraySetter',
    props: {
      mode: 'list',
      extraProps: {
        renderFooter(props) {
          if (DisplayPosition.cnArrayTable === position) {
            return (
              <TableAddOneBtn
                handleFieldList={(originalList) => {
                  const newList = [];
                  if (isArrayNotEmpty(originalList)) {
                    originalList.forEach((item) => {
                      if (item?.dataIndex) {
                        newList.push({
                          name: item.dataIndex,
                          label: handleI18nLabel(item.title),
                          componentName: 'Input',
                        });
                      }
                    });
                  }
                  return newList;
                }}
                dataPrimaryKey={'name'}
                {...props}
              />
            );
          } else {
            return <AddOneBtn position={position} {...props} />;
          }
        },
      },
      itemSetter: {
        componentName: 'ObjectSetter',
        // initialValue: {
        //   "type": "JSFunction",
        //   "value": "() => {return {primaryKey: String(Math.floor(Math.random() * 10000)),children: \"Title\",optType:\"link\"               };}"
        // },
        initialValue(prop) {
          let toAddInitialValue = getItemInitialValue({
            position,
            prop,
            componentName: itemList[0]?.value,
          });
          const tempCurrentAddComponent =
            window.__lowcode_form_current_add_componentName__;
          if (tempCurrentAddComponent) {
            toAddInitialValue = {
              ...getFormItemDefaultProps(position, tempCurrentAddComponent),
              componentName: tempCurrentAddComponent,
            };
            window.__lowcode_form_current_add_componentName__ = undefined;

            const sel =
              window?.AliLowCodeEngine?.project?.currentDocument?.selection;
            sel?.select?.();
            const id = prop?.node?.id;
            if (id) {
              setTimeout(() => {
                sel?.select?.(id);
              });
            }
          }

          return toAddInitialValue;
        },
        props: {
          config: {
            items: configure,
          },
        },
      },
    },
  };
}

export function createFormStyleSetters(config) {
  const { position } = config || {};
  const readOnlyConfigList = [
    {
      groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
      groupExprName: __dataSource__,
      needSecondParam: true,
    },
  ];

  if (ButtonPosition.formDialog === position) {
    readOnlyConfigList.unshift({
      groupExprName: __extraParam__,
      handleCustomGroup: () => {
        return {
          label: formDialogStateLabel,
          children: [
            {
              label: openDialogModeLabel,
              value: `${__extraParam__}.openDialogMode`,
            },
          ],
        };
      },
      renderTypeDom: () => {
        return null;
      },
      renderValueDom: (props) => {
        const { value, onChange } = props || {};
        return (
          <CnAsyncSelect
            size={'small'}
            hasClear
            value={value?.value}
            dataSource={getOpenDialogModeEnum()}
            onChange={(v) => {
              onChange(value, {
                value: v,
              });
            }}
          />
        );
      },
    });
  }
  const result = [
    {
      name: 'layoutMode',
      title: $i18n.get({ id: 'LayoutMode', dm: '布局模式' }),
      initialValue: 'multiple',
      defaultValue: 'multiple',
      setter: {
        title: $i18n.get({ id: 'SelectLayoutMode', dm: '选择布局模式' }),
        componentName: 'RadioGroupSetter',
        props: {
          options: [
            {
              value: 'single',
              title: $i18n.get({ id: 'SingleRow', dm: '单列' }),
            },
            {
              value: 'multiple',
              title: $i18n.get({ id: 'MultipleColumns', dm: '多列' }),
            },
          ],
        },
      },
    },
    getColsSetterSnippet({
      condition(prop) {
        return (
          prop?.getNode?.()?.getPropValue?.('formStyle.layoutMode') ===
          'multiple'
        );
      },
    }),
    {
      name: 'formItemPosition',
      title: $i18n.get({ id: 'FormLocation', dm: '表单位置' }),
      initialValue: 'center',
      defaultValue: 'center',
      display: 'inline',
      setter: {
        componentName: 'RadioGroupSetter',
        props: {
          options: [
            {
              title: $i18n.get({ id: 'Left.KDGXg', dm: '居左' }),
              value: 'left',
            },
            {
              title: $i18n.get({ id: 'Centered', dm: '居中' }),
              value: 'center',
            },
          ],
        },
      },
      condition(prop) {
        return (
          prop?.getNode?.()?.getPropValue?.('formStyle.layoutMode') === 'single'
        );
      },
    },
    {
      name: 'readOnly',
      title: $i18n.get({ id: 'ReadOnly', dm: '只读' }),
      display: 'inline',
      tip: '',
      className: 'cn-text-expr-setter',
      setter: {
        componentName: 'MixedSetter',
        props: {
          setters: [
            {
              title: $i18n.get({ id: 'YesOrNo', dm: '是 或 否' }),
              componentName: 'RadioGroupSetter',
              props: {
                options: [
                  {
                    value: true,
                    title: $i18n.get({ id: 'Yes', dm: '是' }),
                  },
                  {
                    value: false,
                    title: $i18n.get({ id: 'No', dm: '否' }),
                  },
                  { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
                ],
              },
            },
            {
              componentName: <ExprSetter configList={readOnlyConfigList} />,
              title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
            },
            getJSExpressionPrototype({ type: 'form' }),
          ],
        },
      },
    },
    {
      name: 'allowCopy',
      title: $i18n.get({
        id: 'DisplayDataCopyIconWhenReadonly',
        dm: '只读时显示数据拷贝图标',
      }),
      display: 'inline',
      className: 'cn-text-expr-setter',
      setter: {
        componentName: 'MixedSetter',
        props: {
          setters: [
            {
              title: $i18n.get({ id: 'YesOrNo', dm: '是 或 否' }),
              componentName: 'RadioGroupSetter',
              props: {
                options: [
                  {
                    value: true,
                    title: $i18n.get({ id: 'Yes', dm: '是' }),
                  },
                  {
                    value: false,
                    title: $i18n.get({ id: 'No', dm: '否' }),
                  },
                  { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
                ],
              },
            },
          ],
        },
      },
      condition(prop) {
        return prop?.getNode?.()?.getPropValue?.('formStyle.readOnly') === true;
      },
    },
    getLabelAlignSetterSnippet(),
  ];

  if ([DisplayPosition.form, ButtonPosition.formDialog].includes(position)) {
    result.push({
      name: 'isAnchorForm',
      title: $i18n.get({ id: 'OpenElevatorForm', dm: '开启电梯表单' }),
      setter: 'BoolSetter',
    });
    result.push({
      name: 'headingLevel',
      title: $i18n.get({
        id: 'ElevatorFormDisplayHierarchy',
        dm: '电梯表单显示层级',
      }),
      setter: getCnSelectSetterSnippet({
        options: [
          {
            label: $i18n.get({ id: 'Card', dm: '卡片' }),
            value: 1,
          },
          {
            label: $i18n.get({ id: 'Subcard', dm: '子卡片' }),
            value: 2,
          },
        ],

        propsConfig: {
          mode: 'multiple',
        },
      }),
    });
    if (DisplayPosition.form === position) {
      result.push({
        name: 'mode',
        title: $i18n.get({ id: 'ActionBarPosition', dm: '操作栏位置' }),
        initialValue: 'page',
        defaultValue: 'page',
        setter: getCnSelectSetterSnippet({
          options: [
            {
              title: $i18n.get({
                id: 'SuckingAtTheBottomOfThePage',
                dm: '在页面底部吸底',
              }),
              value: 'page',
            },
            {
              title: $i18n.get({
                id: 'SuctionTheBottomAtTheBottomOfThe',
                dm: '在容器底部吸底',
              }),
              value: 'block',
            },
          ],
        }),
      });
    }
  }
  return result;
}

export function createDataSourceSetters() {
  return [
    {
      name: '_dataSource',
      title: $i18n.get({ id: '31255240267403264.CNTM', dm: '数据源' }),
      display: 'inline',
      supportVariable: false,
      mutator(value) {
        const node = this.getProps().getNode();
        const result = getDsNameFromVariable(value);
        if (result) {
          node.setPropValue('_dataSourceName', result);
        }
      },
      initialValue() {
        const v = this.getValue();
        const { componentName } = this;
        const currentDataSource = this.getPropValue('_dataSource');
        if (!currentDataSource && !v) {
          const ds = generateDataSource({ componentName });
          if (ds?.name) {
            this.parent?.setPropValue?.('_dataSourceName', ds.name);
            return {
              type: 'variable',
              variable: `state.${ds.name}`,
            };
          }
        }
      },
      setter: <DataSourceSetter />,
    },
    {
      name: '_dataSourceName',
      title: $i18n.get({ id: 'DataSourceName', dm: '数据源名称' }),
      display: 'none',
      initialValue() {
        const value = this.parent?.getPropValue?.('_dataSourceName');
        if (!value) {
          const node = this.getNode();
          const dsExpr = node?.getPropValue('_dataSource');
          return getDsNameFromVariable(dsExpr);
        }
        return value;
      },
    },
  ];
}

export function createFormDataSourceSetters(config) {
  const { extraConfigure = [] } = config || {};
  return [
    {
      title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
      type: 'group',
      collapsed: true,
      display: 'accordion',
      items: [
        getNodeIdSetterSnippet(),
        ...createDataSourceSetters(),
        ...extraConfigure,
      ],
    },
  ];
}

export function createFormDefaultValueSetters(config) {
  const { afterRequestSuccessPosition, position } = config || {};
  const extraServiceProps = {};
  if (DisplayPosition.formDialog === position) {
    extraServiceProps.dynamicUrlTemplate = $i18n.get(
      {
        id: 'FunctionstateOpenDialogModeOpenD_1467892484.b7dTK',
        dm: "function(state, { openDialogMode }) {\n  // openDialogMode: {openDialogModeLabel}: 新增(new)、编辑(edit)、详情(detail)\n  return '/xxx';\n}",
      },
      { openDialogModeLabel },
    );
  }
  let extraConfigSetter;
  const requestExecuteSetter = getRequestExecuteSetter({
    exprSetter: getExprSetterSnippet({
      position: 'formDefaultValue',
    }),
  });
  if (requestExecuteSetter) {
    extraConfigSetter = [requestExecuteSetter];
  }
  const configure = [
    {
      name: 'defaultParams',
      display: 'accordion',
      title:
        position === DisplayPosition.filter
          ? $i18n.get({ id: 'FilterItemDefaults', dm: '筛选项默认值' })
          : $i18n.get({ id: 'SetFormData', dm: '设置表单数据' }),
      collapsed: false,
      extraProps: {
        defaultCollapsed: false,
      },
      setter: {
        componentName: 'ObjectSetter',
        props: {
          config: {
            items: [
              {
                name: 'dataOrigin',
                title: $i18n.get({ id: 'DataSourceType', dm: '数据来源类型' }),
                display: 'inline',
                extraProps: {
                  defaultValue: 'static',
                },
                defaultValue: 'static',
                initialValue: 'static',
                setter: {
                  componentName: 'MixedSetter',
                  props: {
                    setters: [
                      {
                        componentName: 'RadioGroupSetter',
                        props: {
                          options: [
                            {
                              title: $i18n.get({
                                id: 'StaticData',
                                dm: '静态数据',
                              }),
                              value: 'static',
                            },
                            {
                              title: $i18n.get({
                                id: 'RemoteRequest',
                                dm: '远程请求',
                              }),
                              value: 'request',
                            },
                          ],
                        },
                      },
                      getJSExpressionPrototype({ type: 'dataOrigin' }),
                    ],
                  },
                },
              },
              {
                name: 'requestConfig',
                title: $i18n.get({ id: 'QueryService', dm: '查询服务' }),
                display: 'inline',
                setter: {
                  componentName: 'ServiceChoiceSetter',
                  props: {
                    buttonText: $i18n.get({
                      id: 'SelectRequestAPI',
                      dm: '选择请求API',
                    }),
                    responseDom: (
                      <div style={{ paddingTop: '10px' }}>
                        {$i18n.get({
                          id: 'DataStructureOfTheRequestReturnR_137379149',
                          dm: '请求返回结果的数据结构：',
                        })}

                        <CnTooltip
                          align={'t'}
                          trigger={
                            <a>{$i18n.get({ id: 'TERM.View', dm: '查看' })}</a>
                          }
                        >
                          <div
                            style={{
                              width: '200px',
                              height: '170px',
                            }}
                          >
                            <img
                              style={{ width: '100%' }}
                              src="https://img.alicdn.com/imgextra/i4/O1CN0158wCxG1ddDBKBvDA7_!!6000000003758-0-tps-356-296.jpg"
                            />
                          </div>
                        </CnTooltip>
                      </div>
                    ),

                    resultProcessFuncTemplate: `function(res, state) {
  return res;
}`,
                    params: {
                      env: 'pre',
                      pageSize: 999,
                    },
                    extraConfigSetter,
                    paramSetter: {
                      componentName: 'MixedSetter',
                      props: {
                        setters: [
                          {
                            componentName: 'ParamSelectSetter',
                            props: {
                              ParamSelectSetterComponent: ParamSelectSetter,
                              configList: [
                                {
                                  groupName: $i18n.get({
                                    id: 'OtherData',
                                    dm: '其他数据',
                                  }),
                                  groupExprName: __dataSource__,
                                  needSecondParam: true,
                                },
                              ],
                            },
                            title: $i18n.get({
                              id: 'SelectParameters',
                              dm: '选择参数',
                            }),
                          },
                          {
                            componentName: 'StringSetter',
                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                          },
                          getJSExpressionPrototype({ type: 'base' }),
                        ],
                      },
                    },
                    ...extraServiceProps,
                  },
                },
                condition(prop) {
                  return (
                    prop?.parent?.getPropValue?.('dataOrigin') !== 'static'
                  );
                },
              },
              {
                name: 'defaultValue',
                title: $i18n.get({ id: 'EditDefaultValues', dm: '编辑默认值' }),
                display: 'inline',
                tip: $i18n.get({
                  id: 'EditStaticPresentationData',
                  dm: '编辑静态展示数据',
                }),
                className: 'cn-param-select-setter-style-fix',
                setter: {
                  componentName: 'MixedSetter',
                  props: {
                    setters: [
                      {
                        componentName: 'JsonSetter',
                        title: $i18n.get({
                          id: 'JSONEditor',
                          dm: 'JSON编辑器',
                        }),
                      },
                      {
                        componentName: 'ParamSelectSetter',
                        props: {
                          ParamSelectSetterComponent: ParamSelectSetter,
                          configList: [
                            {
                              groupName: $i18n.get({
                                id: 'OtherData',
                                dm: '其他数据',
                              }),
                              groupExprName: __dataSource__,
                              needSecondParam: true,
                            },
                          ],
                        },
                        title: $i18n.get({
                          id: 'SelectFromDataSource',
                          dm: '从数据源选择',
                        }),
                      },
                      {
                        title: $i18n.get({
                          id: 'AdvancedEditor',
                          dm: '高级编辑器',
                        }),
                        componentName: (
                          <DefaultValueSetter
                            dataKey={'config'}
                            labelKey={'label'}
                            valueKey={'name'}
                            groupName={$i18n.get({
                              id: 'FormFields',
                              dm: '表单字段',
                            })}
                            position={position}
                          />
                        ),
                      },
                      {
                        title: $i18n.get({
                          id: 'BindingDefaultValueMethod',
                          dm: '绑定默认值方法',
                        }),
                        componentName: (
                          <ActionSetter
                            supportTypes={['page']}
                            defaultCode={$i18n.get({
                              id: 'GetDefaultValueReturnsTheFormDef_52362598',
                              dm: '/**\n * getDefaultValue 返回表单默认值\n * 返回格式为: { key: value }\n */\nfunction getDefaultValue() {\n   return {};\n}',
                            })}
                            defaultActionName="getDefaultValue"
                          />
                        ),
                      },
                      getJSExpressionPrototype({ type: 'base' }),
                    ],
                  },
                },
                condition(prop) {
                  return (
                    prop?.parent?.getPropValue?.('dataOrigin') !== 'request'
                  );
                },
              },
            ],
          },
        },
      },
    },
  ];

  if (afterRequestSuccessPosition) {
    configure[0].setter.props.config.items.push(
      createRequestSuccessPrototype(afterRequestSuccessPosition, {
        afterRequestCondition(prop) {
          return prop?.parent?.getPropValue?.('dataOrigin') !== 'static';
        },
      }),
    );
  }
  if (
    [
      DisplayPosition.form,
      DisplayPosition.formDialog,
      DisplayPosition.filter,
    ].includes(position)
  ) {
    configure[0].setter.props.config.items.push({
      name: 'deferRender',
      title: $i18n.get({
        id: 'RenderAfterRequestCompletes',
        dm: '请求完成后再渲染',
      }),
      setter: 'BoolSetter',
      condition(prop) {
        return prop?.parent?.getPropValue?.('dataOrigin') === 'request';
      },
    });
  }
  return configure;
}

// 动态表单setter
export function createDynamicConfigSetter() {
  const configure = [
    {
      name: 'dynamicConfig',
      title: $i18n.get({
        id: 'OriginalDynamicFormConfiguration_509165378',
        dm: '原动态表单配置（废弃）',
      }),
      setter: {
        componentName: 'ObjectSetter',
        props: {
          config: {
            items: [
              {
                name: 'requestConfig',
                title: $i18n.get({
                  id: 'DynamicFormQueryService',
                  dm: '动态表单查询服务',
                }),
                display: 'inline',
                setter: {
                  componentName: 'ServiceChoiceSetter',
                  props: {
                    paramTitleDom: (
                      <div className="">
                        {$i18n.get({
                          id: 'RequestParameterConfiguration',
                          dm: '请求参数配置：',
                        })}
                      </div>
                    ),

                    buttonText: $i18n.get({
                      id: 'SelectRequestAPI',
                      dm: '选择请求API',
                    }),
                    params: {
                      env: 'pre',
                      pageSize: 999,
                      // serviceType: 'HSF',
                    },
                    paramSetter: {
                      componentName: 'MixedSetter',
                      props: {
                        setters: [
                          {
                            componentName: 'ParamSelectSetter',
                            props: {
                              dataKey: 'aa',
                              labelKey: 'aa',
                              valueKey: 'aa',
                              groupName: 'aa',
                            },
                            title: $i18n.get({
                              id: 'SelectParameters',
                              dm: '选择参数',
                            }),
                          },
                          {
                            componentName: 'StringSetter',
                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                          },
                          getJSExpressionPrototype({ type: 'formRequest' }),
                        ],
                      },
                    },
                    resultProcessFuncTemplate: $i18n.get({
                      id: 'FunctionresTheFollowingDataStruc_750429068.M1Cub',
                      dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: [\n  //   ]\n  // }\n return res;\n}',
                    }),
                  },
                },
              },
            ],
          },
        },
      },
    },
  ];

  return configure;
}

// 动态表单setter
export function createDynamicFormConfigSetter(config) {
  const { extraConfig } = config || {};
  return [
    {
      name: 'dynamicFormConfig',
      display: 'accordion',
      title: $i18n.get({ id: 'DynamicFormConfiguration', dm: '动态表单配置' }),
      collapsed: true,
      extraProps: {
        defaultCollapsed: true,
      },
      setter: {
        componentName: 'ObjectSetter',
        props: {
          config: {
            items: [
              {
                name: 'active',
                title: $i18n.get({ id: 'OpenDynamicForm', dm: '开启动态表单' }),
                setter: 'BoolSetter',
              },
              {
                name: 'requestConfig',
                title: $i18n.get({
                  id: 'DynamicFormQueryService',
                  dm: '动态表单查询服务',
                }),
                display: 'inline',
                setter: {
                  componentName: 'ServiceChoiceSetter',
                  props: {
                    paramTitleDom: (
                      <div className="">
                        {$i18n.get({
                          id: 'RequestParameterConfiguration',
                          dm: '请求参数配置：',
                        })}
                      </div>
                    ),

                    buttonText: $i18n.get({
                      id: 'SelectRequestAPI',
                      dm: '选择请求API',
                    }),
                    params: {
                      env: 'pre',
                      pageSize: 999,
                      // serviceType: 'HSF',
                    },
                    paramSetter: {
                      componentName: 'MixedSetter',
                      props: {
                        setters: [
                          {
                            componentName: 'ParamSelectSetter',
                            props: {
                              ParamSelectSetterComponent: ParamSelectSetter,
                              configList: [
                                {
                                  groupName: $i18n.get({
                                    id: 'OtherData',
                                    dm: '其他数据',
                                  }),
                                  groupExprName: __dataSource__,
                                  needSecondParam: true,
                                },
                              ],
                            },
                            title: $i18n.get({
                              id: 'SelectParameters',
                              dm: '选择参数',
                            }),
                          },
                          {
                            componentName: 'StringSetter',
                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                          },
                          getJSExpressionPrototype({ type: 'formRequest' }),
                        ],
                      },
                    },
                    resultProcessFuncTemplate: $i18n.get({
                      id: 'FunctionresTheFollowingDataStruc_750429068',
                      dm: 'function(res) {\n    // 需要返回的如下的数据结构\n    // return {\n    //   success: true,\n    //   data: [\n    //   ]\n    // }\n   return res;\n  }',
                    }),
                  },
                },
                condition(prop) {
                  return prop?.parent?.getPropValue?.('active') === true;
                },
              },
              {
                name: 'reactions',
                title: $i18n.get({
                  id: 'ConfigureDynamicFormLinkage',
                  dm: '配置动态表单联动',
                }),
                setter: <FormilyReactionSetter />,
              },
            ],
          },
        },
      },
      ...extraConfig,
    },
  ];
}
