import React from 'react';

import './index.scss';
// eslint-disable-next-line import/no-cycle
import { getDataSourceList } from '../../util/util';

const { SelectControl } = window?.VisualEngineUtils || {};

class DataSourceSetter extends React.Component {
  // // 组件属性
  // static defaultProps = {
  //   initBind:false,
  // };

  state = {
  };

  componentDidMount() {}

  UNSAFE_componentWillMount() {
    const { prop } = this.props;
    // 监听
    this.willDetach = prop.onValueChange(() => this.forceUpdate());
  }

  componentWillUnmount() {
    // 卸载监听
    this.willDetach && this.willDetach();
  }

  select = (value) => {
    const { prop } = this.props;
    try {
      prop.getNode().setPropValue(prop.getName(), {
        type: 'variable',
        variable: `state.${value}`,
      });
    } catch (e) { /* empty */ }
  };

  getDsName = () => {
    let value = '';
    const { prop } = this.props;
    try {
      // const dsExpr = prop.getNode().getPropValue(prop.getName())
      const dsExpr = prop.getHotValue();
      const variableStr = prop.getVariableValue();
      if (dsExpr && dsExpr.type === 'variable' && dsExpr.variable) {
        value = dsExpr.variable.slice(dsExpr.variable.lastIndexOf('.') + 1);
      } else if (variableStr) {
        value = variableStr.slice(variableStr.lastIndexOf('.') + 1);
      }
    } catch (e) {
      console.log('error');
    }
    return value;
  };

  render() {
    let dataSourceList = [];
    const dsName = this.getDsName();
    dataSourceList = getDataSourceList({
      typeList: ['VALUE'],
    });
    if (Array.isArray(dataSourceList)) {
      dataSourceList = dataSourceList.map((item) => {
        const newLabel = `${item.label} (${item.value})`;
        return {
          ...item,
          label: newLabel,
          text: newLabel,
        };
      });
    }
    return (
      <SelectControl
        readOnly
        options={dataSourceList}
        value={dsName}
        onChange={this.select}
      />
    );
  }
}

export default DataSourceSetter;
