import React from 'react';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import { ActionBarSize } from './type';
import ActionBarBlock from './components/action-bar-block';
import './index.scss';
const CnActionBar = (props) => {
    const { size = ActionBarSize.Medium, leftActionButtons, rightActionButtons, _context, _unsafe_MixedSetter_leftActionButtons_select, _unsafe_MixedSetter_rightActionButtons_select, } = props;
    const renderActionsContainer = ({ needButtonGroup, leftReverse, }) => {
        return (<div className={classNames('cn-action-bar-container', `cn-action-bar-container-${size}`)}>
        <ActionBarBlock _context={_context} setterType={_unsafe_MixedSetter_leftActionButtons_select} buttons={leftActionButtons} isReverse={leftReverse} needButtonGroup={needButtonGroup} size={size}/>
        <ActionBarBlock _context={_context} setterType={_unsafe_MixedSetter_rightActionButtons_select} buttons={rightActionButtons} needButtonGroup={needButtonGroup} extClassName={'cn-action-bar-block-right'} size={size}/>
      </div>);
    };
    const renderMobileActions = () => {
        if (_unsafe_MixedSetter_leftActionButtons_select === 'SlotSetter'
            || _unsafe_MixedSetter_rightActionButtons_select === 'SlotSetter') {
            return renderActionsContainer({ needButtonGroup: true, leftReverse: true });
        }
        const leftActions = isArray(leftActionButtons) ? [...leftActionButtons].reverse() : [];
        const rightActions = isArray(rightActionButtons) ? rightActionButtons : [];
        return (<ActionBarBlock _context={_context} mode={'btn-group'} buttons={[...leftActions, ...rightActions]} size={size}/>);
    };
    if (_context?.cnUtils?.isMobile) {
        return renderMobileActions();
    }
    return renderActionsContainer({});
};
CnActionBar.displayName = 'CnActionBar';
export default CnActionBar;
export { CnActionBar };
