import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { calculateTextExprValue, condition, getCnFormatPlugin, getCnFormatPluginList, getItem, getItemPrototypeListByPosition, isArrayNotEmpty, judgeCnFormatExist, } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { CnReadOnly } from '@cainiaofe/cn-ui';
import { getFormatDocSetter } from '@/common/setter/cn-format-setter';
import { getSetterSnippet } from '@/common/manager/setter-snippet';
import isPlainObject from 'lodash/isPlainObject';
import { handleRequestParams } from '@/common/util/request';
import qs from 'query-string';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import CnFormatAlc from '@/lowcode/cn-format-alc';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { ReadOnly: { title, thumbUrl } } = FORM_ITEM_INFO;
const ReadOnly = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
    ],
    title,
    thumbUrl,
    componentName: 'ReadOnly',
    getDefaultProps: () => {
        return {
            format: 'style-text',
        };
    },
    formComponent: (props) => {
        const { getRequestParams, getFormItemConfig, ...rest } = props || {};
        const extraProps = {};
        const formItemConfig = getFormItemConfig?.();
        const _params = getRequestParams?.();
        if (isPlainObject(formItemConfig)) {
            const { options = {} } = formItemConfig;
            const { format, text, url, urlParams, formatOptions } = options;
            const tempPlugin = getCnFormatPlugin({ name: format });
            if (format === 'style-text') {
                extraProps.type = 'tableCell';
                extraProps.tableCellProps = {
                    format: 'text',
                    tableCellItem: rest?.value,
                };
            }
            else if (format === 'text') {
                const realText = calculateTextExprValue(text, _params) || rest?.value;
                let realUrl = calculateTextExprValue(url, _params);
                const realParams = qs.stringify(handleRequestParams(urlParams, _params));
                if (realUrl && realUrl.indexOf('?') !== -1) {
                    realUrl += `&${realParams}`;
                }
                else if (realParams) {
                    realUrl += `?${realParams}`;
                }
                extraProps.type = 'tableCell';
                extraProps.tableCellProps = {
                    format,
                    tableCellItem: {
                        text: realText,
                        link: realUrl,
                    },
                };
            }
            else if ([
                'progress',
                'tag',
                'percent',
                'multiple-text',
                'currency',
                'currency-with-unit',
            ].includes(format)) {
                extraProps.type = 'tableCell';
                extraProps.tableCellProps = {
                    format,
                    tableCellItem: rest?.value,
                };
                if (format === 'percent' ||
                    format === 'currency' ||
                    format === 'currency-with-unit') {
                    extraProps.tableCellProps.column = {
                        align: 'left',
                    };
                }
                if (format === 'multiple-text') {
                    extraProps.value = undefined;
                }
            }
            else if (tempPlugin || judgeCnFormatExist(format)) {
                const { recordDataSource, _context } = _params || {};
                let formatSchema = [];
                let extraOptions = {};
                if (format === 'CnFormatArrayView2') {
                    formatSchema = formatOptions?.formatSchema;
                    extraOptions = omit(formatOptions, ['formatSchema']);
                }
                else {
                    formatSchema = [
                        {
                            format,
                            options: formatOptions,
                        },
                    ];
                }
                return (<CnFormatAlc formatSchema={formatSchema} {...extraOptions} _context={_params?._context} __getContextData__={() => {
                        return {
                            functionArg: {
                                item: recordDataSource,
                                index: 0,
                                state: _context?.state,
                                _context,
                                value: props?.value,
                            },
                        };
                    }}/>);
            }
        }
        return <CnReadOnly {...rest} {...extraProps}/>;
    },
    formItemBeforeHandler: (formItem, config) => {
        const { urlParams, formValue, state, formItemConfig, _context } = config;
        if (formItem) {
            formItem['x-component-props'].getRequestParams = () => {
                return {
                    urlParamsDataSource: urlParams,
                    recordDataSource: formValue,
                    state,
                    _context,
                };
            };
            formItem['x-component-props'].getFormItemConfig = () => {
                return formItemConfig;
            };
        }
    },
    getPrototypeList: (position) => {
        let paramSetter = 'StringSetter';
        let urlSetter = 'StringSetter';
        const setterSnippet = getSetterSnippet({
            position,
            optType: 'link',
        });
        const { urlSetter: newUrlSetter, requestParamSetter } = setterSnippet || {};
        if (requestParamSetter) {
            paramSetter = requestParamSetter;
        }
        if (newUrlSetter) {
            urlSetter = newUrlSetter;
        }
        const textSetter = cloneDeep(urlSetter);
        if (isArrayNotEmpty(textSetter?.props?.setters)) {
            textSetter.props.setters.unshift({
                componentName: 'CnI18nSetter',
                title: $i18n.get({ id: 'MultilingualText', dm: '多语言文本' }),
            });
        }
        return [
            {
                name: 'format',
                title: $i18n.get({ id: 'PresentationType', dm: '展示类型' }),
                display: 'inline',
                extraProps: {
                    setValue(target) {
                        target?.parent?.setPropValue?.('formatOptions', null);
                        target?.parent?.setPropValue?.('formatOptions', {});
                    },
                },
                setter: {
                    componentName: 'CnSelectSetter',
                    props: () => {
                        const pluginList = getCnFormatPluginList();
                        const result = [
                            {
                                value: 'style-text',
                                title: $i18n.get({ id: 'StyledText', dm: '带样式文本' }),
                            },
                            { value: 'text', title: $i18n.get({ id: 'Link', dm: '链接' }) },
                            {
                                value: 'tag',
                                title: $i18n.get({ id: '31255238841339904.CNTM', dm: '标签' }),
                            },
                            {
                                value: 'progress',
                                title: $i18n.get({ id: 'ProgressBar', dm: '进度条' }),
                            },
                            {
                                value: 'percent',
                                title: $i18n.get({ id: 'Percentage', dm: '百分比' }),
                            },
                            {
                                value: 'multiple-text',
                                title: $i18n.get({ id: 'MultipleLinesOfText', dm: '多行文本' }),
                            },
                            {
                                value: 'currency',
                                title: $i18n.get({ id: 'AmountShow', dm: '金额展示' }),
                            },
                            {
                                value: 'currency-with-unit',
                                title: $i18n.get({
                                    id: 'AmountPresentationWithCurrency',
                                    dm: '金额展示（带币种）',
                                }),
                            },
                        ];
                        if (isArrayNotEmpty(pluginList)) {
                            result.push({
                                label: $i18n.get({ id: 'Extension', dm: '扩展插件' }),
                                children: [...pluginList],
                            });
                        }
                        return {
                            options: result,
                            selectProps: {},
                        };
                    },
                },
            },
            {
                ...getFormatDocSetter(),
                condition: (props) => {
                    return condition(props, 'ReadOnly', 'componentName')
                        && (props?.parent.getPropValue?.('format') || '').startsWith('CnFormat');
                },
            },
            {
                name: 'formatOptions',
                display: 'plain',
                title: $i18n.get({
                    id: 'PleaseCompleteTheConfigurationIt_653589502',
                    dm: '请完善数据类型的配置项',
                }),
                condition(prop) {
                    const format = prop?.parent?.getPropValue?.('format');
                    if (format) {
                        const item = getItem({}, position, format) || {};
                        const { getPrototypeList, configure = [] } = item;
                        if ((getPrototypeList && getPrototypeList()?.length > 0) ||
                            configure?.length > 0) {
                            return true;
                        }
                    }
                    return false;
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: () => {
                        return {
                            config: {
                                items: [
                                    ...getItemPrototypeListByPosition({
                                        position,
                                        primaryKey: 'format',
                                        componentMap: {},
                                    }),
                                ],
                            },
                        };
                    },
                },
            },
            {
                title: $i18n.get({ id: 'LinkCopy', dm: '链接文案' }),
                name: 'text',
                setter: textSetter,
                condition(prop) {
                    return (condition(prop, 'ReadOnly', 'componentName') &&
                        prop?.parent?.getPropValue?.('format') === 'text');
                },
            },
            {
                title: $i18n.get({ id: 'LinkAddress', dm: '链接地址' }),
                name: 'url',
                className: 'cn-link-url-setter',
                setter: urlSetter,
                condition(prop) {
                    return (condition(prop, 'ReadOnly', 'componentName') &&
                        prop?.parent?.getPropValue?.('format') === 'text');
                },
            },
            {
                title: $i18n.get({ id: 'UrlParameter', dm: 'url参数' }),
                name: 'urlParams',
                description: $i18n.get({ id: 'UrlParameter', dm: 'url参数' }),
                className: 'cn-button-url-param-setter',
                setter: {
                    componentName: 'ArraySetter',
                    props: {
                        mode: 'list',
                        itemSetter: {
                            componentName: 'ObjectSetter',
                            initialValue: {
                                label: 'param',
                            },
                            props: {
                                config: {
                                    items: [
                                        {
                                            title: $i18n.get({ id: 'ParameterName', dm: '参数名' }),
                                            name: 'label',
                                            isRequired: true,
                                            description: $i18n.get({
                                                id: 'ParameterName',
                                                dm: '参数名',
                                            }),
                                            setter: 'StringSetter',
                                        },
                                        {
                                            title: $i18n.get({ id: 'ParameterValue', dm: '参数值' }),
                                            name: 'value',
                                            isRequired: true,
                                            description: $i18n.get({
                                                id: 'ParameterValue',
                                                dm: '参数值',
                                            }),
                                            // supportVariable: true,
                                            className: 'cn-param-select-setter',
                                            setter: paramSetter,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
                condition(prop) {
                    return (condition(prop, 'ReadOnly', 'componentName') &&
                        prop?.parent?.getPropValue?.('format') === 'text');
                },
            },
        ];
    },
};
export default ReadOnly;
