import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
// eslint-disable-next-line import/no-cycle
import { handleRequestParams, handleResultProcessFunc, } from '../../util/request';
import { getRealRequestUrl, setAdvancedConfigToProps, } from '@/common/util/util';
import { ImageUploadCom } from '@/common/manager/filter-item/components/image-upload';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { OSSImageUpload: { title, thumbUrl } } = FORM_ITEM_INFO;
const OSSImageUpload = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    componentName: 'OSSImageUpload',
    thumbUrl,
    component: ImageUploadCom,
    formComponent: ImageUploadCom,
    getDefaultProps: () => {
        return {};
    },
    formItemBeforeHandler: (formItem, config) => {
        const { state, formValue, urlParams, formProps } = config;
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                const { uploadProps } = componentProps;
                if (componentProps.requestConfig) {
                    const { method, serviceType, params, resultProcessFunc } = componentProps.requestConfig;
                    const url = getRealRequestUrl({
                        requestConfig: componentProps.requestConfig,
                        state,
                    });
                    if (url) {
                        const requestConfig = { url };
                        if (serviceType === 'mokelay') {
                            requestConfig.method = 'post';
                        }
                        else if (method) {
                            requestConfig.method = method;
                        }
                        requestConfig.formatParam = (oldParams) => {
                            const realParams = handleRequestParams(params, {
                                urlParamsDataSource: urlParams,
                                recordDataSource: formValue,
                                state,
                            });
                            return {
                                ...oldParams,
                                ...realParams,
                            };
                        };
                        if (typeof resultProcessFunc === 'function') {
                            requestConfig.formatResult = handleResultProcessFunc(componentProps.requestConfig);
                        }
                        componentProps.requestConfig = requestConfig;
                    }
                }
                setAdvancedConfigToProps(componentProps);
                formItem['x-component-props'] = {
                    _dataSourceName: formProps?._dataSourceName,
                    ...componentProps,
                    ...uploadProps,
                };
            }
        }
    },
    getPrototypeList: () => {
        return [
            {
                name: 'requestConfig',
                title: $i18n.get({ id: 'UploadAddress', dm: '上传地址' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                    },
                },
            },
            {
                name: 'uploadProps',
                display: 'plain',
                title: $i18n.get({ id: 'UploadProps', dm: '上传props' }),
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'limit',
                                    title: $i18n.get({
                                        id: 'MaximumNumberOfImagesUploaded',
                                        dm: '最大图片上传个数',
                                    }),
                                    setter: 'NumberSetter',
                                },
                                {
                                    name: 'multiple',
                                    title: $i18n.get({
                                        id: 'SupportMultipleFileSelection',
                                        dm: '支持多选文件',
                                    }),
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
export default OSSImageUpload;
