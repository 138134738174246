import $i18n from '@/locales/i18n';
export const countryList = [
    {
        value: 'AF',
        label: $i18n.get({ id: 'AfghanistanAF', dm: '阿富汗（AF）' }),
    },
    {
        value: 'AX',
        label: $i18n.get({ id: 'LandIslandsAX', dm: '奥兰群岛（AX）' }),
    },
    {
        value: 'AL',
        label: $i18n.get({ id: 'AlbaniaAL', dm: '阿尔巴尼亚（AL）' }),
    },
    {
        value: 'DZ',
        label: $i18n.get({ id: 'AlgeriaDZ', dm: '阿尔及利亚（DZ）' }),
    },
    {
        value: 'AS',
        label: $i18n.get({ id: 'AmericanSamoaAS', dm: '美属萨摩亚（AS）' }),
    },
    { value: 'AD', label: $i18n.get({ id: 'AndorraAD', dm: '安道尔（AD）' }) },
    { value: 'AO', label: $i18n.get({ id: 'AngolaAO', dm: '安哥拉（AO）' }) },
    {
        value: 'AI',
        label: $i18n.get({ id: 'AnguillaUKAI', dm: '安圭拉岛(英)（AI）' }),
    },
    { value: 'AQ', label: $i18n.get({ id: 'AntarcticaAQ', dm: '南极洲（AQ）' }) },
    {
        value: 'AG',
        label: $i18n.get({ id: 'AntiguaAndBarbudaAG', dm: '安提瓜和巴布达（AG）' }),
    },
    { value: 'AR', label: $i18n.get({ id: 'ArgentinaAR', dm: '阿根廷（AR）' }) },
    { value: 'AM', label: $i18n.get({ id: 'ArmeniaAM', dm: '亚美尼亚（AM）' }) },
    { value: 'AW', label: $i18n.get({ id: 'ArubaAW', dm: '阿鲁巴（AW）' }) },
    {
        value: 'AU',
        label: $i18n.get({ id: 'AustraliaAU', dm: '澳大利亚（AU）' }),
    },
    { value: 'AT', label: $i18n.get({ id: 'AustriaAT', dm: '奥地利（AT）' }) },
    {
        value: 'AZ',
        label: $i18n.get({ id: 'AzerbaijanAZ', dm: '阿塞拜疆（AZ）' }),
    },
    { value: 'BS', label: $i18n.get({ id: 'BahamasBS', dm: '巴哈马（BS）' }) },
    { value: 'BH', label: $i18n.get({ id: 'BahrainBH', dm: '巴林（BH）' }) },
    {
        value: 'BD',
        label: $i18n.get({ id: 'BangladeshBD', dm: '孟加拉国（BD）' }),
    },
    { value: 'BB', label: $i18n.get({ id: 'BarbadosBB', dm: '巴巴多斯（BB）' }) },
    { value: 'BY', label: $i18n.get({ id: 'BelarusBY', dm: '白俄罗斯（BY）' }) },
    { value: 'BE', label: $i18n.get({ id: 'BelgiumBE', dm: '比利时（BE）' }) },
    { value: 'BZ', label: $i18n.get({ id: 'BelizeBZ', dm: '伯利兹（BZ）' }) },
    { value: 'BJ', label: $i18n.get({ id: 'BeninBJ', dm: '贝宁（BJ）' }) },
    { value: 'BM', label: $i18n.get({ id: 'BermudaBM', dm: '百慕大（BM）' }) },
    { value: 'BT', label: $i18n.get({ id: 'BhutanBT', dm: '不丹（BT）' }) },
    {
        value: 'BO',
        label: $i18n.get({
            id: 'PlurinationalStateOfBoliviaBO',
            dm: '多民族玻利维亚国（BO）',
        }),
    },
    {
        value: 'BA',
        label: $i18n.get({
            id: 'BosniaAndHerzegovinaBA',
            dm: '波斯尼亚和黑塞哥维那（BA）',
        }),
    },
    { value: 'BW', label: $i18n.get({ id: 'BotswanaBW', dm: '博茨瓦纳（BW）' }) },
    {
        value: 'BV',
        label: $i18n.get({ id: 'BouvieIslandBV', dm: '布维岛（BV）' }),
    },
    { value: 'BR', label: $i18n.get({ id: 'BrazilBR', dm: '巴西（BR）' }) },
    {
        value: 'IO',
        label: $i18n.get({
            id: 'BritishIndianOceanTerritoryIO',
            dm: '英属印度洋领地（IO）',
        }),
    },
    {
        value: 'BN',
        label: $i18n.get({ id: 'BruneiDarussalamBN', dm: '文莱达鲁萨兰国（BN）' }),
    },
    { value: 'BG', label: $i18n.get({ id: 'BulgariaBG', dm: '保加利亚（BG）' }) },
    {
        value: 'BF',
        label: $i18n.get({ id: 'BurkinaFasoBF', dm: '布基纳法索（BF）' }),
    },
    { value: 'BI', label: $i18n.get({ id: 'BurundiBI', dm: '布隆迪（BI）' }) },
    { value: 'KH', label: $i18n.get({ id: 'CambodiaKH', dm: '柬埔寨（KH）' }) },
    { value: 'CM', label: $i18n.get({ id: 'CameroonCM', dm: '喀麦隆（CM）' }) },
    { value: 'CA', label: $i18n.get({ id: 'CanadaCA', dm: '加拿大（CA）' }) },
    { value: 'CV', label: $i18n.get({ id: 'CapeVerdeCV', dm: '佛得角（CV）' }) },
    {
        value: 'BQ',
        label: $i18n.get({
            id: 'BonaireSintMaartenSaintEusterAnd_515003285',
            dm: '博内尔、圣马丁圣尤斯特和萨巴（BQ）',
        }),
    },
    {
        value: 'KY',
        label: $i18n.get({ id: 'CaymanIslandsKY', dm: '开曼群岛（KY）' }),
    },
    {
        value: 'CF',
        label: $i18n.get({
            id: 'CentralAfricanRepublicCF',
            dm: '中非共和国（CF）',
        }),
    },
    { value: 'TD', label: $i18n.get({ id: 'ChadTD', dm: '乍得（TD）' }) },
    { value: 'CL', label: $i18n.get({ id: 'ChileCL', dm: '智利（CL）' }) },
    { value: 'CN', label: $i18n.get({ id: 'ChinaCN', dm: '中国（CN）' }) },
    {
        value: 'CX',
        label: $i18n.get({ id: 'ChristmasIslandCX', dm: '圣诞岛（CX）' }),
    },
    {
        value: 'CC',
        label: $i18n.get({
            id: 'CocosKeelingIslandsCC',
            dm: '科科斯（基林）群岛（CC）',
        }),
    },
    { value: 'CO', label: $i18n.get({ id: 'ColombiaCO', dm: '哥伦比亚（CO）' }) },
    { value: 'KM', label: $i18n.get({ id: 'ComorosKM', dm: '科摩罗（KM）' }) },
    {
        value: 'CD',
        label: $i18n.get({
            id: 'DemocraticRepublicOfTheCongoCD',
            dm: '刚果民主共和国（CD）',
        }),
    },
    { value: 'CG', label: $i18n.get({ id: 'CongoCG', dm: '刚果（CG）' }) },
    {
        value: 'CK',
        label: $i18n.get({ id: 'CookIslandsCK', dm: '库克群岛（CK）' }),
    },
    {
        value: 'CR',
        label: $i18n.get({ id: 'CostaRicaCR', dm: '哥斯达黎加（CR）' }),
    },
    {
        value: 'CI',
        label: $i18n.get({ id: 'CteDIvoireCI', dm: '科特迪瓦（CI）' }),
    },
    { value: 'HR', label: $i18n.get({ id: 'CroatiaHR', dm: '克罗地亚（HR）' }) },
    { value: 'CU', label: $i18n.get({ id: 'CubaCU', dm: '古巴（CU）' }) },
    { value: 'CW', label: $i18n.get({ id: 'CuracaoCW', dm: '库拉索（CW）' }) },
    { value: 'CY', label: $i18n.get({ id: 'CyprusCY', dm: '塞浦路斯（CY）' }) },
    {
        value: 'CZ',
        label: $i18n.get({ id: 'CzechRepublicCZ', dm: '捷克（CZ）' }),
    },
    { value: 'DK', label: $i18n.get({ id: 'DenmarkDK', dm: '丹麦（DK）' }) },
    { value: 'DJ', label: $i18n.get({ id: 'DjiboutiDJ', dm: '吉布提（DJ）' }) },
    { value: 'DM', label: $i18n.get({ id: 'DominicaDM', dm: '多米尼加（DM）' }) },
    {
        value: 'DO',
        label: $i18n.get({ id: 'DominicanRepublicDO', dm: '多米尼加共和国（DO）' }),
    },
    { value: 'TL', label: $i18n.get({ id: 'EastTimorTL', dm: '东帝汶（TL）' }) },
    { value: 'EC', label: $i18n.get({ id: 'EcuadorEC', dm: '厄瓜多尔（EC）' }) },
    { value: 'EG', label: $i18n.get({ id: 'EgyptEG', dm: '埃及（EG）' }) },
    {
        value: 'SV',
        label: $i18n.get({ id: 'ElSalvadorSV', dm: '萨尔瓦多（SV）' }),
    },
    {
        value: 'GQ',
        label: $i18n.get({ id: 'EquatorialGuineaGQ', dm: '赤道几内亚（GQ）' }),
    },
    {
        value: 'ER',
        label: $i18n.get({ id: 'EritreaER', dm: '厄立特里亚（ER）' }),
    },
    { value: 'EE', label: $i18n.get({ id: 'EstoniaEE', dm: '爱沙尼亚（EE）' }) },
    {
        value: 'ET',
        label: $i18n.get({ id: 'EthiopiaET', dm: '埃塞俄比亚（ET）' }),
    },
    {
        value: 'FK',
        label: $i18n.get({
            id: 'FalklandIslandsMalvinasFK',
            dm: '福克兰 (马尔维纳斯) 群岛（FK）',
        }),
    },
    {
        value: 'FO',
        label: $i18n.get({ id: 'FaroeIslandsFO', dm: '法罗群岛（FO）' }),
    },
    { value: 'FJ', label: $i18n.get({ id: 'FijiFJ', dm: '斐济（FJ）' }) },
    { value: 'FI', label: $i18n.get({ id: 'FinlandFI', dm: '芬兰（FI）' }) },
    { value: 'FR', label: $i18n.get({ id: 'FranceFR', dm: '法国（FR）' }) },
    {
        value: 'GF',
        label: $i18n.get({ id: 'FrenchGuianaGF', dm: '法属圭亚那（GF）' }),
    },
    {
        value: 'PF',
        label: $i18n.get({ id: 'FrenchPolynesiaPF', dm: '法属波利尼西亚（PF）' }),
    },
    {
        value: 'TF',
        label: $i18n.get({
            id: 'FrenchSouthernTerritoryTF',
            dm: '法属南半球领地（TF）',
        }),
    },
    { value: 'GA', label: $i18n.get({ id: 'GabonGA', dm: '加蓬（GA）' }) },
    { value: 'GM', label: $i18n.get({ id: 'GambiaGM', dm: '冈比亚（GM）' }) },
    { value: 'GE', label: $i18n.get({ id: 'GeorgiaGE', dm: '格鲁吉亚（GE）' }) },
    { value: 'DE', label: $i18n.get({ id: 'GermanyDE', dm: '德国（DE）' }) },
    { value: 'GH', label: $i18n.get({ id: 'GhanaGH', dm: '加纳（GH）' }) },
    {
        value: 'GI',
        label: $i18n.get({ id: 'GibraltarGI', dm: '直布罗陀（GI）' }),
    },
    { value: 'GR', label: $i18n.get({ id: 'GreeceGR', dm: '希腊（GR）' }) },
    { value: 'GL', label: $i18n.get({ id: 'GreenlandGL', dm: '格陵兰（GL）' }) },
    { value: 'GD', label: $i18n.get({ id: 'GrenadaGD', dm: '格林纳达（GD）' }) },
    {
        value: 'GP',
        label: $i18n.get({ id: 'GuadeloupeGP', dm: '瓜德罗普岛（GP）' }),
    },
    { value: 'GU', label: $i18n.get({ id: 'GuamGU', dm: '关岛（GU）' }) },
    {
        value: 'GT',
        label: $i18n.get({ id: 'GuatemalaGT', dm: '危地马拉（GT）' }),
    },
    { value: 'GG', label: $i18n.get({ id: 'GuernseyGG', dm: '格恩西岛（GG）' }) },
    { value: 'GN', label: $i18n.get({ id: 'GuineaGN', dm: '几内亚（GN）' }) },
    {
        value: 'GW',
        label: $i18n.get({ id: 'GuineaBissauGW', dm: '几内亚比绍（GW）' }),
    },
    { value: 'GY', label: $i18n.get({ id: 'GuyanaGY', dm: '圭亚那（GY）' }) },
    { value: 'HT', label: $i18n.get({ id: 'HaitiHT', dm: '海地（HT）' }) },
    {
        value: 'HM',
        label: $i18n.get({
            id: 'HeardAndMacdonaldIslandsHM',
            dm: '赫德岛和麦克唐纳群岛（HM）',
        }),
    },
    { value: 'HN', label: $i18n.get({ id: 'HondurasHN', dm: '洪都拉斯（HN）' }) },
    { value: 'HU', label: $i18n.get({ id: 'HungaryHU', dm: '匈牙利（HU）' }) },
    { value: 'IS', label: $i18n.get({ id: 'IcelandIS', dm: '冰岛（IS）' }) },
    { value: 'IN', label: $i18n.get({ id: 'IndiaIN', dm: '印度（IN）' }) },
    {
        value: 'ID',
        label: $i18n.get({ id: 'IndonesiaID', dm: '印度尼西亚（ID）' }),
    },
    {
        value: 'IR',
        label: $i18n.get({
            id: 'IranIslamicRepublicOfIR',
            dm: '伊朗 (伊斯兰共和国)（IR）',
        }),
    },
    { value: 'IQ', label: $i18n.get({ id: 'IraqIQ', dm: '伊拉克（IQ）' }) },
    { value: 'IE', label: $i18n.get({ id: 'IrelandIE', dm: '爱尔兰（IE）' }) },
    { value: 'IM', label: $i18n.get({ id: 'IsleOfManxIM', dm: '马恩岛（IM）' }) },
    { value: 'IL', label: $i18n.get({ id: 'IsraelIL', dm: '以色列（IL）' }) },
    { value: 'IT', label: $i18n.get({ id: 'ItalyIT', dm: '意大利（IT）' }) },
    { value: 'JM', label: $i18n.get({ id: 'JamaicaJM', dm: '牙买加（JM）' }) },
    { value: 'JP', label: $i18n.get({ id: 'JapanJP', dm: '日本（JP）' }) },
    { value: 'JE', label: $i18n.get({ id: 'NewJerseyJE', dm: '新泽西（JE）' }) },
    { value: 'JO', label: $i18n.get({ id: 'JordanJO', dm: '约旦（JO）' }) },
    {
        value: 'KZ',
        label: $i18n.get({ id: 'KazakhstanKZ', dm: '哈萨克斯坦（KZ）' }),
    },
    { value: 'KE', label: $i18n.get({ id: 'KenyaKE', dm: '肯尼亚（KE）' }) },
    { value: 'KI', label: $i18n.get({ id: 'KiribatiKI', dm: '基里巴斯（KI）' }) },
    { value: 'KW', label: $i18n.get({ id: 'KuwaitKW', dm: '科威特（KW）' }) },
    {
        value: 'KG',
        label: $i18n.get({ id: 'KyrgyzstanKG', dm: '吉尔吉斯斯坦（KG）' }),
    },
    {
        value: 'LA',
        label: $i18n.get({
            id: 'LaoPeoplesDemocraticRepublicLA',
            dm: '老挝人民民主共和国（LA）',
        }),
    },
    { value: 'LV', label: $i18n.get({ id: 'LatviaLV', dm: '拉脱维亚（LV）' }) },
    { value: 'LB', label: $i18n.get({ id: 'LebanonLB', dm: '黎巴嫩（LB）' }) },
    { value: 'LS', label: $i18n.get({ id: 'LesothoLS', dm: '莱索托（LS）' }) },
    { value: 'LR', label: $i18n.get({ id: 'LiberiaLR', dm: '利比里亚（LR）' }) },
    { value: 'LY', label: $i18n.get({ id: 'LibyaLY', dm: '利比亚（LY）' }) },
    {
        value: 'LI',
        label: $i18n.get({ id: 'LiechtensteinLI', dm: '列支敦士登（LI）' }),
    },
    { value: 'LT', label: $i18n.get({ id: 'LithuaniaLT', dm: '立陶宛（LT）' }) },
    { value: 'LU', label: $i18n.get({ id: 'LuxembourgLU', dm: '卢森堡（LU）' }) },
    {
        value: 'MK',
        label: $i18n.get({
            id: 'FormerYugoslavRepublicOfMacedoni_1870780350',
            dm: '前南斯拉夫马其顿共和国（MK）',
        }),
    },
    {
        value: 'MG',
        label: $i18n.get({ id: 'MadagascarMG', dm: '马达加斯加（MG）' }),
    },
    { value: 'MW', label: $i18n.get({ id: 'MalawiMW', dm: '马拉维（MW）' }) },
    { value: 'MY', label: $i18n.get({ id: 'MalaysiaMY', dm: '马来西亚（MY）' }) },
    { value: 'MV', label: $i18n.get({ id: 'MaldivesMV', dm: '马尔代夫（MV）' }) },
    { value: 'ML', label: $i18n.get({ id: 'MaliML', dm: '马里（ML）' }) },
    { value: 'MT', label: $i18n.get({ id: 'MaltaMT', dm: '马耳他（MT）' }) },
    {
        value: 'MH',
        label: $i18n.get({ id: 'MarshallIslandsMH', dm: '马绍尔群岛（MH）' }),
    },
    {
        value: 'MQ',
        label: $i18n.get({ id: 'MartiniqueMQ', dm: '马提尼克（MQ）' }),
    },
    {
        value: 'MR',
        label: $i18n.get({ id: 'MauritaniaMR', dm: '毛里塔尼亚（MR）' }),
    },
    {
        value: 'MU',
        label: $i18n.get({ id: 'MauritiusMU', dm: '毛里求斯（MU）' }),
    },
    { value: 'YT', label: $i18n.get({ id: 'MayotteYT', dm: '马约特（YT）' }) },
    { value: 'MX', label: $i18n.get({ id: 'MexicoMX', dm: '墨西哥（MX）' }) },
    {
        value: 'FM',
        label: $i18n.get({
            id: 'FederatedStatesOfMicronesiaFeder_839490807',
            dm: '密克罗尼西亚联邦 (联邦)（FM）',
        }),
    },
    {
        value: 'MD',
        label: $i18n.get({ id: 'RepublicOfMoldovaMD', dm: '摩尔多瓦共和国（MD）' }),
    },
    { value: 'MC', label: $i18n.get({ id: 'MonacoMC', dm: '摩纳哥（MC）' }) },
    { value: 'MN', label: $i18n.get({ id: 'MongoliaMN', dm: '蒙古（MN）' }) },
    { value: 'ME', label: $i18n.get({ id: 'MontenegroME', dm: '黑山（ME）' }) },
    {
        value: 'MS',
        label: $i18n.get({ id: 'MontserratMS', dm: '蒙特塞拉特（MS）' }),
    },
    { value: 'MA', label: $i18n.get({ id: 'MoroccoMA', dm: '摩洛哥（MA）' }) },
    {
        value: 'MZ',
        label: $i18n.get({ id: 'MozambiqueMZ', dm: '莫桑比克（MZ）' }),
    },
    { value: 'MM', label: $i18n.get({ id: 'MyanmarMM', dm: '缅甸（MM）' }) },
    { value: 'NA', label: $i18n.get({ id: 'NamibiaNA', dm: '纳米比亚（NA）' }) },
    { value: 'NR', label: $i18n.get({ id: 'NauruNR', dm: '瑙鲁（NR）' }) },
    { value: 'NP', label: $i18n.get({ id: 'NepalNP', dm: '尼泊尔（NP）' }) },
    { value: 'NL', label: $i18n.get({ id: 'NetherlandsNL', dm: '荷兰（NL）' }) },
    {
        value: 'NC',
        label: $i18n.get({ id: 'NewCaledoniaNC', dm: '新喀里多尼亚（NC）' }),
    },
    { value: 'NZ', label: $i18n.get({ id: 'NewZealandNZ', dm: '新西兰（NZ）' }) },
    {
        value: 'NI',
        label: $i18n.get({ id: 'NicaraguaNI', dm: '尼加拉瓜（NI）' }),
    },
    { value: 'NE', label: $i18n.get({ id: 'NigerNE', dm: '尼日尔（NE）' }) },
    { value: 'NG', label: $i18n.get({ id: 'NigeriaNG', dm: '尼日利亚（NG）' }) },
    { value: 'NU', label: $i18n.get({ id: 'NiueNU', dm: '纽埃（NU）' }) },
    {
        value: 'NF',
        label: $i18n.get({ id: 'NorfolkIslandNF', dm: '诺福克岛（NF）' }),
    },
    {
        value: 'KP',
        label: $i18n.get({
            id: 'DemocraticPeoplesRepublicOfKorea_11001317',
            dm: '朝鲜民主主义人民共和国（KP）',
        }),
    },
    {
        value: 'MP',
        label: $i18n.get({
            id: 'NorthernMarianaIslandsMP',
            dm: '北马里亚纳群岛（MP）',
        }),
    },
    { value: 'NO', label: $i18n.get({ id: 'NorwayNO', dm: '挪威（NO）' }) },
    { value: 'OM', label: $i18n.get({ id: 'OmanOM', dm: '阿曼（OM）' }) },
    { value: 'PK', label: $i18n.get({ id: 'PakistanPK', dm: '巴基斯坦（PK）' }) },
    { value: 'PW', label: $i18n.get({ id: 'PalauPW', dm: '帕劳（PW）' }) },
    {
        value: 'PS',
        label: $i18n.get({ id: 'StateOfPalestinePS', dm: '巴勒斯坦国（PS）' }),
    },
    { value: 'PA', label: $i18n.get({ id: 'PanamaPA', dm: '巴拿马（PA）' }) },
    {
        value: 'PG',
        label: $i18n.get({ id: 'PapuaNewGuineaPG', dm: '巴布亚新几内亚（PG）' }),
    },
    { value: 'PY', label: $i18n.get({ id: 'ParaguayPY', dm: '巴拉圭（PY）' }) },
    { value: 'PE', label: $i18n.get({ id: 'PeruPE', dm: '秘鲁（PE）' }) },
    {
        value: 'PH',
        label: $i18n.get({ id: 'PhilippinesPH', dm: '菲律宾（PH）' }),
    },
    { value: 'PN', label: $i18n.get({ id: 'PitcairnPN', dm: '皮特凯恩（PN）' }) },
    { value: 'PL', label: $i18n.get({ id: 'PolandPL', dm: '波兰（PL）' }) },
    { value: 'PT', label: $i18n.get({ id: 'PortugalPT', dm: '葡萄牙（PT）' }) },
    {
        value: 'PR',
        label: $i18n.get({ id: 'PuertoRicoPR', dm: '波多黎各（PR）' }),
    },
    { value: 'QA', label: $i18n.get({ id: 'QatarQA', dm: '卡塔尔（QA）' }) },
    { value: 'RE', label: $i18n.get({ id: 'ReunionRE', dm: '留尼汪（RE）' }) },
    { value: 'RO', label: $i18n.get({ id: 'RomaniaRO', dm: '罗马尼亚（RO）' }) },
    {
        value: 'RU',
        label: $i18n.get({ id: 'RussianFederationRU', dm: '俄罗斯联邦（RU）' }),
    },
    { value: 'RW', label: $i18n.get({ id: 'RwandaRW', dm: '卢旺达（RW）' }) },
    {
        value: 'BL',
        label: $i18n.get({ id: 'SaintBarthlmyBL', dm: '圣巴泰勒米（BL）' }),
    },
    {
        value: 'KN',
        label: $i18n.get({
            id: 'SaintKittsAndNevisKN',
            dm: '圣基茨和尼维斯（KN）',
        }),
    },
    {
        value: 'LC',
        label: $i18n.get({ id: 'SaintLuciaLC', dm: '圣卢西亚（LC）' }),
    },
    {
        value: 'MF',
        label: $i18n.get({ id: 'FrenchSaintMartinMF', dm: '法属圣马丁（MF）' }),
    },
    {
        value: 'VC',
        label: $i18n.get({
            id: 'SaintVincentAndTheGrenadinesVC',
            dm: '圣文森特和格林纳丁斯（VC）',
        }),
    },
    { value: 'WS', label: $i18n.get({ id: 'SamoaWS', dm: '萨摩亚（WS）' }) },
    {
        value: 'SM',
        label: $i18n.get({ id: 'SanMarinoSM', dm: '圣马力诺（SM）' }),
    },
    {
        value: 'ST',
        label: $i18n.get({
            id: 'SaoTomeAndPrincipeST',
            dm: '圣多美和普林西比（ST）',
        }),
    },
    {
        value: 'SA',
        label: $i18n.get({ id: 'SaudiArabiaSA', dm: '沙特阿拉伯（SA）' }),
    },
    { value: 'SN', label: $i18n.get({ id: 'SenegalSN', dm: '塞内加尔（SN）' }) },
    { value: 'RS', label: $i18n.get({ id: 'SerbiaRS', dm: '塞尔维亚（RS）' }) },
    { value: 'SC', label: $i18n.get({ id: 'SeychellesSC', dm: '塞舌尔（SC）' }) },
    {
        value: 'SL',
        label: $i18n.get({ id: 'SierraLeoneSL', dm: '塞拉利昂（SL）' }),
    },
    { value: 'SG', label: $i18n.get({ id: 'SingaporeSG', dm: '新加坡（SG）' }) },
    {
        value: 'SX',
        label: $i18n.get({ id: 'SintMaartenSX', dm: '荷属圣马丁（SX）' }),
    },
    { value: 'SK', label: $i18n.get({ id: 'SlovakiaSK', dm: '斯洛伐克（SK）' }) },
    {
        value: 'SI',
        label: $i18n.get({ id: 'SloveniaSI', dm: '斯洛文尼亚（SI）' }),
    },
    {
        value: 'SB',
        label: $i18n.get({ id: 'SolomonIslandsSB', dm: '所罗门群岛（SB）' }),
    },
    { value: 'SO', label: $i18n.get({ id: 'SomaliaSO', dm: '索马里（SO）' }) },
    { value: 'ZA', label: $i18n.get({ id: 'SouthAfricaZA', dm: '南非（ZA）' }) },
    {
        value: 'GS',
        label: $i18n.get({
            id: 'SouthGeorgiaAndTheSouthSandwichI_449162300',
            dm: '南乔治亚岛与南桑威奇群岛（GS）',
        }),
    },
    {
        value: 'KR',
        label: $i18n.get({ id: 'RepublicOfKoreaKR', dm: '大韩民国（KR）' }),
    },
    { value: 'SS', label: $i18n.get({ id: 'SouthSudanSS', dm: '南苏丹（SS）' }) },
    { value: 'ES', label: $i18n.get({ id: 'SpainES', dm: '西班牙（ES）' }) },
    { value: 'LK', label: $i18n.get({ id: 'SriLankaLK', dm: '斯里兰卡（LK）' }) },
    {
        value: 'SH',
        label: $i18n.get({ id: 'SaintHelenaSH', dm: '圣赫勒拿（SH）' }),
    },
    { value: 'SD', label: $i18n.get({ id: 'SudanSD', dm: '苏丹（SD）' }) },
    { value: 'SR', label: $i18n.get({ id: 'SurinameSR', dm: '苏里南（SR）' }) },
    {
        value: 'SJ',
        label: $i18n.get({
            id: 'SvalbardAndJanMayanSJ',
            dm: '斯瓦尔巴特和扬马延岛（SJ）',
        }),
    },
    {
        value: 'SZ',
        label: $i18n.get({ id: 'SwazilandSZ', dm: '斯威士兰（SZ）' }),
    },
    { value: 'SE', label: $i18n.get({ id: 'SwedenSE', dm: '瑞典（SE）' }) },
    { value: 'CH', label: $i18n.get({ id: 'SwitzerlandCH', dm: '瑞士（CH）' }) },
    {
        value: 'SY',
        label: $i18n.get({
            id: 'SyrianArabRepublicSY',
            dm: '阿拉伯叙利亚共和国（SY）',
        }),
    },
    {
        value: 'TJ',
        label: $i18n.get({ id: 'TajikistanTJ', dm: '塔吉克斯坦（TJ）' }),
    },
    {
        value: 'TZ',
        label: $i18n.get({
            id: 'UnitedRepublicOfTanzaniaTZ',
            dm: '坦桑尼亚联合共和国（TZ）',
        }),
    },
    { value: 'TH', label: $i18n.get({ id: 'ThailandTH', dm: '泰国（TH）' }) },
    { value: 'TG', label: $i18n.get({ id: 'TogoTG', dm: '多哥（TG）' }) },
    { value: 'TK', label: $i18n.get({ id: 'TokelauTK', dm: '托克劳（TK）' }) },
    { value: 'TO', label: $i18n.get({ id: 'TongaTO', dm: '汤加（TO）' }) },
    {
        value: 'TT',
        label: $i18n.get({
            id: 'TrinidadAndTobagoTT',
            dm: '特立尼达和多巴哥（TT）',
        }),
    },
    { value: 'TN', label: $i18n.get({ id: 'TunisiaTN', dm: '突尼斯（TN）' }) },
    { value: 'TR', label: $i18n.get({ id: 'TurkeyTR', dm: '土耳其（TR）' }) },
    {
        value: 'TM',
        label: $i18n.get({ id: 'TurkmenistanTM', dm: '土库曼斯坦（TM）' }),
    },
    {
        value: 'TC',
        label: $i18n.get({
            id: 'TurksAndCaicosIslandsTC',
            dm: '特克斯和凯科斯群岛（TC）',
        }),
    },
    { value: 'TV', label: $i18n.get({ id: 'TuvaluTV', dm: '图瓦卢（TV）' }) },
    { value: 'UG', label: $i18n.get({ id: 'UgandaUG', dm: '乌干达（UG）' }) },
    { value: 'UA', label: $i18n.get({ id: 'UkraineUA', dm: '乌克兰（UA）' }) },
    {
        value: 'AE',
        label: $i18n.get({
            id: 'UnitedArabEmiratesAE',
            dm: '阿拉伯联合酋长国（AE）',
        }),
    },
    {
        value: 'GB',
        label: $i18n.get({ id: 'UnitedKingdomGB', dm: '英国（GB）' }),
    },
    {
        value: 'US',
        label: $i18n.get({
            id: 'UnitedStatesOfAmericaUS',
            dm: '美利坚合众国（US）',
        }),
    },
    {
        value: 'UM',
        label: $i18n.get({
            id: 'SmallIslandsOutsideTheContinenta_5219760',
            dm: '美国本土外小岛屿（UM）',
        }),
    },
    { value: 'UY', label: $i18n.get({ id: 'UruguayUY', dm: '乌拉圭（UY）' }) },
    {
        value: 'UZ',
        label: $i18n.get({ id: 'UzbekistanUZ', dm: '乌兹别克斯坦（UZ）' }),
    },
    { value: 'VU', label: $i18n.get({ id: 'VanuatuVU', dm: '瓦努阿图（VU）' }) },
    { value: 'VA', label: $i18n.get({ id: 'HolySeeVA', dm: '罗马教廷（VA）' }) },
    {
        value: 'VE',
        label: $i18n.get({
            id: 'BolivarianRepublicOfVenezuelaVE',
            dm: '委内瑞拉玻利瓦尔共和国（VE）',
        }),
    },
    { value: 'VN', label: $i18n.get({ id: 'VietnamVN', dm: '越南（VN）' }) },
    {
        value: 'VG',
        label: $i18n.get({
            id: 'BritishVirginIslandsVG',
            dm: '英属维尔京群岛（VG）',
        }),
    },
    {
        value: 'VI',
        label: $i18n.get({
            id: 'UnitedStatesVirginIslandsVI',
            dm: '美属维尔京群岛（VI）',
        }),
    },
    {
        value: 'WF',
        label: $i18n.get({
            id: 'WallisAndFutunaWF',
            dm: '瓦利斯群岛和富图纳群岛（WF）',
        }),
    },
    {
        value: 'EH',
        label: $i18n.get({ id: 'WesternSaharaEH', dm: '西撒哈拉（EH）' }),
    },
    { value: 'YE', label: $i18n.get({ id: 'YemenYE', dm: '也门（YE）' }) },
    { value: 'ZM', label: $i18n.get({ id: 'ZambiaZM', dm: '赞比亚（ZM）' }) },
    { value: 'ZW', label: $i18n.get({ id: 'ZimbabweZW', dm: '津巴布韦（ZW）' }) },
];
