import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { condition, handleSelectDataSource, handleSelectRequestConfig, setAdvancedConfigToProps, } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { getRequestConfigSetterSnippet, getStaticDataSourceSnippet, } from '@/common/manager/setter-snippet';
import { __advancedConfig__ } from '@/common/util/const';
import { componentMap as formComponentMap, Transfer as UITransfer, } from '@cainiaofe/cn-ui';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { Transfer: { title, thumbUrl } } = FORM_ITEM_INFO;
const Transfer = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    thumbUrl,
    componentName: 'Transfer',
    formComponent: formComponentMap.CnTransfer || UITransfer,
    getDefaultProps: () => {
        return {
            __advancedConfig__: {
                showSearch: true,
            },
            dataSource: [
                {
                    label: $i18n.get({ id: 'Option1', dm: '选项1' }),
                    value: 'value1',
                },
                {
                    label: $i18n.get({ id: 'Option2', dm: '选项2' }),
                    value: 'value2',
                },
            ],
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { isDesign, urlParams, formValue, state } = config;
        handleSelectRequestConfig({
            componentProps: formItem?.['x-component-props'],
            isDesign,
            urlParamsDataSource: urlParams,
            recordDataSource: formValue,
            state,
        });
        handleSelectDataSource({
            componentProps: formItem?.['x-component-props'],
            state,
        });
        setAdvancedConfigToProps(formItem?.['x-component-props']);
    },
    // RadioGroup的prototype列表
    getPrototypeList: (position) => {
        const extraSetters = [
            {
                name: 'disabled',
                display: 'inline',
                title: $i18n.get({ id: 'Disable', dm: '是否禁用' }),
                setter: 'BoolSetter',
            },
            {
                name: 'description',
                display: 'inline',
                title: $i18n.get({ id: 'TERM.Description', dm: '描述' }),
                setter: 'CnI18nSetter',
            },
        ];
        // extraSetters.push({
        //   name: 'description',
        //   display: 'inline',
        //   title: '描述',
        //   setter: 'CnI18nSetter',
        // })
        const extraStyleSetters = [
            {
                name: 'showSearch',
                title: $i18n.get({ id: 'OpenSearch', dm: '开启搜索' }),
                setter: 'BoolSetter',
            },
            {
                name: 'sortable',
                title: $i18n.get({
                    id: 'TurnOnDraganddropSorting',
                    dm: '开启拖拽排序',
                }),
                setter: 'BoolSetter',
            },
        ];
        return [
            {
                name: 'dataOrigin',
                title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
                display: 'inline',
                defaultValue: 'static',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'RemoteRequest', dm: '远程请求' }),
                                value: 'request',
                            },
                            {
                                title: $i18n.get({ id: 'StaticData', dm: '静态数据' }),
                                value: 'static',
                            },
                        ],
                    },
                },
            },
            getRequestConfigSetterSnippet({
                condition: (prop) => {
                    return (condition(prop, 'Transfer', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'request');
                },
            }, {
                position,
            }),
            {
                name: 'dataSource',
                title: $i18n.get({ id: 'ShuttleBoxStaticData', dm: '穿梭框静态数据' }),
                display: 'plain',
                // hidden: hidden('CheckboxGroup'),
                condition(prop) {
                    return (condition(prop, 'Transfer', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'static');
                },
                setter: getStaticDataSourceSnippet({
                    extraArraySetterItems: extraSetters,
                }),
            },
            {
                title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
                name: __advancedConfig__,
                display: 'plain',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [...extraStyleSetters],
                        },
                    },
                },
            },
        ];
    },
};
export default Transfer;
