/**
 * @description 获取node内所有节点
 */
function getNodeChildrenMap(node, childrenMap) {
    var _a;
    if (childrenMap === void 0) { childrenMap = {}; }
    if (!node)
        return childrenMap; // 处理没有传入节点的情况
    childrenMap[node.id] = node;
    (_a = node === null || node === void 0 ? void 0 : node.children) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
        getNodeChildrenMap(item, childrenMap);
    });
    return childrenMap;
}
export { getNodeChildrenMap, };
