import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
// eslint-disable-next-line import/no-cycle
import { uuid } from '../../util/util';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const CnFormGrid = 'CnFormGrid';
const { CnFormTabItem: { title, thumbUrl } } = FORM_ITEM_INFO;
const CnFormTabItem = {
    position: [DisplayPosition.formDialog, DisplayPosition.form],
    title,
    componentName: 'CnFormTabItem',
    thumbUrl,
    isFormContainer: true,
    allowParentList: ['root'],
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, } = config || {};
        const { formStyle = {} } = formProps;
        const { cols } = formStyle || {};
        if (formItem) {
            const { label, name } = formItemConfig;
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                if (label && !componentProps.title) {
                    componentProps.title = label;
                }
            }
            delete formItem['x-decorator'];
            formItem.properties = {};
            formItem.title = '';
            formItem.type = 'void';
            const { cols: cardCols } = componentProps;
            const formGridProps = {};
            if (cardCols) {
                formGridProps.cols = cardCols;
            }
            else {
                formGridProps.cols = cols;
            }
            formItem.properties = {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            };
        }
    },
    getDefaultProps: () => {
        return {};
    },
    getFormItemDefaultProps: () => {
        return {
            label: $i18n.get({ id: 'TabLabel', dm: 'Tab标签' }),
            name: `form_tab_${uuid()}`,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'title',
                title: $i18n.get({ id: 'TabItemTitle', dm: 'Tab项标题' }),
                display: 'inline',
                setter: 'CnI18nSetter',
            },
        ];
    },
};
export default CnFormTabItem;
