import $i18n from '@/locales/i18n';
import { getJSXTemplate } from '@/common/manager/setter-snippet';
// eslint-disable-next-line import/no-cycle
import { getJSExpressionPrototype, getParamSetterPrototype, } from '../common-style';
import React from 'react';
import { ButtonPosition } from '../position/button-position';
const formJSExpressionParams = {
    type: 'formRequest',
    /* i18n-disable-next-line */
    extraSlot: `// 返回自定义类型参数值
  // return '参数值'`,
};
export const ossImport = {
    title: $i18n.get({ id: 'TERM.Import', dm: '导入' }),
    value: 'ossImport',
    position: [ButtonPosition.tableToolArea],
    action: () => { },
    getPrototypeList: () => {
        return [
            {
                name: 'uploadService',
                title: $i18n.get({ id: 'FileUploadAPI', dm: '文件上传API' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramTitleDom: (<div className="">
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}

                <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/EGd6jK4Nvk3JlZkYDBPnWZOP0LawMmQq" rel="noreferrer">
                  {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
                </a>
              </div>),
                        paramSetter: [
                            getParamSetterPrototype({
                                type: 'filter',
                            }),
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                            },
                            getJSExpressionPrototype(formJSExpressionParams),
                        ],
                    },
                },
            },
            {
                name: 'createService',
                title: $i18n.get({ id: 'CreateATaskAPI', dm: '创建任务API' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramTitleDom: (<div>
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}

                <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/EGd6jK4Nvk3JlZkYDBPnWZOP0LawMmQq" rel="noreferrer">
                  {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
                </a>
              </div>),
                        resultProcessFuncTemplate: $i18n.get({
                            id: 'FunctionresTheFollowingDataStruc_179509775',
                            dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: {\n  //     jobId: number\n  //   }\n  // }\n return res;\n}',
                        }),
                        paramSetter: [
                            getParamSetterPrototype({
                                type: 'filter',
                            }),
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                            },
                            getJSExpressionPrototype(formJSExpressionParams),
                        ],
                    },
                },
            },
            {
                name: 'pollingService',
                title: $i18n.get({ id: 'TaskPollingAPI', dm: '任务轮询API' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramTitleDom: (<div>
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}

                <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/EGd6jK4Nvk3JlZkYDBPnWZOP0LawMmQq" rel="noreferrer">
                  {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
                </a>
              </div>),
                        paramSetter: [
                            getParamSetterPrototype({
                                type: 'filter',
                            }),
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                            },
                            getJSExpressionPrototype(formJSExpressionParams),
                        ],
                    },
                },
            },
            {
                name: 'templateUrl',
                title: $i18n.get({
                    id: 'ImportALinkToATemplateFile',
                    dm: '导入模板文件的链接',
                }),
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                title: $i18n.get({ id: 'ProfileLink', dm: '配置文件链接' }),
                                componentName: 'StringSetter',
                                props: {
                                    placeholder: $i18n.get({
                                        id: 'PleaseFillInTheUrlOfTheImport',
                                        dm: '请填写导入模板文件的url',
                                    }),
                                },
                            },
                            {
                                title: $i18n.get({
                                    id: 'ConfigurationRequestGetFileLink',
                                    dm: '配置请求获取文件链接',
                                }),
                                componentName: 'ServiceChoiceSetter',
                                props: {
                                    buttonText: $i18n.get({
                                        id: 'SelectRequestAPI',
                                        dm: '选择请求API',
                                    }),
                                    params: {
                                        env: 'pre',
                                        pageSize: 999,
                                        // serviceType: 'HSF',
                                    },
                                    paramSetter: [
                                        {
                                            componentName: 'ParamSelectSetter',
                                            props: {
                                                dataKey: 'config',
                                                labelKey: 'label',
                                                valueKey: 'name',
                                                groupName: $i18n.get({ id: 'FilterBar', dm: '筛选栏' }),
                                                relativeDataSourceName: '_bindFilterDataSourceName',
                                                relativeDataSourceNameKey: '_dataSourceName',
                                            },
                                            title: $i18n.get({
                                                id: 'SelectParameters',
                                                dm: '选择参数',
                                            }),
                                        },
                                        {
                                            componentName: 'StringSetter',
                                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                                        },
                                        getJSExpressionPrototype(formJSExpressionParams),
                                    ],
                                },
                            },
                        ],
                    },
                },
            },
            {
                name: 'hideLoop',
                title: $i18n.get({ id: 'TurnOffTaskPolling', dm: '关闭任务轮询' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            { title: $i18n.get({ id: 'Yes', dm: '是' }), value: true },
                            { title: $i18n.get({ id: 'No', dm: '否' }), value: false },
                            { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
                        ],
                    },
                },
            },
            {
                name: 'headerSlot',
                title: $i18n.get({ id: 'HeaderContentArea', dm: '头部内容区' }),
                display: 'inline',
                setter: (prop) => {
                    let jsxTem = {
                        title: $i18n.get({ id: 'ReactJSXCode', dm: 'React JSX代码' }),
                        componentName: 'CnJSXSetter',
                        props: {
                            usePopup: true,
                            enableFullscreen: true,
                            theme: 'vs',
                        },
                        initialValue: {
                            type: 'js',
                            source: 'function content(state, formValue, selectedRowKeys, selectedRowRecords) { \n  \n}',
                            compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(state, formValue, selectedRowKeys, selectedRowRecords) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                            error: {},
                        },
                    };
                    // 如果是命中 footerButtons 代表是可编辑表格场景
                    if (prop.path.includes('footerButtons')) {
                        jsxTem = {
                            title: $i18n.get({ id: 'ReactJSXCode', dm: 'React JSX代码' }),
                            componentName: 'CnJSXSetter',
                            props: {
                                usePopup: true,
                                enableFullscreen: true,
                                theme: 'vs',
                            },
                            initialValue: {
                                type: 'js',
                                source: 'function content(state) { \n  \n}',
                                compiled: 'function main(){\n    \n    "use strict";\n\nvar __compiledFunc__ = function content(state) {\n  \n};\n    return __compiledFunc__.apply(this, arguments);\n  }',
                                error: {},
                            },
                        };
                    }
                    return {
                        componentName: 'MixedSetter',
                        props: {
                            setters: [
                                jsxTem,
                                {
                                    title: $i18n.get({ id: 'Text', dm: '文本' }),
                                    componentName: 'CnI18nSetter',
                                },
                            ],
                        },
                    };
                },
            },
            {
                name: 'dialogDescription',
                title: $i18n.get({ id: 'TERM.PromptMessage', dm: '提示信息' }),
                display: 'inline',
                condition: (prop) => {
                    try {
                        if (prop.path.includes('footerButtons')) {
                            return false;
                        }
                    }
                    catch {
                        return true;
                    }
                    return true;
                },
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                title: $i18n.get({ id: 'Text', dm: '文本' }),
                                componentName: 'CnI18nSetter',
                            },
                            getJSXTemplate({
                                position: ButtonPosition.tableToolArea,
                            }),
                        ],
                    },
                },
            },
            {
                name: 'handleProps',
                title: $i18n.get({
                    id: 'SetComponentPropsManually',
                    dm: '手动设置组件props',
                }),
                tab: 'advanced',
                display: 'inline',
                setter: getJSExpressionPrototype({
                    type: 'props',
                }),
            },
        ];
    },
};
