import { DisplayPosition } from '../position/display-position';
// eslint-disable-next-line import/no-cycle
import { createFormItemSetters } from '../../../lowcode/cn-form-alc/prototype/form-item';
import { CnBox, CnDownload, CnArrayTable as UICnArrayTable, } from '@cainiaofe/cn-ui';
import { calculateTextExprValue, confirmActionHoc, executeFunction, getArrayTableCurrentRowByField2, getJSExpressionPrototypeTemplate, getRealizeValue, getRunTimeItem, makeFormItemSchema, } from '../../util/util';
import { componentMap as filterItemComponentMap } from './index';
import { createButtonListSetters, getButtonAction } from '../button';
import { ButtonPosition } from '../position/button-position';
import { handleRequestParams, transRequestConfigToRemote, } from '@/common/util/request';
import { columnSuffix } from '@/common/util/const';
import $i18n from '@/locales/i18n';
import isEmpty from 'lodash/isEmpty';
import { __arrayTableCurrentRow__, __arrayTableCurrentRowByWhole__, __arrayTableCurrentRowIndex__, __arrayTableCurrentRowIndexByWhole__, __formValue__, } from '@/common/util/expr-const';
import set from 'lodash/set';
import isPlainObject from 'lodash/isPlainObject';
import { getJSXTemplate, getObjectSetterSnippet, } from '@/common/manager/setter-snippet';
import { _getFormValues } from '@/common/util/biz-component-prop-name';
import React from 'react';
import { createFooterButtonListSetters } from '../button/array-table/footer-setter';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { CnArrayTable: { title, thumbUrl }, } = FORM_ITEM_INFO;
const add = 'add';
const remove = 'remove';
const getDefaultButtons = () => {
    return [
        {
            children: $i18n.get({ id: 'AddARow', dm: '添加一行' }),
            optType: 'arrayTableAdd',
            primaryKey: add,
        },
        {
            primaryKey: remove,
            children: $i18n.get({ id: 'Delete', dm: '删除' }),
            optType: 'arrayTableRemove',
        },
    ];
};
const CnArrayTable = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'CnArrayTable',
    formItemBeforeHandler: (formItem, config) => {
        const { formInstance, formItemConfig, formProps, isDesign, usedComponentList, urlParams, formValue, state, _context, } = config || {};
        const { _dataSourceName } = formProps || {};
        const { options } = formItemConfig || {};
        const { config: arrayTableFormConfig, buttons, footerButtons = [], style = {}, __advancedConfig__, } = options || {};
        if (formItem) {
            let isReadOnly = false;
            if (formItem?.readOnly === true) {
                isReadOnly = true;
            }
            formItem.type = 'array';
            let componentProps = {};
            if (!formItem['x-component-props']) {
                formItem['x-component-props'] = componentProps;
            }
            else {
                componentProps = formItem['x-component-props'];
            }
            if (componentProps) {
                componentProps.scroll = {
                    x: 0,
                };
                componentProps.onRow = (record, index) => {
                    return {
                        onClick: () => {
                            // 保证点击行的时候有数据
                            set(state, `valueOf.${_dataSourceName}.${formItemConfig?.name}.${__arrayTableCurrentRowByWhole__}`, record);
                            set(state, `valueOf.${_dataSourceName}.${formItemConfig?.name}.${__arrayTableCurrentRowIndexByWhole__}`, index);
                        },
                    };
                };
                const { pagination } = componentProps;
                if (typeof __advancedConfig__?.summary === 'function') {
                    componentProps.summary = (data) => {
                        return executeFunction(__advancedConfig__.summary, data, UICnArrayTable?.Summary, _context?.state);
                    };
                }
                formItem.items = {
                    type: 'object',
                    properties: {},
                };
                const { openSort, sortColumnWidth = 50 } = style;
                if (openSort) {
                    formItem.items.properties.__sortColumn__ = {
                        type: 'void',
                        'x-component': 'CnArrayTableColumn',
                        'x-component-props': {
                            width: sortColumnWidth,
                            title: $i18n.get({ id: 'Sort', dm: '排序' }),
                            align: 'center',
                        },
                        properties: {
                            sort: {
                                type: 'void',
                                'x-component': 'CnArrayTableSortHandle',
                            },
                        },
                    };
                }
                if (Array.isArray(arrayTableFormConfig) &&
                    arrayTableFormConfig.length > 0) {
                    const widthMap = {
                        RangeTimePicker: 204,
                        RangePicker: 308,
                    };
                    arrayTableFormConfig.forEach((item) => {
                        const { label, name, componentName, fixed } = item || {};
                        // 默认宽度 144px
                        let width = 144;
                        if (item.width || typeof item.width === 'number') {
                            width = item.width;
                        }
                        else if (!isReadOnly && widthMap[componentName]) {
                            width = widthMap[componentName];
                        }
                        if (name && componentName) {
                            const columnName = name + columnSuffix;
                            if (columnName) {
                                const columnTitle = calculateTextExprValue(label, {
                                    recordDataSource: {},
                                    state,
                                });
                                const columnFormItem = makeFormItemSchema({
                                    formItemConfig: item,
                                    isDesign,
                                    urlParams,
                                    formValue,
                                    state,
                                    usedComponentList,
                                    formProps,
                                    _context,
                                    formInstance,
                                });
                                const columnItem = {
                                    type: 'void',
                                    'x-component': 'CnArrayTableColumn',
                                    'x-component-props': { title: columnTitle, width, fixed },
                                    properties: {
                                        [name]: columnFormItem || {},
                                    },
                                };
                                if (item.hidden === true) {
                                    columnItem['x-hidden'] = true;
                                }
                                else if (item.hidden?.group === __formValue__) {
                                    // const formValueJudgeHidden = executeObjectExpr(item.hidden, {
                                    //   [__dataSource__]: _context?.state,
                                    //   [__formValue__]: formValue,
                                    // },formValue || {}, _context?.state, {})
                                    // if(formValueJudgeHidden === true) {
                                    //   columnItem['x-hidden'] = true;
                                    // }
                                } // if(columnFormItem && columnFormItem['x-hidden']) {
                                //   columnItem['x-hidden'] = true;
                                // }
                                // if(columnFormItem && columnFormItem['x-disabled']) {
                                //   columnItem['x-disabled'] = true;
                                // }
                                const componentDefine = getRunTimeItem(filterItemComponentMap, componentName);
                                if (componentDefine) {
                                    const tableColumnBeforeHandler = componentDefine?.tableColumnBeforeHandler;
                                    if (typeof tableColumnBeforeHandler === 'function') {
                                        tableColumnBeforeHandler({
                                            tableColumn: columnItem,
                                            formItem: columnFormItem,
                                        });
                                    }
                                }
                                formItem.items.properties[columnName] = columnItem;
                            }
                        }
                    });
                }
                // 处理按钮
                let realButtons = buttons;
                if (!buttons) {
                    realButtons = getDefaultButtons();
                }
                const optButtons = [];
                let addBtn;
                let importBtn;
                realButtons?.forEach((item) => {
                    if (item.optType === 'arrayTableAdd') {
                        addBtn = item;
                    }
                    else {
                        optButtons.push(item);
                    }
                });
                footerButtons.forEach((item) => {
                    if (item.optType === 'ossImport') {
                        importBtn = item;
                    }
                });
                // 推断是否所有按钮都是隐藏的
                // let judgeAllBtnHidden = true;
                // 只读状态下隐藏操作列
                const hiddenOperateColumn = style.hiddenOperateColumn && isReadOnly;
                if (optButtons?.length > 0 && !hiddenOperateColumn) {
                    // for(let item of optButtons) {
                    // const { hidden } = item || {}
                    // if(hidden?.group === __arrayTableCurrentRow__) {
                    //   judgeAllBtnHidden = false;
                    //   break;
                    // }
                    // const isHidden = executeObjectExpr(hidden, {
                    //   [__dataSource__]: _context?.state,
                    // },{}, _context?.state, {})
                    // if(isHidden !== true) {
                    //   judgeAllBtnHidden = false;
                    //   break;
                    // }
                    // }
                    const optProperties = {};
                    const { operateColumnWidth } = style;
                    const tempProps = {
                        title: $i18n.get({ id: 'OperateColumn', dm: '操作列' }),
                        lock: 'right',
                        fixed: 'right',
                    };
                    if (typeof operateColumnWidth === 'number') {
                        tempProps.width = `${operateColumnWidth}px`;
                    }
                    else {
                        tempProps.width = '100px';
                    }
                    formItem.items.properties.operateColumn = {
                        type: 'void',
                        'x-component': 'CnArrayTableColumn',
                        'x-component-props': tempProps,
                        properties: optProperties,
                    };
                    optButtons?.forEach((item) => {
                        const { children, optType, primaryKey, asyncLoading } = item || {};
                        if (optType && children && primaryKey) {
                            const action = getButtonAction({
                                ...item,
                                position: ButtonPosition.arrayTableOperate,
                            });
                            const temp = {
                                type: 'void',
                                title: '',
                            };
                            if (optType === 'arrayTableRemove' ||
                                optType === 'arrayTableMoveUp' ||
                                optType === 'arrayTableMoveDown') {
                                if (isReadOnly) {
                                    return;
                                }
                                temp['x-component'] = `Cn${optType.slice(0, 1).toUpperCase() + optType.slice(1)}`;
                            }
                            else {
                                temp['x-component'] = 'ArrayTableOptButton';
                                const componentDefine = getRunTimeItem({}, optType);
                                if (componentDefine?.component) {
                                    const _componentProps = {
                                        text: true,
                                    };
                                    temp['x-component-props'] = _componentProps;
                                    const component = getRealizeValue(componentDefine.component);
                                    if (component) {
                                        _componentProps.getChildren = () => {
                                            return React.createElement(component, {
                                                buttonConfig: item,
                                                position: ButtonPosition.arrayTableOperate,
                                                _context,
                                                [_getFormValues]: () => {
                                                    return formInstance?.values;
                                                },
                                            }, children);
                                        };
                                    }
                                }
                                else {
                                    const _componentProps = {
                                        children,
                                        text: true,
                                        type: 'primary',
                                        className: 'cn-ui-array-table-operate-btn',
                                        asyncLoading,
                                    };
                                    temp['x-component-props'] = _componentProps;
                                    if (action) {
                                        _componentProps.onClick = async (arg) => {
                                            let tempCurrentRow;
                                            let currentIndex;
                                            // 记录当前点击行数据
                                            if (arg?.field &&
                                                _dataSourceName &&
                                                _context?.state?.valueOf &&
                                                formItemConfig?.name) {
                                                tempCurrentRow = getArrayTableCurrentRowByField2(arg?.field);
                                                const segments = arg?.field?.path?.segments;
                                                if (isPlainObject(tempCurrentRow)) {
                                                    set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRow__}`, { ...tempCurrentRow });
                                                    currentIndex = segments?.[segments?.length - 2];
                                                    set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRowIndex__}`, currentIndex);
                                                }
                                                else {
                                                    let _currentIndex = segments?.[1];
                                                    if (_currentIndex !== 'number') {
                                                        _currentIndex = segments?.[segments?.length - 2];
                                                    }
                                                    if (typeof _currentIndex === 'number') {
                                                        const currentRow = arg?.field
                                                            ?.query('..')
                                                            ?.value?.()?.[_currentIndex];
                                                        if (isPlainObject(currentRow)) {
                                                            set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRow__}`, { ...currentRow });
                                                            set(state, `valueOf.${_dataSourceName}.${formItemConfig.name}.${__arrayTableCurrentRowIndex__}`, _currentIndex);
                                                        }
                                                    }
                                                }
                                            }
                                            const hocAction = confirmActionHoc({
                                                originalAction: action,
                                                item,
                                                _context,
                                                params: [
                                                    tempCurrentRow || {},
                                                    state,
                                                    currentIndex,
                                                    formInstance?.values,
                                                    formInstance,
                                                ],
                                            });
                                            return await hocAction({
                                                buttonConfig: item,
                                                position: ButtonPosition.arrayTableOperate,
                                                state,
                                                urlParamsDataSource: urlParams,
                                                _context,
                                                arrayTableConfig: config,
                                                field: arg?.field,
                                            }, arg);
                                        };
                                    }
                                }
                            }
                            optProperties[primaryKey] = temp;
                        }
                    });
                    if (isEmpty(optProperties)) {
                        delete formItem.items.properties.operateColumn;
                    }
                }
                formItem.properties = {};
                if (!isReadOnly && addBtn) {
                    const { children, primaryKey } = addBtn;
                    if (primaryKey) {
                        let addBtnComponentProps;
                        const addBtnDefaultValue = addBtn?.options?.defaultValue;
                        if (Array.isArray(addBtnDefaultValue) &&
                            addBtnDefaultValue.length > 0) {
                            const temp = handleRequestParams(addBtnDefaultValue, {
                                recordDataSource: formValue,
                                state: _context?.state,
                            });
                            if (temp && Object.keys(temp).length > 0) {
                                addBtnComponentProps = {
                                    defaultValue: temp,
                                };
                            }
                        }
                        else if (typeof addBtnDefaultValue === 'function') {
                            addBtnComponentProps = {
                                defaultValue: addBtnDefaultValue.bind(this, {}, _context?.state),
                            };
                        }
                        formItem.properties[primaryKey] = {
                            type: 'void',
                            'x-component': 'CnArrayTableAddition',
                            title: children,
                        };
                        if (addBtnComponentProps) {
                            formItem.properties[primaryKey]['x-component-props'] =
                                addBtnComponentProps;
                        }
                    }
                }
                if (!isReadOnly && importBtn) {
                    const { primaryKey } = importBtn;
                    if (primaryKey) {
                        const importOptions = importBtn.options || {};
                        const { handleProps } = importOptions;
                        let { headerSlot } = importOptions;
                        if (typeof headerSlot === 'function') {
                            headerSlot = executeFunction(headerSlot, _context?.state);
                        }
                        let ossImportProps = {
                            autoImport: true,
                            headerSlot,
                            uploadProps: {
                                requestConfig: transRequestConfigToRemote({
                                    requestConfig: importOptions.uploadService,
                                    needFormatResult: true,
                                }),
                            },
                            createRequestConfig: transRequestConfigToRemote({
                                requestConfig: importOptions.createService,
                                needFormatResult: true,
                            }),
                            pollingRequestConfig: transRequestConfigToRemote({
                                requestConfig: importOptions.pollingService,
                                needFormatResult: true,
                            }),
                        };
                        if (importOptions.templateUrl) {
                            const downloadProps = {};
                            if (typeof importOptions.templateUrl === 'string') {
                                downloadProps.url = importOptions.templateUrl;
                            }
                            else {
                                downloadProps.requestConfig = transRequestConfigToRemote({
                                    requestConfig: importOptions.templateUrl,
                                    needFormatResult: true,
                                });
                            }
                            ossImportProps.uploadMsgSlot = (<CnBox direction="row" align="center">
                  {$i18n.get({ id: 'PleaseDownload', dm: '请下载' })}&nbsp;
                  <CnDownload type="primary" text buttonText={$i18n.get({
                                    id: 'ImportFileTemplate',
                                    dm: '导入文件模板',
                                })} {...downloadProps}/>
                </CnBox>);
                        }
                        if (typeof handleProps === 'function') {
                            try {
                                const tempProps = handleProps(ossImportProps, _context?.state, (values) => {
                                    config
                                        .getFormInstance()
                                        ?.setValues(values(config.getFormInstance()?.values));
                                });
                                if (tempProps) {
                                    ossImportProps = { ...ossImportProps, ...tempProps };
                                }
                            }
                            catch (e) {
                                // 忽略用户执行的异常
                                console.error(e);
                            }
                        }
                        formItem.properties[primaryKey] = {
                            type: 'void',
                            'x-component': 'CnArrayTableImport',
                            'x-component-props': ossImportProps,
                        };
                    }
                }
                if (pagination && formItem['x-component-props']) {
                    formItem['x-component-props'].pagination = pagination;
                }
                const additionButtonVisible = Object.values(formItem.properties).some((_item) => _item['x-component'] === 'CnArrayTableAddition');
                if (!formItem['x-component-props'].mobileProps) {
                    formItem['x-component-props'].mobileProps = {};
                }
                formItem['x-component-props'].mobileProps.additionButtonVisible =
                    additionButtonVisible;
                delete formItem?.['x-component-props']?.title;
            }
        }
    },
    getDefaultProps: () => {
        return {
            config: [
                {
                    label: $i18n.get({ id: 'TERM.No', dm: '序号' }),
                    name: '_index',
                    componentName: 'CnArrayTableIndex',
                    options: {
                        width: 50,
                    },
                },
            ],
            pagination: {
                pageSize: 10,
            },
        };
    },
    getPrototypeList: () => {
        const position = DisplayPosition.cnArrayTable;
        return [
            {
                name: 'config',
                title: $i18n.get({ id: 'TableColumnConfiguration', dm: '表格列配置' }),
                setter: createFormItemSetters({ position }),
            },
            {
                name: 'buttons',
                title: $i18n.get({ id: 'OperationColumnButton', dm: '操作列按钮' }),
                defaultValue: getDefaultButtons(),
                // supportVariable: true,
                setter: createButtonListSetters({
                    position: ButtonPosition.arrayTableOperate,
                }),
            },
            {
                name: 'footerButtons',
                title: $i18n.get({ id: 'BottomButton', dm: '底部按钮' }),
                defaultValue: [],
                setter: createFooterButtonListSetters(),
            },
            {
                name: 'style',
                title: $i18n.get({ id: 'TableStyle', dm: '表格样式' }),
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'operateColumnWidth',
                                    title: $i18n.get({
                                        id: 'OperationColumnWidth',
                                        dm: '操作列宽度',
                                    }),
                                    setter: 'NumberSetter',
                                },
                                {
                                    name: 'hiddenOperateColumn',
                                    title: $i18n.get({
                                        id: 'ReadonlyHiddenOperationColumn',
                                        dm: '只读态隐藏操作列',
                                    }),
                                    setter: 'BoolSetter',
                                },
                                {
                                    name: 'openSort',
                                    title: $i18n.get({ id: 'OpenSort', dm: '开启排序' }),
                                    setter: 'BoolSetter',
                                },
                                {
                                    name: 'sortColumnWidth',
                                    title: $i18n.get({ id: 'SortColumnWidth', dm: '排序列宽度' }),
                                    setter: 'NumberSetter',
                                    condition(prop) {
                                        return prop?.parent?.getPropValue?.('openSort') === true;
                                    },
                                },
                            ],
                        },
                    },
                },
            },
            {
                name: '__advancedConfig__',
                title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'summary',
                                    title: $i18n.get({ id: 'DataStatistics', dm: '数据统计' }),
                                    setter: getJSXTemplate({
                                        position: DisplayPosition.arrayTableSummary,
                                    }),
                                },
                            ],
                        },
                    },
                },
            },
            {
                name: 'pagination',
                title: $i18n.get({ id: 'PagingSettings', dm: '分页设置' }),
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'pageSize',
                                    title: $i18n.get({ id: 'EachPageShows', dm: '每页展示' }),
                                    setter: 'NumberSetter',
                                },
                            ],
                        },
                    },
                },
            },
            {
                title: $i18n.get({
                    id: 'OnecodeMultiterminalConfiguratio_447139455',
                    dm: '一码多端配置项',
                }),
                name: 'mobileProps',
                display: 'accordion',
                setter: getObjectSetterSnippet({
                    items: [
                        {
                            name: 'type',
                            title: $i18n.get({
                                id: 'AuxiliaryEndDisplayEffect',
                                dm: '辅端展示效果',
                            }),
                            display: 'inline',
                            setter: {
                                componentName: 'RadioGroupSetter',
                                props: {
                                    options: [
                                        {
                                            title: $i18n.get({ id: 'EntryMode', dm: '入口模式' }),
                                            value: 'entry',
                                        },
                                        {
                                            title: $i18n.get({ id: 'TERM.Expand', dm: '展开' }),
                                            value: 'expand',
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            onecodeDisplay: 'visible',
                            name: 'title',
                            title: $i18n.get({
                                id: 'SecondaryCardTitle',
                                dm: '辅端卡片标题',
                            }),
                            display: 'inline',
                            editable: true,
                            isRequired: true,
                            setter: {
                                componentName: 'MixedSetter',
                                props: {
                                    setters: [
                                        {
                                            componentName: 'CnI18nSetter',
                                            title: $i18n.get({
                                                id: 'MultilingualSetup',
                                                dm: '多语言设置器',
                                            }),
                                        },
                                        getJSExpressionPrototypeTemplate({
                                            initialCode: "(record, index) => {\n  return 'Title ' + (index + 1);\n}",
                                        }),
                                    ],
                                },
                            },
                        },
                    ],
                }),
            },
        ];
        // let configure = [
        //   {
        //     name: 'id',
        //     setter: 'StringSetter',
        //     condition() {
        //       return false;
        //     },
        //   },
        //   {
        //     name: 'label',
        //     title: '字段名称',
        //     display: 'inline',
        //     isRequired: true,
        //     setter: 'StringSetter',
        //   },
        //   {
        //     name: 'name',
        //     title: '字段编码',
        //     display: 'inline',
        //     isRequired: true,
        //     setter: 'StringSetter',
        //   },
        //   {
        //     name: 'componentName',
        //     title: '组件类型',
        //     display: 'inline',
        //     extraProps: {
        //       setValue(target, value) {
        //         const defaultProps = getItemDefaultProps(position, value);
        //         if (defaultProps) {
        //           target?.parent?.setPropValue?.('options', defaultProps);
        //         } else {
        //           target?.parent?.setPropValue?.('options', {});
        //         }
        //         const formItemDefaultProps = getFormItemDefaultProps(position, value);
        //         if (formItemDefaultProps) {
        //           const keys = Object.keys(formItemDefaultProps);
        //           keys.forEach((key) => {
        //             target?.parent?.setPropValue?.(key, formItemDefaultProps[key]);
        //           });
        //         }
        //       },
        //     },
        //     setter: {
        //       componentName: 'SelectSetter',
        //       props: ()=>{
        //         return {
        //           showSearch: true,
        //           options: getItemListByPosition({ position }),
        //         }
        //       }
        //     },
        //   },
        // ]
        // const formItemPrototypeList = getItemPrototypeListByPosition({ position });
        // if (formItemPrototypeList?.length > 0) {
        //   configure.push({
        //     name: 'options',
        //     // display: 'accordion',
        //     display: 'block',
        //     title: '请完善组件的配置项',
        //     condition(prop) {
        //       const componentName = prop?.parent?.getPropValue?.('componentName');
        //       if (componentName) {
        //         const item = getItem(position, componentName) || {};
        //         const { getPrototypeList, configure = []} = item;
        //         if ((getPrototypeList && getPrototypeList()?.length > 0) || configure?.length > 0) {
        //           return true;
        //         }
        //       }
        //       return false;
        //     },
        //     extraProps: {
        //       defaultCollapsed: false,
        //     },
        //     setter: {
        //       componentName: 'ObjectSetter',
        //       props: {
        //         config: {
        //           items: [
        //             ...formItemPrototypeList,
        //           ],
        //         },
        //       },
        //     },
        //   });
        // }
        // if (position) {
        //   configure = [...configure, ...createValidatorSetters({ position: position.replace('display','validator') }), ...getItemStylePrototype({ position })];
        // }
        // return [
        //   {
        //     name: 'config',
        //     title: '表单项配置',
        //     // display: 'block',
        //     // initialValue: [
        //     //
        //     // ],
        //     setter: {
        //       componentName: 'ArraySetter',
        //       props: {
        //         mode: 'list',
        //         extraProps:{
        //           renderFooter(props) {
        //             return <AddOneBtn {...props} />
        //           },
        //         },
        //         itemSetter: {
        //           componentName: 'ObjectSetter',
        //           // initialValue(prop) {
        //           //
        //           // },
        //           props: {
        //             config: {
        //               items: configure,
        //             },
        //           },
        //         },
        //       },
        //     },
        //     // supportVariable: true,
        //   },
        // ];
    },
};
export default CnArrayTable;
