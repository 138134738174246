import $i18n from '@/locales/i18n';
import { CnButton, CnIcon } from '@cainiaofe/cn-ui';
import React from 'react';
export const getFormatDocSetter = () => {
    return {
        condition: (props) => {
            return (props?.parent.getPropValue?.('format') || '').startsWith('CnFormat');
        },
        setter: {
            componentName: 'DocSetter',
            display: 'inline',
            props: () => {
                return {
                    content: (<CnButton text component="a" target="_blank" href="https://alidocs.dingtalk.com/i/nodes/amweZ92PV6DbOdgzU6lY0mqK8xEKBD6p">
              <CnIcon type="help"/>
              {$i18n.get({
                            id: 'ClickToSeeHowContentFormattingIs_168207223',
                            dm: '点击查看内容格式化使用方式',
                        })}
            </CnButton>),
                };
            },
        },
    };
};
