import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import { componentMap as formComponentMap, formilyReact, } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { calculateTextExprValue, executeFunction, getArrayTableCurrentRowByField2, makeButtons, uuid, } from '../../util/util';
import { createButtonListSetters } from '@/common/manager/button';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { getColsSetterSnippet, getJSXTemplate, getMixedSetterSnippet, } from '@/common/manager/setter-snippet';
import { __arrayTableCurrentRow__ } from '@/common/util/expr-const';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { useField } = formilyReact;
const { CnCardSubCard: { title, thumbUrl } } = FORM_ITEM_INFO;
const CnFormGrid = 'CnFormGrid';
const CnCardSubCard = {
    position: [
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'CnCardSubCard',
    formComponent: (props) => {
        const { subTitle, subDesc } = props || {};
        const extraProps = {
            title: subTitle,
            desc: subDesc,
            subTitle: undefined,
            subDesc: undefined,
        };
        if (props.shape === 'CnCardSubAreaCard') {
            return (<formComponentMap.CnCardSubAreaCard {...props} {...extraProps} shape={undefined}/>);
        }
        return (<formComponentMap.CnCard {...props} {...extraProps} shape="simple" noPadding/>);
    },
    isFormContainer: true,
    allowParentList: ['CnCard', 'CnFormStepItem', 'CnFormTabItem'],
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, parentPosition, } = config || {};
        const { formStyle = {} } = formProps;
        const { cols } = formStyle || {};
        if (formItem) {
            const { label, name, options = {}, } = formItemConfig;
            const { buttons = [] } = options;
            const componentProps = formItem['x-component-props'] || {};
            if (componentProps) {
                if (componentProps.subTitle) {
                    componentProps.subTitle = calculateTextExprValue(componentProps.subTitle, {
                        recordDataSource: formValue || {},
                        state,
                    });
                }
                if (componentProps.titleTips) {
                    componentProps.titleTips = calculateTextExprValue(componentProps.titleTips, {
                        recordDataSource: formValue || {},
                        state,
                    });
                }
                if (label && !componentProps.subTitle) {
                    componentProps.subTitle = label;
                }
                if (Array.isArray(buttons) && buttons.length > 0) {
                    let action;
                    const makeButtonConfig = {
                        buttons: buttons.map((item) => {
                            let realChildren = item?.children;
                            if (typeof item?.children === 'function') {
                                realChildren = executeFunction(item.children, formValue, state);
                            }
                            return {
                                ...item,
                                style: { marginLeft: '8px' },
                                size: 'small',
                                position: ButtonPosition.subCardAction,
                                children: realChildren,
                            };
                        }),
                        _context,
                        state,
                        urlParamsDataSource: urlParams,
                        recordDataSource: formValue,
                        formInstance,
                        parentPosition,
                    };
                    if (parentPosition === DisplayPosition.cnArraySubAreaCard) {
                        action = () => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            const field = useField?.();
                            if (field) {
                                const currentRow = getArrayTableCurrentRowByField2(field);
                                makeButtonConfig?.buttons?.forEach?.((item) => {
                                    item.getExtraParam = () => {
                                        return {
                                            [__arrayTableCurrentRow__]: currentRow,
                                        };
                                    };
                                });
                                makeButtonConfig.arrayTableCurrentRow = currentRow;
                            }
                            return makeButtons(makeButtonConfig);
                        };
                    }
                    else {
                        action = makeButtons(makeButtonConfig);
                    }
                    if (action) {
                        componentProps.action = action;
                        delete componentProps.buttons;
                    }
                }
            }
            // if(formItemReadOnly === undefined) {
            //   formItem.readOnly = readOnly;
            // }else if(isBoolean(formItemReadOnly)) {
            //   formItem.readOnly = formItemReadOnly;
            // }
            delete formItem['x-decorator'];
            formItem.title = '';
            formItem.type = 'void';
            const { cols: subCardCols } = componentProps;
            const formGridProps = {};
            if (subCardCols) {
                formGridProps.cols = subCardCols;
            }
            else {
                formGridProps.cols = cols;
            }
            formItem.properties = {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            };
        }
    },
    getFormItemDefaultProps: () => {
        return {
            label: $i18n.get({ id: 'SubcardTitle', dm: '子卡片标题' }),
            name: `subCard_${uuid()}`,
        };
    },
    getDefaultProps: () => {
        return {
            shape: 'CnCardSubCard',
        };
    },
    getPrototypeList: (position) => {
        return [
            {
                name: 'shape',
                title: $i18n.get({ id: 'Style', dm: '样式' }),
                display: 'inline',
                setter: {
                    componentName: 'SelectSetter',
                    props: {
                        options: [
                            {
                                label: $i18n.get({ id: 'Subcard', dm: '子卡片' }),
                                value: 'CnCardSubCard',
                            },
                            {
                                label: $i18n.get({ id: 'Level3Card', dm: '三级卡片' }),
                                value: 'CnCardSubAreaCard',
                            },
                        ],
                    },
                },
            },
            {
                name: 'subTitle',
                title: $i18n.get({ id: 'Title', dm: '标题' }),
                display: 'inline',
                setter: getMixedSetterSnippet({
                    setters: [
                        {
                            title: $i18n.get({ id: 'Text', dm: '文本' }),
                            componentName: 'CnI18nSetter',
                        },
                        getJSExpressionPrototype({
                            type: 'formRequest',
                        }),
                    ],
                }),
            },
            {
                name: 'titleTips',
                title: $i18n.get({ id: 'TitlePrompt', dm: '标题提示' }),
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                title: $i18n.get({ id: 'Text', dm: '文本' }),
                                componentName: 'CnI18nSetter',
                            },
                            getJSXTemplate({
                                position: ButtonPosition.form,
                            }),
                        ],
                    },
                },
            },
            {
                name: 'subDesc',
                title: $i18n.get({ id: 'TERM.Description', dm: '描述' }),
                display: 'inline',
                setter: 'CnI18nSetter',
            },
            getColsSetterSnippet(),
            {
                name: 'buttons',
                title: $i18n.get({
                    id: 'ButtonOnTheRightSideOfTheCard',
                    dm: '卡片右侧按钮',
                }),
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: createButtonListSetters({
                    position: ButtonPosition.subCardAction,
                    additionalPosition: position,
                }),
            },
        ];
    },
};
export default CnCardSubCard;
