import findLastIndex from 'lodash/findLastIndex';
/**
 * 获取可编辑数组/卡片当前行数据
 */
export function getEditArrayCurrentRow(field, arrayIndex) {
    const { segments } = field.path;
    // 获取当前数组在 segments 中的索引
    const rowSegmentIndex = findLastIndex(segments, (item) => item === arrayIndex);
    const rowSegments = segments.slice(0, rowSegmentIndex);
    return field.query(rowSegments).value()?.[arrayIndex] || {};
}
