import { CnOSSImageUpload, formilyReact } from '@cainiaofe/cn-ui';
import { useRef } from 'react';
import { formComponentRefStorage } from '@/common/util/const';
import { componentRefSplit } from '@/common/util/expr-const';
const { useField } = formilyReact;
export const ImageUploadCom = (props) => {
    const { _dataSourceName, ...rest } = props;
    const ref = useRef();
    const field = useField?.();
    const fieldEntire = field?.path?.entire;
    formComponentRefStorage[`${_dataSourceName}${componentRefSplit}${fieldEntire}`] = ref;
    return <CnOSSImageUpload {...rest} ref={ref}/>;
};
