import $i18n from '@/locales/i18n';
import {
  CnCheckboxGroup,
  CnComplexCheckboxGroup,
  componentMap as formComponentMap,
} from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import {
  condition,
  handleSelectDataSource,
  handleSelectRequestConfig,
} from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import {
  getRequestConfigSetterSnippet,
  getStaticDataSourceSnippet,
} from '@/common/manager/setter-snippet';
import { forwardRef } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';

const { CheckboxGroup: { title, thumbUrl } } = FORM_ITEM_INFO;

const CheckboxGroup = {
  position: [
    DisplayPosition.form,
    DisplayPosition.filter,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],

  thumbUrl,
  title,
  componentName: 'CheckboxGroup',
  component: (props) => {
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    if (type === 'complex') {
      return <CnComplexCheckboxGroup {...rest} />;
    }
    return <CnCheckboxGroup {...rest} />;
  },
  formComponent: forwardRef((props, ref) => {
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    if (type === 'complex') {
      return <formComponentMap.CnComplexCheckboxGroup {...rest} ref={ref} />;
    }
    return <formComponentMap.CnCheckboxGroup {...rest} ref={ref} />;
  }),
  getDefaultProps: () => {
    return {
      dataSource: [
        {
          label: $i18n.get({ id: 'Option1', dm: '选项1' }),
          value: 'value1',
        },
        {
          label: $i18n.get({ id: 'Option2', dm: '选项2' }),
          value: 'value2',
        },
      ],
    };
  },
  formItemBeforeHandler: (formItem, config) => {
    const { isDesign, urlParams, formValue, state } = config;
    const tempProps = formItem?.['x-component-props'];
    if (isPlainObject(tempProps)) {
      const { extra } = tempProps;
      const { direction } = extra || {};
      if (direction) {
        tempProps.direction = direction;
      }
    }
    handleSelectRequestConfig({
      componentProps: formItem?.['x-component-props'],
      isDesign,
      urlParamsDataSource: urlParams,
      recordDataSource: formValue,
      state,
      handleDynamicUrl: true,
    });
    handleSelectDataSource({
      componentProps: formItem?.['x-component-props'],
      state,
      recordDataSource: formValue,
    });
  },
  filterItemBeforeHandler: (filterItemProps, config) => {
    handleSelectRequestConfig(config);
    handleSelectDataSource(config);
  },
  getPrototypeList: (position) => {
    const extraSetters = [
      {
        name: 'disabled',
        display: 'inline',
        title: $i18n.get({ id: 'Disable', dm: '是否禁用' }),
        setter: {
          componentName: 'MixedSetter',
          props: {
            setters: [
              {
                componentName: 'BoolSetter',
                title: $i18n.get({
                  id: 'BooleanTruefalse',
                  dm: '布尔（true/false）',
                }),
              },
              getJSExpressionPrototype({ type: 'form' }),
            ],
          },
        },
      },
      {
        name: 'description',
        display: 'inline',
        title: $i18n.get({ id: 'TERM.Description', dm: '描述' }),
        setter: 'CnI18nSetter',
      },
      {
        name: 'icon',
        display: 'inline',
        title: $i18n.get({ id: 'Icons', dm: '图标' }),
        setter: 'CnIconSetter',
      },
      {
        display: 'none',
        setter: {
          componentName: 'DocSetter',
          props: {
            content: (
              <div className="">
                {$i18n.get({ id: 'PleaseCome', dm: '请到' })}{' '}
                <a
                  target={'_blank'}
                  href={
                    'https://done.alibaba-inc.com/dsm/cone/components/detail/cnicon?themeid=26075&tabActiveKey=component'
                  }
                  rel="noreferrer"
                >
                  {$i18n.get({ id: 'IconLibrary', dm: '图标库' })}
                </a>{' '}
                {$i18n.get({
                  id: 'FindTheIconYouWantToUseAnd',
                  dm: '中寻找想用的图标，填写图标type即可。',
                })}
                <a
                  target={'_blank'}
                  href={
                    'https://alidocs.dingtalk.com/i/nodes/NkPaj7GAXdpWOkPeZ6p0Wqwgylnomz9B?utm_scene=team_space&iframeQuery=anchorId%3Duu_lbusmehvlsw40rh61ti'
                  }
                  rel="noreferrer"
                >
                  {$i18n.get({ id: 'ExperienceDemo', dm: '体验Demo' })}
                </a>
              </div>
            ),
          },
        },
      },
    ];

    // extraSetters.push({
    //   name: 'description',
    //   display: 'inline',
    //   title: '描述',
    //   setter: 'CnI18nSetter',
    // })
    const extraStyleSetters = [
      {
        name: 'type',
        title: $i18n.get({ id: 'ShowStyle', dm: '展示样式' }),
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({ id: 'OrdinaryType', dm: '普通型' }),
                value: 'normal',
              },
              {
                title: $i18n.get({ id: 'Enhanced', dm: '增强型' }),
                value: 'complex',
              },
            ],
          },
        },
      },
      {
        name: 'direction',
        title: $i18n.get({ id: 'ArrangementOfOptions', dm: '选项的排列方式' }),
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({
                  id: 'HorizontalArrangement',
                  dm: '水平排列',
                }),
                value: 'hoz',
              },
              {
                title: $i18n.get({ id: 'VerticalArrangement', dm: '竖直排列' }),
                value: 'ver',
              },
            ],
          },
        },
      },
    ];

    return [
      {
        name: 'dataOrigin',
        title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
        display: 'inline',
        defaultValue: 'static',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({ id: 'RemoteRequest', dm: '远程请求' }),
                value: 'request',
              },
              {
                title: $i18n.get({ id: 'StaticData', dm: '静态数据' }),
                value: 'static',
              },
            ],
          },
        },
      },
      getRequestConfigSetterSnippet(
        {
          condition: (prop) => {
            return (
              condition(prop, 'CheckboxGroup', 'componentName') &&
              prop?.parent?.getPropValue?.('dataOrigin') === 'request'
            );
          },
        },
        {
          position,
        },
      ),
      {
        name: 'dataSource',
        title: $i18n.get({
          id: 'CheckBoxGroupStaticData',
          dm: '复选框组静态数据',
        }),
        display: 'plain',
        // hidden: hidden('CheckboxGroup'),
        condition(prop) {
          return (
            condition(prop, 'CheckboxGroup', 'componentName') &&
            prop?.parent?.getPropValue?.('dataOrigin') === 'static'
          );
        },
        setter: getStaticDataSourceSnippet({
          extraArraySetterItems: extraSetters,
        }),
      },
      {
        name: 'extra',
        title: $i18n.get({ id: 'Style', dm: '样式' }),
        display: 'plain',
        collapsed: true,
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [...extraStyleSetters],
            },
          },
        },
      },
    ];
  },
};
export default CheckboxGroup;
