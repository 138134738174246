import $i18n from '@/locales/i18n';
import React from 'react';
import { CnBox, CnCard as UICnCard, CnBalloon } from '@cainiaofe/cn-ui';
import {
  executeFunction,
  isArrayNotEmpty,
  isDesignMode,
  makeButtons,
  mergeHandleProps2,
} from '@/common/util/util';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { AddLayout } from '@/lowcode/cn-card-alc/components/add-layout';

const getExtraDom = (slot) => {
  if (isArrayNotEmpty(slot)) {
    return slot.map((item) => {
      return React.cloneElement(item);
    });
  }
  if (slot) {
    return slot;
  }
  return null;
};

const CnCard = (props) => {
  const {
    title,
    titleTips,
    desc,
    children,
    foldable,
    titleTag,
    buttons,
    _context,
    noPadding,
    noShadow,
    shape,
    icon,
    slotManager,
    contentGap = true,
    _unsafe_MixedSetter_buttons_select,
  } = props;

  const isDesign = isDesignMode(props);

  const { headerBottomSlot, headerRightSlot } = slotManager || {};

  const state = _context?.state;
  let extraProps = {};
  const action = _unsafe_MixedSetter_buttons_select === 'SlotSetter'
    ? buttons
    : makeButtons({
      buttons: buttons?.map((item) => {
        let realChildren = item?.children;
        if (typeof item?.children === 'function') {
          realChildren = executeFunction(item.children, {}, state);
        }
        return {
          ...item,
          position: ButtonPosition.cardActionNotInForm,
          children: realChildren,
        };
      }),
      _context,
      state,
      recordDataSource: {},
    });
  if (headerRightSlot || action) {
    extraProps.action = (
      <>
        {headerRightSlot ? (
          <CnBox className="lc-cn-card-right-filter-slot">{getExtraDom(headerRightSlot)}</CnBox>
        ) : null}
        {action}
      </>
    );
  }

  // 设计态快速添加布局
  if (isDesign && children?.[0]?.props?.className?.includes('lc-container-placeholder')) {
    children[0].props.children = (
      <>
        <div className="cn-ui-card-add-layout">
          <CnBalloon
            v2
            closable={false}
            align={'t'}
            popupStyle={{ minWidth: '312px' }}
            trigger={
              <div onClick={() => {}} className="cn-ui-card-add-layout-btn">
                {$i18n.get({ id: 'ColumnInCard', dm: '卡片内分栏' })}
              </div>
            }
          >
            <AddLayout _leaf={props?._leaf} />
          </CnBalloon>
        </div>
        {$i18n.get({ id: 'DragComponentsOrTemplatesHere', dm: '拖拽组件或模板到这里' })}
      </>
    );
  }

  extraProps = mergeHandleProps2({
    handleProps: props?.handleProps,
    componentProps: extraProps,
    _context: props?._context,
  });

  return (
    <UICnCard
      icon={icon}
      title={title}
      titleTips={titleTips}
      desc={desc}
      foldable={foldable}
      titleTag={titleTag}
      shape={shape}
      noPadding={noPadding}
      noShadow={noShadow}
      contentGap={contentGap}
      {...extraProps}
    >
      {getExtraDom(headerBottomSlot)}
      {children}
    </UICnCard>
  );
};
CnCard.displayName = 'CnCard';

export default CnCard;

export { CnCard };
