import $i18n from '@/locales/i18n';
import React from 'react';
import { CnTooltip, Input, NumberPicker, Select } from '@cainiaofe/cn-ui';
import isPlainObject from 'lodash/isPlainObject';
// eslint-disable-next-line import/no-cycle
import { __ds__, __record__, __urlParams__, compileTextExpr2, getDataSourceList, getFlowListDataSource, handleI18nLabel, isArrayNotEmpty, isRecursionComponent, } from '../../util/util';
import { __dataSource__, __flowData__, __left_tree_currentItem__, __left_tree_currentValue__, __paging__, __selectedRowKeys__, __step_current__, __tab_activeKey__, __tableColumns__, __tableCurrentRow__, __tableData__, __tableExtra__, __totalCount__, tableCurrentRowLabel, } from '@/common/util/expr-const';
import './index.scss';
const symbolList2 = [
    '==',
    '!=',
    '>',
    '<',
    '>=',
    '<=',
    {
        label: $i18n.get({
            id: 'IncludeAvailableWhenSelectingMul_396221440',
            dm: '包含(多选时可用)',
        }),
        value: 'includes',
    },
    {
        label: $i18n.get({
            id: 'NotIncludedMultiselectAvailable',
            dm: '不包含(多选可用)',
        }),
        value: 'notIncludes',
    },
];
const typeList = [
    {
        label: $i18n.get({ id: 'Text', dm: '文本' }),
        value: 'string',
    },
    {
        label: $i18n.get({ id: 'Numbers', dm: '数字' }),
        value: 'number',
    },
    {
        label: $i18n.get({ id: 'Boolean', dm: '布尔' }),
        value: 'boolean',
    },
    {
        label: $i18n.get({ id: 'Array', dm: '数组' }),
        value: 'array',
    },
];
export default class ExprSetter extends React.Component {
    static displayName = 'ExprSetter';
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            map: {},
        };
    }
    componentDidMount() {
        const { configList, value, onChange } = this.props;
        let state;
        if (configList?.length > 0) {
            const temp = this.getList() || {};
            const { list, map = {} } = temp;
            if (list?.length > 0) {
                state = {
                    list,
                    map,
                };
            }
        }
        const objectExpr = this.transTextExprToObjectExpr(value, configList?.[0].groupExprName);
        if (objectExpr) {
            onChange?.(objectExpr);
        }
        if (state) {
            this.setState(state);
        }
    }
    transTextExprToObjectExpr = (str, defaultGroupName) => {
        let expr;
        const { configList = [] } = this.props;
        if (typeof str === 'string' && str.length > 0) {
            const exprObj = compileTextExpr2(str) || {};
            const { attr, dataSourceName, symbol, type = 'string', urlParamsKey, value, } = exprObj;
            if (attr && symbol && type) {
                if (attr === __urlParams__) {
                    if (urlParamsKey) {
                        expr = {
                            group: __dataSource__,
                            param: 'urlParams',
                            symbol,
                            type,
                            value,
                            secondParam: urlParamsKey,
                        };
                    }
                }
                else if (this.isDataSource(attr)) {
                    if (dataSourceName) {
                        expr = {
                            group: __dataSource__,
                            param: dataSourceName,
                            symbol,
                            type,
                            value,
                            secondParam: urlParamsKey,
                        };
                    }
                }
                else if (attr?.startsWith(__record__) && configList?.[0]) {
                    const key = attr.replace(`${__record__}.`, '');
                    if (key && defaultGroupName) {
                        expr = {
                            group: defaultGroupName,
                            param: key,
                            symbol,
                            type,
                            value,
                        };
                    }
                }
            }
        }
        return expr;
    };
    isDataSource = (str) => {
        if (str) {
            return str === __urlParams__ || str?.startsWith(__ds__);
        }
    };
    getList = () => {
        const { field, configList } = this.props;
        let result = [];
        const map = {};
        const newList = [];
        if (Array.isArray(configList) && configList.length > 0) {
            configList.forEach((item) => {
                const { dataKey, labelKey, valueKey, groupName, groupExprName, handleCustomGroup, flowList, currentFlowIndex, } = item;
                if (groupExprName) {
                    if (groupExprName === __dataSource__) {
                        const extra = {
                            label: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                            children: [],
                        };
                        const dsList = getDataSourceList({ typeList: ['URI', 'VALUE'] });
                        if (dsList.length > 0) {
                            dsList.forEach((item2) => {
                                if (item2) {
                                    const { label, value, componentName } = item2;
                                    extra.children.push({
                                        label: handleI18nLabel(label),
                                        value: `${groupExprName}.${value}`,
                                        componentName,
                                    });
                                }
                            });
                        }
                        result.push(extra);
                    }
                    else if (dataKey === 'arrayTable') {
                        const path = field?.path;
                        if (Array.isArray(path) && path.length > 3) {
                            const arrayTableIndex = path[1];
                            if (arrayTableIndex !== undefined) {
                                const formConfig = field?.getNode?.()?.getPropValue?.('config');
                                const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
                                if (isRecursionComponent(arrayTableConfig?.componentName) &&
                                    arrayTableConfig?.options?.config?.length > 0) {
                                    const tempList = arrayTableConfig?.options?.config;
                                    result.push({
                                        label: groupName,
                                        children: tempList
                                            .filter((item2) => item2.name)
                                            .map((item2) => {
                                            return {
                                                label: handleI18nLabel(item2?.[labelKey]),
                                                value: `${groupExprName}.${item2?.[valueKey] || ''}`,
                                            };
                                        }),
                                    });
                                }
                            }
                        }
                    }
                    else if (typeof handleCustomGroup === 'function') {
                        const temp = handleCustomGroup();
                        if (isPlainObject(temp)) {
                            result.push(temp);
                        }
                    }
                    else if (groupExprName === __flowData__) {
                        const flowListDataSource = getFlowListDataSource({
                            currentFlowIndex,
                            flowList,
                        });
                        if (isArrayNotEmpty(flowListDataSource)) {
                            result.push({
                                label: groupName,
                                children: flowListDataSource,
                            });
                        }
                    }
                    else {
                        let originList;
                        try {
                            originList = field?.getNode?.()?.getPropValue?.(dataKey);
                        }
                        catch (e) { /* empty */ }
                        if (originList?.length > 0) {
                            originList.forEach((item2) => {
                                const value = item2[valueKey];
                                let label = handleI18nLabel(item2[labelKey]) || value;
                                if (typeof label !== 'string') {
                                    label = value;
                                }
                                if (value) {
                                    newList.push({
                                        label,
                                        value: `${groupExprName}.${value}`,
                                    });
                                }
                                if (item2?.componentName === 'Compose' &&
                                    isArrayNotEmpty(item2?.options?.config)) {
                                    item2.options.config.forEach((child) => {
                                        const childValue = handleI18nLabel(child[valueKey]);
                                        const childLabel = handleI18nLabel(child[labelKey]) || childValue;
                                        if (childValue) {
                                            newList.push({
                                                label: `${label} - ${childLabel}`,
                                                value: `${groupExprName}.${value}.${childValue}`,
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        if (newList?.length > 0) {
                            if (groupName) {
                                result.push({
                                    label: groupName,
                                    children: newList,
                                });
                            }
                            else {
                                result = [...newList];
                            }
                        }
                    }
                    map[groupExprName] = {
                        ...item,
                    };
                }
            });
        }
        return {
            list: result,
            map,
        };
    };
    getValueEditor = (type, v, value) => {
        const { size = 'small' } = this.props;
        const props = {
            size,
            className: 'ctes-input',
            value: v,
            onChange: (_v) => {
                this.changeValue(value, {
                    value: _v,
                });
            },
        };
        switch (type) {
            case 'number':
                return (<NumberPicker placeholder={$i18n.get({ id: 'Empty', dm: '空' })} {...props}/>);
            case 'boolean':
                return (<Select placeholder={$i18n.get({ id: 'Empty', dm: '空' })} hasClear dataSource={[
                        { label: 'true', value: true },
                        { label: 'false', value: false },
                    ]} {...props}/>);
            default:
                return (<CnTooltip trigger={<Input placeholder={$i18n.get({ id: 'Empty', dm: '空' })} {...props}/>}>
            {$i18n.get({
                        id: 'PleaseFillInTheValueOfTheField',
                        dm: '请填写字段的值（非字段名）',
                    })}
          </CnTooltip>);
        }
    };
    changeValue = (value, newValue) => {
        const { onChange } = this.props;
        const newExprObj = { ...value, ...newValue };
        onChange && onChange(newExprObj);
    };
    findComponentNameByDs = (ds) => {
        const { list } = this.state;
        if (Array.isArray(list)) {
            for (const item of list) {
                if (Array.isArray(item?.children)) {
                    for (const item2 of item.children) {
                        if (item2?.value === ds) {
                            return item2?.componentName;
                        }
                    }
                }
            }
        }
    };
    getPropKeyInput = (config) => {
        const { secondParam, value, size, currentComponentName } = config;
        if (currentComponentName === 'CnTable') {
            return (<Select placeholder={$i18n.get({
                    id: '31255240250626048.CNTM',
                    dm: '属性名',
                })} value={secondParam || ''} onChange={(v) => {
                    this.changeValue(value, {
                        secondParam: v,
                        thirdParam: undefined,
                    });
                }} size={size} autoWidth={false} className={'ctes-input'} dataSource={[
                    {
                        label: $i18n.get({
                            id: 'SelectedDataPrimaryKeyListSelect_1292279660',
                            dm: '选中数据主键列表(selectedRowKeys)',
                        }),
                        value: __selectedRowKeys__,
                    },
                    {
                        label: tableCurrentRowLabel,
                        value: __tableCurrentRow__,
                    },
                    {
                        label: $i18n.get({
                            id: 'TotalNumberOfRequestsReturnedPag_996270687',
                            dm: '请求返回的总条数(paging.totalCount)',
                        }),
                        value: __totalCount__,
                    },
                    {
                        label: $i18n.get({
                            id: 'DataReturnedByATableRequestTable_1597282263',
                            dm: '表格请求返回的数据(tableData)',
                        }),
                        value: __tableData__,
                    },
                    {
                        label: $i18n.get({
                            id: 'TableColumnHeadersColumns',
                            dm: '表格的列头(columns)',
                        }),
                        value: __tableColumns__,
                    },
                    {
                        label: $i18n.get({
                            id: 'PagingInformationReturnedByATabl_1758497143',
                            dm: '表格请求返回的分页信息(paging)',
                        }),
                        value: __paging__,
                    },
                    {
                        label: $i18n.get({
                            id: 'AdditionalInformationReturnedByT_1923775268',
                            dm: '请求返回的额外信息(extra)',
                        }),
                        value: __tableExtra__,
                    },
                ]}/>);
        }
        else if (currentComponentName === 'CnStep') {
            return (<Select placeholder={$i18n.get({
                    id: '31255240250626048.CNTM',
                    dm: '属性名',
                })} value={secondParam || ''} onChange={(v) => {
                    this.changeValue(value, {
                        secondParam: v,
                        thirdParam: undefined,
                    });
                }} size={size} autoWidth={false} className={'ctes-input'} dataSource={[
                    {
                        label: $i18n.get({
                            id: 'CurrentStepCurrent',
                            dm: '当前步骤(current)',
                        }),
                        value: __step_current__,
                    },
                ]}/>);
        }
        else if (currentComponentName === 'CnLeftTree') {
            return (<Select placeholder={$i18n.get({
                    id: '31255240250626048.CNTM',
                    dm: '属性名',
                })} value={secondParam || ''} onChange={(v) => {
                    this.changeValue(value, {
                        secondParam: v,
                        thirdParam: undefined,
                    });
                }} size={size} autoWidth={false} className={'ctes-input'} dataSource={[
                    {
                        label: $i18n.get({
                            id: 'CodeOfTheCurrentlyClickedNode',
                            dm: '当前点击的节点编码',
                        }),
                        value: __left_tree_currentValue__,
                    },
                    {
                        label: $i18n.get({
                            id: 'AllInformationOfTheCurrentlySele_744160904',
                            dm: '当前选中的节点全部信息',
                        }),
                        value: __left_tree_currentItem__,
                    },
                ]}/>);
        }
        else if (currentComponentName === 'CnTab') {
            return (<Select placeholder={$i18n.get({
                    id: '31255240250626048.CNTM',
                    dm: '属性名',
                })} value={secondParam || ''} onChange={(v) => {
                    this.changeValue(value, {
                        secondParam: v,
                        thirdParam: undefined,
                    });
                }} size={size} autoWidth={false} className={'ctes-input'} dataSource={[
                    {
                        label: $i18n.get({ id: 'TabCheckedItems', dm: 'Tab的选中项' }),
                        value: __tab_activeKey__,
                    },
                ]}/>);
        }
        return (<Input placeholder={$i18n.get({ id: '31255240250626048.CNTM', dm: '属性名' })} value={secondParam || ''} onChange={(v) => {
                this.changeValue(value, {
                    secondParam: v,
                });
            }} size={size} className={'ctes-input'}/>);
    };
    render() {
        const { list, map } = this.state;
        let { value } = this.props;
        const { size = 'small', placeholder } = this.props;
        if (!isPlainObject(value)) {
            value = {};
        }
        const { group, param, symbol, type, value: val, secondParam, thirdParam, } = value;
        const symbolDom = (<Select value={symbol || ''} hasClear size={size} autoWidth={false} className={'ctes-input ctes-symbol-input'} dataSource={symbolList2} onChange={(v) => {
                this.changeValue(value, {
                    symbol: v,
                });
            }}/>);
        const { renderTypeDom, renderValueDom } = map?.[group] || {};
        const showSecondParam = map?.[group]?.needSecondParam;
        const currentDs = group && param ? `${group}.${param}` : '';
        const currentComponentName = this.findComponentNameByDs(currentDs);
        const needThirdParam = (currentComponentName === 'CnTable' &&
            (secondParam === __tableCurrentRow__ ||
                secondParam === __tableExtra__)) ||
            (currentComponentName === 'CnLeftTree' &&
                secondParam === __left_tree_currentItem__);
        return (<div className="cn-text-expr-setter-wrap">
        <div className="ctes-first-line">
          <Select showSearch popupClassName={'cn-param-select-setter-popup'} placeholder={placeholder || $i18n.get({ id: 'PleaseSelect', dm: '请选择' })} hasClear value={currentDs} autoWidth={false} size={size} className={'ctes-input'} dataSource={list} onChange={(v) => {
                const newV = {
                    group: undefined,
                    param: undefined,
                    secondParam: undefined,
                    thirdParam: undefined,
                };
                if (v && v?.indexOf('.') !== -1) {
                    const tempGroup = v.slice(0, v.indexOf('.')) || '';
                    const tempParam = v.replace(`${tempGroup}.`, '');
                    if (tempGroup && tempParam) {
                        newV.group = tempGroup;
                        newV.param = tempParam;
                    }
                }
                this.changeValue(value, newV);
            }}/>

          {showSecondParam &&
                this.getPropKeyInput({
                    value,
                    secondParam,
                    size,
                    currentComponentName,
                })}
          {needThirdParam && (<Input placeholder={$i18n.get({
                    id: '31255240250626048.CNTM',
                    dm: '属性名',
                })} value={thirdParam || ''} size={size} className={'ctes-input'} onChange={(v) => {
                    this.changeValue(value, {
                        thirdParam: v,
                    });
                }}/>)}

          {!showSecondParam && (<Select placeholder={$i18n.get({ id: 'Meet', dm: '满足' })} hasClear value={symbol || ''} size={size} autoWidth={false} className={'ctes-input ctes-symbol-input'} dataSource={symbolList2} onChange={(v) => {
                    this.changeValue(value, {
                        symbol: v,
                    });
                }}/>)}
        </div>
        <div className="ctes-second-line">
          {showSecondParam && symbolDom}
          {typeof renderTypeDom === 'function' ? (renderTypeDom({ value })) : (<Select hasClear value={type} size={size} className={'ctes-input'} dataSource={typeList} onChange={(v) => {
                    this.changeValue(value, {
                        type: v,
                        value: undefined,
                    });
                }}/>)}

          {typeof renderValueDom === 'function'
                ? renderValueDom({
                    value,
                    onChange: this.changeValue,
                })
                : this.getValueEditor(type, val, value)}
        </div>
      </div>);
    }
}
