var _a;
import { __assign } from "tslib";
import $i18n from "../locales/i18n";
import React from 'react';
var CnIcon = (_a = window.CNUI) === null || _a === void 0 ? void 0 : _a.CnIcon;
export var ADAPTER_LOCALE_SETTER = {
    name: 'adapterLocale',
    title: {
        label: (React.createElement("div", null,
            $i18n.get({ id: 'InternationalAdaptation', dm: '国际化适配' }),
            React.createElement("a", { href: "https://alidocs.dingtalk.com/i/nodes/m9bN7RYPWdyrPBREcmw3drPzVZd1wyK0", rel: "noreferrer", style: { marginLeft: 5, position: 'relative', top: 3 }, target: "_blank" }, CnIcon ? React.createElement(CnIcon, { size: "medium", type: "help" }) : '❓'))),
    },
    display: 'inline',
    initialValue: undefined,
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: $i18n.get({ id: 'Open', dm: '开启' }), value: true },
                { title: $i18n.get({ id: 'Close', dm: '关闭' }), value: false },
                {
                    title: $i18n.get({ id: 'DoNotSet', dm: '不设置' }),
                    value: undefined,
                },
            ],
        },
    },
};
export var ADAPTER_LOCALE_TABLE_SETTER = __assign(__assign({}, ADAPTER_LOCALE_SETTER), { condition: function (prop) {
        var _a, _b, _c;
        var format = (_c = (_b = (_a = prop === null || prop === void 0 ? void 0 : prop.parent) === null || _a === void 0 ? void 0 : _a.getValue) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.format;
        return [
            'date-time',
            'date',
            'number',
            'currency',
            'currency-with-unit',
            'percent',
            'progress',
        ].includes(format);
    } });
