import $i18n from '@/locales/i18n';
const dsList = [
    [12],
    [6, 6],
    [4, 4, 4],
    [3, 3, 3, 3],
    [2, 2, 2, 2, 2, 2],
    [3, 9],
    [9, 3],
    [3, 6, 3],
    [4, 8],
    [8, 4],
];
export function makeColumnDom(config) {
    const { value, onClick } = config || {};
    return dsList.map((item) => {
        const tempValue = item.length > 1 ? item.join(':') : `${item[0]}`;
        const active = tempValue === value;
        return (<div onClick={() => {
                onClick?.(tempValue);
            }} className={`cn-layout-setter-item-block ${active ? 'active' : ''}`}>
        {tempValue === '6' ? (<>
            <div style={{ flex: 1 }} className="cn-layout-setter-item-block-child"/>
            <div style={{ flex: 1, opacity: 0 }} className="cn-layout-setter-item-block-child"/>
          </>) : (item.map((item2) => {
                return <div style={{ flex: item2 }} className="cn-layout-setter-item-block-child"/>;
            }))}
      </div>);
    });
}
/**
 * 插入布局容器
 */
function insertLayoutSection(config) {
    const { _leaf, spanStr } = config || {};
    if (spanStr && typeof spanStr === 'string') {
        const list = spanStr.split(':');
        let shape = 'simple';
        let noPadding = true;
        let noShadow = true;
        if (_leaf?.propsData?.shape === 'group') {
            shape = 'normal';
            noPadding = false;
            noShadow = false;
        }
        if (Array.isArray(list) && list.length > 0) {
            const cardList = list.map((item) => {
                return {
                    componentName: 'CnLayoutBlock',
                    title: $i18n.get({ id: 'Block', dm: '块' }),
                    props: {
                        span: Number(item),
                        isCnLayoutBlock: true,
                        _context: {
                            type: 'JSExpression',
                            value: 'this',
                        },
                        title: $i18n.get({ id: 'Block', dm: '块' }),
                        isFold: false,
                        foldable: false,
                    },
                    children: [
                        {
                            componentName: 'CnCard',
                            title: $i18n.get({ id: 'Card', dm: '卡片' }),
                            props: {
                                isCnCard: true,
                                _context: {
                                    type: 'JSExpression',
                                    value: 'this',
                                },
                                shape,
                                title: $i18n.get({ id: 'Title', dm: '标题' }),
                                noPadding,
                                noShadow,
                            },
                        },
                    ],
                };
            });
            const sectionSchema = {
                componentName: 'CnLayoutSection',
                props: {
                    isCnLayoutSection: true,
                    _context: {
                        type: 'JSExpression',
                        value: 'this',
                    },
                    normalStyle: {
                        noPadding: true,
                        title: '',
                    },
                },
                children: cardList,
            };
            const sectionDom = _leaf?.document?.createNode(sectionSchema);
            _leaf.insertBefore(sectionDom);
        }
    }
}
export function AddLayout(props) {
    return (<div className="cn-layout-setter-item-container">
      <div className="cn-layout-setter-item-title">
        {$i18n.get({ id: 'ColumnInCard', dm: '卡片内分栏' })}
      </div>

      <div className={'cn-layout-setter-item-content'}>
        {makeColumnDom({
            value: '',
            onClick: (newV) => {
                insertLayoutSection({
                    _leaf: props?._leaf,
                    spanStr: newV,
                });
            },
        })}
      </div>
    </div>);
}
