import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { condition, setAdvancedConfigToProps } from '../../util/util';
import { CnDatePickerPro, CnMonthPicker2, CnWeekPicker2, CnYearPicker2, componentMap as formComponentMap, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import { makeRequest } from '@/common/util/request';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import { getDateTypeSetterSnippet } from '@/common/manager/setter-snippet';
import { getBizExtendPrototype, handleBizExtendComponentProps, } from '@/common/manager/plugin';
import { FORM_ITEM_INFO, ADAPTER_LOCALE_SETTER } from '@cainiaofe/cn-ui-lowcode-prototype-common';
export function requestDate(dateRequest, getRequestParams) {
    const _params = getRequestParams?.();
    if (isPlainObject(dateRequest)) {
        return makeRequest({
            buttonConfig: {
                options: {
                    requestConfig: dateRequest,
                },
            },
            ..._params,
            needSuccessToast: false,
        }).then((res) => {
            const { success, data } = res || {};
            if (success && isPlainObject(data)) {
                const { disabledDate, enabledDate } = data;
                if (Array.isArray(enabledDate)) {
                    const list = [];
                    enabledDate.forEach((item) => {
                        const day = dayjs(item);
                        if (day) {
                            list.push(day.startOf('d').valueOf());
                        }
                    });
                    return {
                        key: 'enabledDate',
                        list,
                    };
                }
                else if (Array.isArray(disabledDate) && disabledDate.length > 0) {
                    const list = [];
                    disabledDate.forEach((item) => {
                        const day = dayjs(item);
                        if (day) {
                            list.push(day.startOf('d').valueOf());
                        }
                    });
                    return {
                        key: 'disabledDate',
                        list,
                    };
                }
            }
        });
    }
}
const presetMap = {
    nowTime: {
        key: $i18n.get({ id: 'AtTheMoment', dm: '此刻' }),
        value: () => dayjs(),
    },
    nowTimeRanger: {
        key: $i18n.get({ id: 'AtTheMoment', dm: '此刻' }),
        value: [dayjs(), dayjs()],
    },
    yesterdayRanger: {
        key: $i18n.get({ id: 'Yesterday', dm: '昨日' }),
        value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
    },
    nowWeekRanger: {
        key: $i18n.get({ id: 'ThisWeek', dm: '本周' }),
        value: [dayjs().startOf('week'), dayjs().endOf('week')],
    },
    nowMonthRanger: {
        key: $i18n.get({ id: 'ThisMonth', dm: '本月' }),
        value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
};
export function handlePreset(presetConfig, getRequestParams) {
    if (isArray(presetConfig)) {
        return presetConfig.reduce((ret, nextPresetConfig) => {
            const matchPreset = presetMap[nextPresetConfig];
            if (matchPreset) {
                return {
                    ...ret,
                    [matchPreset?.key]: matchPreset?.value,
                };
            }
            return ret;
        }, {});
    }
    else if (isFunction(presetConfig)) {
        const _params = getRequestParams?.();
        let formValues = {};
        let _state = {};
        if (_params) {
            const { recordDataSource, state } = _params;
            if (isPlainObject(recordDataSource)) {
                formValues = recordDataSource;
            }
            if (isPlainObject(state)) {
                _state = state;
            }
        }
        return presetConfig(dayjs, formValues, _state);
    }
}
export function handleDisabledDate(disabled, disabledDateList, enabledDateList, getRequestParams, disabledType) {
    if (disabled) {
        if (typeof disabled === 'string') {
            switch (disabled) {
                case 'beforeToday':
                    return (date, mode) => {
                        const currentDate = dayjs();
                        switch (mode) {
                            case 'date':
                                return date.valueOf() < currentDate.startOf('d').valueOf();
                            case 'year':
                                return date.year() < currentDate.year();
                            case 'month':
                                return (date.year() * 100 + date.month() <
                                    currentDate.year() * 100 + currentDate.month());
                        }
                    };
                case 'afterToday':
                    return (date, mode) => {
                        const currentDate = dayjs();
                        switch (mode) {
                            case 'date':
                                return date.valueOf() > currentDate.valueOf();
                            case 'year':
                                return date.year() > currentDate.year();
                            case 'month':
                                return (date.year() * 100 + date.month() >
                                    currentDate.year() * 100 + currentDate.month());
                        }
                    };
            }
        }
        else if (isPlainObject(disabled)) {
            if (disabledType === 'enabledDate' && Array.isArray(enabledDateList)) {
                return (date, mode) => {
                    switch (mode) {
                        case 'date':
                            return !enabledDateList.includes(date.startOf('d').valueOf());
                    }
                };
            }
            else if (disabledType === 'disabledDate' &&
                disabledDateList?.length > 0) {
                return (date, mode) => {
                    switch (mode) {
                        case 'date':
                            return disabledDateList.includes(date.startOf('d').valueOf());
                    }
                };
            }
        }
        else if (typeof disabled === 'function') {
            const _params = getRequestParams?.();
            let formValues = {};
            let _state = {};
            if (_params) {
                const { recordDataSource, state } = _params;
                if (isPlainObject(recordDataSource)) {
                    formValues = recordDataSource;
                }
                if (isPlainObject(state)) {
                    _state = state;
                }
            }
            return (date, mode) => {
                try {
                    return disabled.call(this, date, mode, formValues, _state);
                }
                catch (e) {
                    console.log(e, '执行disableDate失败');
                }
            };
        }
    }
}
export function createDisabledDateSetter() {
    return {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    title: $i18n.get({ id: 'SelectDisableDate', dm: '选择禁用日期' }),
                    componentName: 'SelectSetter',
                    props: {
                        options: [
                            {
                                label: $i18n.get({
                                    id: 'DisabledBeforeToday',
                                    dm: '禁用今天以前',
                                }),
                                value: 'beforeToday',
                            },
                            {
                                label: $i18n.get({
                                    id: 'DisableAfterToday',
                                    dm: '禁用今天以后',
                                }),
                                value: 'afterToday',
                            },
                        ],
                    },
                },
                {
                    title: $i18n.get({
                        id: 'ConfigurationRequestGetDisableDa_511520875',
                        dm: '配置请求获取禁用日期',
                    }),
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    {
                                        componentName: 'ParamSelectSetter',
                                        props: {
                                            dataKey: 'config',
                                            labelKey: 'label',
                                            valueKey: 'name',
                                            groupName: $i18n.get({
                                                id: 'CurrentForm',
                                                dm: '当前表单',
                                            }),
                                        },
                                        title: $i18n.get({
                                            id: 'SelectParameters',
                                            dm: '选择参数',
                                        }),
                                    },
                                    {
                                        componentName: 'StringSetter',
                                        title: $i18n.get({ id: 'String', dm: '字符串' }),
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                    },
                },
                getJSExpressionPrototype({ type: 'datepicker' }),
            ],
        },
    };
}
export function DatePickerComposite(props) {
    const { type, disabledDate, getRequestParams, ...rest } = props;
    const extraProps = {};
    const [disabledType, setDisabledType] = useState();
    const [disabledDateList, setDisabledDateList] = useState();
    const [enabledDateList, setEnabledDateList] = useState();
    useEffect(() => {
        requestDate(disabledDate, getRequestParams)?.then((res) => {
            if (res) {
                const { key, list } = res;
                if (key) {
                    setDisabledType(key);
                }
                if (key === 'disabledDate') {
                    setDisabledDateList(list);
                }
                else if (key === 'enabledDate') {
                    setEnabledDateList(list);
                }
            }
        });
    }, []);
    extraProps.disabledDate = handleDisabledDate(disabledDate, disabledDateList, enabledDateList, getRequestParams, disabledType);
    switch (type) {
        case 'week':
            return <CnWeekPicker2 {...rest} {...extraProps}/>;
        case 'month':
            return <CnMonthPicker2 {...rest} {...extraProps}/>;
        case 'year':
            return <CnYearPicker2 {...rest} {...extraProps}/>;
        default:
            return <CnDatePickerPro {...rest} {...extraProps}/>;
    }
}
const { DatePicker: { title, thumbUrl } } = FORM_ITEM_INFO;
const DatePicker = {
    position: [
        DisplayPosition.form,
        DisplayPosition.filter,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'DatePicker',
    component: DatePickerComposite,
    formItemBeforeHandler: (formItem, config) => {
        const { urlParams, formValue, state } = config || {};
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                componentProps.getRequestParams = () => {
                    return {
                        urlParamsDataSource: urlParams,
                        recordDataSource: formValue,
                        state,
                    };
                };
            }
            handleBizExtendComponentProps(componentProps, 'CnDatePickerPro');
            setAdvancedConfigToProps(componentProps);
        }
    },
    filterItemBeforeHandler: (filterItemProps, config) => {
        const { componentProps, urlParamsDataSource, recordDataSource, state } = config || {};
        if (componentProps) {
            componentProps.getRequestParams = () => {
                return {
                    urlParamsDataSource,
                    recordDataSource,
                    state,
                };
            };
        }
    },
    formComponent: (props) => {
        const { type, disabledDate, presetConfig, getRequestParams, ...rest } = props;
        const { showTime } = rest || {};
        const extraProps = {};
        let Com;
        if (showTime) {
            // if(disabledDate) {
            //   extraProps.timePanelProps = {
            //     disabledDate:handleDisabledDate(disabledDate),
            //     ...handleDisabledTime(disabledDate),
            //   }
            // }
            // if(format?.includes(' HH')){
            //   extraProps.timePanelProps = {
            //     format: format.slice(format.indexOf(' HH') + 1)
            //   }
            // }
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [disabledDateList, setDisabledDateList] = useState([]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [enabledDateList, setEnabledDateList] = useState([]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [disabledType, setDisabledType] = useState();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            requestDate(disabledDate, getRequestParams)?.then((res) => {
                if (res) {
                    const { key, list } = res;
                    if (key) {
                        setDisabledType(key);
                    }
                    if (key === 'disabledDate') {
                        setDisabledDateList(list);
                    }
                    else if (key === 'enabledDate') {
                        setEnabledDateList(list);
                    }
                }
            });
        }, []);
        extraProps.disabledDate = handleDisabledDate(disabledDate, disabledDateList, enabledDateList, getRequestParams, disabledType);
        if (presetConfig?.length > 0 || isFunction(presetConfig)) {
            extraProps.preset = handlePreset(presetConfig, getRequestParams);
        }
        switch (type) {
            case 'week':
                Com = formComponentMap.CnWeekPickerPro;
                break;
            case 'month':
                Com = formComponentMap.CnMonthPickerPro;
                break;
            case 'year':
                Com = formComponentMap.CnYearPickerPro;
                break;
            default:
                Com = formComponentMap.CnDatePickerPro;
        }
        return <Com {...rest} {...extraProps}/>;
    },
    getDefaultProps: () => {
        return {
            type: 'date',
            outputFormat: '',
        };
    },
    getPrototypeList: () => {
        return [
            ADAPTER_LOCALE_SETTER,
            getDateTypeSetterSnippet(),
            {
                name: 'showTime',
                title: $i18n.get({ id: 'ShowTime', dm: '显示时间' }),
                display: 'inline',
                setter: 'BoolSetter',
                condition(prop) {
                    return (condition(prop, 'DatePicker', 'componentName') &&
                        prop?.parent?.getPropValue?.('type') === 'date');
                },
            },
            {
                name: 'outputFormat',
                title: $i18n.get({ id: 'OutputFormat', dm: '输出格式' }),
                display: 'inline',
                condition(prop) {
                    return condition(prop, 'DatePicker', 'componentName');
                },
                setter: {
                    componentName: 'SelectSetter',
                    props: {
                        options: [
                            {
                                label: $i18n.get({ id: 'Timestamp', dm: '时间戳' }),
                                value: '',
                            },
                            {
                                label: 'YYYY',
                                value: 'YYYY',
                            },
                            {
                                label: 'YYYY-MM',
                                value: 'YYYY-MM',
                            },
                            {
                                label: 'YYYY-MM-DD',
                                value: 'YYYY-MM-DD',
                            },
                            {
                                label: 'YYYY-MM-DD HH',
                                value: 'YYYY-MM-DD HH',
                            },
                            {
                                label: 'YYYY-MM-DD HH:mm',
                                value: 'YYYY-MM-DD HH:mm',
                            },
                            {
                                label: 'YYYY-MM-DD HH:mm:ss',
                                value: 'YYYY-MM-DD HH:mm:ss',
                            },
                        ],
                    },
                },
            },
            {
                name: 'format',
                title: $i18n.get({ id: 'FrontEndDisplayFormat', dm: '前端显示格式' }),
                display: 'inline',
                condition(prop) {
                    return condition(prop, 'DatePicker', 'componentName');
                },
                setter: {
                    componentName: 'SelectSetter',
                    props: {
                        options: [
                            {
                                label: 'YYYY',
                                value: 'YYYY',
                            },
                            {
                                label: 'YYYY-MM',
                                value: 'YYYY-MM',
                            },
                            {
                                label: 'YYYY-MM-DD',
                                value: 'YYYY-MM-DD',
                            },
                            {
                                label: 'YYYY-MM-DD HH',
                                value: 'YYYY-MM-DD HH',
                            },
                            {
                                label: 'YYYY-MM-DD HH:mm',
                                value: 'YYYY-MM-DD HH:mm',
                            },
                            {
                                label: 'YYYY-MM-DD HH:mm:ss',
                                value: 'YYYY-MM-DD HH:mm:ss',
                            },
                        ],
                    },
                },
            },
            {
                name: 'disabledDate',
                title: $i18n.get({ id: 'DateDisabled', dm: '禁用日期' }),
                display: 'inline',
                condition(prop) {
                    return condition(prop, 'DatePicker', 'componentName');
                },
                setter: createDisabledDateSetter(),
            },
            {
                name: 'presetConfig',
                title: $i18n.get({ id: 'QuickOperation', dm: '快捷操作' }),
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                title: $i18n.get({ id: 'SelectShortcut', dm: '选择快捷' }),
                                componentName: 'CnSelectSetter',
                                props: {
                                    mode: 'multiple',
                                    options: [
                                        {
                                            label: $i18n.get({ id: 'AtTheMoment', dm: '此刻' }),
                                            value: 'nowTime',
                                        },
                                    ],
                                },
                            },
                            getJSExpressionPrototype({ type: 'datepickerPreset' }),
                        ],
                    },
                },
            },
            ...getBizExtendPrototype({
                componentName: 'CnDatePickerPro',
            }),
        ];
    },
};
export default DatePicker;
