import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, } from 'react';
import { CnListPro as UICnListPro, CnListProItem as UICnListProItem, } from '@cainiaofe/cn-ui';
import { isPC } from '@cainiaofe/cn-ui-lowcode-view-common';
import { isDesignMode, handleNewFormatChildren, mergeHandleProps2, executeEventWithoutJS, setDataToDs, getFilterValue, } from '@/common/util/util';
import { makeRequest, } from '@/common/util/request';
import { ButtonPosition } from '@/common/manager/position/button-position';
import useFilterSearchListener from '@/common/hook/useFilterSearchListener';
import { __list_pro_selected_row_keys__, __list_pro_selected_rows__, __list_pro_click_current_item__, } from '@/common/util/expr-const';
import omit from 'lodash/omit';
const CnListPro = forwardRef((props, ref) => {
    const { dataFrom, _context, slotManager, _bindFilter, rowSelection, pagination, grid, gridConfig, events, _dataSourceName, shape, itemKey, _leaf, children, } = props || {};
    const { titleSlot, descriptionSlot, avatarSlot, actionsSlot, extraSlot, bottomAreaSlot } = slotManager || {};
    const state = _context?.state;
    // state缓存
    const listProStateCache = useRef({});
    // 低码编辑态标识
    const isDesign = isDesignMode(props);
    // 远程请求标识
    const isFromRequest = dataFrom?.dataOrigin === 'request';
    // 静态数据标识
    const isFormStatic = dataFrom?.dataOrigin === 'static';
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState(rowSelection?.defaultSelectedRowKeys || []);
    const defaultSelectedRowKeysInit = useRef(false);
    const handleClickStore = useRef([]);
    // 记录当前页
    const [pageInfo, setPageInfo] = useState({
        currentPage: 0,
        pageSize: pagination?.pageSize || 10,
        totalCount: null,
    });
    useFilterSearchListener({
        _bindFilter,
        sendRequest: () => {
            if (isFromRequest) {
                fetchList(1, pageInfo.pageSize);
            }
        },
        _context,
    });
    // 设置默认选择，更新state中默认选中key和行的数据，并且触发默认选中后的事件
    const setDefaultSelectedRowKeys = (listData, defaultSelectedRowKeys) => {
        if (Array.isArray(defaultSelectedRowKeys)) {
            setSelectedRowKeys(defaultSelectedRowKeys);
            const dataSourceMap = {};
            listData.forEach((item) => {
                dataSourceMap[item[itemKey]] = item;
            });
            const rowData = [];
            defaultSelectedRowKeys.forEach((item) => {
                if (dataSourceMap[item]) {
                    rowData.push(dataSourceMap[item]);
                }
            });
            handleUpdateDs({
                [__list_pro_selected_row_keys__]: defaultSelectedRowKeys,
                [__list_pro_selected_rows__]: rowData,
            });
            executeEventWithoutJS({
                eventType: 'onListProDefaultSelectFinished',
                events,
                _context,
                position: ButtonPosition.listProItemEvent,
                recordDataSource: rowData,
            });
        }
    };
    // 默认选中和dataSource发生变化时，更新选中的keys和行数据
    useEffect(() => {
        if (!defaultSelectedRowKeysInit.current &&
            rowSelection?.defaultSelectedRowKeys &&
            dataSource) {
            setDefaultSelectedRowKeys(dataSource, rowSelection?.defaultSelectedRowKeys);
        }
    }, [rowSelection?.defaultSelectedRowKeys, dataSource]);
    // 行选中变化时，更新选中的keys和行数据
    const doChangeSelectedRowKeys = (record, _selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(_selectedRowKeys);
        handleUpdateDs({
            [__list_pro_selected_row_keys__]: _selectedRowKeys,
            [__list_pro_selected_rows__]: selectedRows,
        });
        handleClickStore.current?.[0]?.();
        handleClickStore.current = [];
    };
    useImperativeHandle(ref, () => {
        return {
            load() {
                fetchList(pageInfo.currentPage || 1, pageInfo.pageSize);
            },
            getDataSource() {
                return dataSource;
            },
            getPageInfo() {
                return pageInfo;
            },
            setPageInfo,
            setDataSource,
            doChangeSelectedRowKeys,
            fetchList,
        };
    });
    // 远程请求，更新dataSource
    const fetchList = (currentPage = 1, pageSize = 10) => {
        if (!isFromRequest) {
            return;
        }
        setLoading(true);
        return new Promise((resolve, reject) => {
            makeRequest?.({
                // 请求成功后是否toast提示
                needSuccessToast: false,
                buttonConfig: {
                    options: {
                        requestConfig: dataFrom.requestConfig,
                    },
                },
                recordDataSource: {},
                state: _context?.state || {},
                extraParam: {
                    currentPage,
                    pageSize,
                },
                extraParamList: [
                    {
                        currentPage,
                        pageSize,
                    },
                ],
                // 设置额外请求参数的回调
                handleParams: () => {
                    const filterValue = getFilterValue({
                        filterConfig: {
                            _bindFilter,
                        },
                        _context,
                    }) || {};
                    return {
                        // 当前分页信息作为请求参数
                        ...filterValue,
                        currentPage,
                        pageSize,
                    };
                },
            })
                .then((res) => {
                const { paging, tableData, activeKey, tableColumns } = res?.data || {};
                if (pagination && paging && tableData) {
                    // 请求结果暂存到dataSource中
                    setPageInfo((pre) => ({ ...pre, ...paging }));
                    isPC ? setDataSource(tableData) : setDataSource([...dataSource, ...tableData]);
                }
                else if (Array.isArray(tableData)) {
                    setDataSource(tableData);
                }
                if (tableColumns && isDesign) {
                    _leaf.setPropValue('requestColumns', tableColumns);
                }
                if (Array.isArray(activeKey) && !defaultSelectedRowKeysInit.current) {
                    setDefaultSelectedRowKeys(tableData, activeKey);
                }
                resolve(res);
            })
                .catch((err) => {
                reject(err);
            })
                .finally(() => {
                setLoading(false);
            });
        });
    };
    // 数据类型是远程数据时，请求数据
    useEffect(() => {
        fetchList(1, pagination?.pageSize);
    }, []);
    useEffect(() => {
        if (isFormStatic) {
            if (Array.isArray(dataFrom?.dataSource)) {
                setDataSource(dataFrom?.dataSource);
            }
        }
    }, [dataFrom?.dataOrigin, dataFrom?.dataSource]);
    // 渲染slot
    const renderSlot = (slot, item, index) => {
        return handleNewFormatChildren(slot, {
            item,
            index,
            state,
            _context,
        });
    };
    const realComponentProps = {};
    if (rowSelection?.rowSelection) {
        realComponentProps.rowSelection = {
            selectedRowKeys,
            type: rowSelection.type,
            onChange: doChangeSelectedRowKeys,
        };
    }
    // 解决CnListPro在使用静态数据时，但选择的是绑定变量时，无法正确渲染的问题
    if (dataFrom?.dataOrigin === 'static' &&
        dataFrom?._unsafe_MixedSetter_dataSource_select !==
            'VariableSetter') {
        realComponentProps.children = React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                slotManager,
            });
        });
    }
    else {
        realComponentProps.dataSource = dataSource;
        realComponentProps.renderItem = (item, index) => {
            return (<UICnListProItem key={item[itemKey]} itemKey={item[itemKey]} rowData={item} title={renderSlot(titleSlot, item, index)} description={renderSlot(descriptionSlot, item, index)} avatar={renderSlot(avatarSlot, item, index)} actions={renderSlot(actionsSlot, item, index)} extra={renderSlot(extraSlot, item, index)} bottomArea={renderSlot(bottomAreaSlot, item, index)} onClickCapture={handleClickCapture}/>);
        };
    }
    const mergeProps = mergeHandleProps2({
        handleProps: props?.handleProps,
        componentProps: realComponentProps,
        _context: props?._context,
    });
    let paginationProps;
    if (isFromRequest && pagination?.pagination) {
        paginationProps = {
            currentPage: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            totalCount: pageInfo.totalCount,
            onChange: (_currentPage) => {
                setPageInfo((pre) => ({ ...pre, currentPage: _currentPage }));
                // pageInfo.current.currentPage = page;
                // pageInfo.current.pageSize = pageSize;
                fetchList(_currentPage, pageInfo.pageSize);
            },
            onPageSizeChange: (_pageSize) => {
                setPageInfo((pre) => ({ ...pre, pageSize: _pageSize }));
                fetchList(1, _pageSize);
            },
        };
    }
    // 更新全局state上的数据
    const handleUpdateDs = (data) => {
        listProStateCache.current = { ...listProStateCache.current, ...data };
        if (data) {
            setDataToDs({
                _context,
                _dataSource: listProStateCache.current,
                _dataSourceName,
                data,
            });
        }
    };
    const handleClickCapture = (e, rowData) => {
        if (isDesign) {
            return;
        }
        handleUpdateDs({
            [__list_pro_click_current_item__]: rowData,
        });
        const handleClick = () => {
            executeEventWithoutJS({
                e,
                eventType: 'onListProItemClick',
                events,
                _context,
                position: ButtonPosition.listProItemEvent,
                recordDataSource: rowData,
            });
        };
        if (shape === 'card' && rowSelection) {
            // 在捕获阶段将handleClick放入handleClickStore中，确保点击事件时能够正确拿到当前行的数据
            handleClickStore.current.push(handleClick);
        }
        else {
            handleClick();
        }
    };
    // 栅格布局
    let gridProps = omit(gridConfig, ['imagePosition', 'gridType']);
    // 图片位置
    const gridImagePosition = gridConfig?.imagePosition;
    // 布局类型
    const gridType = gridConfig?.gridType;
    if (gridType === 'responsive') {
        gridProps = {
            wrap: true,
        };
    }
    return (<UICnListPro shape={shape} pagination={paginationProps} grid={grid ? gridProps : false} imagePosition={gridImagePosition} loading={loading} itemKey={itemKey} {...mergeProps}/>);
});
CnListPro.displayName = 'CnListPro';
export default CnListPro;
export { CnListPro };
