export var DataOriginType;
(function (DataOriginType) {
    /** 静态数据源 */
    DataOriginType["Static"] = "static";
    /** 远程请求数据源 */
    DataOriginType["Request"] = "request";
    /** DataEasy数据源 */
    DataOriginType["DataEasy"] = "dataeasy";
    /** 鸟搭数据源 */
    DataOriginType["SmartModel"] = "smartModel";
})(DataOriginType || (DataOriginType = {}));
