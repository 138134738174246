import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
// eslint-disable-next-line import/no-cycle
import { createFormItemSetters } from '../../../lowcode/cn-form-alc/prototype/form-item';
import { executeFunction, makeButtons, makeFormItemSchema, } from '../../util/util';
import { createButtonListSetters } from '../button';
import { ButtonPosition } from '../position/button-position';
import { getColsSetterSnippet } from '@/common/manager/setter-snippet';
import { __arrayCardCurrentRowByWhole__, __arrayCardCurrentRowIndexByWhole__, __arrayTableCurrentRow__, } from '@/common/util/expr-const';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import { formilyReact } from '@cainiaofe/cn-ui';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import { handleRequestParams } from '@/common/util/request';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { useField } = formilyReact;
const { CnArraySubAreaCard: { title, thumbUrl } } = FORM_ITEM_INFO;
const add = 'add';
const remove = 'remove';
const getDefaultButtons = () => {
    return [
        {
            children: $i18n.get({ id: 'AddCard', dm: '添加卡片' }),
            optType: 'arraySubAreaCardAdd',
            primaryKey: add,
        },
        {
            primaryKey: remove,
            children: $i18n.get({ id: 'Delete', dm: '删除' }),
            optType: 'arraySubAreaCardRemove',
        },
    ];
};
const CnArraySubAreaCard = {
    position: [DisplayPosition.form, DisplayPosition.formDialog],
    title,
    componentName: 'CnArraySubAreaCard',
    thumbUrl,
    formItemBeforeHandler: (formItem, config) => {
        const { formInstance, formItemConfig, formProps, isDesign, usedComponentList, urlParams, formValue, state, _context, } = config || {};
        const { _dataSourceName, formStyle = {} } = formProps || {};
        const { options } = formItemConfig || {};
        const { config: arrayTableFormConfig, buttons, style } = options || {};
        const { cols: formCols } = formStyle || {};
        let realCols = formCols;
        const { cols } = style || {};
        if (typeof cols === 'number') {
            realCols = cols;
        }
        if (formItem) {
            const decoratorProps = formItem['x-decorator-props'];
            if (isPlainObject(decoratorProps)) {
                decoratorProps.labelCol = {
                    hidden: true,
                };
            }
            let isReadOnly = false;
            if (formItem?.readOnly === true || formItemConfig?.readOnly === true) {
                isReadOnly = true;
            }
            formItem.type = 'array';
            let componentProps = {};
            // delete formItem['x-decorator']
            if (!formItem['x-component-props']) {
                formItem['x-component-props'] = componentProps;
            }
            else {
                componentProps = formItem['x-component-props'];
            }
            if (componentProps) {
                if (isFunction(options?.cardTitle)) {
                    componentProps.title = (record, index) => {
                        return executeFunction(options?.cardTitle, formValue, state, record, index);
                    };
                }
                componentProps.allowRemove = false;
                formItem.items = {
                    type: 'object',
                    'x-component': 'CnFormGrid',
                    'x-component-props': {
                        cols: realCols,
                    },
                    properties: {},
                };
                if (Array.isArray(arrayTableFormConfig) &&
                    arrayTableFormConfig.length > 0) {
                    arrayTableFormConfig.forEach((item) => {
                        const { name, componentName } = item || {};
                        if (name && componentName) {
                            const cardFormItem = makeFormItemSchema({
                                formItemConfig: item,
                                isDesign,
                                urlParams,
                                formValue,
                                state,
                                usedComponentList,
                                formProps,
                                _context,
                                formInstance,
                                parentPosition: DisplayPosition.cnArraySubAreaCard,
                            });
                            if (cardFormItem) {
                                formItem.items.properties[name] = cardFormItem;
                            }
                        }
                    });
                }
                // 处理按钮
                let realButtons = buttons;
                if (!buttons) {
                    realButtons = getDefaultButtons();
                }
                const optButtons = [];
                let addBtn;
                realButtons?.forEach((item) => {
                    if (item.optType === 'arraySubAreaCardAdd') {
                        addBtn = item;
                    }
                    else if (item.optType === 'arraySubAreaCardRemove') {
                        if (!(isReadOnly === true ||
                            formProps?.formStyle?.readOnly === true)) {
                            const newItem = { ...item };
                            newItem.style = {
                                color: '#ff695c',
                            };
                            optButtons.push(newItem);
                        }
                    }
                    else {
                        optButtons.push(item);
                    }
                });
                if (!isReadOnly && addBtn) {
                    const { children, primaryKey } = addBtn;
                    if (primaryKey) {
                        let addBtnComponentProps;
                        const addBtnDefaultValue = addBtn?.options?.defaultValue;
                        if (Array.isArray(addBtnDefaultValue) &&
                            addBtnDefaultValue.length > 0) {
                            const temp = handleRequestParams(addBtnDefaultValue, {
                                recordDataSource: formValue,
                                state: _context?.state,
                            });
                            if (temp && Object.keys(temp).length > 0) {
                                addBtnComponentProps = {
                                    defaultValue: temp,
                                };
                            }
                        }
                        else if (typeof addBtnDefaultValue === 'function') {
                            addBtnComponentProps = {
                                defaultValue: addBtnDefaultValue.bind(this, {}, _context?.state),
                            };
                        }
                        formItem.properties = {
                            [primaryKey]: {
                                type: 'void',
                                'x-component': 'CnArraySubAreaCard.Addition',
                                title: children,
                            },
                        };
                        if (addBtnComponentProps) {
                            formItem.properties[primaryKey]['x-component-props'] =
                                addBtnComponentProps;
                        }
                    }
                }
                componentProps.titleSlot = (record, index) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const field = useField?.();
                    return makeButtons({
                        buttons: optButtons.map((item) => {
                            let realChildren = item?.children;
                            if (typeof item?.children === 'function') {
                                realChildren = executeFunction(item.children, formValue, state);
                            }
                            return {
                                ...item,
                                text: true,
                                type: 'primary',
                                position: ButtonPosition.arraySubAreaCardOperate,
                                children: realChildren,
                                getExtraParam: () => {
                                    return {
                                        [__arrayTableCurrentRow__]: record,
                                    };
                                },
                            };
                        }),
                        _context,
                        state,
                        urlParamsDataSource: urlParams,
                        recordDataSource: record,
                        formInstance,
                        arrayTableCurrentRow: record,
                        arrayBaseField: field,
                        arrayBaseFieldIndex: index,
                    });
                };
                componentProps.onItemClick = (event, record, index) => {
                    set(state, `valueOf.${_dataSourceName}.${formItemConfig?.name}.${__arrayCardCurrentRowByWhole__}`, record);
                    set(state, `valueOf.${_dataSourceName}.${formItemConfig?.name}.${__arrayCardCurrentRowIndexByWhole__}`, index);
                };
                componentProps.onItemClickCapture = componentProps.onItemClick;
                delete componentProps.buttons;
            }
        }
    },
    getDefaultProps: () => {
        return {
            config: [
                {
                    label: $i18n.get({ id: 'Name', dm: '姓名' }),
                    name: 'name',
                    componentName: 'Input',
                },
            ],
        };
    },
    getPrototypeList: () => {
        const position = DisplayPosition.cnArraySubAreaCard;
        return [
            {
                name: 'cardTitle',
                title: $i18n.get({ id: 'CardTitle', dm: '卡片标题' }),
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({ id: 'String', dm: '字符串' }),
                            },
                            getJSExpressionPrototype({ type: 'subAreaCard' }),
                        ],
                    },
                },
            },
            {
                name: 'config',
                title: $i18n.get({ id: 'FormItemConfiguration', dm: '表单项配置' }),
                setter: createFormItemSetters({ position }),
            },
            {
                name: 'buttons',
                title: $i18n.get({ id: 'OperationColumnButton', dm: '操作列按钮' }),
                defaultValue: getDefaultButtons(),
                // supportVariable: true,
                setter: createButtonListSetters({
                    position: ButtonPosition.arraySubAreaCardOperate,
                }),
            },
            {
                name: 'style',
                title: $i18n.get({ id: 'Style', dm: '样式' }),
                display: 'accordion',
                collapsed: true,
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [getColsSetterSnippet()],
                        },
                    },
                },
            },
        ];
    },
};
export default CnArraySubAreaCard;
