import React from 'react';
import CnListProView from './list-pro-view';
import { CnListProItemTitle } from './components/cn-list-pro-item-title/view';
import { CnListProItemExtra } from './components/cn-list-pro-item-extra/view';
import { CnListProItemBottomArea } from './components/cn-list-pro-item-bottom-area/view';
import { CnListProItemAction } from './components/cn-list-pro-item-action/view';
import { CnListProItemAvatar } from './components/cn-list-pro-item-avatar/view';
import { CnListProItem } from './components/cn-list-pro-item';
import { CnListProItemDescription } from './components/cn-list-pro-item-description/view';

class CnListPro extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  load = () => {
    this.ref?.current?.load?.();
  };

  reRender = () => {
    this.ref?.current?.reRender?.();
  };

  render() {
    return <CnListProView ref={this.ref} {...this.props} />;
  }
}

CnListPro.displayName = 'CnListPro';
export default [
  CnListPro,
  CnListProItem,
  CnListProItemTitle,
  CnListProItemDescription,
  CnListProItemExtra,
  CnListProItemBottomArea,
  CnListProItemAction,
  CnListProItemAvatar,
];

export { CnListPro };
