export const ButtonPosition = {
    // 操作栏按钮区
    actionBar: 'btn_actionBar',
    // 表格操作列按钮区
    tableOperate: 'btn_tableOperate',
    // 表格cell点击
    tableCell: 'btn_tableCell',
    // 表格工具按钮区
    tableToolArea: 'btn_tableToolArea',
    // 表格批量按钮区
    tableBatchArea: 'btn_tableBatchArea',
    // 弹窗按钮区域
    dialog: 'btn_dialog',
    // 表单弹窗按钮区域
    formDialog: 'btn_formDialog',
    // 表单按钮区域
    form: 'btn_form',
    // filter 默认值请求成功的回调动作
    filterDefaultValueAfterRequestSuccess: 'btn_filterDefaultValueAfterRequestSuccess',
    // form 默认值请求成功的回调动作
    formDefaultValueAfterRequestSuccess: 'btn_formDefaultValueAfterRequestSuccess',
    // form提交成功的回调动作
    formSubmitAfterRequestSuccess: 'btn_formSubmitAfterRequestSuccess',
    // formDialog提交成功的回调动作
    formDialogSubmitAfterRequestSuccess: 'btn_formDialogSubmitAfterRequestSuccess',
    // filter 输入框的回调
    filterItemEvent: 'btn_filterItemEvent',
    // form 输入框回调
    formItemEvent: 'btn_formItemEvent',
    // 表单弹窗输入框的回调
    formDialogItemEvent: 'btn_formDialogItemEvent',
    // 可编辑表格输入框的回调
    cnArrayTableItemEvent: 'btn_cnArrayTableItemEvent',
    // 自增卡片输入框的回调
    cnArraySubAreaCardItemEvent: 'btn_cnArraySubAreaCardItemEvent',
    // tab item的点击动作
    tabItem: 'btn_tabItem',
    // 页面初始加载
    pageDidMount: 'btn_pageDidMount',
    // 页面异步数据
    pageAsyncRequestComplete: 'btn_pageAsyncRequestCompelete',
    // 可编辑表格的操作区
    arrayTableOperate: 'btn_arrayTableOperate',
    // 可编辑表格cell点击
    arrayTableCell: 'btn_arrayTableCell',
    // 自增卡片右侧按钮
    arraySubAreaCardOperate: 'btn_arraySubAreaCardOperate',
    // 卡片的右侧操作区按钮
    cardAction: 'btn_cardAction',
    // 子卡片的右侧操作区按钮
    subCardAction: 'btn_subCardAction',
    // 表格操作列按钮请求成功后的回调
    tableOperateAfterRequestSuccess: 'btn_tableOperateAfterRequestSuccess',
    // 批量提交成功的回调动作
    tableBatchAreaAfterRequestSuccess: 'btn_tableBatchAreaAfterRequestSuccess',
    // 表格单元格按钮请求成功后的回调
    tableCellAfterRequestSuccess: 'btn_tableCellAfterRequestSuccess',
    // 表格工具栏请求成功的回调动作
    tableToolAreaAfterRequestSuccess: 'btn_tableToolAreaAfterRequestSuccess',
    // 步骤条底部按钮
    step: 'btn_step',
    // 动作流
    flowAction: 'btn_flowAction',
    // tab 的回调事件
    tabEvent: 'btn_tabEvent',
    // step 的回调事件
    stepEvent: 'btn_stepEvent',
    // filter 的回调事件
    filterEvent: 'btn_filterEvent',
    // 指标卡 的回调事件
    statisticCardEvent: 'btn_statisticCardEvent',
    // form 回调事件
    formEvent: 'btn_formEvent',
    // table 回调事件
    tableEvent: 'btn_tableEvent',
    // 树节点按钮栏
    treeNode: 'btn_treeNode',
    // 树节点的回调
    treeEvent: 'btn_treeEvent',
    // 结果反馈底部按钮栏
    result: 'btn_result',
    // CnPage右侧按钮区
    pageRightButton: 'btn_pageRightButton',
    // 子表格操作列按钮区
    childTableOperate: 'btn_childTableOperate',
    // 表单项右侧按钮区
    formItemRightButton: 'btn_formItemRightButton',
    // 列表右侧按钮
    listItemRightButton: 'btn_listItemRightButton',
    // 入口组件的回调
    entryPointEvent: 'btn_entryPointEvent',
    // 列表项点击的回调
    listItemEvent: 'btn_listItemEvent',
    // 卡片的右侧操作区按钮（单独使用的卡片，非表单中的卡片）
    cardActionNotInForm: 'btn_cardActionNotInForm',
    // 子卡片右侧按钮（单独使用的子卡片，非表单中的子卡片）
    subCardActionNotInForm: 'btn_subCardActionNotInForm',
    // 筛选栏扩展按钮
    filterExtendButton: 'btn_filterExtendButton',
    // 弹窗回调事件
    dialogEvent: 'btn_dialogEvent',
    // 瓷片右侧按钮
    tileRightButton: 'btn_tileRightButton',
    // 指标卡右侧按钮
    statisticCardRightButton: 'btn_statisticCardRightButton',
    // 布局Blocks组件按钮区域
    blockButton: 'btn_blockButton',
    // cnFormat
    cnFormat: 'btn_cnFormat',
    // format的回调事件
    cnFormatEvent: 'btn_cnFormatEvent',
    // 通用按钮位置
    normalButton: 'btn_normalButton',
    // 通用按钮位置，与normalButton作用相同
    baseButton: 'btn_baseButton',
    // pro列表项点击的回调
    listProItemEvent: 'btn_listProItemEvent',
};
