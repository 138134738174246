import $i18n from '@/locales/i18n';
import React from 'react';
import {
  CnButton,
  CnDialog as UICnDialog,
  CnDrawer as UICnDrawer,
  formilyReact,
} from '@cainiaofe/cn-ui';
import {
  confirmActionHoc,
  executeEventWithoutJS,
  executeFunction,
  executeObjectExpr,
  getRealizeValue,
  getRunTimeItem as getCommonRunTimeItem,
  isArrayNotEmpty,
  isDesignMode,
  renderIcon,
} from '@/common/util/util';
import './view.scss';
import {
  getButtonAction,
  getItem as getButtonItem,
  getRunTimeItem,
} from '@/common/manager/button';
import CnForm from '../cn-form-alc';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { _getFormValues, _state } from '@/common/util/biz-component-prop-name';
import {
  __dataSource__,
  __extraParam__,
  __filterValue__,
  __formValue__,
  __stateValueOfSplit__,
} from '@/common/util/expr-const';
import set from 'lodash/set';
import get from 'lodash/get';

const { Observer } = formilyReact || {};
class CnFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.formInstance = null;
    this.formStepInstance = null;
    this.formRef = null;
  }

  getFormInstance = () => {
    return this.formInstance;
  };

  emptyContent = () => {
    return (
      <div
        style={{
          padding: '20px 0 60px 0',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '24px',
        }}
      >
        {$i18n.get({
          id: 'PleaseAddAFormItemInTheForm',
          dm: '请在右侧表单配置面板添加表单项',
        })}
      </div>
    );
  };

  close = () => {
    const { _context, _dataSourceName, events } = this.props;
    this.formInstance && this.formInstance.setValues({}, 'overwrite');
    this.formInstance = null;
    if (_dataSourceName) {
      const path = `${_dataSourceName}${__stateValueOfSplit__}openDialogMode`;
      if (_context) {
        set(_context.state.valueOf, path, undefined);
      }
    }
    this.setState({
      visible: false,
    });
    if (isArrayNotEmpty(events)) {
      executeEventWithoutJS({
        eventType: 'onDialogClose',
        events,
        _context,
        position: ButtonPosition.formEvent,
        recordDataSource: {},
      });
    }
  };

  open = (data, config) => {
    const { _context, _dataSourceName } = this.props;
    const { selectedRowKeys, selectedRowRecords, buttonConfig } = config || {};
    const { options } = buttonConfig || {};
    const { openDialogMode } = options || {};
    // FIXME 临时方案，表格选中项托管到当前弹窗上
    this.selectedRowKeys = selectedRowKeys;
    this.selectedRowRecords = selectedRowRecords;
    if (data !== undefined && data !== null) {
      if (_context && _dataSourceName) {
        _context?.setState({
          [_dataSourceName]: data,
        });
      }
    }
    if (openDialogMode && _context?.state?.valueOf) {
      set(
        _context.state.valueOf,
        `${_dataSourceName}$$$openDialogMode`,
        openDialogMode,
      );
      set(
        _context.state.valueOf,
        `${_dataSourceName}${__stateValueOfSplit__}openDialogMode`,
        openDialogMode,
      );
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          {
            visible: true,
          },
          () => {
            // 给formInstance创建留出时间
            setTimeout(() => {
              resolve(true);
            }, 50);
          },
        );
      });
    });
  };

  getUrlParams = () => {
    const { _context } = this.props;
    return _context?.state?.urlParams || {};
  };

  load = () => {
    this.formRef?.load?.();
  };

  reRender = () => {
    this.formRef?.reRender?.();
  };

  getFormRef = () => {
    return this.formRef?.getFormRef?.();
  };

  getExtraParam = () => {
    const { _context, _dataSourceName } = this.props;
    return {
      openDialogMode: get(
        _context?.state?.valueOf,
        `${_dataSourceName}${__stateValueOfSplit__}openDialogMode`,
      ),
    };
  };

  getButtons = () => {
    const { buttons = [], _context, dialogMode } = this.props;
    if (!(buttons?.length > 0)) {
      return false;
    }
    const leftButtons = [];
    const rightButtons = [];
    buttons.forEach((item, index) => {
      const {
        iconType,
        optType,
        options = {},
        children,
        type,
        hidden,
        disabled,
        asyncLoading,
        btnPosition,
      } = item;
      const position = ButtonPosition.formDialog;
      const formValue = this.formInstance?.values;
      const action = confirmActionHoc({
        originalAction: getButtonAction({
          ...item,
          position: ButtonPosition.formDialog,
        }),
        item: {
          ...item,
          position: ButtonPosition.formDialog,
        },
        _context,
        params: [formValue || {}, _context?.state, this.formInstance],
      });

      const getRenderDom = getButtonItem(position, optType, 'getRenderDom');
      const componentDefine = getRunTimeItem(optType);
      const btnProps = {
        type,
        asyncLoading,
      };
      // 存量页面
      let needShowLoading;
      if (optType === 'submit') {
        needShowLoading = true;
      } else if (optType === 'flowAction') {
        const hasSubmitAction = options?.flowList?.find?.(
          (_item) => _item?.optType === 'submit',
        );
        if (hasSubmitAction) {
          needShowLoading = true;
        }
      }
      if (dialogMode === 'drawer') {
        btnProps.size = 'large';
      }
      const isHidden = executeObjectExpr(
        hidden,
        {
          [__filterValue__]: formValue || {},
          [__formValue__]: formValue || {},
          [__dataSource__]: _context?.state,
          [__extraParam__]: {
            currentFormStep: this.formStepInstance?.current,
          },
        },
        formValue || {},
        _context?.state,
      );
      if (isHidden) {
        return null;
      }
      if (
        optType === 'previousStep' &&
        this.formStepInstance?.allowBack !== true
      ) {
        return null;
      }
      if (optType === 'nextStep' && this.formStepInstance?.allowNext !== true) {
        return null;
      }
      // 存量页面
      if (needShowLoading) {
        btnProps.asyncLoading = true;
      }
      const isDisabled = executeObjectExpr(
        disabled,
        {
          [__filterValue__]: formValue || {},
          [__formValue__]: formValue || {},
          [__dataSource__]: _context?.state,
        },
        formValue || {},
        _context?.state,
      );
      if (isDisabled === true) {
        btnProps.disabled = true;
      }
      if (componentDefine?.component) {
        const component = getRealizeValue(componentDefine.component);
        if (component) {
          const btn = React.createElement(component, {
            ...btnProps,
            buttonConfig: item,
            dialogRef: this,
            _context,
          });
          if (btnPosition === 'left') {
            leftButtons.push(btn);
          } else {
            rightButtons.push(btn);
          }
        }
        return;
      } else if (typeof getRenderDom === 'function') {
        const btn = executeFunction(getRenderDom, {
          _context,
          buttonConfig: item,
          state: _context?.state,
          urlParamsDataSource: this.getUrlParams(),
          recordDataSource: {
            realize: () => {
              return this?.formInstance?.values;
            },
          },
        });
        if (btnPosition === 'left') {
          leftButtons.push(btn);
        } else {
          rightButtons.push(btn);
        }
        return;
      }
      if (action) {
        btnProps.onClick = action.bind(this, {
          componentProps: this.props,
          buttonConfig: item,
          open: this.open,
          close: this.close,
          dialogRef: this,
          formInstance: {
            realize: () => {
              return this?.formInstance;
            },
          },
          position: ButtonPosition.formDialog,
          state: _context?.state,
          urlParamsDataSource: this.getUrlParams(),
          recordDataSource: {
            realize: () => {
              return this?.formInstance?.values;
            },
          },
          _context,
          formStepInstance: this.formStepInstance,
          getExtraParam: this.getExtraParam,
        });
      }
      const dom = (
        <CnButton
          key={index}
          mobileProps={{ fullWidth: true, size: 'large' }}
          {...btnProps}
        >
          {renderIcon(iconType)}
          {children}
        </CnButton>
      );

      if (btnPosition === 'right') {
        rightButtons.push(dom);
      } else if (btnPosition === 'left' || optType === 'previousStep') {
        leftButtons.push(dom);
      } else {
        rightButtons.push(dom);
      }
    });

    if (isArrayNotEmpty(rightButtons) || isArrayNotEmpty(leftButtons)) {
      if (isArrayNotEmpty(leftButtons)) {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {leftButtons}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {rightButtons}
            </div>
          </div>
        );
      } else {
        return (
          <>
            {leftButtons} {rightButtons}
          </>
        );
      }
    } else {
      return <div />;
    }
  };

  getForm = () => {
    const { config, dynamicFormConfig = {} } = this.props;
    const { active: dynamicFormConfigActive } = dynamicFormConfig;
    if (dynamicFormConfigActive || (config && config.length > 0)) {
      return (
        <CnForm
          {...this.props}
          onCreateForm={(formInstance, formStepInstance) => {
            this.formInstance = formInstance;
            this.formStepInstance = formStepInstance;
          }}
          dialogRef={this}
          ref={(c) => {
            this.formRef = c;
          }}
        />
      );
    } else {
      return this.emptyContent();
    }
  };

  render() {
    let { visible } = this.state;
    const {
      config,
      children,
      dialogMode,
      title,
      _context,
      canCloseByOutSideClick,
      ...otherProps
    } = this.props;
    const { _dataSourceName } = otherProps || {};
    const isDesign = isDesignMode(this.props);

    if (isDesign) {
      visible = true;
    }
    let drawerEmbeddable = false;
    const Component = dialogMode === 'drawer' ? UICnDrawer : UICnDialog;

    if (dialogMode && config && config[0] && config[0].componentName === 'CnCard') {
      drawerEmbeddable = true;
    }
    let realTitle = title;
    if (typeof title === 'function') {
      realTitle = executeFunction(
        title,
        get(
          _context?.state?.valueOf,
          `${_dataSourceName}${__stateValueOfSplit__}openDialogMode`,
        ),
        _context?.state,
      );
    } else {
      const componentDefine = getCommonRunTimeItem({}, title);
      if (componentDefine?.component) {
        const component = getRealizeValue(componentDefine.component);
        if (component) {
          realTitle = React.createElement(component, {
            _context,
            [_state]: _context?.state,
            [_getFormValues]: () => {
              return this?.formInstance?.values;
            },
          });
        }
      }
    }
    const componentProps = {
      className: 'cn-dialog-alc',
      ...otherProps,
      title: realTitle,
      visible,
      onClose: this.close,
      okProps: { visible: false },
      cancelProps: { visible: false },
    };
    if (dialogMode === 'drawer') {
      // componentProps.canCloseByOutSideClick = canCloseByOutSideClick;
    }
    if (isArrayNotEmpty(this.props?.buttons)) {
      componentProps.footer = (
        <Observer>
          {() => {
            return this.getButtons();
          }}
        </Observer>
      );
    } else {
      componentProps.footer = false;
    }
    if (drawerEmbeddable) {
      componentProps.embeddable = drawerEmbeddable;
    }

    return (
      <Observer>
        {() => {
          return <Component {...componentProps}>{this.getForm()}</Component>;
        }}
      </Observer>
    );
  }
}

CnFormDialog.displayName = 'CnFormDialog';
export default CnFormDialog;

export { CnFormDialog };
