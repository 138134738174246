import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import React from 'react';
import { CnImageViewer } from '@cainiaofe/cn-ui';
import './image-viewer.scss';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { ImageViewer: { title, thumbUrl } } = FORM_ITEM_INFO;
const ImageViewer = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'ImageViewer',
    getDefaultProps: () => {
        return {
            width: 100,
            height: 100,
        };
    },
    formComponent: (props) => {
        const { value, width, height } = props || {};
        return (<CnImageViewer className={'cn-image-viewer-alc'} src={value} width={width} height={height}/>);
    },
    getPrototypeList: () => {
        return [
            {
                name: 'width',
                title: $i18n.get({ id: 'PictureWidth', dm: '图片宽度' }),
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'NumberSetter',
                                title: $i18n.get({ id: 'SetWidthPx', dm: '设置宽度（px）' }),
                            },
                            {
                                componentName: 'CnSelectSetter',
                                title: $i18n.get({ id: 'CommonSettings', dm: '常用设置' }),
                                props: {
                                    options: [
                                        {
                                            value: '100%',
                                            title: $i18n.get({ id: '100Wide', dm: '100%宽' }),
                                        },
                                        {
                                            value: 'auto',
                                            title: $i18n.get({
                                                id: 'AutomaticWidthAuto',
                                                dm: '自动宽度(auto)',
                                            }),
                                        },
                                    ],
                                    selectProps: {},
                                },
                            },
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({
                                    id: 'SetWidthString',
                                    dm: '设置宽度（字符串）',
                                }),
                            },
                        ],
                    },
                },
            },
            {
                name: 'height',
                title: $i18n.get({ id: 'PictureHeight', dm: '图片高度' }),
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                componentName: 'NumberSetter',
                                title: $i18n.get({ id: 'SetHeightPx', dm: '设置高度（px）' }),
                            },
                            {
                                componentName: 'CnSelectSetter',
                                title: $i18n.get({ id: 'CommonSettings', dm: '常用设置' }),
                                props: {
                                    options: [
                                        {
                                            value: '100%',
                                            title: $i18n.get({ id: '100PercentHigh', dm: '100%高' }),
                                        },
                                        {
                                            value: 'auto',
                                            title: $i18n.get({
                                                id: 'AutoHeightAuto',
                                                dm: '自动高度（auto）',
                                            }),
                                        },
                                    ],
                                    selectProps: {},
                                },
                            },
                            {
                                componentName: 'StringSetter',
                                title: $i18n.get({
                                    id: 'SetHeightString',
                                    dm: '设置高度度（字符串）',
                                }),
                            },
                        ],
                    },
                },
            },
        ];
    },
};
export default ImageViewer;
