import $i18n from '@/locales/i18n';
import { DisplayPosition } from './position/display-position';
import { ButtonPosition } from './position/button-position';
// eslint-disable-next-line import/no-cycle
import { __record__, condition, getAllButtonPositionList, getJSExpressionPrototype2, handleOriginList, isArrayNotEmpty, isRecursionComponent, } from '../util/util';
import React from 'react';
import { __arrayTableCurrentRow__, __dataSource__, __extraParam__, __flowData__, __permission_point_list__, __permissionGranted__, __tableCurrentRow__, button_view, currentFormCardLabel, currentFormStepLabel, currentPageLabel, formDialogStateLabel, formStateLabel, getFormExprNameByPosition, leftTreeAfterDropDataSourceLabel, leftTreeStateLabel, openDialogModeLabel, pageSizeLabel, } from '@/common/util/expr-const';
import ExprSetter from '@/common/setter/expr-setter';
// eslint-disable-next-line import/no-cycle
import { getItem } from '@/common/manager/button';
// eslint-disable-next-line import/no-cycle
import { getFormStateSetterSnippet, getJSXTemplate, getJsActionSetterSnippet, getMixedSetterSnippet, getVariableSetterSnippet, } from '@/common/manager/setter-snippet';
import { ParamSelectSetter } from '@/common/setter/param-select-setter';
import { allowQuickComponentList } from '@/common/util/const';
export const needAsyncLoading = () => ({
    name: 'asyncLoading',
    title: $i18n.get({
        id: 'ClickTheButtonAfterLoading',
        dm: '点击后按钮loading',
    }),
    display: 'inline',
    // 隐藏 此处display: 'none'隐藏不掉 留着做代码提示
    condition: (props) => {
        return props?.parent?.getPropValue?.('optType') !== 'buttonGroup';
    },
    setter: 'BoolSetter',
    position: getAllButtonPositionList(),
});
const type = {
    name: 'type',
    title: $i18n.get({ id: 'ButtonStyle', dm: '按钮样式' }),
    // supportVariable: true,
    display: 'inline',
    position: getAllButtonPositionList(),
    setter: getMixedSetterSnippet({
        setters: [
            {
                title: $i18n.get({ id: 'Select', dm: '选择' }),
                componentName: 'SelectSetter',
                props: {
                    options: [
                        {
                            title: $i18n.get({ id: 'NormalButton', dm: '普通按钮' }),
                            value: 'normal',
                        },
                        {
                            title: $i18n.get({ id: 'MainButton', dm: '主按钮' }),
                            value: 'primary',
                        },
                        {
                            title: $i18n.get({ id: 'SecondaryButton', dm: '次按钮' }),
                            value: 'secondary',
                        },
                    ],
                },
            },
            {
                title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
                componentName: 'ActionSetter',
                props: {
                    supportTypes: ['page'],
                    defaultCode: $i18n.get({
                        id: 'FunctionButtontypeargState',
                        dm: "\nfunction button_type(arg, state){\n  // primary: 主按钮；secondary: 次按钮；normal: 普通按钮\n  // return 'primary';\n}\n",
                    }),
                    defaultActionName: 'button_type',
                },
            },
        ],
    }),
};
const text = {
    name: 'text',
    title: $i18n.get({ id: 'TextButton', dm: '是否文本按钮' }),
    display: 'inline',
    condition(prop) {
        // 按钮组中的按钮，隐藏是否文本按钮配置项（只能为文本按钮）
        const { optType = '' } = prop?.parent?.parent?.parent?.parent?.parent?.getValue?.() || {};
        const isInButtonGroup = optType === 'buttonGroup';
        return !isInButtonGroup;
    },
    position: getAllButtonPositionList().filter((item) => item !== ButtonPosition.tableOperate),
    setter: {
        componentName: 'BoolSetter',
    },
};
const size = {
    name: 'size',
    title: $i18n.get({ id: 'ButtonSize', dm: '按钮尺寸' }),
    display: 'inline',
    position: getAllButtonPositionList().filter((item) => {
        return item !== ButtonPosition.tableOperate
            && item !== ButtonPosition.tableToolArea
            && item !== ButtonPosition.tableBatchArea
            && item !== ButtonPosition.actionBar;
    }),
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: $i18n.get({ id: 'Big', dm: '大' }), value: 'large' },
                { title: $i18n.get({ id: 'Medium', dm: '中' }), value: 'medium' },
                { title: $i18n.get({ id: 'Small', dm: '小' }), value: 'small' },
            ],
        },
    },
};
const btnPosition = {
    name: 'btnPosition',
    title: $i18n.get({ id: 'ButtonPosition', dm: '按钮位置' }),
    display: 'inline',
    position: [
        ButtonPosition.form,
        ButtonPosition.blockButton,
        ButtonPosition.formDialog,
    ],
    setter: {
        componentName: 'CnSelectSetter',
        props: {
            options: [
                {
                    title: $i18n.get({
                        id: 'OnTheLeftSideOfTheBottomButton',
                        dm: '在底部按钮栏的左侧',
                    }),
                    value: 'left',
                },
                {
                    title: $i18n.get({
                        id: 'OnTheRightSideOfTheBottomButton',
                        dm: '在底部按钮栏的右侧',
                    }),
                    value: 'right',
                },
            ],
            selectProps: {
                hasClear: true,
                placeholder: $i18n.get({
                    id: 'DefaultToTheRightOfTheBottomButt_837264421',
                    dm: '默认在底部按钮栏的右侧',
                }),
            },
        },
    },
};
const tableOperateHidden = {
    name: 'hidden',
    title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
    display: 'inline',
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                dataKey: 'columns',
                                labelKey: 'title',
                                valueKey: 'dataIndex',
                                groupName: $i18n.get({
                                    id: 'CurrentRowData',
                                    dm: '当前行数据',
                                }),
                                groupExprName: __tableCurrentRow__,
                                needSecondParam: false,
                            },
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'table' }),
                getPermissionListPrototype(),
            ],
        },
    },
    position: [ButtonPosition.tableOperate, ButtonPosition.listItemRightButton],
};
const tableOperateDisabled = {
    name: 'disabled',
    title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
    display: 'inline',
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                dataKey: 'columns',
                                labelKey: 'title',
                                valueKey: 'dataIndex',
                                groupName: $i18n.get({
                                    id: 'CurrentRowData',
                                    dm: '当前行数据',
                                }),
                                groupExprName: __tableCurrentRow__,
                                needSecondParam: false,
                            },
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'table' }),
            ],
        },
    },
    position: [ButtonPosition.tableOperate, ButtonPosition.listItemRightButton],
};
const arrayTableOperateHidden = (position) => {
    return {
        name: 'hidden',
        title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    dataKey: 'arrayTable',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({
                                        id: 'TableCurrentRowData',
                                        dm: '表格当前行数据',
                                    }),
                                    groupExprName: __arrayTableCurrentRow__,
                                    needSecondParam: false,
                                },
                                {
                                    dataKey: 'config',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
                                    groupExprName: getFormExprNameByPosition({ position }),
                                    needSecondParam: false,
                                },
                                {
                                    groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    getJSExpressionPrototype({ type: 'arrayTableOperate' }),
                    getJsActionSetterSnippet({
                        defaultCode: `
function btn_hidden(record, state, index, formValues, field){
  // return true/false;
}
`,
                        defaultActionName: 'btn_hidden',
                    }),
                    getPermissionListPrototype(),
                ],
            },
        },
        position: [
            ButtonPosition.arrayTableOperate,
            ButtonPosition.arraySubAreaCardOperate,
        ],
    };
};
const arrayTableOperateDisabled = (position) => {
    return {
        name: 'disabled',
        title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    dataKey: 'arrayTable',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({
                                        id: 'TableCurrentRowData',
                                        dm: '表格当前行数据',
                                    }),
                                    groupExprName: __arrayTableCurrentRow__,
                                    needSecondParam: false,
                                },
                                {
                                    dataKey: 'config',
                                    labelKey: 'label',
                                    valueKey: 'name',
                                    groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
                                    groupExprName: getFormExprNameByPosition({ position }),
                                    needSecondParam: false,
                                },
                                {
                                    groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    getJsActionSetterSnippet({
                        defaultCode: `
function btn_disabled(record, state, index, formValues, field){
  // return true/false;
}
`,
                        defaultActionName: 'btn_disabled',
                    }),
                    getJSExpressionPrototype({ type: 'arrayTableOperate' }),
                ],
            },
        },
        position: [
            ButtonPosition.arrayTableOperate,
            ButtonPosition.arraySubAreaCardOperate,
        ],
    };
};
const span = {
    name: 'span',
    display: 'inline',
    title: $i18n.get({ id: 'NumberOfGrids', dm: '网格数' }),
    // setter: <NumberSetter min={1} max={2} />,
    position: [DisplayPosition.filter],
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                {
                    title: $i18n.get({ id: '1GridNarrow', dm: '1格（窄）' }),
                    value: 1,
                },
                {
                    title: $i18n.get({ id: '2CellsWide', dm: '2格（宽）' }),
                    value: 2,
                },
            ],
        },
    },
};
const required = {
    name: 'required',
    position: [],
    display: 'inline',
    title: $i18n.get({ id: 'Required', dm: '必填' }),
    // supportVariable: true,
    setter: 'BoolSetter',
};
const requiredMessage = {
    position: [],
    name: 'requiredMessage',
    display: 'inline',
    title: $i18n.get({ id: 'RequiredPrompt', dm: '必填提示' }),
    tip: $i18n.get({
        id: 'TheWrongPromptCopyOfTheTimesWas',
        dm: '未填写时报的错误提示文案',
    }),
    // supportVariable: true,
    setter: 'CnI18nSetter',
};
const hidden = {
    name: 'hidden',
    title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
    display: 'inline',
    // position: [DisplayPosition.form, DisplayPosition.filter,DisplayPosition.formDialog],
    // supportVariable: true,
    setter: 'BoolSetter',
};
const placeholder = {
    position: [
        DisplayPosition.filter,
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    name: 'placeholder',
    title: $i18n.get({ id: 'EmptyPromptMessage', dm: '空提示信息' }),
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'CnI18nSetter',
                    title: $i18n.get({ id: 'MultilingualSetup', dm: '多语言设置器' }),
                },
                getJSExpressionPrototype({ type: 'formRequest' }),
            ],
        },
    },
};
const tip = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    name: 'tip',
    title: $i18n.get({ id: 'LabelPromptInformation', dm: '标签提示信息' }),
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    title: $i18n.get({ id: 'Text', dm: '文本' }),
                    componentName: 'CnI18nSetter',
                },
                getJSXTemplate({
                    position: ButtonPosition.form,
                }),
            ],
        },
    },
};
const extra = () => {
    const linkGetPrototypeList = getItem(ButtonPosition.form, 'link', 'getPrototypeList');
    return {
        position: [DisplayPosition.form, DisplayPosition.formDialog],
        name: 'extra',
        title: $i18n.get({ id: 'AdditionalTipsAtBottom', dm: '底部额外提示信息' }),
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        title: $i18n.get({ id: 'Text', dm: '文本' }),
                        condition() {
                            return true;
                        },
                        componentName: 'CnI18nSetter',
                        props: {},
                    },
                    {
                        title: $i18n.get({ id: 'Hyperlink', dm: '超链接' }),
                        componentName: 'ObjectSetter',
                        props: {
                            config: {
                                items: [
                                    {
                                        name: 'text',
                                        title: $i18n.get({ id: 'LinkCopy', dm: '链接文案' }),
                                        display: 'inline',
                                        setter: {
                                            componentName: 'MixedSetter',
                                            props: {
                                                setters: [
                                                    {
                                                        componentName: 'CnI18nSetter',
                                                        title: $i18n.get({ id: 'Text', dm: '文本' }),
                                                    },
                                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                                ],
                                            },
                                        },
                                    },
                                    ...linkGetPrototypeList(ButtonPosition.form),
                                ],
                            },
                        },
                    },
                    getJSXTemplate({
                        position: ButtonPosition.form,
                    }),
                ],
            },
        },
    };
};
// const disabled = {
//   name: 'disabled',
//   title: '禁用',
//   display: 'inline',
//   // supportVariable: true,
//   // setter: {
//   //   componentName: "MixedSetter",
//   //   props: {
//   //     setters: ["BoolSetter"],
//   //   },
//   // },
//   setter: 'BoolSetter',
//   position: [DisplayPosition.formDialog],
// };
const filterItemHidden = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if (DisplayPosition.formDialog === position) {
        configList.splice(1, 0, getFormStateSetterSnippet());
    }
    let jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    if (DisplayPosition.cnArrayTable === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'TableCurrentRowData', dm: '表格当前行数据' }),
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    else if (DisplayPosition.cnArraySubAreaCard === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: currentFormCardLabel,
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    return {
        name: 'hidden',
        title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [
            DisplayPosition.filter,
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
            DisplayPosition.cnArraySubAreaCard,
        ],
    };
};
const arrayTableColumnHidden = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    const jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    return {
        name: 'arrayTableColumnHidden',
        title: $i18n.get({ id: 'EntireColumnHide', dm: '整列隐藏' }),
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        componentName: <ExprSetter configList={configList}/>,
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [DisplayPosition.cnArrayTable],
    };
};
const filterItemDisabled = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if (DisplayPosition.formDialog === position) {
        configList.splice(1, 0, getFormStateSetterSnippet());
    }
    let jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    if (DisplayPosition.cnArrayTable === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'TableCurrentRowData', dm: '表格当前行数据' }),
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    else if (DisplayPosition.cnArraySubAreaCard === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: currentFormCardLabel,
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    return {
        name: 'disabled',
        title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [
            DisplayPosition.filter,
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
            DisplayPosition.cnArraySubAreaCard,
        ],
    };
};
const filterItemReadOnly = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
            groupExprName: getFormExprNameByPosition({ position }),
            needSecondParam: false,
        },
        {
            groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if (DisplayPosition.formDialog === position) {
        configList.splice(1, 0, getFormStateSetterSnippet());
    }
    let jsExpressionPrototype = getJSExpressionPrototype({ type: 'form' });
    if (DisplayPosition.cnArrayTable === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'TableCurrentRowData', dm: '表格当前行数据' }),
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    else if (DisplayPosition.cnArraySubAreaCard === position) {
        configList.unshift({
            dataKey: 'arrayTable',
            labelKey: 'label',
            valueKey: 'name',
            groupName: currentFormCardLabel,
            groupExprName: __arrayTableCurrentRow__,
            needSecondParam: false,
        });
        jsExpressionPrototype = getJSExpressionPrototype({ type: 'arrayTable' });
    }
    return {
        name: 'readOnly',
        title: $i18n.get({ id: 'ReadOnly', dm: '只读' }),
        display: 'inline',
        // supportVariable: true,
        className: 'cn-text-expr-setter',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'RadioGroupSetter',
                        props: {
                            options: [
                                {
                                    title: $i18n.get({ id: 'Yes', dm: '是' }),
                                    value: true,
                                },
                                {
                                    title: $i18n.get({ id: 'No', dm: '否' }),
                                    value: false,
                                },
                                {
                                    title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }),
                                },
                            ],
                        },
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    jsExpressionPrototype,
                ],
            },
        },
        position: [
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
            DisplayPosition.cnArraySubAreaCard,
        ],
    };
};
const filterItemNotSubmitWhenHidden = () => {
    return {
        name: 'notSubmitWhenHidden',
        title: $i18n.get({
            id: 'DataNotSubmittedDuringHiding',
            dm: '隐藏时数据不提交',
        }),
        display: 'inline',
        // supportVariable: true,
        // className: 'cn-text-expr-setter',
        // setter: {
        //   componentName: 'MixedSetter',
        //   props: {
        //     setters: [
        //       {
        //         componentName: 'RadioGroupSetter',
        //         props: {
        //           options: [
        //             { title: '是', value: 'none' },
        //             { title: '否', value: '' },
        //             { title: '默认值' },
        //           ],
        //         },
        //       },
        //     ],
        //   },
        // },
        setter: 'BoolSetter',
        condition(prop) {
            return prop?.parent?.getPropValue?.('hidden') !== undefined;
        },
        position: [
            DisplayPosition.filter,
            DisplayPosition.formDialog,
            DisplayPosition.form,
            DisplayPosition.cnArrayTable,
        ],
    };
};
const hasClear = {
    position: [
        DisplayPosition.filter,
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    name: 'hasClear',
    title: $i18n.get({ id: 'AllowEmpty', dm: '允许清空' }),
    display: 'inline',
    // supportVariable: true,
    tip: $i18n.get({
        id: 'DesignSpecificationNoModificatio_192917185',
        dm: '设计规范规定，不建议修改',
    }),
    setter: 'BoolSetter',
};
// const readOnly = {
//   position: [DisplayPosition.form, DisplayPosition.formDialog,DisplayPosition.cnArrayTable],
//   name: 'readOnly',
//   title: '只读',
//   display: 'inline',
//   // supportVariable: true,
//   tip: '',
//   className: 'cn-text-expr-setter',
//   setter: {
//     componentName: 'MixedSetter',
//     props: {
//       setters: [
//         {
//           // componentName: 'BoolSetter',
//           // title: '布尔（true/false）',
//           title: '是 或 否',
//           componentName: 'RadioGroupSetter',
//           props: {
//             options: [
//               { value: true, title: '是' },
//               { value: false, title: '否' },
//               { title: '默认值' },
//             ],
//           },
//         },
//         {
//           // componentName: <TextExprSetter
//           //   dataKey={'config'}
//           //   labelKey={'label'}
//           //   valueKey={'name'}
//           //   groupName={'表单字段'}
//           // />,
//           componentName: <ExprSetter
//             configList={[
//               {
//                 dataKey:'config',
//                 labelKey:'label',
//                 valueKey:'name',
//                 groupName:'表单字段',
//                 groupExprName: getFormExprNameByPosition({}),
//                 needSecondParam:false,
//               },
//               {
//                 groupName:'其他数据',
//                 groupExprName: __dataSource__,
//                 needSecondParam:true,
//               }
//             ]}
//           />,
//           title: '简单表达式',
//         },
//         getJSExpressionPrototype({ type: 'form' }),
//       ],
//     },
//   },
// };
const tableToolbarHidden = {
    name: 'hidden',
    title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
    display: 'inline',
    onecodeDisplay: 'visible',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'aa'}
                    //   labelKey={'aa'}
                    //   valueKey={'aa'}
                    //   groupName={' '}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'form' }),
                getPermissionListPrototype(),
            ],
        },
    },
    position: [ButtonPosition.tableToolArea, ButtonPosition.tableBatchArea],
};
const tableToolbarDisabled = {
    name: 'disabled',
    title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'aa'}
                    //   labelKey={'aa'}
                    //   valueKey={'aa'}
                    //   groupName={' '}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'tableBatchArea' }),
            ],
        },
    },
    position: [ButtonPosition.tableToolArea, ButtonPosition.tableBatchArea],
};
const formButtonHidden = (position) => {
    const configList = [
        {
            dataKey: 'config',
            labelKey: 'label',
            valueKey: 'name',
            groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
            groupExprName: getFormExprNameByPosition({}),
            needSecondParam: false,
        },
        {
            groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
            groupExprName: __dataSource__,
            needSecondParam: true,
        },
    ];
    if ([ButtonPosition.form, ButtonPosition.formDialog].includes(position)) {
        const stateConfig = {
            title: formStateLabel,
            children: [
                {
                    label: currentFormStepLabel,
                    value: `${__extraParam__}.currentFormStep`,
                },
            ],
            extraConfig: {
                renderTypeDom: undefined,
                renderValueDom: undefined,
            },
        };
        configList.splice(1, 0, getFormStateSetterSnippet(stateConfig));
    }
    return {
        name: 'hidden',
        title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
        display: 'inline',
        // supportVariable: true,
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        // componentName: <TextExprSetter
                        //   dataKey={'config'}
                        //   labelKey={'label'}
                        //   valueKey={'name'}
                        //   groupName={'表单字段列表'}
                        // />,
                        componentName: <ExprSetter configList={configList}/>,
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    getJSExpressionPrototype({ type: 'form' }),
                    getPermissionListPrototype(),
                ],
            },
        },
        position: [
            ButtonPosition.formDialog,
            ButtonPosition.form,
            ButtonPosition.cardAction,
            ButtonPosition.subCardAction,
            ButtonPosition.formItemRightButton,
        ],
    };
};
const formButtonDisabled = {
    name: 'disabled',
    title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    // componentName: <TextExprSetter
                    //   dataKey={'config'}
                    //   labelKey={'label'}
                    //   valueKey={'name'}
                    //   groupName={'表单字段列表'}
                    // />,
                    componentName: (<ExprSetter configList={[
                            {
                                dataKey: 'config',
                                labelKey: 'label',
                                valueKey: 'name',
                                groupName: $i18n.get({ id: 'FormFields', dm: '表单字段' }),
                                groupExprName: getFormExprNameByPosition({}),
                                needSecondParam: false,
                            },
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'form' }),
            ],
        },
    },
    position: [
        ButtonPosition.formDialog,
        ButtonPosition.form,
        ButtonPosition.cardAction,
        ButtonPosition.subCardAction,
        ButtonPosition.formItemRightButton,
    ],
};
const dialogButtonHidden = () => {
    return {
        name: 'hidden',
        title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
        display: 'inline',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    getVariableSetterSnippet(),
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    getJSExpressionPrototype({ type: 'base' }),
                    getPermissionListPrototype(),
                ],
            },
        },
        position: [
            ButtonPosition.dialog,
            ButtonPosition.treeNode,
            ButtonPosition.blockButton,
        ],
    };
};
const dialogButtonDisabled = {
    name: 'disabled',
    title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
    display: 'inline',
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                getVariableSetterSnippet(),
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'base' }),
            ],
        },
    },
    position: [
        ButtonPosition.dialog,
        ButtonPosition.treeNode,
        ButtonPosition.blockButton,
    ],
};
const stepButtonHidden = {
    name: 'hidden',
    title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'step' }),
                getPermissionListPrototype(),
            ],
        },
    },
    position: [ButtonPosition.step],
};
const stepButtonDisabled = {
    name: 'disabled',
    title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
    display: 'inline',
    // supportVariable: true,
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                getJSExpressionPrototype({ type: 'step' }),
            ],
        },
    },
    position: [ButtonPosition.step],
};
const width = {
    name: 'width',
    title: $i18n.get({ id: 'ColumnWidth', dm: '列宽度' }),
    display: 'inline',
    setter: 'NumberSetter',
    position: [DisplayPosition.cnArrayTable],
};
const iconType = {
    name: 'iconType',
    title: $i18n.get({ id: 'ButtonIcon', dm: '按钮图标' }),
    display: 'inline',
    condition: (props) => {
        return props?.parent?.getPropValue?.('optType') !== 'buttonGroup';
    },
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    title: $i18n.get({ id: 'SelectIcon', dm: '选择图标' }),
                    componentName: 'CnIconSetter',
                },
                {
                    title: $i18n.get({ id: 'String', dm: '字符串' }),
                    componentName: 'StringSetter',
                },
                {
                    title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
                    componentName: 'ActionSetter',
                    props: {
                        supportTypes: ['page'],
                        defaultCode: $i18n.get({
                            id: 'FunctionButtoniconargState',
                            dm: "\nfunction button_icon(arg, state){\n  // 需要返回icon字符串，完整的icon列表请参考 https://cone.cainiao-inc.com/dsm/cnui/material/pc/CnIcon\n  // return 'home';\n}\n",
                        }),
                        defaultActionName: 'button_icon',
                    },
                },
            ],
        },
    },
    position: getAllButtonPositionList().filter((item) => item !== ButtonPosition.tableOperate),
};
const iconTypeHelp = {
    display: 'none',
    condition: (props) => {
        return props?.parent?.getPropValue?.('optType') !== 'buttonGroup';
    },
    setter: {
        componentName: 'DocSetter',
        props: {
            content: (<div className="">
          {$i18n.get({ id: 'PleaseCome', dm: '请到' })}{' '}
          <a target={'_blank'} href={'https://done.alibaba-inc.com/dsm/cone/components/detail/cnicon?themeid=26075&tabActiveKey=component'} rel="noreferrer">
            {$i18n.get({ id: 'IconLibrary', dm: '图标库' })}
          </a>{' '}
          {$i18n.get({
                    id: 'FindTheIconYouWantToUseAnd',
                    dm: '中寻找想用的图标，填写图标type即可。',
                })}
          <a target={'_blank'} href={'https://alidocs.dingtalk.com/i/nodes/NkPaj7GAXdpWOkPeZ6p0Wqwgylnomz9B?utm_scene=team_space&iframeQuery=anchorId%3Duu_lbusmehvlsw40rh61ti'} rel="noreferrer">
            {$i18n.get({ id: 'ExperienceDemo', dm: '体验Demo' })}
          </a>
        </div>),
        },
    },
    position: [
        ButtonPosition.dialog,
        ButtonPosition.formDialog,
        ButtonPosition.form,
        ButtonPosition.tableBatchArea,
        ButtonPosition.tableToolArea,
        ButtonPosition.cardAction,
        ButtonPosition.filterExtendButton,
        ButtonPosition.blockButton,
    ],
};
const colSpan = {
    name: 'colSpan',
    title: $i18n.get({
        id: 'HowManyColumnsTheCurrentComponen_1288951514',
        dm: '当前组件在一行占几列',
    }),
    display: 'inline',
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: $i18n.get({ id: 'None', dm: '无' }), value: undefined },
                { title: '1', value: 1 },
                { title: '2', value: 2 },
                { title: '3', value: 3 },
                { title: '4', value: 4 },
                { title: '5', value: 5 },
                { title: '6', value: 6 },
            ],
        },
    },
    position: [
        DisplayPosition.formDialog,
        DisplayPosition.form,
        DisplayPosition.cnArraySubAreaCard,
    ],
};
const buttonHoverInfo = (position) => {
    const paramSelectSetter = getParamSetterPrototype({
        type: 'filter',
        prototypeConfig: {},
    });
    const jsExpressionSetter = getJSExpressionPrototype({
        type: [ButtonPosition.cardAction, ButtonPosition.subCardAction].includes(position)
            ? 'form'
            : 'tableBatchArea',
        prototypeConfig: {},
    });
    return {
        name: 'buttonHoverInfo',
        title: $i18n.get({ id: 'BubbleTip', dm: '气泡提示' }),
        display: 'plain',
        setter: {
            componentName: 'ObjectSetter',
            props: {
                config: {
                    items: [
                        {
                            name: 'needHover',
                            title: $i18n.get({ id: 'BubbleTip', dm: '气泡提示' }),
                            display: 'inline',
                            setter: {
                                componentName: 'BoolSetter',
                            },
                        },
                        {
                            name: 'hoverInfo',
                            display: 'plain',
                            title: $i18n.get({
                                id: 'SuspensionConfigurationItem',
                                dm: '悬浮配置项',
                            }),
                            setter: {
                                componentName: 'ObjectSetter',
                                props: {
                                    config: {
                                        items: [
                                            {
                                                name: 'title',
                                                title: $i18n.get({
                                                    id: 'BubbleTipTitle',
                                                    dm: '气泡提示标题',
                                                }),
                                                display: 'inline',
                                                setter: {
                                                    componentName: 'MixedSetter',
                                                    props: {
                                                        setters: [
                                                            {
                                                                componentName: 'CnI18nSetter',
                                                                title: $i18n.get({
                                                                    id: 'String',
                                                                    dm: '字符串',
                                                                }),
                                                            },
                                                            paramSelectSetter,
                                                            jsExpressionSetter ||
                                                                getJSExpressionPrototype({
                                                                    type: 'tableRequest',
                                                                }),
                                                        ],
                                                    },
                                                },
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                            {
                                                name: 'content',
                                                title: $i18n.get({
                                                    id: 'BubblePromptContent',
                                                    dm: '气泡提示内容',
                                                }),
                                                display: 'inline',
                                                setter: {
                                                    componentName: 'MixedSetter',
                                                    props: {
                                                        setters: [
                                                            {
                                                                componentName: 'CnI18nSetter',
                                                                title: $i18n.get({
                                                                    id: 'String',
                                                                    dm: '字符串',
                                                                }),
                                                            },
                                                            paramSelectSetter,
                                                            jsExpressionSetter ||
                                                                getJSExpressionPrototype({
                                                                    type: 'tableRequest',
                                                                }),
                                                            getJSXTemplate({ position }),
                                                        ],
                                                    },
                                                },
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                            {
                                                name: 'align',
                                                title: $i18n.get({
                                                    id: 'BubblePosition',
                                                    dm: '气泡位置',
                                                }),
                                                display: 'inline',
                                                setter: {
                                                    componentName: 'SelectSetter',
                                                    props: {
                                                        options: [
                                                            {
                                                                label: $i18n.get({ id: 'Left', dm: '左' }),
                                                                value: 'l',
                                                            },
                                                            {
                                                                label: $i18n.get({
                                                                    id: 'UpperLeft',
                                                                    dm: '上左',
                                                                }),
                                                                value: 'tl',
                                                            },
                                                            {
                                                                label: $i18n.get({ id: 'Up', dm: '上' }),
                                                                value: 't',
                                                            },
                                                            {
                                                                label: $i18n.get({
                                                                    id: 'UpperRight',
                                                                    dm: '上右',
                                                                }),
                                                                value: 'tr',
                                                            },
                                                            {
                                                                label: $i18n.get({ id: 'Right', dm: '右' }),
                                                                value: 'r',
                                                            },
                                                            {
                                                                label: $i18n.get({
                                                                    id: 'LowerRight',
                                                                    dm: '下右',
                                                                }),
                                                                value: 'br',
                                                            },
                                                            {
                                                                label: $i18n.get({ id: 'Lower', dm: '下' }),
                                                                value: 'b',
                                                            },
                                                            {
                                                                label: $i18n.get({
                                                                    id: 'LowerLeft',
                                                                    dm: '下左',
                                                                }),
                                                                value: 'bl',
                                                            },
                                                        ],
                                                    },
                                                },
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                            {
                                                name: 'handleProps',
                                                title: $i18n.get({
                                                    id: 'ManuallySetTheBubbleComponentPro_263925293',
                                                    dm: '手动设置气泡组件props',
                                                }),
                                                display: 'inline',
                                                setter: getJSExpressionPrototype({
                                                    type: 'props',
                                                }),
                                                condition(prop) {
                                                    return condition(prop, true, 'needHover');
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },
        condition: (props) => {
            return props?.parent?.getPropValue?.('optType') !== 'buttonGroup';
        },
        position: [
            ButtonPosition.tableBatchArea,
            ButtonPosition.tableToolArea,
            ButtonPosition.cardAction,
            ButtonPosition.subCardAction,
        ],
    };
};
const fixed = {
    name: 'fixed',
    title: $i18n.get({ id: 'ColumnFixed', dm: '列固定' }),
    display: 'inline',
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                { title: $i18n.get({ id: 'TERM.NotFreezed', dm: '不固定' }) },
                { value: 'left', title: $i18n.get({ id: 'Left_', dm: '左侧' }) },
                { value: 'right', title: $i18n.get({ id: 'Right_', dm: '右侧' }) },
            ],
        },
    },
    position: [DisplayPosition.cnArrayTable],
};
const rightIconType = {
    name: 'rightIconType',
    title: $i18n.get({ id: 'RightButtonIcon', dm: '右侧按钮图标' }),
    display: 'inline',
    condition: (props) => {
        return props?.parent?.getPropValue?.('optType') !== 'buttonGroup';
    },
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    title: $i18n.get({ id: 'SelectIcon', dm: '选择图标' }),
                    componentName: 'CnIconSetter',
                },
                {
                    title: $i18n.get({ id: 'String', dm: '字符串' }),
                    componentName: 'StringSetter',
                },
                {
                    title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
                    componentName: 'ActionSetter',
                    props: {
                        supportTypes: ['page'],
                        defaultCode: $i18n.get({
                            id: 'FunctionButtonrighticonargState',
                            dm: "\nfunction button_right_icon(arg, state){\n  // 需要返回icon字符串，完整的icon列表请参考 https://cone.cainiao-inc.com/dsm/cnui/material/pc/CnIcon\n  // return 'home';\n}\n",
                        }),
                        defaultActionName: 'button_right_icon',
                    },
                },
            ],
        },
    },
    position: getAllButtonPositionList().filter((item) => item !== ButtonPosition.tableOperate),
};
const iconSize = {
    name: 'iconSize',
    title: $i18n.get({ id: 'IconSize', dm: '图标大小' }),
    display: 'inline',
    defaultValue: 'small',
    setter: {
        componentName: 'RadioGroupSetter',
        props: {
            options: [
                {
                    label: $i18n.get({ id: 'Small', dm: '小' }),
                    value: 'small',
                },
                {
                    label: $i18n.get({ id: 'Medium', dm: '中' }),
                    value: 'medium',
                },
                {
                    label: $i18n.get({ id: 'Big', dm: '大' }),
                    value: 'large',
                },
            ],
        },
    },
    position: [ButtonPosition.cnFormat, ButtonPosition.subCardActionNotInForm],
};
const warning = (position) => {
    if ([ButtonPosition.tableOperate].includes(position)) {
        return null;
    }
    else {
        const setters = [
            {
                componentName: 'BoolSetter',
                title: $i18n.get({ id: 'Switch', dm: '开关' }),
            },
        ];
        if ([ButtonPosition.tableBatchArea, ButtonPosition.tableToolArea].includes(position)) {
            setters.push(getVariableSetterSnippet());
            setters.push(getJsActionSetterSnippet({
                defaultCode: `
function btn_warning(filterParams, state) {
  // return true/false;
}
`,
                defaultActionName: 'btn_warning',
            }));
        }
        return {
            name: 'warning',
            title: $i18n.get({ id: 'WarningButton', dm: '警告按钮' }),
            display: 'inline',
            condition: (props) => {
                return props?.parent?.getPropValue?.('optType') !== 'buttonGroup';
            },
            setter: getMixedSetterSnippet({
                setters,
            }),
            position: getAllButtonPositionList(),
        };
    }
};
const confirmInfo = (position) => {
    let jsExpressionSetter = getJSExpressionPrototype({ type: 'base' });
    if ([
        ButtonPosition.tableToolArea,
        ButtonPosition.tableBatchArea,
        ButtonPosition.cardAction,
        ButtonPosition.subCardAction,
    ].includes(position)) {
        jsExpressionSetter = getJSExpressionPrototype({ type: 'form' });
    }
    if ([ButtonPosition.formDialog, ButtonPosition.form].includes(position)) {
        jsExpressionSetter = getJSExpressionPrototype({ type: 'confirmInForm' });
    }
    if ([ButtonPosition.childTableOperate, ButtonPosition.tableOperate].includes(position)) {
        jsExpressionSetter = getJSExpressionPrototype({ type: 'table' });
    }
    if ([ButtonPosition.step].includes(position)) {
        jsExpressionSetter = getJSExpressionPrototype({ type: 'step' });
    }
    if ([
        ButtonPosition.arrayTableOperate,
        ButtonPosition.arraySubAreaCardOperate,
    ].includes(position)) {
        jsExpressionSetter = getJSExpressionPrototype({
            type: 'arrayTableOperate',
        });
    }
    const needConfirmSetters = [
        {
            componentName: 'BoolSetter',
            title: $i18n.get({ id: 'BooleanTruefalse', dm: '布尔（true/false）' }),
        },
        jsExpressionSetter,
    ];
    return {
        name: 'confirmInfo',
        display: 'plain',
        title: $i18n.get({ id: 'SecondaryConfirmation', dm: '二次确认' }),
        condition(prop) {
            const optType = prop?.parent?.getPropValue?.('optType');
            // FIXME: request、submit的二次确认已迁移至其他配置项，因此需要隐藏该处配置，非代码写错
            return !['request', 'remove', 'submit', 'batch'].includes(optType) && optType !== 'buttonGroup';
        },
        initialValue: false,
        defaultValue: false,
        position: getAllButtonPositionList(),
        setter: {
            componentName: 'ObjectSetter',
            props: {
                config: {
                    items: [
                        {
                            name: 'needConfirm',
                            title: $i18n.get({ id: 'SecondaryConfirmation', dm: '二次确认' }),
                            display: 'inline',
                            setter: {
                                componentName: 'MixedSetter',
                                props: {
                                    setters: needConfirmSetters,
                                },
                            },
                        },
                        {
                            name: 'title',
                            title: $i18n.get({
                                id: 'SecondaryConfirmationTitle',
                                dm: '二次确认标题',
                            }),
                            display: 'inline',
                            setter: {
                                componentName: 'MixedSetter',
                                props: {
                                    setters: [
                                        {
                                            componentName: 'CnI18nSetter',
                                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                                        },
                                        {
                                            componentName: 'VariableSetter',
                                            title: $i18n.get({
                                                id: 'VariableBinding',
                                                dm: '变量绑定',
                                            }),
                                        },
                                    ],
                                },
                            },
                            condition(prop) {
                                return !!prop?.parent?.getPropValue?.('needConfirm');
                            },
                        },
                        {
                            name: 'content',
                            title: $i18n.get({
                                id: 'SecondConfirmationContent',
                                dm: '二次确认内容',
                            }),
                            display: 'inline',
                            condition(prop) {
                                return !!prop?.parent?.getPropValue?.('needConfirm');
                            },
                            // setter: {
                            //   componentName: 'TextAreaSetter',
                            // },
                            setter: {
                                componentName: 'MixedSetter',
                                props: {
                                    setters: [
                                        {
                                            componentName: 'CnI18nSetter',
                                            title: $i18n.get({ id: 'String', dm: '字符串' }),
                                        },
                                        {
                                            componentName: 'VariableSetter',
                                            title: $i18n.get({
                                                id: 'VariableBinding',
                                                dm: '变量绑定',
                                            }),
                                        },
                                        getJSXTemplate({ position }),
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        },
    };
};
const normalButtonHidden = () => {
    return {
        name: 'hidden',
        title: $i18n.get({ id: 'Hide', dm: '隐藏' }),
        display: 'inline',
        setter: {
            componentName: 'MixedSetter',
            props: {
                setters: [
                    {
                        componentName: 'BoolSetter',
                        title: $i18n.get({
                            id: 'BooleanTruefalse',
                            dm: '布尔（true/false）',
                        }),
                    },
                    {
                        componentName: (<ExprSetter configList={[
                                {
                                    groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                    groupExprName: __dataSource__,
                                    needSecondParam: true,
                                },
                            ]}/>),
                        title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                    },
                    {
                        title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
                        componentName: 'ActionSetter',
                        props: {
                            supportTypes: ['page'],
                            defaultCode: $i18n.get({
                                id: 'FunctionHiddenargState',
                                dm: '\nfunction hidden(arg, state){\n  // 返回 true/false。true: 隐藏；false: 显示\n  // return true;\n}\n',
                            }),
                            defaultActionName: 'hidden',
                        },
                    },
                ],
            },
        },
        position: [ButtonPosition.normalButton, ButtonPosition.baseButton],
    };
};
const normalButtonDisabled = {
    name: 'disabled',
    title: $i18n.get({ id: 'Disable.Dgodg', dm: '禁用' }),
    display: 'inline',
    setter: {
        componentName: 'MixedSetter',
        props: {
            setters: [
                {
                    componentName: 'BoolSetter',
                    title: $i18n.get({
                        id: 'BooleanTruefalse',
                        dm: '布尔（true/false）',
                    }),
                },
                {
                    componentName: (<ExprSetter configList={[
                            {
                                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                                groupExprName: __dataSource__,
                                needSecondParam: true,
                            },
                        ]}/>),
                    title: $i18n.get({ id: 'SimpleExpression', dm: '简单表达式' }),
                },
                {
                    title: $i18n.get({ id: 'JSCode', dm: 'JS代码' }),
                    componentName: 'ActionSetter',
                    props: {
                        supportTypes: ['page'],
                        defaultCode: $i18n.get({
                            id: 'FunctionDisabledargState',
                            dm: '\nfunction disabled(arg, state){\n  // 返回 true/false。true: 禁用点击；false: 正常点击\n  // return true;\n}\n',
                        }),
                        defaultActionName: 'disabled',
                    },
                },
            ],
        },
    },
    position: [ButtonPosition.normalButton, ButtonPosition.baseButton],
};
const quick = {
    name: 'quick',
    display: 'inline',
    disabled: true,
    title: $i18n.get({ id: 'WhetherToFilterQuickly', dm: '是否快速过滤' }),
    position: [DisplayPosition.filter],
    setter: 'BoolSetter',
    onecodeDisplay: 'visible',
    condition(prop) {
        const client = window?.getCnLowcodeStoreItem?.('__next_cone_client__');
        if (client === 'onecode' &&
            allowQuickComponentList.includes(prop?.parent?.parent?.getPropValue?.('componentName'))) {
            return true;
        }
        return false;
    },
};
const allStyleMap = {
    quick,
    width,
    type,
    text,
    size,
    btnPosition,
    tableOperateHidden,
    tableOperateDisabled,
    span,
    required,
    requiredMessage,
    hidden,
    placeholder,
    tip,
    extra,
    hasClear,
    // disabled,
    filterItemHidden,
    filterItemNotSubmitWhenHidden,
    arrayTableColumnHidden,
    filterItemDisabled,
    filterItemReadOnly,
    fixed,
    // readOnly,
    tableToolbarHidden,
    tableToolbarDisabled,
    formButtonHidden,
    formButtonDisabled,
    dialogButtonHidden,
    dialogButtonDisabled,
    arrayTableOperateHidden,
    arrayTableOperateDisabled,
    stepButtonDisabled,
    stepButtonHidden,
    normalButtonHidden,
    normalButtonDisabled,
    buttonHoverInfo,
    warning,
    iconSize,
    iconType,
    iconTypeHelp,
    colSpan,
    rightIconType,
    needAsyncLoading,
    confirmInfo,
};
// 注册样式
export function registerStyle(plugin) {
    const { name } = plugin || {};
    if (name) {
        allStyleMap[name] = plugin;
    }
}
// 获取样式列表
export function getStyleListByPosition(position) {
    const defaultStyleList = Object.keys(allStyleMap);
    const result = [];
    if (position !== undefined) {
        defaultStyleList.forEach((name) => {
            let item = allStyleMap[name];
            if (typeof item === 'function') {
                item = item(position);
            }
            if (item && item.position && item.position.includes(position)) {
                result.push(item);
            }
        });
    }
    return result;
}
export function getJSExpressionPrototype(config) {
    return getJSExpressionPrototype2(config);
}
export function getPermissionListPrototype() {
    return {
        name: 'permissionHidden',
        componentName: 'CnSelectSetter',
        title: $i18n.get({ id: 'PermissionControl', dm: '权限控制' }),
        props(prop) {
            const permissionList = window.getCnLowcodeStoreItem?.(__permission_point_list__) || [];
            return {
                selectProps: {
                    hasClear: true,
                    showSearch: true,
                    menuProps: {},
                    onChange: (v) => {
                        if (!v) {
                            prop?.parent?.setPropValue?.('hidden', undefined);
                            return;
                        }
                        prop?.parent?.setPropValue?.('hidden', {
                            group: __dataSource__,
                            param: `valueOf.${__permissionGranted__}`,
                            secondParam: `${v}`,
                            thirdParam: button_view,
                            symbol: '!=',
                            type: 'boolean',
                            value: true,
                        });
                    },
                    value: prop?.parent?.getPropValue?.('hidden')?.secondParam,
                },
                options: permissionList.filter((i) => i.type === button_view),
            };
        },
    };
}
export function getParamSetterPrototype(config) {
    const { type: _type, prototypeConfig } = config || {};
    const { flowList, currentFlowIndex, additionalPosition } = prototypeConfig || {};
    if (_type === 'formDialog') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: $i18n.get({ id: 'CurrentForm', dm: '当前表单' }),
                    },
                    {
                        groupName: formDialogStateLabel,
                        handleCustomGroup: (_extra) => {
                            if (Array.isArray(_extra?.children)) {
                                _extra.children.push({
                                    label: openDialogModeLabel,
                                    value: `${__extraParam__}.openDialogMode`,
                                });
                            }
                        },
                    },
                    {
                        groupName: $i18n.get({
                            id: 'ActionChoreographyData',
                            dm: '动作编排的数据',
                        }),
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'form') {
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'config',
            //   labelKey: 'label',
            //   valueKey: 'name',
            //   groupName: '当前表单',
            // },
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: $i18n.get({ id: 'CurrentForm', dm: '当前表单' }),
                    },
                    {
                        groupName: $i18n.get({
                            id: 'ActionChoreographyData',
                            dm: '动作编排的数据',
                        }),
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'table') {
        const configList = [
            {
                dataKey: 'columns',
                labelKey: 'title',
                valueKey: 'dataIndex',
                groupName: $i18n.get({ id: 'CurrentRowData', dm: '当前行数据' }),
                // groupExprName: __tableCurrentRow__,
                needSecondParam: false,
            },
            {
                groupName: $i18n.get({
                    id: 'ActionChoreographyData',
                    dm: '动作编排的数据',
                }),
                groupExprName: __flowData__,
                needSecondParam: true,
                flowList,
                currentFlowIndex,
            },
            {
                groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                groupExprName: __dataSource__,
                needSecondParam: true,
            },
        ];
        if (additionalPosition === ButtonPosition.childTableOperate) {
            configList[0] = {
                groupName: $i18n.get({ id: 'CurrentRowData', dm: '当前行数据' }),
                groupExprName: __record__,
                handleCustomGroup: (_extra, field) => {
                    if (Array.isArray(_extra?.children) && field) {
                        const originList = field
                            ?.getNode?.()
                            ?.getPropValue?.('childTable')?.columns;
                        if (isArrayNotEmpty(configList)) {
                            _extra.children = handleOriginList(originList, 'title', 'dataIndex', __record__);
                        }
                    }
                },
            };
        }
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'columns',
            //   labelKey: 'title',
            //   valueKey: 'dataIndex',
            //   groupName: '当前行数据',
            // },
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList,
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'filter') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                dataKey: 'config',
                labelKey: 'label',
                valueKey: 'name',
                groupName: $i18n.get({ id: 'FilterBar', dm: '筛选栏' }),
                relativeDataSourceName: '_bindFilterDataSourceName',
                relativeDataSourceNameKey: '_dataSourceName',
            },
            // props: {
            //   configList:[
            //     {
            //       dataKey: 'config',
            //       labelKey: 'label',
            //       valueKey: 'name',
            //       groupName: '筛选栏',
            //       relativeDataSourceName: '_bindFilterDataSourceName',
            //       relativeDataSourceNameKey: '_dataSourceName',
            //     },
            //     {
            //       groupName:'动作编排的数据',
            //       groupExprName: __flowData__,
            //       needSecondParam:true,
            //       flowList,
            //       currentFlowIndex,
            //     },
            //     {
            //       groupName:'其他数据',
            //       groupExprName: __dataSource__,
            //       needSecondParam:true,
            //     },
            //   ]
            // },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'arrayTable') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                dataKey: 'arrayTable',
                labelKey: 'label',
                valueKey: 'name',
                groupName: $i18n.get({ id: 'CurrentRowData', dm: '当前行数据' }),
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'arrayTableItemEvent') {
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'arrayTable',
            //   labelKey: 'label',
            //   valueKey: 'name',
            //   groupName: '当前行数据',
            // },
            props: {
                configList: [
                    {
                        groupName: $i18n.get({ id: 'CurrentRowData', dm: '当前行数据' }),
                        groupExprName: __arrayTableCurrentRow__,
                        handleCustomGroup: (_extra, field) => {
                            const path = field?.path;
                            if (Array.isArray(path) &&
                                path.length > 5 &&
                                path[0] === 'config' &&
                                path[2] === 'options' &&
                                (path[3] === 'config' || path[3] === 'buttons')) {
                                const arrayTableIndex = path[1];
                                if (typeof arrayTableIndex === 'number') {
                                    const formConfig = field
                                        ?.getNode?.()
                                        ?.getPropValue?.('config');
                                    const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
                                    if (isRecursionComponent(arrayTableConfig?.componentName) &&
                                        arrayTableConfig?.options?.config?.length > 0) {
                                        const originList = arrayTableConfig?.options?.config;
                                        const tempList = handleOriginList(originList, 'label', 'name', `${__extraParam__}.${__arrayTableCurrentRow__}`);
                                        if (isArrayNotEmpty(tempList)) {
                                            _extra.children = tempList;
                                        }
                                    }
                                }
                            }
                        },
                    },
                    {
                        groupName: $i18n.get({
                            id: 'ActionChoreographyData',
                            dm: '动作编排的数据',
                        }),
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'base') {
        return {
            componentName: 'ParamSelectSetter',
            // props: {
            //   dataKey: 'aa',
            //   labelKey: 'aa',
            //   valueKey: 'aa',
            //   groupName: '参数列表',
            // },
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        groupName: $i18n.get({
                            id: 'ActionChoreographyData',
                            dm: '动作编排的数据',
                        }),
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'filterWithPagination') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: $i18n.get({ id: 'FilterBar', dm: '筛选栏' }),
                        relativeDataSourceName: '_bindFilterDataSourceName',
                        relativeDataSourceNameKey: '_dataSourceName',
                    },
                    {
                        groupName: $i18n.get({
                            id: 'PagingInformationForTheCurrentTa_1431849479',
                            dm: '当前表格的的分页信息',
                        }),
                        groupExprName: __extraParam__,
                        handleCustomGroup: (_extra) => {
                            if (Array.isArray(_extra?.children)) {
                                _extra.children.push({
                                    label: currentPageLabel,
                                    value: `${__extraParam__}.currentPage`,
                                });
                                _extra.children.push({
                                    label: pageSizeLabel,
                                    value: `${__extraParam__}.pageSize`,
                                });
                            }
                        },
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'tree') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                ParamSelectSetterComponent: ParamSelectSetter,
                configList: [
                    {
                        groupName: leftTreeStateLabel,
                        handleCustomGroup: (_extra) => {
                            if (Array.isArray(_extra?.children)) {
                                _extra.children.push({
                                    label: leftTreeAfterDropDataSourceLabel,
                                    value: `${__extraParam__}.afterDropDataSource`,
                                });
                            }
                        },
                    },
                    {
                        groupName: $i18n.get({
                            id: 'ActionChoreographyData',
                            dm: '动作编排的数据',
                        }),
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
    else if (_type === 'arrayCardSubCardButton') {
        return {
            componentName: 'ParamSelectSetter',
            props: {
                configList: [
                    {
                        groupName: $i18n.get({ id: 'CurrentCardData', dm: '当前卡片数据' }),
                        groupExprName: __arrayTableCurrentRow__,
                        handleCustomGroup: (_extra, field) => {
                            const path = field?.path;
                            if (Array.isArray(path) &&
                                path.length > 5 &&
                                path[0] === 'config' &&
                                path[2] === 'options' &&
                                (path[3] === 'config' || path[3] === 'buttons')) {
                                const arrayTableIndex = path[1];
                                if (typeof arrayTableIndex === 'number') {
                                    const formConfig = field
                                        ?.getNode?.()
                                        ?.getPropValue?.('config');
                                    const arrayTableConfig = formConfig?.[arrayTableIndex] || {};
                                    if (isRecursionComponent(arrayTableConfig?.componentName) &&
                                        arrayTableConfig?.options?.config?.length > 0) {
                                        const originList = arrayTableConfig?.options?.config;
                                        const tempList = handleOriginList(originList, 'label', 'name', `${__extraParam__}.${__arrayTableCurrentRow__}`);
                                        if (isArrayNotEmpty(tempList)) {
                                            _extra.children = tempList;
                                        }
                                    }
                                }
                            }
                        },
                    },
                    {
                        dataKey: 'config',
                        labelKey: 'label',
                        valueKey: 'name',
                        groupName: $i18n.get({ id: 'CurrentForm', dm: '当前表单' }),
                    },
                    {
                        groupName: $i18n.get({
                            id: 'ActionChoreographyData',
                            dm: '动作编排的数据',
                        }),
                        groupExprName: __flowData__,
                        needSecondParam: true,
                        flowList,
                        currentFlowIndex,
                    },
                    {
                        groupName: $i18n.get({ id: 'OtherData', dm: '其他数据' }),
                        groupExprName: __dataSource__,
                        needSecondParam: true,
                    },
                ],
            },
            title: $i18n.get({ id: 'SelectParameters', dm: '选择参数' }),
        };
    }
}
