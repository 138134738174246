import { useLayoutEffect } from 'react';
import { debounce } from 'lodash';
export function useFooterAutoWidth(props) {
    const { footerId, containerId } = props || {};
    const setFooterWidth = () => {
        const footerDom = document.getElementById(footerId);
        const formDom = document.getElementById(containerId);
        const formWidth = formDom?.getBoundingClientRect?.()?.width;
        if (footerDom && typeof formWidth === 'number') {
            footerDom.style.width = `${formWidth}px`;
        }
    };
    const debounceSetFooterWidth = debounce(() => {
        setFooterWidth();
    }, 500, {
        leading: true,
    });
    useLayoutEffect(() => {
        setTimeout(() => {
            setFooterWidth();
            const formDom = document.getElementById(containerId);
            if (ResizeObserver && formDom) {
                const resizeObserver = new ResizeObserver((entries) => {
                    entries.forEach(() => {
                        debounceSetFooterWidth();
                    });
                });
                resizeObserver.observe(formDom);
            }
        }, 300);
    }, []);
}
