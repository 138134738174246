import { CnButton, formilyReact } from '@cainiaofe/cn-ui';
const { useField } = formilyReact;
export function CnButtonProxy(props) {
    const { onClick } = props;
    const extraProps = {};
    if (onClick) {
        let field;
        try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            field = useField();
        }
        catch (e) { /* empty */ }
        extraProps.onClick = () => {
            onClick({
                field,
            });
        };
    }
    return <CnButton {...props} {...extraProps}/>;
}
