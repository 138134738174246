import $i18n from "../locales/i18n";
export var TABLE_MOCK_DATA = {
    success: true,
    data: {
        tableData: [
            {
                trackingNumber: 'LT123456789CN',
                currentStatus: $i18n.get({ id: 'Received', dm: '已入库' }),
                warehouseEntryTime: '2023-10-01T10:30:00Z',
                packageWeight: '1.2kg',
            },
        ],
        paging: {
            currentPage: 1,
            pageSize: 10,
            totalCount: 1,
        },
        tableColumns: [
            {
                dataIndex: 'trackingNumber',
                title: $i18n.get({ id: 'WaybillNumber', dm: '运单号码' }),
            },
            {
                dataIndex: 'currentStatus',
                title: $i18n.get({ id: 'CurrentStatus', dm: '当前状态' }),
            },
            {
                dataIndex: 'warehouseEntryTime',
                title: $i18n.get({ id: 'ReceiptTime', dm: '入库时间' }),
            },
            {
                dataIndex: 'packageWeight',
                title: $i18n.get({ id: 'PackageWeight', dm: '包裹重量' }),
            },
        ],
    },
};
