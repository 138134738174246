import $i18n from '@/locales/i18n';
import React from 'react';
import './index.scss';
import { Button, CnAsyncSelect, CnIcon, CnInput, CnNumberPicker, CnRangeDatePicker2, CnRangeTimePicker2, CnTimePicker2, } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { DatePickerComposite } from '@/common/manager/filter-item/datepicker';
import { RelativeDateSelect } from '@/common/setter/default-value-setter/components/relative-date-select';
import { JsonEditor } from '@/common/setter/default-value-setter/components/json-editor';
import { handleI18nLabel } from '@/common/util/util';
import { allowSelectFirstComponentList } from '@/common/util/const';
import { DisplayPosition } from '@/common/manager/position/display-position';
import isString from 'lodash/isString';
const ParamSelectSetter = window.AliLowCodeEngine?.setters?.getSetter?.('ParamSelectSetter')
    ?.component || CnInput;
const typeList = [
    {
        label: $i18n.get({ id: 'TimeSelector', dm: '时间选择器' }),
        value: 'CnTimePicker2',
        component: CnTimePicker2,
        position: ['TimePicker'],
        props: {},
    },
    {
        label: $i18n.get({ id: 'TimeIntervalSelector', dm: '时间区间选择器' }),
        value: 'CnRangeTimePicker2',
        component: CnRangeTimePicker2,
        position: ['RangeTimePicker'],
        props: {
            popupAlign: 'br br',
        },
    },
    {
        label: $i18n.get({ id: 'DateIntervalSelector', dm: '日期区间选择器' }),
        value: 'CnRangeDatePicker2',
        component: CnRangeDatePicker2,
        position: ['RangePicker'],
        props: {
            popupAlign: 'br br',
        },
    },
    {
        label: $i18n.get({ id: 'DatePicker', dm: '日期选择器' }),
        value: 'CnDatePicker2',
        component: DatePickerComposite,
        position: ['DatePicker'],
        props: {
            popupAlign: 'br br',
        },
    },
    {
        label: $i18n.get({ id: 'SelectRelativeTime', dm: '选择相对时间' }),
        value: 'buildIn',
        component: RelativeDateSelect,
        position: ['DatePicker', 'RangePicker'],
    },
    {
        label: $i18n.get({ id: 'TextInputBox', dm: '文本输入框' }),
        value: 'CnInput',
        component: CnInput,
        props: {},
    },
    {
        label: $i18n.get({ id: 'DigitalInputBox', dm: '数字输入框' }),
        value: 'CnNumberPicker',
        component: CnNumberPicker,
        props: {
            precision: 9,
        },
    },
    {
        label: $i18n.get({ id: 'YesNo', dm: '是 / 否' }),
        value: 'CnAsyncSelect',
        component: CnAsyncSelect,
        props: {
            dataSource: [
                {
                    label: $i18n.get({ id: 'Yes', dm: '是' }),
                    value: true,
                },
                {
                    label: $i18n.get({ id: 'No', dm: '否' }),
                    value: false,
                },
            ],
        },
    },
    {
        label: $i18n.get({ id: 'JSONEditor', dm: 'JSON编辑器' }),
        value: 'JSON',
        component: JsonEditor,
        props: {},
    },
    {
        label: $i18n.get({ id: 'SelectFromDataSource', dm: '从数据源选择' }),
        value: 'SelectFromDataSource',
        component: ParamSelectSetter,
        props: {
            dataKey: 'aa',
            labelKey: 'aa',
            valueKey: 'aa',
            groupName: $i18n.get({ id: 'ListOfParameters', dm: '参数列表' }),
        },
    },
    {
        label: $i18n.get({
            id: 'WhichItemIsSelectedByDefault',
            dm: '默认选中第几项',
        }),
        value: 'DefaultSelect',
        position: allowSelectFirstComponentList,
        componentPosition: [
            DisplayPosition.filter,
            DisplayPosition.form,
            DisplayPosition.formDialog,
        ],
        component: CnAsyncSelect,
        props: {
            dataSource: [
                {
                    label: $i18n.get({ id: 'Item1', dm: '第1项' }),
                    value: 0,
                },
            ],
        },
    },
];
const typeMap = {};
typeList.forEach((item) => {
    typeMap[item.value] = item;
});
function getTypeList(config) {
    const { position, componentPosition } = config;
    const result = [];
    typeList.forEach((item) => {
        if (Array.isArray(item.position) && position) {
            if (item.position.includes(position)) {
                if (item.componentPosition) {
                    if (item.componentPosition.includes(componentPosition)) {
                        result.push(item);
                    }
                }
                else {
                    result.push(item);
                }
            }
        }
        else {
            result.push(item);
        }
    });
    return result;
}
export default class DefaultValueSetter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getList = () => {
        const { field, dataKey, labelKey, valueKey } = this.props;
        const originList = field?.getNode?.()?.getPropValue?.(dataKey);
        const result = [];
        const map = {};
        if (originList?.length > 0) {
            originList.forEach((item) => {
                const { componentName } = item;
                const value = item[valueKey];
                // 如果label不是string 那就用字段名显示
                let label = handleI18nLabel(item[labelKey]);
                label = label && isString(label) ? label : value;
                if (label && value && componentName) {
                    result.push({
                        label,
                        value,
                        componentName,
                    });
                    map[value] = {
                        ...item,
                        label,
                    };
                }
            });
        }
        return { fieldList: result, fieldMap: map };
    };
    changeField = (index, key, v) => {
        const { value, onChange } = this.props;
        const newValue = [...value];
        if (newValue?.[index]) {
            const temp = {
                [key]: v,
            };
            const { name, valueType } = newValue[index];
            if (key === 'valueType') {
                temp.name = name;
            }
            if (key === 'value') {
                temp.valueType = valueType;
                temp.name = name;
            }
            newValue[index] = temp;
            onChange(newValue);
        }
    };
    addField = () => {
        const { value, onChange } = this.props;
        let newValue;
        if (Array.isArray(value)) {
            newValue = [...value, {}];
        }
        else {
            newValue = [{}];
        }
        onChange(newValue);
    };
    deleteField = (index) => {
        const { value, onChange } = this.props;
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange(newValue);
    };
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!Array.isArray(value)) {
            onChange('');
        }
    }
    render() {
        const { field, value, position } = this.props;
        const { fieldList, fieldMap } = this.getList();
        return (<div className="cn-default-value-setter">
        {Array.isArray(value) &&
                value.map((item, index) => {
                    const { name, valueType, value: fieldValue } = item;
                    const current = fieldMap[name];
                    const temp = typeMap[valueType];
                    let Component = CnInput;
                    let prop;
                    if (temp?.component) {
                        prop = { ...temp.props };
                        Component = temp.component;
                        if (current?.componentName === 'RangePicker') {
                            prop = {
                                ...prop,
                                hideTime: true,
                                componentName: current?.componentName,
                            };
                        }
                        if (valueType === 'SelectFromDataSource') {
                            prop.field = field;
                        }
                    }
                    const extraProps = {};
                    const tempDataSource = current?.options?.dataSource;
                    if (Array.isArray(tempDataSource) && tempDataSource.length > 0) {
                        extraProps.dataSource = tempDataSource.map((item2) => {
                            item2.label = handleI18nLabel(item2.label);
                            return item2;
                        });
                    }
                    const options = { ...current?.options };
                    delete options.addonAfter;
                    let comProps = {
                        ...options,
                        ...extraProps,
                        ...prop,
                    };
                    if (valueType === 'DefaultSelect') {
                        comProps = { ...prop };
                    }
                    if (Component === CnInput) {
                        comProps = {};
                    }
                    return (<div style={{ marginBottom: '10px' }}>
                <div className={'cdvs-item'}>
                  <CnAsyncSelect autoWidth={false} size={'small'} value={name} style={{ flex: '1' }} dataSource={fieldList} onChange={this.changeField.bind(this, index, 'name')}/>

                  <CnAsyncSelect autoWidth={false} size={'small'} value={valueType} style={{ width: '100px', flex: '0 0 100px' }} dataSource={getTypeList({
                            position: current?.componentName,
                            componentPosition: position,
                        })} onChange={this.changeField.bind(this, index, 'valueType')}/>
                </div>
                <div className={'cdvs-item'}>
                  <Component {...comProps} size={'small'} style={{ flex: '1 0 70px' }} onChange={this.changeField.bind(this, index, 'value')} value={fieldValue}/>

                  <CnIcon onClick={this.deleteField.bind(this, index)} style={{ marginLeft: '5px' }} type="delete" size="medium"/>
                </div>
              </div>);
                })}
        <Button size={'small'} onClick={this.addField} style={{ type: 'primary', width: '100%' }}>
          {$i18n.get({ id: 'AddAnItem', dm: '添加一项' })}
        </Button>
      </div>);
    }
}
