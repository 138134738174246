import { __assign, __extends } from "tslib";
import * as React from 'react';
/**
 * 视图层高阶组件
 *
 * @description 用于视图层组件的重新渲染
 * @param {React.Component} View 视图层组件
 * @returns {React.Component}
 */
var ViewWrapper = /** @class */ (function (_super) {
    __extends(ViewWrapper, _super);
    function ViewWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this.reRender = function () {
            _this.forceUpdate();
        };
        _this.state = {
            updateKey: Date.now(),
        };
        _this.ref = React.createRef();
        return _this;
    }
    ViewWrapper.prototype.render = function () {
        return React.cloneElement(this.props.children, __assign(__assign({}, this.props), { key: this.state.updateKey, ref: this.ref }));
    };
    return ViewWrapper;
}(React.Component));
export { ViewWrapper };
