import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { condition, handlePlaceholder, setAdvancedConfigToProps, } from '../../util/util';
import { CnRangeDatePickerPro, componentMap as formComponentMap, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import React, { forwardRef, useEffect, useState } from 'react';
import { createDisabledDateSetter, handleDisabledDate, handlePreset, requestDate, } from '@/common/manager/filter-item/datepicker';
import { getDateTypeSetterSnippet } from '@/common/manager/setter-snippet';
import { __advancedConfig__ } from '@/common/util/const';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import { getBizExtendPrototype, handleBizExtendComponentProps, } from '@/common/manager/plugin';
import isFunction from 'lodash/isFunction';
import { ADAPTER_LOCALE_SETTER, FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
function RangePickerComposite(props) {
    const { disabledDate, getRequestParams, ...rest } = props;
    const extraProps = {};
    const [disabledDateList, setDisabledDateList] = useState();
    const [enabledDateList, setEnabledDateList] = useState();
    useEffect(() => {
        requestDate(disabledDate, getRequestParams)?.then((res) => {
            if (res) {
                const { key, list } = res;
                if (key === 'disabledDate') {
                    setDisabledDateList(list);
                }
                else if (key === 'enabledDate') {
                    setEnabledDateList(list);
                }
            }
        });
    }, []);
    extraProps.disabledDate = handleDisabledDate(disabledDate, disabledDateList, enabledDateList, getRequestParams);
    return <CnRangeDatePickerPro {...rest} {...extraProps}/>;
}
const { RangePicker: { title, thumbUrl } } = FORM_ITEM_INFO;
const RangePicker = {
    position: [
        DisplayPosition.form,
        DisplayPosition.filter,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    thumbUrl,
    componentName: 'RangePicker',
    component: RangePickerComposite,
    formItemBeforeHandler: (formItem, config) => {
        const { urlParams, formValue, state } = config || {};
        if (formItem) {
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                componentProps.getRequestParams = () => {
                    return {
                        urlParamsDataSource: urlParams,
                        recordDataSource: formValue,
                        state,
                    };
                };
                handlePlaceholder(componentProps);
                handleBizExtendComponentProps(componentProps, 'CnRangeDatePickerPro');
                setAdvancedConfigToProps(componentProps);
            }
        }
    },
    filterItemBeforeHandler: (filterItemProps, config) => {
        const { componentProps, urlParamsDataSource, recordDataSource, state } = config || {};
        if (componentProps) {
            componentProps.getRequestParams = () => {
                return {
                    urlParamsDataSource,
                    recordDataSource,
                    state,
                };
            };
            handlePlaceholder(componentProps);
            setAdvancedConfigToProps(componentProps);
        }
    },
    formComponent: forwardRef((props, ref) => {
        const { disabledDate, getRequestParams, presetConfig, ...rest } = props;
        const extraProps = {};
        const [disabledDateList, setDisabledDateList] = useState([]);
        const [enabledDateList, setEnabledDateList] = useState([]);
        useEffect(() => {
            requestDate(disabledDate, getRequestParams)?.then((res) => {
                if (res) {
                    const { key, list } = res;
                    if (key === 'disabledDate') {
                        setDisabledDateList(list);
                    }
                    else if (key === 'enabledDate') {
                        setEnabledDateList(list);
                    }
                }
            });
        }, []);
        extraProps.disabledDate = handleDisabledDate(disabledDate, disabledDateList, enabledDateList, getRequestParams);
        if (presetConfig?.length > 0 || isFunction(presetConfig)) {
            extraProps.preset = handlePreset(presetConfig, getRequestParams);
        }
        return (<formComponentMap.CnRangeDatePickerPro {...rest} {...extraProps} ref={ref}/>);
    }),
    getDefaultProps: () => {
        return {
            mode: 'date',
            showTime: true,
        };
    },
    getFormItemDefaultProps: () => {
        return {
            span: 2,
        };
    },
    // select的prototype列表
    getPrototypeList: () => {
        return [
            ADAPTER_LOCALE_SETTER,
            getDateTypeSetterSnippet({
                name: 'mode',
            }),
            {
                name: 'showTime',
                title: $i18n.get({ id: 'ShowTime', dm: '显示时间' }),
                display: 'inline',
                setter: 'BoolSetter',
                // supportVariable: true,
                // hidden:hidden('RangePicker'),
                condition(prop) {
                    return condition(prop, 'RangePicker', 'componentName');
                },
            },
            {
                name: 'outputFormat',
                title: $i18n.get({ id: 'OutputFormat', dm: '输出格式' }),
                display: 'inline',
                condition(prop) {
                    return condition(prop, 'RangePicker', 'componentName');
                },
                setter: {
                    componentName: 'SelectSetter',
                    props: {
                        options: [
                            {
                                label: $i18n.get({ id: 'Timestamp', dm: '时间戳' }),
                                value: '',
                            },
                            {
                                label: 'YYYY-MM-DD',
                                value: 'YYYY-MM-DD',
                            },
                            {
                                label: 'YYYY-MM-DD HH',
                                value: 'YYYY-MM-DD HH',
                            },
                            {
                                label: 'YYYY-MM-DD HH:mm',
                                value: 'YYYY-MM-DD HH:mm',
                            },
                            {
                                label: 'YYYY-MM-DD HH:mm:ss',
                                value: 'YYYY-MM-DD HH:mm:ss',
                            },
                        ],
                    },
                },
            },
            {
                name: 'disabledDate',
                title: $i18n.get({ id: 'DateDisabled', dm: '禁用日期' }),
                display: 'inline',
                condition(prop) {
                    return condition(prop, 'RangePicker', 'componentName');
                },
                setter: createDisabledDateSetter(),
            },
            {
                name: 'presetConfig',
                title: $i18n.get({ id: 'QuickOperation', dm: '快捷操作' }),
                display: 'inline',
                setter: {
                    componentName: 'MixedSetter',
                    props: {
                        setters: [
                            {
                                title: $i18n.get({ id: 'SelectShortcut', dm: '选择快捷' }),
                                componentName: 'CnSelectSetter',
                                props: {
                                    mode: 'multiple',
                                    options: [
                                        {
                                            label: $i18n.get({ id: 'AtTheMoment', dm: '此刻' }),
                                            value: 'nowTimeRanger',
                                        },
                                        {
                                            label: $i18n.get({ id: 'Yesterday', dm: '昨日' }),
                                            value: 'yesterdayRanger',
                                        },
                                        {
                                            label: $i18n.get({ id: 'ThisWeek', dm: '本周' }),
                                            value: 'nowWeekRanger',
                                        },
                                        {
                                            label: $i18n.get({ id: 'ThisMonth', dm: '本月' }),
                                            value: 'nowMonthRanger',
                                        },
                                    ],
                                },
                            },
                            getJSExpressionPrototype({ type: 'datepickerPreset' }),
                        ],
                    },
                },
            },
            ...getBizExtendPrototype({
                componentName: 'CnRangeDatePickerPro',
            }),
            {
                title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'resetTime',
                                    title: $i18n.get({
                                        id: 'TimeDefaultsTo000000',
                                        dm: '时间默认为00:00:00',
                                    }),
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
export default RangePicker;
