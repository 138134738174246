import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { calculateTextExprValue, condition, setAdvancedConfigToProps, } from '../../util/util';
import { CnEmployeeSelect, CnTooltip } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import React from 'react';
import { __advancedConfig__ } from '@/common/util/const';
import { getMixedSetterSnippet } from '@/common/manager/setter-snippet';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { EmployeeSelect: { title, thumbUrl } } = FORM_ITEM_INFO;
const useTooltip = (<CnTooltip align={'t'} trigger={<div>
        {$i18n.get({ id: 'AllInformationOfPersonnel', dm: '人员全部信息' })}
      </div>}>
    <div style={{ width: '150px' }}>
      {$i18n.get({ id: 'Example', dm: '示例：' })}

      <div>{'aliWorkNo: "xxx"'}</div>
      <div>{'buMail: "xxx@cainiao.com"'}</div>
      <div>
        {$i18n.get({
        id: 'DepartmentCainiaoGroupCainiaoCai_1801735598',
        dm: 'department: "菜鸟集团-菜鸟-菜鸟-xxx部门"',
    })}
      </div>
      <div>{'dingding: "xxx"'}</div>
      <div>{$i18n.get({ id: 'NameRealName', dm: 'name: "真名"' })}</div>
      <div>
        {$i18n.get({ id: 'NickNameFlowerName', dm: 'nickName: "花名"' })}
      </div>
      <div>{$i18n.get({ id: 'WorkNoWorkNo', dm: 'workNo: "工号"' })}</div>
      <div>workStatus: true</div>
    </div>
  </CnTooltip>);
const EmployeeSelect = {
    position: [
        DisplayPosition.form,
        DisplayPosition.filter,
        DisplayPosition.formDialog,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'EmployeeSelect',
    component: CnEmployeeSelect,
    formComponent: CnEmployeeSelect,
    getDefaultProps: () => {
        return {
            mode: 'single',
            serviceType: 'cainiao',
            __advancedConfig__: {
                valuePaddingZero: true,
                isOnJob: true,
            },
            // dataSource: [],
        };
    },
    // select的prototype列表
    getPrototypeList: () => {
        return [
            // {
            //   title: '员工选择器配置',
            //   type: 'group',
            //   display: 'accordion',
            //   collapsed: true,
            //   // hidden: hidden('EmployeeSelect'),
            //   condition(prop){
            //     return condition(prop,'EmployeeSelect','componentName')
            //   },
            // },
            {
                name: 'mode',
                title: $i18n.get({ id: 'SelectMode', dm: '选择模式' }),
                display: 'inline',
                // supportVariable: true,
                // hidden: hidden('EmployeeSelect'),
                condition(prop) {
                    return condition(prop, 'EmployeeSelect', 'componentName');
                },
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'SingleSelection', dm: '单选' }),
                                value: 'single',
                            },
                            {
                                title: $i18n.get({ id: 'MultipleChoice', dm: '多选' }),
                                value: 'multiple',
                            },
                        ],
                    },
                },
            },
            {
                name: 'serviceType',
                display: 'inline',
                title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
                // hidden: hidden('EmployeeSelect'),
                condition(prop) {
                    return condition(prop, 'EmployeeSelect', 'componentName');
                },
                setter: getMixedSetterSnippet({
                    setters: [
                        {
                            title: $i18n.get({ id: 'SelectDataSource', dm: '选择数据来源' }),
                            componentName: 'RadioGroupSetter',
                            props: {
                                options: [
                                    {
                                        title: $i18n.get({ id: 'TERM.CAINIAO', dm: '菜鸟' }),
                                        value: 'cainiao',
                                    },
                                    {
                                        title: $i18n.get({ id: 'Group', dm: '集团' }),
                                        value: 'ali',
                                    },
                                ],
                            },
                        },
                        getJSExpressionPrototype({
                            type: 'formRequest',
                        }),
                    ],
                }),
            },
            {
                name: 'useDetailValue',
                display: 'inline',
                title: $i18n.get({
                    id: 'DataFormatOfTheReturnedResult',
                    dm: '返回结果的数据格式',
                }),
                defaultValue: false,
                condition(prop) {
                    return condition(prop, 'EmployeeSelect', 'componentName');
                },
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: (<CnTooltip align={'t'} trigger={<div>{$i18n.get({ id: 'WorkNo', dm: '工号' })}</div>}>
                    {$i18n.get({ id: 'Example12345', dm: '示例：12345' })}
                  </CnTooltip>),
                                value: false,
                            },
                            {
                                title: useTooltip,
                                value: true,
                            },
                        ],
                    },
                },
            },
            {
                name: 'mokelay',
                display: 'inline',
                title: $i18n.get({ id: 'WhetherCentralized', dm: '是否中心化' }),
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'Yes', dm: '是' }),
                                value: true,
                            },
                            {
                                title: $i18n.get({ id: 'No', dm: '否' }),
                                value: false,
                            },
                            {
                                title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }),
                            },
                        ],
                    },
                },
            },
            {
                title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'valuePaddingZero',
                                    title: $i18n.get({
                                        id: 'WhetherTheJobNumberIsFilledWith0',
                                        dm: '工号是否补0',
                                    }),
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                                {
                                    title: $i18n.get({
                                        id: 'EmployeeAttributesDefaultOwn',
                                        dm: '员工属性（默认自有）',
                                    }),
                                    name: 'typeList',
                                    display: 'inline',
                                    setter: {
                                        componentName: 'CnSelectSetter',
                                        props: {
                                            options: [
                                                {
                                                    label: $i18n.get({ id: 'Own', dm: '自有' }),
                                                    value: 'REGULAR',
                                                },
                                                {
                                                    label: $i18n.get({ id: 'Outsourcing', dm: '外包' }),
                                                    value: 'OUTSOURCING',
                                                },
                                                {
                                                    label: $i18n.get({
                                                        id: 'TemporaryWorkers',
                                                        dm: '临时工',
                                                    }),
                                                    value: 'CASUAL',
                                                },
                                                {
                                                    label: $i18n.get({
                                                        id: 'ExternalCooperation',
                                                        dm: '外部合作',
                                                    }),
                                                    value: 'PARTNER',
                                                },
                                            ],
                                            selectProps: {
                                                hasClear: true,
                                                // 开启多选
                                                mode: 'multiple',
                                            },
                                        },
                                    },
                                },
                                {
                                    name: 'isOnJob',
                                    title: $i18n.get({
                                        id: 'IncumbentIncumbentByDefault',
                                        dm: '是否在职（默认在职）',
                                    }),
                                    display: 'inline',
                                    setter: {
                                        componentName: 'RadioGroupSetter',
                                        props: {
                                            options: [
                                                {
                                                    title: $i18n.get({ id: 'Onthejob', dm: '在职' }),
                                                    value: true,
                                                },
                                                {
                                                    title: $i18n.get({ id: 'Resignation', dm: '离职' }),
                                                    value: false,
                                                },
                                                {
                                                    title: $i18n.get({ id: 'All', dm: '全部' }),
                                                    value: 'all',
                                                },
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
CnEmployeeSelect.filterBeforeHandler = (props, config) => {
    const newProps = {
        ...props,
    };
    const { serviceType } = props || {};
    const { recordDataSource, state } = config || {};
    if (newProps.dataSource === undefined) {
        delete newProps.dataSource;
    }
    newProps.serviceType = calculateTextExprValue(serviceType, {
        recordDataSource,
        state,
    });
    if (newProps.serviceType !== 'ali') {
        newProps.serviceType = 'cainiao';
    }
    setAdvancedConfigToProps(newProps);
    return newProps;
};
CnEmployeeSelect.formBeforeHandler = CnEmployeeSelect.filterBeforeHandler;
export default EmployeeSelect;
