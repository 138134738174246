import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { condition, handleSelectDataSource, handleSelectRequestConfig, setAdvancedConfigToProps, } from '../../util/util';
import { CnCascaderSelect, CnTooltip, componentMap as formComponentMap, formilyReact, } from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import { getJSExpressionPrototype } from '../common-style';
import { getSelectParamSelectSetter } from '@/common/manager/filter-item/select';
import { handleRequestParams } from '@/common/util/request';
import isPlainObject from 'lodash/isPlainObject';
import { getExprSetterSnippet, getRequestExecuteSetter, getRequestWhenFocusSetterSnippet, getStaticDataSourceSnippet, } from '@/common/manager/setter-snippet';
import { useRef } from 'react';
import { __advancedConfig__, formComponentRefStorage, } from '@/common/util/const';
import { componentRefSplit } from '@/common/util/expr-const';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { useField } = formilyReact;
const { CascaderSelect: { title, thumbUrl } } = FORM_ITEM_INFO;
const CascaderSelect = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.filter,
        DisplayPosition.cnArrayTable,
        DisplayPosition.cnArraySubAreaCard,
    ],
    thumbUrl,
    title,
    componentName: 'CascaderSelect',
    component: CnCascaderSelect,
    formComponent: (props) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const realRef = useRef(null);
        const { _dataSourceName } = props || {};
        const params = props?.requestConfig?.params;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const field = useField?.();
        if (Array.isArray(params) && params?.length > 0) {
            let _params = {};
            const { getRequestParams } = props;
            if (typeof getRequestParams === 'function') {
                _params = getRequestParams();
            }
            if (typeof field?.index === 'number') {
                const temp = field.query('..').value();
                const currentRow = temp?.[field.index];
                if (isPlainObject(currentRow)) {
                    _params.tableCurrentRow = currentRow;
                }
            }
            const realParams = handleRequestParams(params, { ..._params });
            if (realParams) {
                if (props?.requestConfig?.method === 'post') {
                    props.requestConfig.data = realParams;
                    delete props.requestConfig.params;
                }
                else {
                    props.requestConfig.params = realParams;
                }
            }
            else {
                delete props.requestConfig.params;
            }
        }
        const extraProps = {
            getRequestParams: undefined,
        };
        const fieldEntire = field?.path?.entire;
        if (_dataSourceName && fieldEntire && realRef) {
            formComponentRefStorage[`${_dataSourceName}${componentRefSplit}${fieldEntire}`] = realRef;
        }
        return (<formComponentMap.CnCascaderSelect {...props} {...extraProps} ref={realRef}/>);
    },
    getDefaultProps: () => {
        return {
            dataOrigin: 'request',
            multiple: false,
        };
    },
    formItemBeforeHandler: (formItem, config) => {
        const { isDesign, urlParams, formValue, state, formProps } = config;
        handleSelectRequestConfig({
            componentProps: formItem?.['x-component-props'],
            isDesign,
            urlParamsDataSource: urlParams,
            recordDataSource: formValue,
            state,
            ignoreHandleParam: true,
        });
        if (formItem?.['x-component-props']) {
            formItem['x-component-props'].getRequestParams = () => {
                return {
                    urlParamsDataSource: urlParams,
                    recordDataSource: formValue,
                    state,
                };
            };
            formItem['x-component-props']._dataSourceName =
                formProps?._dataSourceName;
        }
        handleSelectDataSource({
            componentProps: formItem?.['x-component-props'],
            state,
        });
        setAdvancedConfigToProps(formItem?.['x-component-props']);
    },
    filterItemBeforeHandler: (filterItemProps, config) => {
        handleSelectRequestConfig(config);
        handleSelectDataSource(config);
    },
    // select的prototype列表
    getPrototypeList: (position) => {
        const paramSelectSetter = getSelectParamSelectSetter({ position });
        let extraConfigSetter;
        const requestExecuteSetter = getRequestExecuteSetter({
            exprSetter: getExprSetterSnippet({
                position,
                ignoreArrayTableCurrentRow: true,
            }),
        });
        if (requestExecuteSetter) {
            extraConfigSetter = [requestExecuteSetter];
        }
        return [
            {
                name: 'multiple',
                title: $i18n.get({ id: 'SelectMode', dm: '选择模式' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'SingleSelection', dm: '单选' }),
                                value: false,
                            },
                            {
                                title: $i18n.get({ id: 'MultipleChoice', dm: '多选' }),
                                value: true,
                            },
                        ],
                    },
                },
            },
            {
                name: 'dataOrigin',
                title: $i18n.get({ id: 'DataSource', dm: '数据来源' }),
                display: 'inline',
                setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                        options: [
                            {
                                title: $i18n.get({ id: 'RemoteRequest', dm: '远程请求' }),
                                value: 'request',
                            },
                            {
                                title: $i18n.get({ id: 'StaticData', dm: '静态数据' }),
                                value: 'static',
                            },
                        ],
                    },
                },
            },
            {
                name: 'requestConfig',
                title: $i18n.get({ id: 'QueryService', dm: '查询服务' }),
                display: 'inline',
                setter: {
                    componentName: 'ServiceChoiceSetter',
                    props: {
                        mockDataTemplate: {
                            success: true,
                            data: [
                                {
                                    label: $i18n.get({ id: 'OptionOne', dm: '选项一' }),
                                    value: 'first',
                                    children: [
                                        {
                                            label: $i18n.get({ id: 'SubOptionOne', dm: '子选项一' }),
                                            value: 'sub1',
                                        },
                                    ],
                                },
                                {
                                    label: $i18n.get({ id: 'Option2.NBzVe', dm: '选项二' }),
                                    value: 'second',
                                },
                            ],
                        },
                        paramTitleDom: (<div className="">
                {$i18n.get({
                                id: 'RequestParameterConfiguration',
                                dm: '请求参数配置：',
                            })}
              </div>),
                        responseDom: (<div style={{ paddingTop: '10px' }}>
                {$i18n.get({
                                id: 'DataStructureOfTheRequestReturnR_137379149',
                                dm: '请求返回结果的数据结构：',
                            })}
                <a target={'_blank'} href="https://alidocs.dingtalk.com/i/nodes/3mzaPNMZ6jkJq3dwkOLLWYLDwXq4Ky1r" rel="noreferrer">
                  {$i18n.get({ id: 'InterfaceDocumentation', dm: '接口文档' })}
                </a>{' '}
                <CnTooltip v2 align={'t'} trigger={<a>
                      {$i18n.get({ id: 'InterfacePreview', dm: '接口预览' })}
                    </a>}>
                  <div style={{ width: '200px', height: '260px' }}>
                    <img style={{ width: '100%' }} src="https://img.alicdn.com/imgextra/i1/O1CN01W5SNFR25Fg4QyRC53_!!6000000007497-0-tps-456-570.jpg"/>
                  </div>
                </CnTooltip>
              </div>),
                        buttonText: $i18n.get({
                            id: 'SelectRequestAPI',
                            dm: '选择请求API',
                        }),
                        params: {
                            env: 'pre',
                            pageSize: 999,
                            // serviceType: 'HSF',
                        },
                        extraConfigSetter,
                        paramSetter: {
                            componentName: 'MixedSetter',
                            props: {
                                setters: [
                                    paramSelectSetter,
                                    {
                                        componentName: 'StringSetter',
                                        title: $i18n.get({ id: 'String', dm: '字符串' }),
                                    },
                                    getJSExpressionPrototype({ type: 'formRequest' }),
                                ],
                            },
                        },
                        resultProcessFuncTemplate: $i18n.get({
                            id: 'FunctionresTheFollowingDataStruc_958986499',
                            dm: 'function(res) {\n  // 需要返回的如下的数据结构\n  // return {\n  //   success: true,\n  //   data: [\n  //     {\n  //        label:"xx",\n  //        value:"xx",\n  //     }\n  //   ]\n  // }\n return res;\n}',
                        }),
                        // paramSetter:{
                        //   componentName: 'ParamSelectSetter',
                        //   props:{
                        //     dataKey: 'config',
                        //     labelKey: 'label',
                        //     valueKey: 'name',
                        //     groupName: '参数列表',
                        //   },
                        //   title:'选择参数',
                        // },
                    },
                },
                // hidden:hidden('Select'),
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'request');
                },
            },
            {
                name: 'dataSource',
                title: $i18n.get({ id: 'EditStaticData', dm: '编辑静态数据' }),
                display: 'plain',
                tip: $i18n.get({
                    id: 'EditStaticPresentationData',
                    dm: '编辑静态展示数据',
                }),
                // initialValue: [],
                setter: getStaticDataSourceSnippet(),
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('dataOrigin') === 'static');
                },
            },
            getRequestWhenFocusSetterSnippet(),
            {
                name: 'changeOnSelect',
                title: $i18n.get({
                    id: 'AllowParentNodesToBeSelected',
                    dm: '允许选中父节点',
                }),
                display: 'inline',
                setter: 'BoolSetter',
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('multiple') === false);
                },
            },
            {
                name: 'searchRemote',
                title: $i18n.get({
                    id: 'ResendRequestWhenNewInput',
                    dm: '当新输入时 重新发请求',
                }),
                display: 'inline',
                tip: $i18n.get({
                    id: 'WhenTurnedOnEachInputWillResendT_979364164',
                    dm: '开启时，每次输入都会重新发请求获取数据',
                }),
                setter: {
                    componentName: 'BoolSetter',
                    props: {},
                },
            },
            {
                name: 'searchKey',
                title: $i18n.get({ id: 'KeyForRemoteSearch', dm: '远程搜索时的key' }),
                display: 'inline',
                setter: {
                    componentName: 'StringSetter',
                    props: {},
                },
                condition(prop) {
                    return (condition(prop, 'CascaderSelect', 'componentName') &&
                        prop?.parent?.getPropValue?.('searchRemote') === true);
                },
            },
            {
                title: $i18n.get({
                    id: 'ConfigurationForAsynchronouslyLo_1858031496',
                    dm: '异步加载数据的配置',
                }),
                name: 'extraProps',
                display: 'accordion',
                collapsed: true,
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'enableRemoteLazyLoad',
                                    title: $i18n.get({
                                        id: 'TurnOnAsynchronousLoading',
                                        dm: '开启异步加载',
                                    }),
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                    // condition(prop) {
                                    //   return condition(prop, 'Select', 'componentName') && prop?.parent?.getPropValue?.('mode') === 'multiple';
                                    // },
                                },
                                {
                                    name: 'remoteLazyLoadKey',
                                    title: $i18n.get({
                                        id: 'TheKeyWhenTheDataIsRequestedAsyn_8485484',
                                        dm: '异步请求数据时的key',
                                    }),
                                    display: 'inline',
                                    setter: {
                                        componentName: 'StringSetter',
                                        props: {},
                                    },
                                    defaultValue: 'key',
                                },
                            ],
                        },
                    },
                },
            },
            {
                title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
                name: __advancedConfig__,
                display: 'accordion',
                extraProps: {
                    defaultCollapsed: true,
                },
                setter: {
                    componentName: 'ObjectSetter',
                    props: {
                        config: {
                            items: [
                                {
                                    name: 'canOnlyCheckLeaf',
                                    title: $i18n.get({
                                        id: 'OnlyLeavesAllowedToBeChecked',
                                        dm: '只允许勾选叶子项',
                                    }),
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                                {
                                    name: 'showSearch',
                                    title: $i18n.get({ id: 'AllowSearch', dm: '允许搜索' }),
                                    display: 'inline',
                                    setter: 'BoolSetter',
                                },
                            ],
                        },
                    },
                },
            },
        ];
    },
};
export default CascaderSelect;
