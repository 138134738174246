/* -- cone add component import 初始化 start，勿删 --*/
import CnFilter from './cn-filter-alc';
import CnTable from './cn-table-alc';
import CnPage from './cn-page-alc';
import CnTab from './cn-tab-alc';
import CnForm from './cn-form-alc';
import CnDialog from './cn-dialog-alc';
import CnFormDialog from './cn-form-dialog-alc';
import CnMessage from './cn-message-alc';
import Page from './page';
import CnCard from './cn-card-alc';
import CnCardSubCard from './cn-card-sub-card-alc';
import CnStep from './cn-step-alc';
import CnLeftTree from './cn-left-tree-alc';
import CnResult from './cn-result-alc';
import CnList from './cn-list-alc';
import CnEntryPoints from './cn-entry-points-alc';
import CnSlider from './cn-slider-alc';
import CnDetailInfo from './cn-detail-info-alc';
import CnFormat from './cn-format-alc';
/* -- cone add component import 初始化 end，勿删 --*/
import CnLowcodePage from '@alife/cn-lowcode-page';
import CnLayoutBundle from '@cainiaofe/cn-ui-layout/es/lowcode/index';
import CnListPro from './cn-list-pro-alc';
import CnActionBar from './cn-action-bar-alc';
// 通过arr控制输出的顺序
const bundle = [
    /* -- cone add component bundle 初始化 start，勿删 --*/
    CnFilter,
    CnTable,
    CnForm,
    CnFormDialog,
    CnPage,
    CnTab,
    CnDialog,
    CnMessage,
    Page,
    CnCard,
    CnCardSubCard,
    CnStep,
    CnLeftTree,
    CnResult,
    CnList,
    CnEntryPoints,
    CnSlider,
    CnDetailInfo,
    CnFormat,
    /* -- cone add component bundle 初始化 end，勿删 --*/
    ...CnLayoutBundle,
    CnLowcodePage,
    CnListPro,
    CnActionBar,
];
export default bundle;
