import $i18n from '@/locales/i18n';
import { ValidatorPosition } from './position/validator-position';
// eslint-disable-next-line import/no-cycle
import { executeFunction, getItemDefaultProps, getItemListByPosition, getItemPrototypeListByPosition, } from '../util/util';
import { getJSExpressionPrototype } from './common-style';
import React from 'react';
import ExprSetter from '@/common/setter/expr-setter';
import { __dataSource__, getFormExprNameByPosition, symbolMap, timeMillisecondMap, timeUnitList, timeUnitList2, timeUnitMap, } from '@/common/util/expr-const';
import dayjs from 'dayjs';
const { ActionSetter } = window.VisualEngineUtils || {};
export const compareList = [
    {
        label: $i18n.get({ id: 'CannotExceed', dm: '不能超过' }),
        value: '<=',
    },
    {
        label: $i18n.get({ id: 'CannotBeLess', dm: '不能小于' }),
        value: '>=',
    },
];
const compareMap = {};
compareList.forEach((item) => {
    const { label, value } = item || {};
    compareMap[value] = label;
});
const required = {
    title: $i18n.get({ id: 'Required', dm: '必填' }),
    value: 'required',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            required: true,
        };
    },
};
const phone = {
    title: $i18n.get({ id: 'TERM.MobilePhoneNumber', dm: '手机号' }),
    value: 'phone',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'phone',
        };
    },
};
const email = {
    title: $i18n.get({ id: 'Mailbox', dm: '邮箱' }),
    value: 'email',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'email',
        };
    },
};
const number = {
    title: $i18n.get({ id: 'NumbersIncludingDecimals', dm: '数字(包含小数)' }),
    value: 'number',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'number',
        };
    },
};
const integer = {
    title: $i18n.get({ id: 'Integer', dm: '整数' }),
    value: 'integer',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'integer',
        };
    },
};
const idcard = {
    title: $i18n.get({ id: 'IdentityCard', dm: '身份证' }),
    value: 'idcard',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'idcard',
        };
    },
};
const zh = {
    title: $i18n.get({ id: 'Chinese', dm: '中文' }),
    value: 'zh',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'zh',
        };
    },
};
const pattern = {
    title: $i18n.get({ id: 'RegularExpressions', dm: '正则表达式' }),
    value: 'pattern',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    configure: [
        {
            name: 'pattern',
            title: $i18n.get({ id: 'RegularExpressions', dm: '正则表达式' }),
            display: 'inline',
            setter: 'StringSetter',
        },
    ],
};
const maxItems = {
    title: $i18n.get({
        id: 'MaximumNumberOfEntriesApplicable_1512206866',
        dm: '最大条目数（可编辑表格适用）',
    }),
    value: 'maxItems',
    position: [
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    configure: [
        {
            name: 'maxItems',
            title: $i18n.get({ id: 'MaximumNumberOfEntries', dm: '最大条目数' }),
            display: 'inline',
            setter: 'NumberSetter',
        },
    ],
};
const minItems = {
    title: $i18n.get({
        id: 'MinimumNumberOfEntriesApplicable_964053888',
        dm: '最小条目数（可编辑表格适用）',
    }),
    value: 'minItems',
    position: [
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    configure: [
        {
            name: 'minItems',
            title: $i18n.get({ id: 'MinimumNumberOfEntries', dm: '最小条目数' }),
            display: 'inline',
            setter: 'NumberSetter',
        },
    ],
};
/* i18n-disable-next-line */
const checkUploadIsComplete = {
    title: $i18n.get({
        id: 'VerifyThatTheUploadIsCompleteOnl_18559238',
        dm: '校验上传是否完成(上传组件专用)',
    }),
    value: 'checkUploadIsComplete',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
        ValidatorPosition.cnArrayTable,
        ValidatorPosition.cnArraySubAreaCard,
    ],
    getDefaultProps: () => {
        return {
            format: 'checkUploadIsComplete',
        };
    },
};
const custom = (staticConfig) => {
    const { position: staticPosition } = staticConfig || {};
    const result = {
        title: $i18n.get({ id: 'CustomValidation', dm: '自定义校验' }),
        value: 'custom',
        position: [
            ValidatorPosition.filter,
            ValidatorPosition.form,
            ValidatorPosition.formDialog,
            ValidatorPosition.cnArrayTable,
            ValidatorPosition.cnArraySubAreaCard,
        ],
        getDefaultProps: () => {
            return {};
        },
        getPrototypeList: (position) => {
            let defaultCode = $i18n.get({
                id: 'ValidatorPleaseReferTo',
                dm: '/**\n* validator\n* 用法请参考formily\n*\n* @param value 当前值\n* @param rules 当前校验规则\n* @param context 当前上下文\n*/\nfunction validator(value, rules, context, state) {\n  // // message: 用户配置的错误提示信息\n  // const { message } = rules;\n  // //field: 当前字段；  form: 当前表单实例\n  // const { field, form } = context;\n  // // 查询其他字段的值\n  // const otherFieldValue = field.query(\'name\').value()\n  // //如果return了值，那就表示校验不通过；如果没有return值，表示校验通过\n  // return message || "校验不通过";\n}',
            });
            if ([
                ValidatorPosition.cnArrayTable,
                ValidatorPosition.cnArraySubAreaCard,
            ].includes(position)) {
                /* i18n-disable-next-line */
                defaultCode = `/**
* validator
* 用法请参考formily
*
* @param value 当前值
* @param rules 当前校验规则
* @param context 当前上下文
*/
function validator(value, rules, context) {
  // // message: 用户配置的错误提示信息
  // const { message } = rules;
  // // field: 当前字段；  form: 当前表单实例
  // const { field, form } = context;
  // // 当前行的index
  // const index = field?.path?.segments?.[1];
  // // 查询当前行其他字段的值，xxx代表可编辑表格的字段编码，yyy代表表格某列的字段编码
  // const otherFieldValue = field.query(\`xxx.$\{index}.yyy\`).value()
  // // 查询其他字段的值
  // const otherFieldValue = field.query('name').value()
  // //如果return了值，那就表示校验不通过；如果没有return值，表示校验通过
  // return message || "校验不通过";
}`;
            }
            if ([ValidatorPosition.filter].includes(position)) {
                defaultCode = $i18n.get({
                    id: 'ValidatorParam',
                    dm: "/**\n* validator\n*\n* @param rule 校验规则\n* @param value 当前输入框的值\n* @param callback 校验不通过时调用\n* @param filterValue 当前筛选栏的所有数据\n* @param state 当前页面的全部数据\n*/\nfunction validator(rule, value, callback, filterValue, state) {\n  // if(value !== 'xxx') {\n  //   callback('校验不通过的错误信息')\n  // }\n  // // 校验通过时调用\n  // callback()\n}",
                });
            }
            return [
                {
                    name: 'validator',
                    title: $i18n.get({ id: 'CustomValidation', dm: '自定义校验' }),
                    display: 'inline',
                    setter: (<ActionSetter supportTypes={['page']} defaultCode={defaultCode} defaultActionName="validator"/>),
                    // supportVariable: true,
                },
            ];
        },
    };
    if (ValidatorPosition.filter === staticPosition) {
        result.title = $i18n.get({ id: 'Abandoned', dm: '【已废弃】' });
    }
    return result;
};
const filterCustom = {
    title: $i18n.get({ id: 'CustomValidation', dm: '自定义校验' }),
    value: 'filterCustom',
    position: [ValidatorPosition.filter],
    getDefaultProps: () => {
        return {};
    },
    getPrototypeList: () => {
        const defaultCode = $i18n.get({
            id: 'ValidatorPleaseReferTo',
            dm: '/**\n* validator\n* 用法请参考formily\n*\n* @param value 当前值\n* @param rules 当前校验规则\n* @param context 当前上下文\n*/\nfunction validator(value, rules, context, state) {\n  // // message: 用户配置的错误提示信息\n  // const { message } = rules;\n  // //field: 当前字段；  form: 当前表单实例\n  // const { field, form } = context;\n  // // 查询其他字段的值\n  // const otherFieldValue = field.query(\'name\').value()\n  // //如果return了值，那就表示校验不通过；如果没有return值，表示校验通过\n  // return message || "校验不通过";\n}',
        });
        return [
            {
                name: 'validator',
                title: $i18n.get({
                    id: 'AdvancedCustomValidation',
                    dm: '高级自定义校验',
                }),
                display: 'inline',
                setter: (<ActionSetter supportTypes={['page']} defaultCode={defaultCode} defaultActionName="validator"/>),
                // supportVariable: true,
            },
        ];
    },
};
const dateInterval = {
    title: $i18n.get({
        id: 'StartAndEndTimeIntervalVerificat_1553491695',
        dm: '起止时间间隔校验',
    }),
    value: 'dateInterval',
    position: [
        ValidatorPosition.filter,
        ValidatorPosition.form,
        ValidatorPosition.formDialog,
    ],
    showInFilterComponentList: ['RangePicker'],
    configure: [
        {
            name: 'compare',
            title: $i18n.get({ id: 'StartstopInterval', dm: '起止间隔' }),
            display: 'inline',
            setter: {
                componentName: 'SelectSetter',
                props: {
                    options: compareList,
                },
            },
        },
        {
            name: 'num',
            title: $i18n.get({ id: 'Quantity', dm: '数量' }),
            display: 'inline',
            setter: 'NumberSetter',
        },
        {
            name: 'unit',
            title: $i18n.get({ id: 'Unit', dm: '单位' }),
            display: 'inline',
            setter: {
                componentName: 'SelectSetter',
                props: {
                    options: [...timeUnitList2, ...timeUnitList],
                },
            },
        },
    ],
    action: (config) => {
        const { position, validateItemConfig } = config || {};
        const { name, message, options } = validateItemConfig || {};
        const { compare, num, unit } = options || {};
        if ([ValidatorPosition.filter].includes(position)) {
            if (name && unit && compare && typeof num === 'number') {
                return {
                    validator: (rule, value, cb) => {
                        if (Array.isArray(value)) {
                            const start = value[0];
                            const end = value[1];
                            if (start && end) {
                                const isDay = timeUnitList.find((item) => item.value === unit);
                                const func = symbolMap[compare];
                                if (isDay) {
                                    const startDay = dayjs(start).startOf(unit);
                                    const endDay = dayjs(end).startOf(unit);
                                    const diff = endDay.diff(startDay, unit);
                                    if (typeof func === 'function') {
                                        const result = func(diff, num);
                                        if (typeof result === 'boolean') {
                                            if (result !== true) {
                                                cb(message ||
                                                    `${$i18n.get({ id: 'Interval_Time', dm: '间隔时间' })} ${compareMap[compare] || compare} ${num} ${timeUnitMap[unit] || unit}`);
                                                return;
                                            }
                                        }
                                    }
                                }
                                else {
                                    const startTime = dayjs(start).valueOf();
                                    const endTime = dayjs(end).valueOf();
                                    if (typeof func === 'function') {
                                        const diff = endTime - startTime;
                                        const result = func(diff, num * timeMillisecondMap[unit]);
                                        if (typeof result === 'boolean') {
                                            if (result !== true) {
                                                cb(message ||
                                                    `${$i18n.get({ id: 'Interval_Time', dm: '间隔时间' })} ${compareMap[compare] || compare} ${num} ${timeUnitMap[unit] || unit}`);
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        cb();
                    },
                };
            }
        }
        else if ([ValidatorPosition.form, ValidatorPosition.formDialog].includes(position)) {
            if (name && unit && compare && typeof num === 'number') {
                return {
                    validator: (value) => {
                        if (Array.isArray(value)) {
                            const start = value[0];
                            const end = value[1];
                            if (start && end) {
                                const isDay = timeUnitList.find((item) => item.value === unit);
                                const func = symbolMap[compare];
                                if (isDay) {
                                    const startDay = dayjs(start).startOf(unit);
                                    const endDay = dayjs(end).startOf(unit);
                                    const diff = endDay.diff(startDay, unit);
                                    if (typeof func === 'function') {
                                        const result = func(diff, num);
                                        if (typeof result === 'boolean') {
                                            if (result !== true) {
                                                return (message ||
                                                    `${$i18n.get({ id: 'Interval_Time', dm: '间隔时间' })} ${compareMap[compare] || compare} ${num} ${timeUnitMap[unit] || unit}`);
                                            }
                                        }
                                    }
                                }
                                else {
                                    const startTime = dayjs(start).valueOf();
                                    const endTime = dayjs(end).valueOf();
                                    if (typeof func === 'function') {
                                        const diff = endTime - startTime;
                                        const result = func(diff, num * timeMillisecondMap[unit]);
                                        if (typeof result === 'boolean') {
                                            if (result !== true) {
                                                return (message ||
                                                    `${$i18n.get({ id: 'Interval_Time', dm: '间隔时间' })} ${compareMap[compare] || compare} ${num} ${timeUnitMap[unit] || unit}`);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                };
            }
        }
    },
};
export const componentMap = {
    required,
    phone,
    email,
    idcard,
    integer,
    number,
    zh,
    pattern,
    maxItems,
    minItems,
    custom,
    filterCustom,
    dateInterval,
    checkUploadIsComplete,
};
// 注册校验方法
export function registerValidator(plugin) {
    const { name } = plugin || {};
    if (name) {
        componentMap[name] = plugin;
    }
}
// 获取列表
export function getValidatorListByPosition(position) {
    const defaultValidatorList = Object.keys(componentMap);
    const result = [];
    if (position !== undefined) {
        defaultValidatorList.forEach((name) => {
            let item = componentMap[name];
            if (typeof item === 'function') {
                item = executeFunction(item, position);
            }
            if (item && item.position && item.position.includes(position)) {
                result.push(item);
            }
        });
    }
    return result;
}
export function createValidatorSetters(config = {}) {
    const { position } = config;
    const prototypeList = getItemPrototypeListByPosition({
        position,
        primaryKey: 'name',
        componentMap,
    });
    const configure = [];
    if (prototypeList?.length > 0) {
        configure.push({
            name: 'options',
            display: 'plain',
            title: $i18n.get({
                id: 'PleasePerfectTheConfigurationOfV_366992574',
                dm: '请完善校验的配置',
            }),
            setter: {
                componentName: 'ObjectSetter',
                props: {
                    config: {
                        items: [...prototypeList],
                    },
                },
            },
        });
    }
    else {
        return [];
    }
    return [
        {
            name: 'rules',
            title: $i18n.get({
                id: 'ConfigurationFieldVerificationFo_739883692',
                dm: '配置字段的校验格式',
            }),
            display: 'accordion',
            collapsed: false,
            // extraProps:{
            //   collapsed:false,
            // },
            // initialValue:{
            //   format:'required',
            // },
            className: 'cn-array-setter-validator',
            setter: {
                componentName: 'ArraySetter',
                props: {
                    mode: 'list',
                    itemSetter: {
                        componentName: 'ObjectSetter',
                        // initialValue: {
                        //   "type": "JSFunction",
                        //   "value": "() => {return {primaryKey: String(Math.floor(Math.random() * 10000)),children: \"Title\",optType:\"link\"               };}"
                        // },
                        initialValue: {
                            // todo
                            active: true,
                        },
                        props: {
                            config: {
                                items: [
                                    {
                                        name: 'name',
                                        display: 'inline',
                                        title: $i18n.get({
                                            id: 'VerificationFormat',
                                            dm: '校验格式',
                                        }),
                                        isRequired: true,
                                        tip: $i18n.get({
                                            id: 'VerificationExampleTheNameOfTheF_937666303',
                                            dm: '校验示例—筛选项名称为"订单数量"/校验格式选择"必填"，错误提示默认为"订单数量是必填字段"（您也可以下方填写自定义错误提示文案）',
                                        }),
                                        setter: {
                                            componentName: 'CnSelectSetter',
                                            props: (field) => {
                                                const parentComponentConfig = field?.parent?.parent?.parent?.getValue?.();
                                                const componentName = parentComponentConfig?.componentName;
                                                const validatorList = getItemListByPosition({
                                                    position,
                                                    componentMap,
                                                    extraQueryParams: {
                                                        showInFilterComponentList: componentName,
                                                    },
                                                });
                                                return {
                                                    options: validatorList,
                                                    selectProps: {
                                                        placeholder: $i18n.get({
                                                            id: 'PleaseSelectAVerificationFormat',
                                                            dm: '请选择校验格式',
                                                        }),
                                                    },
                                                };
                                            },
                                        },
                                        extraProps: {
                                            setValue(target, value) {
                                                const defaultProps = getItemDefaultProps(componentMap, position, value);
                                                if (defaultProps &&
                                                    Object.keys(defaultProps)?.length > 0) {
                                                    target?.parent?.setPropValue?.('options', defaultProps);
                                                }
                                                else {
                                                    target?.parent?.setPropValue?.('options', {});
                                                }
                                            },
                                        },
                                    },
                                    {
                                        name: 'message',
                                        display: 'inline',
                                        title: $i18n.get({
                                            id: 'CustomErrorPrompt',
                                            dm: '自定义错误提示',
                                        }),
                                        // isRequired: true,
                                        setter: {
                                            componentName: 'CnI18nSetter',
                                            props: {
                                                placeholder: $i18n.get({
                                                    id: 'ErrorPromptCopy',
                                                    dm: '错误提示文案',
                                                }),
                                            },
                                        },
                                    },
                                    {
                                        name: 'active',
                                        display: 'inline',
                                        className: 'cn-array-setter-validator-active',
                                        title: $i18n.get({
                                            id: '31255239495651328.CNTM',
                                            dm: '启用',
                                        }),
                                        // setter: 'CheckboxSetter',
                                        setter: {
                                            componentName: 'MixedSetter',
                                            props: {
                                                setters: [
                                                    {
                                                        componentName: 'CheckboxSetter',
                                                        title: $i18n.get({
                                                            id: 'EnableDisable',
                                                            dm: '启用/禁用',
                                                        }),
                                                    },
                                                    {
                                                        // componentName: <TextExprSetter
                                                        //   dataKey={'config'}
                                                        //   labelKey={'label'}
                                                        //   valueKey={'name'}
                                                        //   groupName={'表单字段列表'}
                                                        // />,
                                                        componentName: (<ExprSetter configList={[
                                                                {
                                                                    dataKey: 'config',
                                                                    labelKey: 'label',
                                                                    valueKey: 'name',
                                                                    groupName: $i18n.get({
                                                                        id: 'FormFields',
                                                                        dm: '表单字段',
                                                                    }),
                                                                    groupExprName: getFormExprNameByPosition({}),
                                                                    needSecondParam: false,
                                                                },
                                                                {
                                                                    groupName: $i18n.get({
                                                                        id: 'OtherData',
                                                                        dm: '其他数据',
                                                                    }),
                                                                    groupExprName: __dataSource__,
                                                                    needSecondParam: true,
                                                                },
                                                            ]}/>),
                                                        title: $i18n.get({
                                                            id: 'SimpleExpression',
                                                            dm: '简单表达式',
                                                        }),
                                                    },
                                                    getJSExpressionPrototype({ type: 'form' }),
                                                ],
                                            },
                                        },
                                    },
                                    // {
                                    //   name: 'active',
                                    //   display: 'inline',
                                    //   isRequired:true,
                                    //   title: '是否启用',
                                    //   setter: 'BoolSetter',
                                    // },
                                    {
                                        name: 'triggerType',
                                        display: 'inline',
                                        title: $i18n.get({ id: 'TriggerTiming', dm: '触发时机' }),
                                        // isRequired:true,
                                        setter: {
                                            componentName: 'CnSelectSetter',
                                            props: {
                                                options: [
                                                    {
                                                        label: $i18n.get({
                                                            id: 'WhenEntering',
                                                            dm: '输入时',
                                                        }),
                                                        value: 'onInput',
                                                    },
                                                    {
                                                        label: $i18n.get({
                                                            id: 'WhenFocusIsObtained',
                                                            dm: '获得焦点时',
                                                        }),
                                                        value: 'onFocus',
                                                    },
                                                    {
                                                        label: $i18n.get({
                                                            id: 'WhenFocusIsLost',
                                                            dm: '失去焦点时',
                                                        }),
                                                        value: 'onBlur',
                                                    },
                                                ],
                                                selectProps: {
                                                    hasClear: true,
                                                },
                                            },
                                        },
                                    },
                                    ...configure,
                                ],
                            },
                        },
                    },
                },
            },
        },
    ];
}
