import $i18n from '@/locales/i18n';
import { Button as UIButton, formilyReact } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { executeFunction } from '@/common/util/util';
const { useField } = formilyReact;
const ArrayTableOptButton = {
    title: $i18n.get({ id: 'Button', dm: '按钮' }),
    componentName: 'ArrayTableOptButton',
    formComponent: (props) => {
        const { onClick, getChildren } = props;
        const extraProps = {};
        if (onClick) {
            let field;
            try {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                field = useField();
            }
            catch (e) { /* empty */ }
            extraProps.onClick = async () => {
                await onClick({
                    field,
                });
            };
        }
        if (typeof getChildren === 'function') {
            extraProps.children = executeFunction(getChildren);
            extraProps.getChildren = undefined;
        }
        return <UIButton {...props} {...extraProps}/>;
    },
};
export default ArrayTableOptButton;
