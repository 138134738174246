import { DisplayPosition } from '../position/display-position';
import { CnRange, componentMap as formComponentMap } from '@cainiaofe/cn-ui';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const { Range: { title, thumbUrl } } = FORM_ITEM_INFO;
const Range = {
    position: [
        DisplayPosition.form,
        DisplayPosition.formDialog,
        DisplayPosition.cnArraySubAreaCard,
    ],
    title,
    thumbUrl,
    componentName: 'Range',
    component: CnRange,
    formComponent: formComponentMap.CnRange,
};
export default Range;
