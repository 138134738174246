import $i18n from '@/locales/i18n';
import React, { useState } from 'react';
import { Button, Dropdown, Tree } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { getNodeFieldList, handleI18nLabel } from '@/common/util/util';
export const ImportField = (props) => {
    const [selectList, setSelectList] = useState([]);
    const { prop, trigger, visible, setVisible, dataPrimaryKey } = props;
    const currentId = prop?.getNode?.()?.id;
    const nodeData = getNodeFieldList(prop, {
        componentNameList: ['CnFilter', 'CnForm', 'CnTable', 'CnFormDialog'],
    });
    const getNewList = () => {
        const result = [];
        const currentComponent = props?.prop?.getNode?.()?.componentName;
        if (selectList?.length > 0) {
            selectList.forEach((item) => {
                if (item) {
                    const { dataIndex, parentComponent, config } = item;
                    const title = handleI18nLabel(item.title);
                    let newItem = {};
                    if (parentComponent === currentComponent) {
                        newItem = { ...config };
                    }
                    else if (currentComponent === 'CnTable') {
                        newItem.title = title;
                        newItem.dataIndex = dataIndex;
                    }
                    else if (currentComponent === 'CnFilter' ||
                        currentComponent === 'CnForm' ||
                        currentComponent === 'CnFormDialog') {
                        newItem.label = title;
                        newItem.name = dataIndex;
                        newItem.componentName = 'Input';
                    }
                    result.push(newItem);
                }
            });
        }
        return result;
    };
    const allInsert = () => {
        const result = getNewList();
        if (result.length > 0) {
            props?.prop?.setValue(result);
            const sel = window?.AliLowCodeEngine?.project?.currentDocument?.selection;
            if (sel) {
                sel?.select?.();
                setTimeout(() => {
                    sel?.select?.(currentId);
                });
            }
        }
    };
    const addInsert = () => {
        const primaryKey = dataPrimaryKey || 'dataIndex';
        const existColumns = props?.prop?.getValue() || [];
        const existMap = {};
        existColumns.forEach((item) => {
            if (item[primaryKey]) {
                existMap[item[primaryKey]] = true;
            }
        });
        const result = getNewList();
        const toAddList = [...existColumns];
        if (result.length > 0) {
            result.forEach((item) => {
                if (!(item[primaryKey] && existMap[item[primaryKey]])) {
                    toAddList.push(item);
                }
            });
            if (toAddList.length > 0) {
                props?.prop?.setValue(toAddList);
                const sel = window?.AliLowCodeEngine?.project?.currentDocument?.selection;
                if (sel) {
                    sel?.select?.();
                    setTimeout(() => {
                        sel?.select?.(currentId);
                    });
                }
            }
        }
        setVisible(false);
    };
    const select = (selectedKeys, extra = {}) => {
        const { checkedNodes = [] } = extra;
        const result = [];
        checkedNodes.forEach((item) => {
            const { originLabel, originValue, originConfig, parentComponent } = item?.props;
            if (originLabel && originValue) {
                result.push({
                    title: originLabel,
                    dataIndex: originValue,
                    config: originConfig,
                    parentComponent,
                });
            }
        });
        setSelectList(result);
    };
    return (<Dropdown visible={visible} onVisibleChange={(_visible) => {
            setVisible(_visible);
        }} trigger={trigger} triggerType={['click']}>
      <div style={{
            background: '#fff',
            padding: '12px',
            border: '1px solid #ddd',
            borderRadius: '3px',
        }}>
        <div style={{
            maxHeight: '300px',
            overflow: 'auto',
        }}>
          <Tree selectable={false} isNodeBlock onCheck={select} defaultExpandAll checkable dataSource={nodeData}/>
        </div>
        <div style={{
            paddingTop: '10px',
            borderTop: '1px solid #ddd',
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
          <Button size={'small'} onClick={() => {
            setVisible(false);
        }}>
            {$i18n.get({ id: 'TERM.Cancel', dm: '取消' })}
          </Button>
          &nbsp;&nbsp;
          <Button size={'small'} type={'secondary'} onClick={allInsert}>
            {$i18n.get({ id: 'OverwriteImport', dm: '覆盖导入' })}
          </Button>
          &nbsp;&nbsp;
          <Button size={'small'} type={'secondary'} onClick={addInsert}>
            {$i18n.get({ id: 'IncrementalImport', dm: '增量导入' })}
          </Button>
        </div>
      </div>
    </Dropdown>);
};
