import React from 'react';
import CnFormView from './form-view';
import { formComponentRefStorage } from '@/common/util/const';
import { componentRefSplit } from '@/common/util/expr-const';

class CnForm extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  load = () => {
    this.ref?.current?.load?.();
  };

  reRender = () => {
    this.ref?.current?.reRender?.();
  };

  getFormInstance = () => {
    return this.ref?.current?.getFormInstance?.();
  };

  getFormRef = () => {
    return this.ref?.current?.getFormRef?.();
  };

  // 获取组件的ref实例。目前仅支持获取文件上传组件的ref实例。
  getComponentRef = (fieldName) => {
    const { _dataSourceName } = this.props || {};
    if (fieldName) {
      const key = `${_dataSourceName}${componentRefSplit}${fieldName}`;
      return formComponentRefStorage[key];
    } else {
      return formComponentRefStorage;
    }
  };

  render() {
    return <CnFormView ref={this.ref} {...this.props} />;
  }
}
CnForm.displayName = 'CnForm';
export default CnForm;

export { CnForm };
