import $i18n from '@/locales/i18n';
import { DisplayPosition } from '../position/display-position';
import { componentMap as formComponentMap } from '@cainiaofe/cn-ui';
// eslint-disable-next-line import/no-cycle
import { uuid } from '../../util/util';
import { getStepShapeSetterSnippet } from '@/common/manager/setter-snippet';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { getButtonAction } from '@/common/manager/button';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
const CnFormGrid = 'CnFormGrid';
const { CnFormStepItem: { title, thumbUrl } } = FORM_ITEM_INFO;
const CnFormStepItem = {
    position: [DisplayPosition.formDialog, DisplayPosition.form],
    title,
    componentName: 'CnFormStepItem',
    thumbUrl,
    formComponent: formComponentMap.CnFormStepItem,
    isFormContainer: true,
    allowParentList: ['root'],
    formItemBeforeHandler: (formItem, config) => {
        const { formItemConfig = {}, formProps = {}, state, _context, urlParams, formValue, formInstance, isDesign, formStepInstance, } = config || {};
        const { formStyle = {}, events } = formProps;
        const { cols } = formStyle || {};
        if (formItem) {
            const { label, name } = formItemConfig;
            const componentProps = formItem['x-component-props'];
            if (componentProps) {
                if (label && !componentProps.title) {
                    componentProps.title = label;
                }
                // 编辑态
                if (isDesign && typeof formStepInstance?.setCurrent === 'function') {
                    componentProps.onClick = (newCurrent) => {
                        formStepInstance.setCurrent(newCurrent);
                    };
                }
                // 运行态 CnFormStepItem onClick 事件，临时且不成熟的逻辑
                if (!isDesign && events?.length) {
                    const hasOnFormStepItemClickEvent = events.find((e) => e?.name === 'onFormStepItemClick');
                    if (hasOnFormStepItemClickEvent) {
                        componentProps.onClick = (stepIndex) => {
                            const { optType } = hasOnFormStepItemClickEvent;
                            if (['setFormFieldState'].includes(optType)) {
                                const action = getButtonAction({
                                    ...hasOnFormStepItemClickEvent,
                                    position: ButtonPosition.formEvent,
                                });
                                if (typeof action === 'function') {
                                    action({
                                        buttonConfig: {
                                            position: ButtonPosition.formEvent,
                                            options: hasOnFormStepItemClickEvent,
                                        },
                                        recordDataSource: formValue,
                                        state,
                                        _context,
                                        urlParams,
                                        formValue,
                                        formInstance,
                                        isDesign,
                                        formStepInstance,
                                    });
                                }
                            }
                            // const e = events[0];
                            // const ret = calculateTextExprValue(e?.current);
                            formStepInstance?.setCurrent(stepIndex);
                        };
                    }
                }
            }
            delete formItem['x-decorator'];
            formItem.properties = {};
            formItem.title = '';
            formItem.type = 'void';
            const { cols: cardCols } = componentProps;
            const formGridProps = {};
            if (cardCols) {
                formGridProps.cols = cardCols;
            }
            else {
                formGridProps.cols = cols;
            }
            formItem.properties = {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            };
        }
    },
    getDefaultProps: () => {
        return {};
    },
    getFormItemDefaultProps: () => {
        return {
            label: $i18n.get({ id: 'Steps', dm: '步骤' }),
            name: `form_step_${uuid()}`,
        };
    },
    getPrototypeList: () => {
        return [
            {
                name: 'title',
                title: $i18n.get({ id: 'StepTitle', dm: '步骤标题' }),
                display: 'inline',
                setter: 'CnI18nSetter',
            },
            getStepShapeSetterSnippet(),
        ];
    },
};
export default CnFormStepItem;
