import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import {
  condition,
  handlePropsDynamicUrl,
  handleSelectDataSource,
  handleSelectRequestConfig,
  setAdvancedConfigToProps,
} from '../../util/util';
import { forwardRef } from 'react';
import {
  CnComplexRadioGroup,
  CnRadioGroup,
  componentMap as formComponentMap,
  formilyReact,
} from '@cainiaofe/cn-ui';
import { DisplayPosition } from '../position/display-position';
import {
  getDataOriginSetterSnippet,
  getRequestConfigSetterSnippet,
  getStaticDataSourceSnippet,
} from '@/common/manager/setter-snippet';
import { __arrayTableCurrentRow__ } from '@/common/util/expr-const';
import isPlainObject from 'lodash/isPlainObject';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import {
  getBizExtendPrototype,
  handleBizExtendComponentProps,
} from '@/common/manager/plugin';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';

const { useField } = formilyReact || {};

const { RadioGroup: { title, thumbUrl } } = FORM_ITEM_INFO;

const RadioGroup = {
  position: [
    DisplayPosition.filter,
    DisplayPosition.form,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  thumbUrl,
  title,
  componentName: 'RadioGroup',
  component: (props) => {
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    let shape;
    if (type === 'complex') {
      return <CnComplexRadioGroup {...rest} />;
    }
    if (['button', 'tag'].includes(type)) {
      shape = type;
    }
    return <CnRadioGroup shape={shape} {...rest} />;
  },
  formComponent: forwardRef((props, ref) => {
    const field = useField?.();
    handlePropsDynamicUrl(props, {
      field,
    });
    const { extra, ...rest } = props || {};
    const { type } = extra || {};
    let shape;
    if (type === 'complex') {
      return <formComponentMap.CnComplexRadioGroup {...rest} />;
    }
    if (['button', 'tag'].includes(type)) {
      shape = type;
    }
    return <formComponentMap.CnRadioGroup shape={shape} {...rest} ref={ref} />;
  }),
  formItemBeforeHandler: (formItem, config) => {
    const { isDesign, urlParams, formValue, state } = config;
    const tempProps = formItem?.['x-component-props'];
    if (isPlainObject(tempProps)) {
      tempProps.getRequestParams = () => {
        return {
          state,
          recordDataSource: formValue,
        };
      };
      const { extra } = tempProps;
      const { direction } = extra || {};
      if (direction) {
        tempProps.direction = direction;
      }
    }
    handleSelectRequestConfig({
      componentProps: formItem?.['x-component-props'],
      isDesign,
      urlParamsDataSource: urlParams,
      recordDataSource: formValue,
      state,
      handleDynamicUrl: true,
    });
    handleSelectDataSource({
      componentProps: formItem?.['x-component-props'],
      state,
      recordDataSource: formValue,
    });
    const componentProps = formItem?.['x-component-props'];
    handleBizExtendComponentProps(componentProps, 'CnRadioGroup');
    setAdvancedConfigToProps(componentProps);
  },
  filterItemBeforeHandler: (filterItemProps, config) => {
    handleSelectRequestConfig(config);
    handleSelectDataSource(config);
  },
  getDefaultProps: () => {
    return {
      extra: {
        direction: 'hoz',
      },
      dataSource: [
        {
          label: $i18n.get({ id: 'Option1', dm: '选项1' }),
          value: 'radio1',
        },
        {
          label: $i18n.get({ id: 'Option2', dm: '选项2' }),
          value: 'radio2',
        },
      ],
    };
  },
  // RadioGroup的prototype列表
  getPrototypeList: (position) => {
    const extraServiceProps = {};
    const extraSetters = [
      {
        name: 'disabled',
        display: 'inline',
        title: $i18n.get({ id: 'Disable', dm: '是否禁用' }),
        setter: {
          componentName: 'MixedSetter',
          props: {
            setters: [
              {
                componentName: 'BoolSetter',
                title: $i18n.get({
                  id: 'BooleanTruefalse',
                  dm: '布尔（true/false）',
                }),
              },
              getJSExpressionPrototype({ type: 'form' }),
            ],
          },
        },
      },
    ];

    const extraStyleSetters = [
      {
        name: 'type',
        title: $i18n.get({ id: 'ShowStyle', dm: '展示样式' }),
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({ id: 'OrdinaryType', dm: '普通型' }),
                value: 'normal',
              },
              {
                title: $i18n.get({ id: 'ButtonType', dm: '按钮型' }),
                value: 'button',
              },
              {
                title: $i18n.get({ id: 'LabelType', dm: '标签型' }),
                value: 'tag',
              },
            ],
          },
        },
      },
    ];

    if (DisplayPosition.cnArrayTable === position) {
      extraServiceProps.dynamicUrlTemplate = $i18n.get(
        {
          id: 'FunctionstateArrayTableCurrentRo_933468039.ABh3e',
          dm: "function(state, { {arrayTableCurrentRow} }) {\n  // __arrayTableCurrentRow__: 可编辑表格当前行的数据。\n  return '/xxx';\n}",
        },
        { arrayTableCurrentRow: __arrayTableCurrentRow__ },
      );
    }
    // if(DisplayPosition.filter === position) {
    extraSetters.push({
      name: 'description',
      display: 'inline',
      title: $i18n.get({ id: 'TERM.Description', dm: '描述' }),
      setter: 'CnI18nSetter',
    });
    extraSetters.push({
      name: 'icon',
      display: 'inline',
      title: $i18n.get({ id: 'Icons', dm: '图标' }),
      setter: 'CnIconSetter',
    });
    extraSetters.push({
      display: 'none',
      setter: {
        componentName: 'DocSetter',
        props: {
          content: (
            <div className="">
              {$i18n.get({ id: 'PleaseCome', dm: '请到' })}{' '}
              <a
                target={'_blank'}
                href={
                  'https://done.alibaba-inc.com/dsm/cone/components/detail/cnicon?themeid=26075&tabActiveKey=component'
                }
                rel="noreferrer"
              >
                {$i18n.get({ id: 'IconLibrary', dm: '图标库' })}
              </a>{' '}
              {$i18n.get({
                id: 'FindTheIconYouWantToUseAnd',
                dm: '中寻找想用的图标，填写图标type即可。',
              })}
              <a
                target={'_blank'}
                href={
                  'https://alidocs.dingtalk.com/i/nodes/NkPaj7GAXdpWOkPeZ6p0Wqwgylnomz9B?utm_scene=team_space&iframeQuery=anchorId%3Duu_lbusmehvlsw40rh61ti'
                }
                rel="noreferrer"
              >
                {$i18n.get({ id: 'ExperienceDemo', dm: '体验Demo' })}
              </a>
            </div>
          ),
        },
      },
    });
    extraStyleSetters[0].setter.props.options.push({
      title: $i18n.get({ id: 'Enhanced', dm: '增强型' }),
      value: 'complex',
    });
    // }
    const result = [
      getDataOriginSetterSnippet({
        display: 'inline',
        defaultValue: 'static',
      }),
      getRequestConfigSetterSnippet(
        {
          condition: (prop) => {
            return (
              condition(prop, 'RadioGroup', 'componentName') &&
              prop?.parent?.getPropValue?.('dataOrigin') === 'request'
            );
          },
        },
        {
          extraServiceProps,
          position,
        },
      ),
      {
        name: 'dataSource',
        title: $i18n.get({
          id: 'RadioBoxGroupStaticData',
          dm: '单选框组静态数据',
        }),
        display: 'plain',
        // hidden: hidden('RadioGroup'),
        condition(prop) {
          return (
            condition(prop, 'RadioGroup', 'componentName') &&
            prop?.parent?.getPropValue?.('dataOrigin') !== 'request'
          );
        },
        setter: getStaticDataSourceSnippet({
          extraArraySetterItems: extraSetters,
        }),
      },
      {
        name: 'extra',
        title: $i18n.get({ id: 'Style', dm: '样式' }),
        display: 'plain',
        collapsed: true,
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [
                ...extraStyleSetters,
                {
                  name: 'direction',
                  title: $i18n.get({
                    id: 'ArrangementOfOptions',
                    dm: '选项的排列方式',
                  }),
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        {
                          title: $i18n.get({
                            id: 'HorizontalArrangement',
                            dm: '水平排列',
                          }),
                          value: 'hoz',
                        },
                        {
                          title: $i18n.get({
                            id: 'VerticalArrangement',
                            dm: '竖直排列',
                          }),
                          value: 'ver',
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      },
      ...getBizExtendPrototype({
        componentName: 'CnRadioGroup',
      }),
    ];

    return result;
  },
};
export default RadioGroup;
