import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Avatar, CnBox, CnList as UICnList, CnStatusTag } from '@cainiaofe/cn-ui';
import { dataOriginRequest, dataOriginStatic } from '@/common/util/const';
import {
  calculateTextExprValue,
  executeEventWithoutJS,
  executeFunction,
  isDesignMode,
  makeButtons,
  setDataToDs,
  mergeHandleProps2,
  uuid,
  calculateRequestExecute,
} from '@/common/util/util';
import { __list_activeKey__, __list_currentItem__ } from '@/common/util/expr-const';
import { handleRequestParams, transRequestConfigToRemote } from '@/common/util/request';
import { ButtonPosition } from '@/common/manager/position/button-position';
import isPlainObject from 'lodash/isPlainObject';

const CnList = forwardRef((props, ref) => {
  const {
    header,
    _context,
    _dataSource,
    _dataSourceName,
    dataFrom,
    buttons,
    events,
    itemContentRender,
    itemDescriptionRender,
    itemTitleRender,
    ...rest
  } = props;
  const { dataSource, dataOrigin, requestConfig } = dataFrom || {};
  const state = _context?.state;
  const isDesign = isDesignMode(props);
  const listRef = useRef(null);
  const [listData, setListData] = useState(() => {
    if (dataOrigin === dataOriginStatic) {
      return calculateTextExprValue(dataSource, {
        recordDataSource: {},
        state,
      });
    }
  });

  const [refreshDeps, setRefreshDeps] = useState(uuid(6));

  useImperativeHandle(ref, () => ({
    load() {
      setRefreshDeps([uuid(6)]);
    },
    reRender() {},
    setListData,
    ...listRef.current,
  }));

  const setDataSource = (data) => {
    if (data) {
      setDataToDs({
        _context,
        _dataSource,
        _dataSourceName,
        data,
      });
    }
  };

  useEffect(() => {
    setDataSource({
      [__list_activeKey__]: null,
      [__list_currentItem__]: null,
    });
  }, []);

  // if (isDesign) {
  useEffect(() => {
    if (dataOrigin === dataOriginStatic) {
      setListData(
        calculateTextExprValue(dataSource, {
          recordDataSource: {},
          state,
        }),
      );
    }
  }, [dataSource]);
  // }

  const getUrlParams = () => {
    return _context?.state?.urlParams || {};
  };

  let extraProps = {};

  if (dataOrigin === dataOriginStatic) {
    extraProps.dataSource = listData;
  } else if (dataOrigin === dataOriginRequest) {
    extraProps.requestConfig = transRequestConfigToRemote({
      requestConfig,
      state,
      recordDataSource: {},
      isDesign,
      refreshDeps: [refreshDeps],
      needFormatResult: true,
      formatParam: (() => {
        return function (data) {
          const realParams =
            handleRequestParams(dataFrom?.requestConfig.params, {
              urlParamsDataSource: getUrlParams(),
              recordDataSource: {},
              state,
            }) || {};

          return { ...(data || {}), ...realParams };
        };
      })(),
    });

    const execute = calculateRequestExecute(
      requestConfig,
      {},
      {},
      state,
    );
    if (execute === false) {
      extraProps.requestConfig.manual = true;
    }
  }

  const getTag = useCallback((tag) => {
    if (isPlainObject(tag)) {
      return (
        <CnStatusTag
          type={'primary'}
          style={{ marginLeft: '8px' }}
          size={'small'}
          {...tag}
        />
      );
    }
    if (Array.isArray(tag)) {
      return tag.map((item, index) => {
        return (
          <CnStatusTag
            key={index}
            type={'primary'}
            style={{ marginLeft: '8px' }}
            size={'small'}
            {...item}
          />
        );
      });
    }
    return null;
  }, []);

  const listItemClick = useCallback((item) => {
    executeEventWithoutJS({
      eventType: 'onListItemClick',
      events,
      _context,
      position: ButtonPosition.listItemEvent,
      recordDataSource: item,
    });
  }, []);

  const onListActivityKeyChange = (item) => {
    executeEventWithoutJS({
      eventType: 'onListActivityKeyChange',
      events,
      _context,
      position: ButtonPosition.listItemEvent,
      recordDataSource: item,
    });
  };

  extraProps = mergeHandleProps2({
    handleProps: props?.handleProps,
    componentProps: extraProps,
    _context: props?._context,
  });

  return (
    <UICnList
      header={header}
      {...rest}
      {...extraProps}
      renderItem={(item, index) => {
        const { title, media, description, tag } = item || {};
        return (
          <UICnList.Item
            onClick={() => {
              listItemClick(item);
            }}
            key={index}
            description={
              typeof itemDescriptionRender === 'function'
                ? executeFunction(itemDescriptionRender, item, index, state)
                : description
            }
            extra={
              <CnBox spacing={8} direction="row">
                {makeButtons({
                  buttons: buttons?.map((_item) => {
                    return {
                      ..._item,
                      position: ButtonPosition.listItemRightButton,
                    };
                  }),
                  _context,
                  recordDataSource: item,
                  arrayBaseFieldIndex: index,
                  state: _context?.state,
                  extraParamList: {
                    recordDataSource: item,
                  },
                })}
              </CnBox>
            }
            title={
              <>
                {typeof itemTitleRender === 'function'
                  ? executeFunction(itemTitleRender, item, index, state)
                  : title}
                {getTag(tag)}
              </>
            }
            media={media && <Avatar src={media} />}
          >
            {typeof itemContentRender === 'function'
              ? executeFunction(itemContentRender, item, index, state)
              : item.content ?? null}
          </UICnList.Item>
        );
      }}
      onChange={(activeKey, currentItem) => {
        setDataSource({
          [__list_activeKey__]: activeKey,
          [__list_currentItem__]: currentItem,
        });
        onListActivityKeyChange(currentItem);
      }}
      ref={listRef}
    />
  );
});
CnList.displayName = 'CnList';
export default CnList;

export { CnList };
