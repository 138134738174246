import $i18n from "../locales/i18n";
export var FORM_ITEM_INFO = {
    Input: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01OAiYh21mwkrKE8Kda_!!6000000005019-2-tps-240-136.png',
        title: $i18n.get({ id: 'TextInputBox', dm: '文本输入框' }),
    },
    CnBatchInput: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01asUJ821LnbJi2xRNT_!!6000000001344-2-tps-240-136.png',
        title: $i18n.get({ id: 'BatchInputBox', dm: '批量输入框' }),
    },
    NumberPicker: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01F8oraZ22WgA4efXK9_!!6000000007128-2-tps-240-136.png',
        title: $i18n.get({ id: 'DigitalInputBox', dm: '数字输入框' }),
    },
    RangeNumberPicker: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01ADtjH71SrRxMifEqQ_!!6000000002300-2-tps-240-136.png',
        title: $i18n.get({ id: 'RangeNumberInputBox', dm: '范围数字输入框' }),
    },
    Select: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01CWMbdu1NYba3XHo5W_!!6000000001582-2-tps-240-136.png',
        title: $i18n.get({ id: 'DropdownSelection', dm: '下拉选择' }),
    },
    RadioGroup: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01Uh1JPt251wQc2tByi_!!6000000007467-2-tps-240-136.png',
        title: $i18n.get({ id: 'RadioBoxGroup', dm: '单选框组' }),
    },
    CheckboxGroup: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN012IHw3t1VIaenALAWX_!!6000000002630-2-tps-240-136.png',
        title: $i18n.get({ id: 'CheckBoxGroup', dm: '复选框组' }),
    },
    DatePicker: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01k4KLmR26pEfiXRyws_!!6000000007710-2-tps-240-136.png',
        title: $i18n.get({ id: 'DatePicker', dm: '日期选择器' }),
    },
    TimePicker: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN016p17a61oDzNUsH6r3_!!6000000005192-2-tps-240-136.png',
        title: $i18n.get({ id: 'TimeSelector', dm: '时间选择器' }),
    },
    RangePicker: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01AQofsx1nzKhjSdo4E_!!6000000005160-2-tps-240-136.png',
        title: $i18n.get({ id: 'DateIntervalSelector', dm: '日期区间选择器' }),
    },
    RangeTimePicker: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01HtpuvJ1ZzsYUOPFln_!!6000000003266-2-tps-240-136.png',
        title: $i18n.get({ id: 'TimeIntervalSelector', dm: '时间区间选择器' }),
    },
    EmployeeSelect: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01bLuVp31hHvTKbzM4h_!!6000000004253-2-tps-240-136.png',
        title: $i18n.get({ id: 'EmployeeSelector', dm: '员工选择器' }),
    },
    AddressSelect: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN014fQRpy1Q9ozqC2e5Z_!!6000000001934-2-tps-240-136.png',
        title: $i18n.get({ id: 'AddressSelector', dm: '地址选择器' }),
    },
    DepartmentSelect: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01ANX7Mb1LMZwtl0nfb_!!6000000001285-2-tps-240-136.png',
        title: $i18n.get({ id: 'SectorSelector', dm: '部门选择器' }),
    },
    TextArea: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN018h2btw1k9d975lQ1B_!!6000000004641-2-tps-240-136.png',
        title: $i18n.get({
            id: 'MultilinePlainTextInputBox',
            dm: '多行纯文本输入框',
        }),
    },
    Switch: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01GGDxDP1f7H1qvBpXF_!!6000000003959-2-tps-240-136.png',
        title: $i18n.get({ id: 'Switch', dm: '开关' }),
    },
    CnCard: {
        title: $i18n.get({ id: 'Card', dm: '卡片' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01hMbQHp1FgplrJ2XdZ_!!6000000000517-2-tps-240-136.png',
    },
    CnCardSubCard: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01Bn9rlS1txOj3nTobB_!!6000000005968-2-tps-240-136.png',
        title: $i18n.get({ id: 'Subcard', dm: '子卡片' }),
    },
    Message: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01y1vBcq21NENLVd8Su_!!6000000006972-2-tps-240-136.png',
        title: $i18n.get({ id: 'InformationTip', dm: '信息提示' }),
    },
    Upload: {
        title: $i18n.get({ id: 'FileUpload', dm: '文件上传' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01qImfhZ1sdPpRmFdnP_!!6000000005789-2-tps-240-136.png',
    },
    OSSImageUpload: {
        title: $i18n.get({ id: 'ImageUpload', dm: '图片上传' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01oi5O1Z1egFf2iKZgl_!!6000000003900-2-tps-240-136.png',
    },
    CnArrayTable: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01Qn8fmZ1ht1YKykEJ4_!!6000000004334-2-tps-240-136.png',
        title: $i18n.get({ id: 'EditableTable', dm: '可编辑表格' }),
    },
    ArrayTableOptButton: { title: $i18n.get({ id: 'Button', dm: '按钮' }) },
    TreeSelect: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN017NorAe1wE0Jina0MA_!!6000000006275-2-tps-240-136.png',
        title: $i18n.get({ id: 'TreeSelection', dm: '树选择' }),
    },
    CascaderSelect: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01BNDp2w1ItbfCG4j9Q_!!6000000000951-2-tps-240-136.png',
        title: $i18n.get({ id: 'CascadeSelection', dm: '级联选择' }),
    },
    ImageViewer: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01edt0LP1L2sv11N7d4_!!6000000001242-2-tps-240-136.png',
        title: $i18n.get({ id: 'PictureBrowsing', dm: '图片浏览' }),
    },
    Rating: {
        title: $i18n.get({ id: 'ScoringComponent', dm: '评分组件' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01qEgGTe1mvq4BVaMNf_!!6000000005017-2-tps-240-136.png',
    },
    Range: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01K0sbA71WgEipK1rs7_!!6000000002817-2-tps-240-136.png',
        title: $i18n.get({ id: 'SlideInputBar', dm: '滑动输入条' }),
    },
    CnFormStepItem: {
        title: $i18n.get({
            id: 'StepItemsForStepbyStepForms',
            dm: '分步表单的步骤项',
        }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01pEj4qZ1UOiKywT7pz_!!6000000002508-2-tps-240-136.png',
    },
    Compose: {
        thumbUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01Jy0CO31ar0UzrWRg5_!!6000000003382-2-tps-240-136.png',
        title: $i18n.get({ id: 'ComboBox', dm: '组合框' }),
    },
    AutoComplete: {
        title: $i18n.get({
            id: 'AuxiliaryCompletionInputBoxAutoC_90230318',
            dm: '辅助完成输入框（AutoComplete）',
        }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01qHyMew1mQEjhPCf25_!!6000000004948-2-tps-240-136.png',
    },
    CnArraySubAreaCard: {
        title: $i18n.get({ id: 'SelfincreasingCard', dm: '自增卡片' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i1/O1CN01oLtwj61GQAyskJVmj_!!6000000000616-2-tps-240-136.png',
    },
    CnFormTabItem: {
        title: $i18n.get({ id: 'TabItem', dm: 'Tab项' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01zQ6GcZ1VubX1esB5f_!!6000000002713-2-tps-240-136.png',
    },
    Transfer: {
        title: $i18n.get({ id: 'ShuttleBox', dm: '穿梭框' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01VHm6Dh1fZDBlOY949_!!6000000004020-2-tps-240-136.png',
    },
    FormItemSlot: {
        title: $i18n.get({ id: 'TableSlot', dm: '表格插槽' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i3/O1CN01graSGr1Ot2s9MxJYo_!!6000000001762-2-tps-240-136.png',
    },
    TimeLine: {
        title: $i18n.get({ id: 'Timeline', dm: '时间轴' }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01cJusvN1oe5x9IKSJG_!!6000000005249-2-tps-240-136.png',
    },
    ReadOnly: {
        title: $i18n.get({
            id: 'ShowContentFormattingWithStyledT_688536867',
            dm: '展示内容格式化（带样式文本、标签、...）',
        }),
        thumbUrl: 'https://img.alicdn.com/imgextra/i2/O1CN01yQfNVy23TlFmx0wN3_!!6000000007257-2-tps-240-136.png',
    },
};
