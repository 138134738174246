import CnAddressSelect from '@cainiaofe/cn-address';
// eslint-disable-next-line import/no-cycle
import { condition, isEmptyButNotZero } from '../../util/util';
import { DisplayPosition } from '../position/display-position';
import { countryList } from '@/common/util/country-datasource';
import { FORM_ITEM_INFO } from '@cainiaofe/cn-ui-lowcode-prototype-common';
import locale from '@cainiaofe/cn-address/lib/locale';
import $i18n from '@/locales/i18n';
import isPlainObject from 'lodash/isPlainObject';
import set from 'lodash/set';

const { AddressSelect: { title, thumbUrl } } = FORM_ITEM_INFO;

const AddressSelect = {
  position: [
    DisplayPosition.form,
    DisplayPosition.filter,
    DisplayPosition.formDialog,
    DisplayPosition.cnArrayTable,
    DisplayPosition.cnArraySubAreaCard,
  ],
  thumbUrl,
  title,
  componentName: 'AddressSelect',
  component: CnAddressSelect,
  formComponent: CnAddressSelect,
  getDefaultProps: () => {
    return {
      multiple: false,
      showTopLevel: false,
      maxLevel: 4,
      showSearch: true,
    };
  },
  filterItemBeforeHandler: (filterItemProps, config) => {
    const { componentProps } = config || {};
    if (isPlainObject(componentProps)) {
      const { extra } = componentProps;
      const { realm, needSkipLevelData, showDisabledDivision } = extra || {};
      if (realm) {
        set(componentProps, 'config.realm', realm);
      }
      if (needSkipLevelData) {
        set(componentProps, 'needSkipLevelData', needSkipLevelData);
      }
      if (showDisabledDivision) {
        set(componentProps, 'showDisabledDivision', showDisabledDivision);
      }
      delete componentProps.extra;
    }
  },
  formItemBeforeHandler: (formItem) => {
    if (formItem) {
      const componentProps = formItem['x-component-props'];
      if (isPlainObject(componentProps)) {
        const { extra = {} } = componentProps;
        if (isPlainObject(extra)) {
          for (const key in extra) {
            const temV = extra[key];
            if (key && !isEmptyButNotZero(temV)) {
              if (
                key === 'needSkipLevelData' ||
                key === 'showDisabledDivision' ||
                key === 'isSearchById'
              ) {
                set(componentProps, `${key}`, temV);
              } else {
                set(componentProps, `config.${key}`, temV);
              }
            }
          }
        }
        delete componentProps.extra;
      }
    }
  },
  getPrototypeList: () => {
    return [
      // {
      //   title: '员工选择器配置',
      //   type: 'group',
      //   display: 'accordion',
      //   collapsed: true,
      //   // hidden: hidden('AddressSelect'),
      //   condition(prop){
      //     return condition(prop,'AddressSelect','componentName')
      //   },
      // },
      {
        name: 'multiple',
        title: $i18n.get({ id: 'SelectMode', dm: '选择模式' }),
        display: 'inline',
        // supportVariable: true,
        condition(prop) {
          return condition(prop, 'AddressSelect', 'componentName');
        },
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              {
                title: $i18n.get({ id: 'SingleSelection', dm: '单选' }),
                value: false,
              },
              {
                title: $i18n.get({ id: 'MultipleChoice', dm: '多选' }),
                value: true,
              },
            ],
          },
        },
      },
      {
        name: 'showTopLevel',
        title: $i18n.get({ id: 'SelectCountry', dm: '选择国家' }),
        display: 'inline',
        condition(prop) {
          return condition(prop, 'AddressSelect', 'componentName');
        },
        setter: {
          componentName: 'MixedSetter',
          props: {
            setters: [
              {
                componentName: 'RadioGroupSetter',
                title: $i18n.get({ id: 'YesOrNo', dm: '是 或 否' }),
                props: {
                  options: [
                    {
                      title: $i18n.get({ id: 'AllCountries', dm: '全部国家' }),
                      value: true,
                    },
                    {
                      title: $i18n.get({ id: 'NotSupported', dm: '不支持' }),
                      value: false,
                    },
                  ],
                },
              },
              {
                componentName: 'CnSelectSetter',
                title: $i18n.get({
                  id: 'SelectSomeCountries',
                  dm: '选择部分国家',
                }),
                props: {
                  options: countryList,
                  selectProps: {
                    mode: 'multiple',
                    showSearch: true,
                  },
                },
              },
            ],
          },
        },
      },
      {
        name: 'maxLevel',
        title: $i18n.get({ id: 'DisplayAFewLevelsAtMost', dm: '最多展示几级' }),
        display: 'inline',
        setter: {
          componentName: 'NumberSetter',
          props: {
            hasClear: true,
          },
        },
      },
      {
        name: 'useDetailValue',
        title: $i18n.get({ id: 'UseDetailedData', dm: '使用详细数据' }),
        display: 'inline',
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              { title: $i18n.get({ id: 'Yes', dm: '是' }), value: true },
              { title: $i18n.get({ id: 'No', dm: '否' }), value: false },
              { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
            ],
          },
        },
      },
      {
        name: 'showSearch',
        title: $i18n.get({ id: 'SupportSelection', dm: '支持选择' }),
        condition() {
          return false;
        },
        setter: 'BoolSetter',
      },
      {
        name: 'changeOnSelect',
        title: $i18n.get({
          id: 'SupportForSelectingNonlastLevel',
          dm: '支持选中非最后一级',
        }),
        setter: {
          componentName: 'RadioGroupSetter',
          props: {
            options: [
              { title: $i18n.get({ id: 'Yes', dm: '是' }), value: true },
              { title: $i18n.get({ id: 'No', dm: '否' }), value: false },
              { title: $i18n.get({ id: 'DefaultValue', dm: '默认值' }) },
            ],
          },
        },
      },
      {
        title: $i18n.get({ id: 'AdvancedConfiguration', dm: '高级配置' }),
        name: 'extra',
        display: 'accordion',
        extraProps: {
          defaultCollapsed: true,
        },
        setter: {
          componentName: 'ObjectSetter',
          props: {
            config: {
              items: [
                {
                  name: 'realm',
                  title: $i18n.get({ id: 'DataVersion', dm: '数据版本' }),
                  setter: 'StringSetter',
                },
                {
                  display: 'none',
                  setter: {
                    componentName: 'DocSetter',
                    props: {
                      content: (
                        <a target={'_blank'}>
                          {$i18n.get({
                            id: 'NormallyYouDoNotNeedToConfigureT_1972959641',
                            dm: '正常您不需要配置该属性，当您对数据有定制要求时请联系星马',
                          })}
                        </a>
                      ),
                    },
                  },
                },
                {
                  name: 'needSkipLevelData',
                  title: $i18n.get({
                    id: 'WhetherLevelSkippingDataIsRequir_277807833',
                    dm: '是否需要跳级数据',
                  }),
                  display: 'inline',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        {
                          title: $i18n.get({ id: 'Yes', dm: '是' }),
                          value: true,
                        },
                        {
                          title: $i18n.get({ id: 'No', dm: '否' }),
                          value: false,
                        },
                        {
                          title: $i18n.get({
                            id: 'DefaultValue',
                            dm: '默认值',
                          }),
                        },
                      ],
                    },
                  },
                },
                {
                  name: 'showDisabledDivision',
                  title: $i18n.get({
                    id: 'WhetherToDisplayDeletedDataInThe_391376902',
                    dm: '地址数据库中,是否展示已删除数据',
                  }),
                  display: 'inline',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        {
                          title: $i18n.get({ id: 'Yes', dm: '是' }),
                          value: true,
                        },
                        {
                          title: $i18n.get({ id: 'No', dm: '否' }),
                          value: false,
                        },
                        {
                          title: $i18n.get({
                            id: 'DefaultValue',
                            dm: '默认值',
                          }),
                        },
                      ],
                    },
                  },
                },
                {
                  name: 'isSearchById',
                  title: $i18n.get({ id: 'EnableIDSearch', dm: '开启ID搜索' }),
                  display: 'inline',
                  setter: 'BoolSetter',
                },
                {
                  name: 'apiModel',
                  title: 'apiModel',
                  display: 'inline',
                  setter: {
                    componentName: 'RadioGroupSetter',
                    props: {
                      options: [
                        { title: 'mtop', value: 'mtop' },
                        { title: 'cdn', value: 'cdn' },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      },
    ];
  },
};

CnAddressSelect.filterBeforeHandler = (props) => {
  const newProps = {
    ...props,
  };
  const { showTopLevel } = newProps || {};
  if (!newProps.config) {
    newProps.config = {
      apiModel: 'mtop', // 'mtop'
      businessId: 'toffee',
    };
  }
  if (showTopLevel === true) {
    // newProps.maxLevel = 5
    newProps.iso = '';
  } else if (Array.isArray(showTopLevel) && showTopLevel.length > 0) {
    newProps.iso = '';
    newProps.includeList = showTopLevel;
  }
  delete newProps.showTopLevel;

  const lang = $i18n.getLang();
  if (lang) {
    const tempLocale = locale?.[lang];
    if (tempLocale) {
      newProps.locale = tempLocale;
    }

    newProps.dataLanguage = lang;
  }

  return newProps;
};
CnAddressSelect.formBeforeHandler = CnAddressSelect.filterBeforeHandler;
CnAddressSelect.defautlProps = {
  prefix: 'cn-next-',
};

export default AddressSelect;
