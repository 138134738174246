import * as button from '@/common/manager/button';
import { ButtonPosition } from '@/common/manager/position/button-position';
import { DisplayPosition } from '@/common/manager/position/display-position';
import { ValidatorPosition } from '@/common/manager/position/validator-position';
import * as util from '@/common/util/util';
import { ParamSelectSetter } from '@/common/setter/param-select-setter';
import * as event from '@/common/manager/event';
import { registerValidator } from '@/common/manager/validator';
import * as filterItem from '@/common/manager/filter-item';
import * as commonStyle from '@/common/manager/common-style';
import { getJSExpressionPrototype } from '@/common/manager/common-style';
import * as setterSnippet from '@/common/manager/setter-snippet';
import { getSelectDialogSnippet, getSelectFilterSnippet, getSelectTableSnippet } from '@/common/manager/setter-snippet';
import { makeRequest } from '@/common/util/request';
import * as hoc from '@/common/util/hoc';
/**
 * 通用的辅助方法，供 L2 业务组件使用，挂载在 window 上
 */
window.cnLowCodeUtils = {
    /**
     * 【运行时】弹窗相关方法
     */
    dialog: {
        /**
         * 【运行时】打开弹窗
         */
        getSelectDialogSnippet,
    },
    /**
     * 表格相关方法
     */
    table: {
        /**
         * 选择表格setter
         */
        getSelectTableSnippet,
    },
    /**
     * 筛选栏相关方法
     */
    filter: {
        /**
         * 选择表格setter
         */
        getSelectFilterSnippet,
    },
    /**
     * 【运行时】表达式相关方法
     */
    expression: {
        /**
         * 【运行时】获取表达式
         */
        getJSExpressionPrototype,
    },
    ...getCommonOpenUtil(),
};
export function getCommonOpenUtil() {
    return {
        /**
         * 按钮相关方法
         */
        button,
        /**
         * 枚举
         */
        enumMap: {
            ButtonPosition,
            DisplayPosition,
            ValidatorPosition,
        },
        /**
         * 工具类
         */
        util,
        /**
         * 事件回调类
         */
        event,
        /**
         * setter类
         */
        setterMap: {
            ParamSelectSetter,
        },
        /**
         * 公共样式
         */
        commonStyle,
        /**
         * Setter片段
         */
        setterSnippet,
        /**
         * 请求相关
         */
        request: {
            makeRequest,
        },
        /**
         * hoc
         */
        hoc,
        /**
         * filterItem
         */
        filterItem: {
            ...filterItem,
            registerValidator,
        },
    };
}
