import $i18n from '@/locales/i18n';
// eslint-disable-next-line import/no-cycle
import { executeFunction, getItemListByPosition } from '../util/util';
import { getButtonListByPosition, getButtonPrototypeListByPosition } from './button';
import { ButtonPosition } from './position/button-position';
import { getActionInterventionSetter } from './setter-snippet';
const onFieldInputValueChange = {
    title: $i18n.get({
        id: 'WhenFieldInputValueChangesOnFiel_1135361265',
        dm: '当字段输入值变化时（onFieldInputValueChange）',
    }),
    value: 'onFieldInputValueChange',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
    ],
};
const onFieldInitialValueChange = {
    title: $i18n.get({
        id: 'WhenFieldInitialValueChangesOnFi_2063922085',
        dm: '当字段初始值变化时（onFieldInitialValueChange）',
    }),
    value: 'onFieldInitialValueChange',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.filterItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
    ],
};
const onFieldInit = {
    title: $i18n.get({
        id: 'FieldInitializationOnFieldInit',
        dm: '字段初始化(onFieldInit)',
    }),
    value: 'onFieldInit',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.filterItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
    ],
};
const onFieldValueChange = (config) => {
    const { position } = config || {};
    let title = $i18n.get({
        id: 'AdvancedWhenAFieldValueChangesOn_1318846130',
        dm: '【高级】当字段值变化时（onFieldValueChange）',
    });
    if (ButtonPosition.filterItemEvent === position) {
        title = $i18n.get({
            id: 'WhenFieldInputValueChangesOnFiel_1135361265',
            dm: '当字段输入值变化时（onFieldInputValueChange）',
        });
    }
    return {
        title,
        value: 'onFieldValueChange',
        position: [
            ButtonPosition.filterItemEvent,
            ButtonPosition.formItemEvent,
            ButtonPosition.formDialogItemEvent,
            ButtonPosition.cnArrayTableItemEvent,
        ],
    };
};
const onChange = (config) => {
    const { position } = config || {};
    let title = $i18n.get({
        id: 'WhenTheTabIsSwitchedOnChange',
        dm: '标签页切换时（onChange）',
    });
    if (ButtonPosition.treeEvent === position) {
        title = $i18n.get({
            id: 'WhenATreeNodeIsClickedOnChange',
            dm: '树节点点击时（onChange）',
        });
    }
    return {
        title,
        value: 'onChange',
        position: [ButtonPosition.tabEvent, ButtonPosition.treeEvent],
    };
};
const onTabDeleteItemClick = {
    title: $i18n.get({ id: 'WhenTheTabItemIsDeleted', dm: 'tab项删除时' }),
    value: 'onTabDeleteItemClick',
    position: [ButtonPosition.tabEvent],
};
const onTabAddItemClick = {
    title: $i18n.get({ id: 'WhenTabItemIsAdded', dm: 'tab项添加时' }),
    value: 'onTabAddItemClick',
    position: [ButtonPosition.tabEvent],
};
const onStepItemClick = {
    title: $i18n.get({
        id: 'WhenStepItemIsClickedOnClick',
        dm: '步骤项点击时（onClick）',
    }),
    value: 'onStepItemClick',
    position: [ButtonPosition.stepEvent],
};
const onStatisticCardClick = {
    title: $i18n.get({
        id: 'WhenTheIndicatorCardIsSelectedNe_1010387592',
        dm: '指标卡选中时（需开启选中）',
    }),
    value: 'onStatisticCardClick',
    position: [ButtonPosition.statisticCardEvent],
};
const onStatisticCardItemClick = {
    title: $i18n.get({
        id: 'WhenTheIndicatorCardIsClickedOnC_1436811683',
        dm: '指标卡点击时（onClick）',
    }),
    value: 'onStatisticCardItemClick',
    position: [ButtonPosition.statisticCardEvent],
};
const onStatisticCardRequestFinish = {
    title: $i18n.get({
        id: 'AfterTheRemoteRequestOfTheIndica_1706747244',
        dm: '指标卡远程请求完成后',
    }),
    value: 'onStatisticCardRequestFinish',
    position: [ButtonPosition.statisticCardEvent],
};
const onTabRequestFinish = {
    title: $i18n.get({
        id: 'WhenTheRemoteRequestLoadComplete_71326794',
        dm: '远程请求加载完成时',
    }),
    value: 'onTabRequestFinish',
    position: [ButtonPosition.tabEvent],
};
const onSearch = {
    title: $i18n.get({ id: 'WhenTheQueryButtonIsClicked', dm: '点击查询按钮时' }),
    value: 'onSearch',
    position: [ButtonPosition.filterEvent],
};
const onDefaultValueFinished = {
    title: $i18n.get({ id: 'AfterSettingDefaultValues', dm: '设置默认值完成后' }),
    value: 'onDefaultValueFinished',
    position: [ButtonPosition.filterEvent, ButtonPosition.formEvent],
};
const onFormValueRequestFinished = {
    title: $i18n.get({ id: 'AfterASuccessfulFormRequest', dm: '表单请求成功后' }),
    value: 'onFormValueRequestFinished',
    position: [ButtonPosition.filterEvent, ButtonPosition.formEvent],
};
const onFormValueRequestError = {
    title: $i18n.get({ id: 'AfterAFormRequestFails', dm: '表单请求失败后' }),
    value: 'onFormValueRequestError',
    position: [ButtonPosition.filterEvent, ButtonPosition.formEvent],
};
const onBlur = {
    title: $i18n.get({
        id: 'WhenTheInputBoxLosesFocusOnBlur',
        dm: '输入框失去焦点时（onBlur）',
    }),
    value: 'onBlur',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
        ButtonPosition.cnArraySubAreaCardItemEvent,
        ButtonPosition.filterItemEvent,
    ],
};
const onFieldReact = {
    title: $i18n.get({
        id: 'DynamicallyCalculateTheValueOfTh_1892334205',
        dm: '动态计算本字段的值（onFieldReact）',
    }),
    value: 'onFieldReact',
    position: [
        ButtonPosition.formItemEvent,
        ButtonPosition.formDialogItemEvent,
        ButtonPosition.cnArrayTableItemEvent,
    ],
};
const onDrop = {
    title: $i18n.get({
        id: 'AfterTheDragIsCompletedOnDrop',
        dm: '拖拽完成后（onDrop）',
    }),
    value: 'onDrop',
    position: [ButtonPosition.treeEvent],
};
const onFormStepItemClick = {
    title: $i18n.get({ id: 'WhenStepIsClicked', dm: '步骤点击时' }),
    value: 'onFormStepItemClick',
    position: [ButtonPosition.formEvent],
};
const onTablePageChange = {
    title: $i18n.get({ id: 'WhenTablePagingIsSwitched', dm: '表格分页切换时' }),
    value: 'onTablePageChange',
    position: [ButtonPosition.tableEvent],
};
const onTableRemoteDataChange = {
    title: $i18n.get({
        id: 'FormRemoteRequestSuccessful',
        dm: '表格远程请求成功',
    }),
    value: 'onTableRemoteDataChange',
    position: [ButtonPosition.tableEvent],
};
const onTableRemoteError = {
    title: $i18n.get({
        id: 'AfterAFormRequestFails.Kn6Th',
        dm: '表格请求失败后',
    }),
    value: 'onTableRemoteError',
    position: [ButtonPosition.tableEvent],
};
const onEntryItemClick = {
    title: $i18n.get({ id: 'AfterTheEntranceClick', dm: '入口点击后' }),
    value: 'onEntryItemClick',
    position: [ButtonPosition.entryPointEvent],
};
const onListItemClick = {
    title: $i18n.get({ id: 'AfterTheListItemIsClicked', dm: '列表项点击后' }),
    value: 'onListItemClick',
    position: [ButtonPosition.listItemEvent],
};
const onListProItemClick = {
    title: $i18n.get({ id: 'ListItemProAfterClick', dm: '列表项（Pro）点击后' }),
    value: 'onListProItemClick',
    position: [ButtonPosition.listProItemEvent],
};
const onListProDefaultSelectFinished = {
    title: $i18n.get({ id: 'ListProAfterDefaultSelected', dm: '列表（Pro）默认选中后' }),
    value: 'onListProDefaultSelectFinished',
    position: [ButtonPosition.listProItemEvent],
};
const onListOnChange = {
    title: $i18n.get({
        id: 'WhenTheSelectedItemInTheListChan_906453333',
        dm: '列表选中项改变时（onChange 需开启选中模式）',
    }),
    value: 'onListActivityKeyChange',
    position: [ButtonPosition.listItemEvent],
};
const onDialogClose = {
    title: $i18n.get({ id: 'WhenThePopupWindowIsClosed', dm: '弹窗关闭时' }),
    value: 'onDialogClose',
    position: [ButtonPosition.dialogEvent, ButtonPosition.formEvent],
};
const onCnFormatClick = {
    title: $i18n.get({ id: 'AfterContentClick', dm: '内容点击后' }),
    value: 'onCnFormatClick',
    position: [ButtonPosition.cnFormatEvent],
};
export const componentMap = {
    onFieldInputValueChange,
    onChange,
    onTabRequestFinish,
    onSearch,
    onBlur,
    onStepItemClick,
    onFieldReact,
    onDrop,
    onFormStepItemClick,
    onDefaultValueFinished,
    onFormValueRequestFinished,
    onFormValueRequestError,
    onFieldInitialValueChange,
    onFieldInit,
    onTablePageChange,
    onTableRemoteDataChange,
    onTableRemoteError,
    onStatisticCardClick,
    onStatisticCardRequestFinish,
    onTabDeleteItemClick,
    onTabAddItemClick,
    onEntryItemClick,
    onListItemClick,
    onListProItemClick,
    onDialogClose,
    onStatisticCardItemClick,
    onListOnChange,
    onListProDefaultSelectFinished,
    onFieldValueChange,
    onCnFormatClick,
};
export function createEventSetters(config = {}) {
    const { position, initialValue, title, handleList, extraConfig } = config;
    let eventList = getItemListByPosition({
        position,
        componentMap,
    });
    if (typeof handleList === 'function') {
        eventList = executeFunction(handleList, eventList);
    }
    if (eventList?.length === 0) {
        return [];
    }
    return [
        {
            name: 'events',
            title: title ||
                $i18n.get({
                    id: 'ConfigurationFieldCallbackEvents',
                    dm: '配置字段的回调事件',
                }),
            display: 'accordion',
            collapsed: true,
            setter: {
                componentName: 'ArraySetter',
                props: {
                    mode: 'list',
                    itemSetter: {
                        componentName: 'ObjectSetter',
                        initialValue: initialValue || {
                            optType: 'flowAction',
                        },
                        props: (field) => {
                            const configure = [
                                ...getButtonPrototypeListByPosition(position, {
                                    __field: field,
                                }),
                            ];
                            let defaultButtonList = [
                                {
                                    label: $i18n.get({
                                        id: 'ActionChoreography',
                                        dm: '动作编排',
                                    }),
                                    value: 'flowAction',
                                },
                                {
                                    label: $i18n.get({
                                        id: 'JsScriptActionChoreographyIsReco_1431009217',
                                        dm: 'js脚本（建议使用动作编排）',
                                    }),
                                    value: 'jsAction',
                                },
                            ];
                            if ([
                                ButtonPosition.filterItemEvent,
                                ButtonPosition.formItemEvent,
                                ButtonPosition.formDialogItemEvent,
                                ButtonPosition.tabEvent,
                                ButtonPosition.filterEvent,
                                ButtonPosition.treeEvent,
                                ButtonPosition.cnArraySubAreaCardItemEvent,
                                ButtonPosition.treeEvent,
                                ButtonPosition.formEvent,
                                ButtonPosition.statisticCardEvent,
                                ButtonPosition.entryPointEvent,
                                ButtonPosition.listItemEvent,
                                ButtonPosition.listProItemEvent,
                                ButtonPosition.dialogEvent,
                                ButtonPosition.cnFormatEvent,
                            ].includes(position)) {
                                const buttonList = getButtonListByPosition(position);
                                if (Array.isArray(buttonList) && buttonList.length > 0) {
                                    const extraButtonList = buttonList
                                        .filter((item) => {
                                        return item?.value !== 'jsAction' && item?.value !== 'flowAction';
                                    })
                                        .map((item) => ({ label: item.title, value: item.value }));
                                    defaultButtonList = [...extraButtonList, ...defaultButtonList];
                                }
                            }
                            if (Array.isArray(configure)) {
                                configure.forEach((item) => {
                                    if (item) {
                                        item.condition = function (prop) {
                                            if (item?.__optType) {
                                                const tempResult = item?.__optType === prop?.parent?.getValue?.()?.optType;
                                                // FIXME：此处用originalCondition覆盖了condition，会导致一级子setter的condition失效
                                                if (typeof item?.originalCondition === 'function') {
                                                    return tempResult && item?.originalCondition(prop);
                                                }
                                                return tempResult;
                                            }
                                        };
                                    }
                                });
                            }
                            return {
                                config: {
                                    items: [
                                        {
                                            name: 'name',
                                            display: 'inline',
                                            title: $i18n.get({ id: 'EventType', dm: '事件类型' }),
                                            isRequired: true,
                                            setter: {
                                                componentName: 'SelectSetter',
                                                props: {
                                                    options: eventList,
                                                },
                                            },
                                        },
                                        {
                                            name: 'optType',
                                            title: $i18n.get({ id: 'ActionType', dm: '动作类型' }),
                                            isRequired: true,
                                            setter: {
                                                componentName: 'CnSelectSetter',
                                                props: {
                                                    options: defaultButtonList,
                                                    selectProps: {
                                                        showSearch: true,
                                                    },
                                                },
                                            },
                                        },
                                        ...getActionInterventionSetter(),
                                        ...configure,
                                    ],
                                },
                            };
                        },
                    },
                },
            },
            ...extraConfig,
        },
    ];
}
// 注册事件
export function registerEvent(name, event) {
    if (name && event) {
        componentMap[name] = event;
    }
}
